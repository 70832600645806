import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import './style.css';

function Success() {
  return (
    <Translate>
      {({translate}) => (
        <div className='registration-triple-success'>
          <p>
            {translate('Cooperation with the SGP exchange')}
          </p>
          <p>
            {translate('After completing the card shipping')}
          </p>
        </div> 
      )}
    </Translate>
  )
}

export default Success;
