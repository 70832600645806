import React, {Component} from "react";
import Search from "../../../../components/Search";
import {FormGroup, Input, Table} from "reactstrap";
import {Link} from "react-router-dom";
import "./style.css";

export default class Cards extends Component {
    renderHeaderTable() {
        return (
            <tr>
                <th></th>
                <th>GREEN BOX ID</th>
                <th>NAME</th>
                <th>メールアドレス</th>
                <th>CARD QUANTITY</th>
                <th>APPLICATION DATE</th>
            </tr>
        )
    }

    renContentTable() {
        return (
            <tr>
                <td>1</td>
                <td><Link to="/admin/card-issuer/detail">xxx</Link></td>
                <td><Link to="/admin/card-issuer/detail">Minh Nguyen</Link></td>
                <td>minnv@bap.jp</td>
                <td>2</td>
                <td>21/7/2018</td>
            </tr>
        )
    }

    renderTable() {
        return (
            <Table bordered>
                <thead>
                {this.renderHeaderTable()}
                </thead>
                <tbody>
                {this.renContentTable()}
                </tbody>
            </Table>
        )
    }

    render() {
        return (
            <div className="card-issuer">
                <div className="bread-crumb d-flex">
                    <div className="title">Card issuer</div>
                </div>
                <div className="d-flex justify-content-between mt-4">
                    <FormGroup>
                        <Input type="select" name="select" id="exampleSelect" className="h-40">
                            <option>Agency code</option>
                        </Input>
                    </FormGroup>
                    <Search placeholder="ID, NAME, EMAIL"/>
                </div>
                {this.renderTable()}
            </div>
        )
    }
}