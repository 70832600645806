import { Form, message } from 'antd';
import { Formik } from 'formik';
import * as moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import * as Yup from 'yup';
import actions, {
  ACTIONS
} from '../../../../../../actions/registrationTrippleActions';
import {
  Button,
  Checkbox,
  FieldItem,
  Input
} from '../../../../../../components/antd';
import { convertBase64toFile, applicationInfoOnLocal } from '../../../../../../utils/commonUtils';
import { countryUsing } from "../../../../../../constants/commonData";
import './style.css';

const MESSAGE = {
  email: 'Email invalid',
  plus: 'Value must not contain + character',
  required: 'Value is required',
  match: 'Passwords must match',
  formatPass: 'Password must at least 1 uppercase, 1 numeric, 1 lowercase',
  special: 'Password must not contain special charactors',
  min: 'Password must be at least ${min} characters'
};

const validationSchema = newSgp => {
  const newValiate = {
    email: Yup.string()
      .trim()
      .email(MESSAGE.email)
      .required(MESSAGE.required)
      .matches(/^[^+]+$/, { message: MESSAGE.plus }),
    password: Yup.string()
      .required(MESSAGE.required)
      .min(6, MESSAGE.min)
      // .matches(/^([a-zA-Z0-9]+)$/, { message: MESSAGE.special })
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/, {
        message: MESSAGE.formatPass
      })
  };
  if (newSgp) {
    newValiate.passwordConfirm = Yup.string()
      .required(MESSAGE.required)
      .oneOf([Yup.ref('password'), null], MESSAGE.match);
  }
  return Yup.object().shape(newValiate);
};

const SGP_VALUES = { newSGP: 'newSGP', alreadySGP: 'alreadySGP ' };

class RegistrationStep8 extends Component {
  constructor (props) {
    super(props);
    const { registerTrippleStore : { businessMemberId, tempData } } = props;
    const sgpValue = tempData && tempData.sgpValue ? tempData.sgpValue : null;
    this.application = applicationInfoOnLocal(businessMemberId);
    this.state = {
      newSGP: sgpValue === SGP_VALUES.newSGP,
      alreadySGP: sgpValue === SGP_VALUES.alreadySGP,
      sgpValue,
      error: false
    };
  }

  handleChangeCheckNewSGP = e => {
    const { checked } = e.target;
    const newState = { newSGP: checked, error: false };
    if (checked) {
      newState.alreadySGP = false;
    }
    this.setState(newState);
  };

  handleChangeCheckAlreadySGP = e => {
    const { checked, name } = e.target;
    const newState = { alreadySGP: checked, error: false };
    if (checked) {
      newState.newSGP = false;
    }
    this.setState(newState);
  };

  handleChooseSGPValue = e => {
    const { newSGP, alreadySGP } = this.state;
    const { changeTempDataTriple } = this.props;
    if (!newSGP && !alreadySGP) {
      this.setState({ error: true });
      return;
    }
    const sgpValue = newSGP ? SGP_VALUES.newSGP : SGP_VALUES.alreadySGP;
    changeTempDataTriple({ sgpValue })
    this.setState({ sgpValue, error: false });
  };

  handleClickBack = e => {
    this.setState({ sgpValue: null });
  };

  _renderForm = ({ handleSubmit, ...form }) => {
    const {
      registerTrippleStore: { type }
    } = this.props;
    const { newSGP, alreadySGP, sgpValue, error } = this.state;
    const isNewSGP = sgpValue === SGP_VALUES.newSGP;

    if (!sgpValue) {
      return (
        <Translate>
          {({translate}) => (
            <div className="list-check-container">
              <p className="sub-title">{translate('When applying for a card SGP account')}</p>
              <p className="sub-title">{translate('Ownership is a prerequisite')}.</p>
              <p className="sub-title">
                {translate('Check one of the items below and go to the next')}.
              </p>
              {error && <p className="error">{translate('Must choose 1 option to continue')}</p>}
              <ul className="list-check">
                <li>
                  <Checkbox
                    name="newSGP"
                    position="right"
                    fullWidth
                    checked={newSGP}
                    onChange={this.handleChangeCheckNewSGP}
                  >
                    {translate('Create a new SGP account upon completing card application')}
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    name="alreadySGP"
                    position="right"
                    fullWidth
                    checked={alreadySGP}
                    onChange={this.handleChangeCheckAlreadySGP}
                  >
                    {translate('Have already SGP account')}
                  </Checkbox>
                </li>
              </ul>
              <div className="group-button-submit">
                <Button
                  onClick={this.handleChooseSGPValue}
                  className="submit"
                >
                  {translate('Next')}
                </Button>
              </div>
            </div>
          )}
        </Translate>
      );
    }

    const disabled = [
      ACTIONS.LOGIN_TO_SGP_REQUEST,
      ACTIONS.CREATE_ACCOUNT_SGP_REQUEST,
      ACTIONS.CREATE_APPLICATION_TRIPLE_REQUEST,
    ].includes(type);

    return (
      <Translate>
        {({translate}) => (
          <Form className="form" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }}>
            <p className="sub-title">
              {translate(isNewSGP ? 'Register a new SGP account' : 'Current SGP account ID')}
            </p>
            <FieldItem
              form={form}
              component={Input}
              name="email"
              label="Email"
              required
              disabled={disabled}
            />
            <FieldItem
              form={form}
              component={Input}
              name="password"
              label="Password"
              type="password"
              required
              disabled={disabled}
            />
            {isNewSGP && (
              <FieldItem
                form={form}
                component={Input}
                name="passwordConfirm"
                label="Re-enter password"
                type="password"
                required
                disabled={disabled}
              />
            )}
            <div className="group-button-submit">
              <Button
                type="delete"
                onClick={this.handleClickBack}
                className="submit"
                disabled={disabled}
              >
                {translate('Back')}
              </Button>
              <Button
                htmlType="submit"
                onClick={handleSubmit}
                className="submit"
                loading={disabled}
              >
                {translate('Next')}
              </Button>
            </div>
          </Form>
        )}
      </Translate>
    );
  };

  _onSubmit = values => {
    const {
      nextStep,
      createAccountSGP,
      loginToSGP,
      registerApplication,
      changeTempDataTriple
    } = this.props;
    const { sgpValue } = this.state;
    const application = this.application;
    const { businessId } = this.props;
    if (!application) {
      return;
    }
    const payload = {
      email: values.email.trim(),
      password: values.password,
      phoneNumber: application.phoneNumber
    };
    const tempAccountSGP = {
      email: payload.email,
      password: payload.password,
      passwordConfirm: payload.password
    };
    if (sgpValue === SGP_VALUES.newSGP) {
      changeTempDataTriple({ accountSGP: tempAccountSGP });
      createAccountSGP(payload, (action, data) => {
        if (action === ACTIONS.CREATE_ACCOUNT_SGP_SUCCESS) {
          nextStep({
            email: payload.email,
            tokenSGP: data.token
          });
        }
      });
    } else {
      delete tempAccountSGP.passwordConfirm;
      changeTempDataTriple({ accountSGP: tempAccountSGP });
      loginToSGP(payload, async (action, data) => {
        if (action === ACTIONS.LOGIN_TO_SGP_SUCCESS) {
          let {
            imageFont,
            imageBack,
            imageSelfie,
            imageBankStatement,
            imageSign
          } = this.props.registerTrippleStore;

          const params = {
            ...application,
            imageFont,
            imageBack,
            imageSelfie,
            imageBankStatement,
            imageSign,
            email: payload.email,
            passwordSGP: payload.password,
            gender: application.gender === 'male',
            birthday: application.birthday.format('YYYY-M-D'),
            expiredDate: application.expiredDate === '' ? '' : moment(application.expiredDate).format('YYYY-M-D'),
            issueDate: moment(application.issueDate).format('YYYY-M-D'),
            businessId
          }

          delete params.tokenSGP;
          if(!countryUsing.identityCard.includes(application.nationality))
            delete params.imageBack;
          
          registerApplication(params);
        }
      });
    }
  };

  render() {
    const { registerTrippleStore : { tempData } } = this.props;
    const { sgpValue } = this.state;
    
    const initialValues = tempData.accountSGP ? {
      email: tempData.accountSGP.email,
      password: tempData.accountSGP.password,
      passwordConfirm: tempData.accountSGP.passwordConfirm,
    } : {};

    return (
      <div className="registration-triple-step8">
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={this._onSubmit}
          component={this._renderForm}
          validationSchema={validationSchema(sgpValue === SGP_VALUES.newSGP)}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    registerTrippleStore: state.registrationTripple,
    registrationStore: state.registration
  }),
  {
    createAccountSGP: actions.createAccountSGP,
    loginToSGP: actions.loginToSGP,
    registerApplication: actions.registerApplication,
    changeTempDataTriple: actions.changeTempDataTriple,
  }
)(RegistrationStep8);
