import {allCountries} from './utils/commonUtils';

const ENV = {
    LCL: {
        API_SERVER: 'https://dev-api.ezpay-card.com',
        SUPPORT_EMAIL: 'admin@localhost',
        ALLOWED_ADDRESS_COUNTRIES: allCountries,
        ALLOWED_PASSPORT_COUNTRIES: allCountries,
        API_SGP: 'https://dev-api.exchange-sgp.com',
        DOMAIN_EZP: 'https://dev.ezpay-card.com',
    },

    DEV: {
        API_SERVER: 'https://dev-api.ezpay-card.com',
        SUPPORT_EMAIL: 'support@dev.ezpay-card.com',
        ALLOWED_ADDRESS_COUNTRIES: allCountries,
        ALLOWED_PASSPORT_COUNTRIES: allCountries,
        API_SGP: 'https://dev-api.exchange-sgp.com',
        DOMAIN_EZP: 'https://dev.ezpay-card.com',
    },

    STG: {
        API_SERVER: 'https://stg-api.ezpay-card.com',
        SUPPORT_EMAIL: 'support@dev.ezpay-card.com',
        ALLOWED_ADDRESS_COUNTRIES: allCountries,
        ALLOWED_PASSPORT_COUNTRIES: allCountries,
        API_SGP: 'https://stg-api.exchange-sgp.com',
        DOMAIN_EZP: 'https://stg.ezpay-card.com',
    },

    PRO: {
        API_SERVER: 'https://api.ezpay-card.com',
        SUPPORT_EMAIL: 'support@ezpay-card.com',
        ALLOWED_ADDRESS_COUNTRIES: allCountries,
        ALLOWED_PASSPORT_COUNTRIES: allCountries,
        API_SGP: '',
        DOMAIN_EZP: 'https://ezpay-card.com',
    }
};

const getEnv = (name, defaultValue) => {
    return process.env[name] || ENV[process.env.REACT_APP_STAGE || "LCL"][name] || defaultValue;
};

export {getEnv};
