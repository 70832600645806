import React, { Component } from 'react';
import './style.css';
import { Table } from 'reactstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { formatNumber } from '../../utils/commonUtils';
// import { Button } from '../../components/antd';

export default class TableStatistic extends Component {
  renderContentTable(statistic) {
    if (statistic && statistic.length) {
      return statistic.map((data, index) => {
        return (
          <tr key={index}>
            {index === 0 ? <td>全期間</td> : <td>{index + ' 月'}</td>}
            <td className="font-weight-bold">{formatNumber(data.sumGbt)}</td>
            <td>{formatNumber(data.sumGbtOneCard)}</td>
            <td>{formatNumber(data.sumGbtTwoCard)}</td>
            <td>{formatNumber(data.sumGbtFree)}</td>
            <td>{formatNumber(data.sumUser)}</td>
            <td>{formatNumber(data.sumUserOneCard)}</td>
            <td>{formatNumber(data.sumUserTwoCard)}</td>
            <td>{formatNumber(data.sumUserFree)}</td>
            <td>{formatNumber(data.sumCard)}</td>
            <td>{formatNumber(data.sumCardOneCard)}</td>
            <td>{formatNumber(data.sumCardTwoCard)}</td>
            <td>{formatNumber(data.sumCardFree)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={13}>
            <div className="d-flex justify-content-center">
              <span>表示するデータがありません</span>
            </div>
          </td>
        </tr>
      );
    }
  }

  render() {
    const { title, data } = this.props;
    let name = '';
    switch (title) {
      case 0:
        name = '申込全て';
        break;
      case 1:
        name = '送付前';
        break;
      case 2:
        name = '送付完了';
        break;
      case 3:
        name = 'キャンセル';
        break;
      default:
        break;
    }
    return (
      <React.Fragment>
        <Table
          id={title + '_table_download'}
          className="component-table-statistic"
          bordered
          responsive
          key={title}
        >
          <thead>
            <tr>
              <td rowSpan="3" />
              <td colSpan="12">{name}</td>
            </tr>
            <tr>
              <td colSpan="4">GBT</td>
              <td colSpan="4">件数</td>
              <td colSpan="4">カード枚数</td>
            </tr>
            <tr>
              <td>計</td>
              <td>
                1枚申込
                <br />
                <br /> 110 GBT
              </td>
              <td>
                2枚申込
                <br />
                <br /> 180 GBT
              </td>
              <td>Free申込</td>
              <td>計</td>
              <td>
                1枚申込
                <br />
                <br /> 110 GBT
              </td>
              <td>
                2枚申込
                <br />
                <br /> 180 GBT
              </td>
              <td>Free申込</td>
              <td>計</td>
              <td>
                1枚申込
                <br />
                <br /> 110 GBT
              </td>
              <td>
                2枚申込
                <br />
                <br /> 180 GBT
              </td>
              <td>Free申込</td>
            </tr>
          </thead>
          <tbody>{this.renderContentTable(data)}</tbody>
        </Table>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button mb-5"
          table={title + '_table_download'}
          filename={name}
          sheet={name}
          buttonText="Download as XLS"
        />
      </React.Fragment>
    );
  }
}
