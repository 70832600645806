
import React, { Component } from "react";
import "./style.css";

export default class CardSettings extends Component {
    render() {
        return (
            <div className="red-warning">WAITING FOR DETAIL 権限設定</div>
        )
    }
}