import React, { Component } from "react";
import { Form, FormGroup, Label, Col } from "reactstrap";
import { sendEmailPaymentAgain } from "../../../../actions/adminActions";
import { connect } from "react-redux";
import "./style.css";
import { STATUS } from "../../../../reducers/adminReducer";
import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css";
import { Translate } from "react-localize-redux";
import Alert from "../../../../components/Alert";
import { Button } from "../../../../components/antd";
import { PERMISSION } from "../../../../utils/commonUtils";

class SendMailToEachUser extends Component {
  constructor() {
    super();
    this.state = {
      isOpenConfirm: false,
      emails: [],
      alertAck: true
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  handleChange = data => {
    if (Array.isArray(data) && data.length > 0 && data[0].indexOf(",") > -1) {
      this.setState({ emails: data[0].split(",") });
    } else {
      this.setState({
        emails: data
      });
    }
  };

  render() {
    const loading =
      this.props.admin.status === STATUS.SEND_MAIL_PAYMENT_PROGRESS;
    const { listPermission } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <div className="system-send-mail-each-user">
            <div className="title"> 支払うメールを再送する</div>
            <Form className="mt-5 mb-5">
              <FormGroup row>
                <Label for="emails" sm={2} className="lbl-right">
                  に:
                </Label>
                <Col sm={8}>
                  <TagsInput
                    value={this.state.emails}
                    onChange={this.handleChange}
                    inputProps={{
                      placeholder: "問い合わせ番号"
                    }}
                    addOnBlur={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={10}>
                  {listPermission.includes(
                      PERMISSION.MAIL_SERVICE
                    ) && (
                      <Button
                        className="btn-send"
                        disabled={loading || !this.state.emails}
                        onClick={() => {
                          this.setState({ isOpenConfirm: true });
                        }}
                        variant="contained"
                        loading={loading}
                      >
                        送る
                      </Button>
                    )}
                </Col>
              </FormGroup>
            </Form>
            {this.props.admin.status === STATUS.SEND_MAIL_PAYMENT_SUCCESS && (
              <div className="mt-5 send-mail-success">
                メールを送信しました。
                {this.props.admin.data &&
                  this.props.admin.data.sendMailFail &&
                  this.props.admin.data.sendMailFail.length !== 0 && (
                    <div className="mt-3 email-error">
                      下記で正しくないものがあります :
                      {this.props.admin.data.sendMailFail.join(", ")}
                    </div>
                  )}
              </div>
            )}
            {this.state.isOpenConfirm && (
              <Alert
                title="確認ダイアログ"
                content="メールを送りますか？"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ isOpenConfirm: false });
                  this.alertAck();
                  this.props.handleSendMail({
                    data: this.state.emails
                  });
                }}
                onCancel={() => {
                  this.setState({ isOpenConfirm: false });
                }}
              />
            )}
            {this.props.admin.status === STATUS.SEND_MAIL_PAYMENT_FAIL &&
              !this.state.alertAck && (
                <Alert
                  title="Error"
                  content={translate(
                    this.props.error && "errors." + this.props.error.code
                  )}
                  okTitle="OK"
                  onOK={() => {
                    this.setState({ alertAck: true });
                  }}
                />
              )}
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    admin: state.admin,
    error: state.admin.error,
    listPermission: state.admin.listPermission
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSendMail: data => {
      dispatch(sendEmailPaymentAgain(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMailToEachUser);
