import React, { Component } from "react";
import { Form, FormGroup, Label, Col, Input } from "reactstrap";

export default class AgencyFom extends Component {
    render() {
        return (
            <Form>
                <FormGroup row>
                    <FormGroup className="row col-6">
                        <Label for="agencyCode" sm={4}>Agency code</Label>
                        <Col sm={8}>
                            <Input type="text" name="agencyCode" id="agencyCode" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row col-6">
                        <Label for="agencyAddress" sm={4}>Agency address</Label>
                        <Col sm={8}>
                            <Input type="text" name="agencyAddress" id="agencyAddress" />
                        </Col>
                    </FormGroup>
                </FormGroup>
                <FormGroup row>
                    <FormGroup className="row col-6">
                        <Label for="agencyName" sm={4}>Agency name</Label>
                        <Col sm={8}>
                            <Input type="text" name="agencyName" id="agencyName" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row col-6">
                        <Label for="agencyNotes" sm={4}>Notes</Label>
                        <Col sm={8}>
                            <Input type="textarea" name="agencyNotes" id="agencyNotes" />
                        </Col>
                    </FormGroup>
                </FormGroup>
                <FormGroup row>
                    <FormGroup className="row col-6">
                        <Label for="responsibleParty" sm={4}>Responsible party</Label>
                        <Col sm={8}>
                            <Input type="text" name="responsibleParty" id="responsibleParty" />
                        </Col>
                    </FormGroup>
                </FormGroup>
                <FormGroup row>
                    <FormGroup className="row col-6">
                        <Label for="email" sm={4}>Email</Label>
                        <Col sm={8}>
                            <Input type="email" name="email" id="email" />
                        </Col>
                    </FormGroup>
                </FormGroup>
                <FormGroup row>
                    <FormGroup className="row col-6">
                        <Label for="phoneNumber" sm={4}>Phone number</Label>
                        <Col sm={8}>
                            <Input type="number" name="phoneNumber" id="phoneNumber" />
                        </Col>
                    </FormGroup>
                </FormGroup>
                <FormGroup row>
                    <FormGroup className="row col-6">
                        <Label for="cardName" sm={4}>Card name</Label>
                        <Col sm={8}>
                            <Input type="text" name="cardName" id="cardName" />
                        </Col>
                    </FormGroup>
                </FormGroup>
                <FormGroup row>
                    <FormGroup className="row col-6">
                        <Label for="cardCurrency" sm={4}>Card currency</Label>
                        <Col sm={8}>
                            <Input type="text" name="cardCurrency" id="cardCurrency" />
                        </Col>
                    </FormGroup>
                </FormGroup>
                <FormGroup row>
                    <FormGroup className="row col-6">
                        <Label for="posFee" sm={4}>POS fee</Label>
                        <Col sm={4}>
                            <Input type="text" name="posFee" id="posFee" />
                        </Col>
                        <Label for="posFee" sm={1}>%</Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup row>
                    <FormGroup className="row col-6">
                        <Label for="ATMFee" sm={4}>ATM fee</Label>
                        <Col sm={4}>
                            <Input type="text" name="ATMFee" id="ATMFee" />
                        </Col>
                        <Label for="posFee" sm={1}>%</Label>
                    </FormGroup>
                </FormGroup>
            </Form>
        )
    }
} 