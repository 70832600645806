import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "querystring";
import {Translate} from "react-localize-redux";
import {
    STATUS,
    updateWrongUserInfo,
} from "../../../../actions/registrationActions";
import "./style.css";
import {TYPE_SEND_MAIL, Validation} from "../../../../utils/commonUtils"
import {saveUserRegisterToken} from "../../../../utils/apiUtils";
import {Button, CircularProgress} from "@material-ui/core";
import RegisterForm from "../Form";
import Alert from "../../../../components/Alert";

const Compress = require('compress.js')
const compress = new Compress()

class Passport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passportImage: null,
            passportImageNew: null,
            alertAck: false,
            purchaseId: null,
            typeMail: null,
            businessId: null,
            typeImage: null
        };
        let query = props.location.search;
        if (query.indexOf("?") >= 0) query = query.substr(query.indexOf("?") + 1);
        let parsed = queryString.parse(query);
        if (parsed["purchase_id"]) {
            const token = parsed["token"];
            this.state.purchaseId = parsed['purchase_id'];
            saveUserRegisterToken({token: token});
        }
        this.state.businessId = parsed['businessId'];
        this.state.typeImage = parsed['type'];
    }

    handleChange(name) {
        return (e) => {
            if (e.target) {
                this.setState({[name]: e.target.value});
            }
            if (e.target.files) {
                const files = [...e.target.files];
                let uploadableFiles = [];
                console.log(e.target)
                compress.compress([...e.target.files], {
                    quality: 1, // the quality of the image, max is 1,
                }).then(async (modFiles) => {
                    for (let i = modFiles.length - 1; i >= 0; i--) {
                        let newFile = Compress.convertBase64ToFile(modFiles[i].data);
                        let filename = Date.now() + modFiles[i].alt;
                        let fileType = files[0].type;
                        let fileLastMod = files[i].lastModified;

                        uploadableFiles.push(new File([newFile], filename, {type: fileType, lastModified: fileLastMod}));

                        this.setState({
                            [name+'New']: uploadableFiles[0],
                            [name]: files && files.length ? files[0] : null
                        });
                    }
                }).catch(async () => {
                    this.setState({[name]: files && files.length ? files[0] : null});
                })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.stepData !== nextProps.stepData) {
            this.setState({stepData: nextProps.stepData});
        }
    }

    render() {
        const type = Number(this.state.typeImage) === 1 ? TYPE_SEND_MAIL.FUZZY_PASSPORT : Number(this.state.typeImage) === 5 ? TYPE_SEND_MAIL.FUZZY_SELFIE : TYPE_SEND_MAIL.WRONG_ADDRESS_DOC;
        return (
            <Translate>
                {({translate}) => (
                    <RegisterForm>
                        <div className="passport-update">
                            <div className="content">
                                <div className="text-danger text-center">
                                    Required to have more than seven months' validity remaining on your
                                    KYC.
                                </div>
                                <div className="text-danger text-center">
                                    Please use a KYC with information identical to the information
                                    you have registered
                                </div>
                                <div className="row">

                                    <div className="col-12 col-sm-4">
                                        <div className="content-title">
                                            <label htmlFor="other">KYC image upload </label><br></br>
                                            <label>({
                                                type.substr(6).toUpperCase() === 'ADDRESS DOC' 
                                                ? 
                                                    'BANK STATEMENT' 
                                                : 
                                                    type.substr(6).toUpperCase() === 'PASSPORT' 
                                                    ?
                                                        'KYC FONT'
                                                    :
                                                        type.substr(6).toUpperCase()
                                            })</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        { this.state.businessId === '01' ? 
                                        <>
                                            <div className="content-value">
                                                <input
                                                    accept="image/*"
                                                    multiple={false}
                                                    required={true}
                                                    placeholder="Please upload image"
                                                    onChange={this.handleChange("passportImage")}
                                                    className="upload-image"
                                                    type="file"
                                                    name="fileToUpload"
                                                    ref="fileInput"
                                                    id="fileToUpload"/>
                                                <div>
                                                    Required size: 300Kb ~ 4Mb
                                                </div>
                                                {this.state.passportImage && Validation.validateUploadFileTripleSize(this.state.passportImage) !== 0 &&
                                                <div className="text-danger">
                                                    File size is not valid
                                                    (currently {Math.round(this.state.passportImage.size / 1024)} Kb)
                                                </div>
                                                }
                                                {this.state.passportImage && Validation.validateUploadFileType(this.state.passportImage) !== 0 &&
                                                <div className="text-danger">
                                                    File type is not valid
                                                </div>
                                                }
                                            </div>
                                       
                                            <div className="content-value mt-2">
                                                <Button
                                                    onClick={() => {
                                                        this.setState({alertAck: false});
                                                        this.props.updatePassport({
                                                            type,
                                                            applicationId: this.state.purchaseId,
                                                            passportImage: this.state.passportImageNew
                                                        });
                                                    }}
                                                    disabled={
                                                        !this.state.passportImage || 
                                                        Validation.validateUploadFileTripleSize(this.state.passportImage) !== 0 || 
                                                        Validation.validateUploadFileType(this.state.passportImage) !== 0 || 
                                                        this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS
                                                    }
                                                    variant="contained"
                                                    color="primary">
                                                    {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS && (
                                                        <CircularProgress className="margin-right-20px" size={20}/>
                                                    )}
                                                    <span>Update KYC</span>
                                                </Button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="content-value">
                                                <input
                                                    accept="image/jpg, image/JPG, image/JPEG, image/jpeg"
                                                    multiple={false}
                                                    required={true}
                                                    placeholder="Please upload image"
                                                    onChange={this.handleChange("passportImage")}
                                                    className="upload-image"
                                                    type="file"
                                                    name="fileToUpload"
                                                    ref="fileInput"
                                                    id="fileToUpload"/>
                                                <div>
                                                    Required size: 300Kb ~ 4Mb
                                                </div>
                                                {this.state.passportImage && Validation.validateUploadFileTripleSize(this.state.passportImage) !== 0 &&
                                                <div className="text-danger">
                                                    File size is not valid
                                                    (currently {Math.round(this.state.passportImage.size / 1024)} Kb)
                                                </div>
                                                }
                                                {this.state.passportImage && Validation.validateUploadFileType(this.state.passportImage) !== 0 &&
                                                <div className="text-danger">
                                                    File type is not valid
                                                </div>
                                                }
                                            </div>
                                       
                                            <div className="content-value mt-2">
                                                <Button
                                                    onClick={() => {
                                                        this.setState({alertAck: false});
                                                        this.props.updatePassport({
                                                            type,
                                                            applicationId: this.state.purchaseId,
                                                            passportImage: this.state.passportImageNew
                                                        });
                                                    }}
                                                    disabled={
                                                        !this.state.passportImage || 
                                                        Validation.validateUploadFileTripleSize(this.state.passportImage) !== 0 || 
                                                        Validation.validateUploadFileType(this.state.passportImage) !== 0 || 
                                                        this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS
                                                    }
                                                    variant="contained"
                                                    color="primary">
                                                    {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS && (
                                                        <CircularProgress className="margin-right-20px" size={20}/>
                                                    )}
                                                    <span>Update KYC</span>
                                                </Button>
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>

                            {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_FAIL && !this.state.alertAck && (
                                <Alert title="Error"
                                       content={translate("errors." + this.props.stepData.statusInfo.error.code)}
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                            {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_SUCCESS && !this.state.alertAck && (
                                <Alert title="Success"
                                       content="Update passport success. Thank for your support"
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                        </div>
                    </RegisterForm>
                )}
            </Translate>
        );
    }
}

const mapStateToProps = state => {
    return {
        stepData: state.registration.stepData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePassport: data => {
            dispatch(updateWrongUserInfo(data));
        },
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Passport)
);
