import React from "react";
import { connect } from "react-redux";
import { getPassportImage } from "../../../../actions/adminActions";
import { STATUS as STATUSES, STATUS } from "../../../../reducers/adminReducer";
import "./style.css";
import Alert from "../../../../components/Alert";
import { Translate } from "react-localize-redux";
import { Button, Input } from "../../../../components/antd";
import { PERMISSION } from "../../../../utils/commonUtils";

class PassportImageExport extends React.Component {
  constructor() {
    super();
    this.state = {
      file: null,
      notifyEmail: "",
      alertAck: false
    };
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  handleFileChange(e) {
    if (e.target.files) {
      this.setState({
        file:
          e.target.files && e.target.files.length > 0 ? e.target.files[0] : null
      });
    }
  }

  render() {
    const loading = this.props.admin.status === STATUS.PASSPORT_IMAGE_EXPORTING;
    const { admin: { listPermission } } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <div className="passport-image-export">
            <div className="title-page">パスポート画像出力</div>
            <div className="mt-4 mb-4">
              <label className="mr-4">ファイルを洗濯して下さい:</label>
              <input
                type="file"
                id="fileSelect"
                accept=".xlsx, .xls, .csv"
                onChange={this.handleFileChange}
              />
            </div>
            <div>
              <label className="mr-4">お知らせされるメール:</label>
              <Input
                placeholder="オプション"
                type="email"
                value={this.state.notifyEmail}
                className="input-option"
                onChange={e => {
                  this.setState({ notifyEmail: e.target.value });
                }}
              />
              {listPermission.includes(
                  PERMISSION.PASSPORT_IMAGE_EXPORT
                ) && (
                  <Button
                    className="btn-send"
                    disabled={loading || !this.state.file}
                    onClick={() => {
                      this.alertAck();
                      this.props.getPassportImage({
                        file: this.state.file,
                        notifyEmail: this.state.notifyEmail
                      });
                    }}
                    variant="contained"
                    loading={loading}
                  >
                    送る
                  </Button>
                )}
            </div>
            {this.props.admin.linkPassportImage &&
              this.props.admin.status !== STATUS.PASSPORT_IMAGE_EXPORTING && (
                <div className="mt-5">
                  <div>
                    <a href={this.props.admin.linkPassportImage}>
                      Please click here to download passport image
                    </a>
                  </div>
                </div>
              )}
            {this.props.admin.status ===
              STATUSES.PASSPORT_IMAGE_EXPORT_FAILED &&
              !this.state.alertAck && (
                <Alert
                  title="Error"
                  content={translate(
                    this.props.error && "errors." + this.props.error.code
                  )}
                  okTitle="OK"
                  onOK={() => {
                    this.setState({ alertAck: true });
                  }}
                />
              )}
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = store => {
  return {
    error: store.admin.error,
    admin: store.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPassportImage: filter => {
      dispatch(getPassportImage(filter));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PassportImageExport);
