import React from 'react'
import AsyncPaginate from 'react-select-async-paginate'
import {callApi} from "../../utils/apiUtils";
import './style.scss'

const colourStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    let styleCustome = {}
    if (isSelected) {
      styleCustome = {
        backgroundColor: '#E0FFFF',
        fontWeight: 600
      }
    } else if (isFocused) {
      styleCustome = {
        backgroundColor: '#E0FFFF'
      }
    }
    return {
      ...styles,
      color: 'rgba(0,0,0,.65)',
      ...styleCustome,
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && '#E0FFFF'
      }
    }
  }
}

const SelectAsync = ({
  field,
  onChange,
  excluded,
  optionBinding,
  value,
  api,
  ...props
}) => {
  async function loadOptions(search, loadedOptions, { page }) {
    const filter = search ? { cardNumber: { $regex: search }} : {};
    const query = {
      limit: 100,
      page,
      filter
    }

    let options = []
    let hasMore = false
    try {
      let config = {
        method: 'post',
        isAuthorization: true,
        body: query
      };
      let response = null;
      await callApi(api,
          config,
          null,
          (data) => {
            response = data
          },
          (err) => {
          }
      );

      if (response) {
        const { list, total, limit } = response
        if (list && list.length) {
          list.forEach((item) => {
            if (item && item[optionBinding.value] !== excluded) {
              const value = item[optionBinding.value]
              const label = item[optionBinding.name]
              options.push({ value, label })
            }
          });

          hasMore = page * limit < total;
        }
      }
    // eslint-disable-next-line no-empty
    } catch (error) { }
    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    }
  }

  const handleChange = (value) => {
    if (onChange) {
      onChange({ target: { value, name: field ? field.name : props.name } })
    }
  }

  return (
    <AsyncPaginate
      loadOptions={loadOptions}
      additional={{
        page: 1
      }}
      value={value}
      onChange={handleChange}
      classNamePrefix="react-async-paginate"
      debounceTimeout={500}
      styles={colourStyles}
      {...props}
    />
  )
}

export default SelectAsync
