import {ACTIONS} from '../actions/permissionAction';

const initialState = {
    status: null,
    role: [],
    permissionError: null,
};


const STATUS = {
    GET_ROLE_PROGRESS: "GET_ROLE_PROGRESS",
    GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
    GET_ROLE_FAILED: "GET_ROLE_FAILED",
    DELETE_ROLE_PROGRESS: "DELETE_ROLE_PROGRESS",
    DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
    DELETE_ROLE_FAILED: "DELETE_ROLE_FAILED",
    CREATE_ROLE_PROGRESS: "CREATE_ROLE_PROGRESS",
    CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
    CREATE_ROLE_FAILED: "CREATE_ROLE_FAILED",
    UPDATE_ROLE_PROGRESS: "UPDATE_ROLE_PROGRESS",
    UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
    UPDATE_ROLE_FAILED: "UPDATE_ROLE_FAILED",
};
export {STATUS};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_STATUS:
            switch (action.data.status) {
                case STATUS.GET_ROLE_PROGRESS:
                case STATUS.DELETE_ROLE_PROGRESS:
                case STATUS.CREATE_ROLE_PROGRESS:
                case STATUS.UPDATE_ROLE_PROGRESS:
                    return {
                        ...state,
                        status: action.data.status
                    };
                case STATUS.GET_ROLE_SUCCESS:
                    return {
                        ...state,
                        status: action.data.status,
                        role: action.data.statusInfo.role
                    };
                case STATUS.CREATE_ROLE_SUCCESS:
                    return {
                        ...state,
                        status: action.data.status,
                        role: [...state.role, action.data.statusInfo.role]
                    };
                case STATUS.DELETE_ROLE_SUCCESS:
                    let roleIndex = [...state.role].findIndex(item => item._id === action.data.statusInfo.data._id);
                    return {
                        ...state,
                        status: action.data.status,
                        role: [
                            ...state.role.slice(0, roleIndex),
                            ...state.role.slice(roleIndex + 1)
                        ]
                    };
                case STATUS.UPDATE_ROLE_SUCCESS:
                    let role = [...state.role].findIndex(item => item._id === action.data.statusInfo.data._id);
                    return {
                        ...state,
                        status: action.data.status,
                        role: [
                            ...state.role.slice(0, role),
                            Object.assign({}, state.role[role], action.data.statusInfo.data),
                            ...state.role.slice(role + 1)
                        ]
                    };

                case STATUS.GET_ROLE_FAILED:
                case STATUS.DELETE_ROLE_FAILED:
                case STATUS.CREATE_ROLE_FAILED:
                case STATUS.UPDATE_ROLE_FAILED:
                    return {
                        ...state,
                        status: action.data.status,
                        permissionError: action.data.statusInfo.error
                    };
                default:
                    return state;
            }
        default:
            return state;
    }
}


