import React, { Component } from "react";
import Card from "../CardIssuer/Cards";
import CardDetail from "../CardIssuer/Detail";
import TradingHistory from "../CardIssuer/Detail/TradingHistory";
import { Switch, Route } from "react-router-dom";
import "./style.css";

export default class CardIssuer extends Component {

    render() {
        return (
            <Switch>
                <Route path="/admin/card-issuer/detail/trading-history" component={TradingHistory} />
                <Route path="/admin/card-issuer/detail" component={CardDetail} />
                <Route path="/admin/card-issuer" component={Card} />
            </Switch>
        )
    }
}