import 'isomorphic-fetch';
import {getEnv} from '../env';

const constructRequest = (config) => {
    if (config && config.isUserRegisterAuthorization)
        config = {
            ...config,
            headers: {
                ...config.headers,
                "Authorization": getUserRegisterToken()
            }
        };
    if (config && config.isAuthorization)
        config = {
            ...config,
            headers: {
                ...config.headers,
                "Authorization": getToken()
            }
        };
    if (config && config.isFormData) {
        config = {
            ...config,
            body: config.body
        };
    } else {
        if (config.method && config.method.toLowerCase() === 'post')
            config = {
                ...config,
                headers: {
                    ...config.headers,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(config.body)
            };
    }
    return config;
};

const callApi = (url,
                 config,
                 onRequestFinish,
                 onRequestSuccess,
                 onRequestFailure) => {
    return fetch(getEnv('API_SERVER') + url, constructRequest(config))
        .then(response => {
            if (config.isFileDownload) {
                onRequestSuccess && onRequestSuccess(response);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            onRequestFinish && onRequestFinish();
            data && data.success && !config.isFileDownload && onRequestSuccess && onRequestSuccess(data.result);
            !config.isFileDownload && (!data || (data && !data.success)) && onRequestFailure && onRequestFailure(data ? data.error : {code: 'UNKNOWN_ERROR'});
        })
        .catch((err) => {
            console.log(err);
            onRequestFinish && onRequestFinish();
            onRequestFailure && onRequestFailure({code: 'NETWORK_ERROR'});
        });
};

const callApiSGP = (url,
    config,
    onRequestFinish,
    onRequestSuccess,
    onRequestFailure) => {
    return fetch(getEnv('API_SGP') + url, constructRequest(config))
        .then(response => response.json())
        .then((data) => {
            if (data && data.data && data.status === 200 && onRequestSuccess) {
                onRequestSuccess(data.data);
            } else if (onRequestFailure) {
                onRequestFailure(data ? data.message : {code: 'UNKNOWN_ERROR'})
            }
        })
        .catch((err) => {
            console.log(err);
            onRequestFinish && onRequestFinish();
            onRequestFailure && onRequestFailure({code: 'NETWORK_ERROR'});
        });
};


const saveToken = ({id, token, timeExpired}) => {
    localStorage.setItem("TOKEN", token);
    localStorage.setItem("ID", id);
    localStorage.setItem("TOKEN_EXPIRATION", timeExpired);
};

const saveUserRegisterToken = ({token}) => {
    localStorage.setItem("registerToken", token);
};

const getUserRegisterToken = () => {
    return localStorage.getItem("registerToken");
};

const removeUserRegisterToken = () => {
    localStorage.removeItem("registerToken");
};

const getToken = () => {
    return localStorage.getItem("TOKEN");
};

const getId = () => {
    return localStorage.getItem("ID");
};

const removeToken = () => {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("TOKEN_EXPIRATION");
};

export {
    callApi,
    saveToken,
    removeToken,
    getToken,
    saveUserRegisterToken,
    getUserRegisterToken,
    removeUserRegisterToken,
    getId,
    callApiSGP
};
