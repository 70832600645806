import React, {Component} from "react";
import "./style.css";

class Step extends Component {
	constructor() {
		super();
		this.state = {
			steps: [
				{
					title: "Step 1",
					content: "Term of agreement",
				},
				{
					title: "Step 2",
					content: "Mail authentication"
				},
				{
					title: "Step 3",
					content: "Customer Information",
				},
				{
					title: "Step 4",
					content: "Confirmation"
				},
				{
					title: "Step 5",
					content: "Finish"
				}
			]
		}
	}
	
	render() {
		const {steps} = this.state;
		const {activeStep} = this.props;
		return (
			<React.Fragment>
				<div className="component-step">
					{
						steps.map((step, index) => {
							return (
								<div key={index}
								     className={"component-step-item" + ((index + 1 === activeStep) ? " active-step" : "")}>
									<div className="title">{step.title}</div>
									<div className="content"> {step.content}</div>
								</div>
							)
						})
					}
				</div>
				<div className="container component-step-slider">
					<div className="liner"/>
					<div className="post-step">
						{
							steps.map((step, index) => {
								return (
									<div
										className={"post-step-item" + ((index + 1 === activeStep) ? " active" : "")}
										key={index}>
										<span
											className={"icon" + ((index + 1 === activeStep) ? " active" : "")}>{index + 1}</span>
										<span className="title">{(index + 1 === activeStep) ? step.content : ""}</span>
									</div>
								)
							})
						}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Step;