import React, { Component } from "react";

import { connect } from "react-redux";
import {
  changeApplicationStatus,
  resetUpdatedStatus,
  exportCSVApplication,
  handleExportUserTriple,
  getApplications,
  setApplicationStoreParams,
  ACTIONS
} from "../../../../../actions/adminActions";
import RegistrationForm from "../../../../RegistrationForm";
import {
  STATUS,
  formatDateTimeFull,
  formatAddress,
  formatInforUser,
  formatCardNumber,
  PERMISSION
} from "../../../../../utils/commonUtils";
import { STATUS as STATUSES } from "../../../../../reducers/adminReducer";
import Alert from "../../../../../components/Alert";
import { Translate } from "react-localize-redux";
import "./style.css";

import { Tooltip, Icon } from "antd";
import {
  PaginationBar,
  Table,
  Button,
  Modal,
  Select,
  Checkbox
} from "../../../../../components/antd";
import {
  businessOptions,
  businessIds
} from "../../../../../constants/commonData";

class BeforeConfirmAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowModal: false,
      selectedUser: null,
      isOpenConfirm: false
    };

    props.setTitle("表示するデータがありません");
    props.setShowBox(true);
  }

  componentDidMount() {
    this._getApplications({
      page: 1,
      limit: 100,
      str: "",
      box: null,
      businessId: null
    });
  }

  _onChangeCheckboxRow = (e, record) => {
    const {
      applicationStore: {
        users,
        check: { checkRows }
      },
      setApplicationStoreParams
    } = this.props;
    let newCheckRows = [...checkRows];
    let indeterminateCheckAll = false;
    let checkAll = false;

    if (e.target.checked) {
      newCheckRows.push(record._id);
    } else {
      const indexRemove = newCheckRows.findIndex(item => item === record._id);
      newCheckRows.splice(indexRemove, 1);
    }

    if (newCheckRows.length) {
      indeterminateCheckAll = newCheckRows.length < users.length;
      checkAll = newCheckRows.length === users.length;
    }
    setApplicationStoreParams({
      checkRows: newCheckRows,
      indeterminateCheckAll,
      checkAll,
      type: "check"
    });
  };

  _onCheckAllChange = e => {
    const {
      applicationStore: { users },
      setApplicationStoreParams
    } = this.props;
    const checkAll = e.target.checked;
    const checkRows = [];
    if (checkAll) {
      users.forEach(item => {
        if (item && item._id) {
          checkRows.push(item._id);
        }
      });
    }
    setApplicationStoreParams({
      checkAll,
      checkRows,
      indeterminateCheckAll: false,
      type: "check"
    });
  };

  _onPaginationBarChange = (type, value) => {
    if (type === "page") {
      this._getApplications({ page: value }, "search");
    } else {
      this._getApplications({ page: 1, limit: value }, "search");
    }
  };

  _getApplications = (newParams, type) => {
    const {
      getApplications,
      setApplicationStoreParams,
      applicationStore: { params }
    } = this.props;

    let payload = {
      ...params,
      ...newParams,
      status: STATUS.SENT,
      type: type ? type : "status"
    };
    setApplicationStoreParams({
      ...newParams,
      status: STATUS.SENT,
      type: "params"
    });

    getApplications(payload);
  };

  handleEditUser(e, item) {
    e.preventDefault();
    this.setState({ selectedUser: item, isShowModal: true });
  }

  closeModal = () => {
    this.props.resetUpdatedStatus();
    this.setState({ isShowModal: !this.state.isShowModal, selectedUser: null });
  };

  renderUserFormDialog() {
    const { selectedUser } = this.state;
    const {
      applicationStore: { listPermission }
    } = this.props;
    if (selectedUser) {
      let newSelectedUser = {
        ...selectedUser,
        ...formatInforUser(selectedUser),
        isOpenConfirm: false,
        alertAck: true
      };
      return (
        <Modal
          size={"ls"}
          onCancel={this.closeModal}
          title="Detail User"
          visible={this.state.isShowModal}
          className="registration-form-modal"
        >
          <RegistrationForm
            status={selectedUser.status}
            // reconfirming={true}
            data={newSelectedUser}
            update={listPermission.includes(PERMISSION.EDIT_SENT_LIST)}
            isUpdate={true}
            onClose={() => this.closeModal()}
            editMail={true}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  _onBussinessType = e => {
    this._getApplications(
      {
        [e.target.name]: e.target.value,
        page: 1,
        type: "params"
      },
      "search"
    );
  };

  render() {
    const {
      applicationStore: {
        users,
        params,
        submitting,
        check,
        status,
        listPermission
      }
    } = this.props;
    let columns = [
      {
        title: "No",
        align: "center",
        width: 100,
        key: "no",
        className: "again",
        render: (cell, record, index) => (
          <div className={record.isExistApplication ? "exist" : ""}>
            {index + 1}
          </div>
        )
      },
      {
        title: "",
        width: 100,
        align: "center",
        key: "isChecked",
        render: (cell, record) => {
          const {
            applicationStore: {
              check: { checkRows }
            }
          } = this.props;
          const isCheckRow = checkRows.some(item => item === record._id);
          return (
            <Checkbox
              checked={isCheckRow}
              onChange={e => this._onChangeCheckboxRow(e, record)}
            />
          );
        }
      },
      {
        title: "行動",
        width: 100,
        align: "center",
        key: "action",
        render: (cell, record) => (
          <div className="style-icon">
            <Icon
              onClick={e => this.handleEditUser(e, record)}
              className="cell-edit-button"
              type="edit"
              theme="filled"
            />
          </div>
        )
      },
      {
        key: "note",
        width: 120,
        align: "center",
        title: "理由",
        dataIndex: "note"
      },
      {
        key: "businessMemberId",
        align: "center",
        width: 150,
        title: "メンバーID",
        dataIndex: "businessMemberId"
      },
      {
        align: "center",
        width: 300,
        key: "cardNumber1",
        title: (
          <>
            カード番号１
            <br />
            有効期限
          </>
        ),
        render: (cell, record) => {
          const card = Array.isArray(record.cards) && record.cards[0];
          const cardNumber = card && card.cardNumber;
          const expiredDay = card && card.expiredDay;
          return (
            <>
              {formatCardNumber(cardNumber)}
              <br />
              {expiredDay && `(${expiredDay})`}
            </>
          );
        }
      },
      {
        align: "center",
        width: 130,
        key: "box",
        title: <>Box No.</>,
        render: (cell, record) => {
          const card = Array.isArray(record.cards) && record.cards[0];
          const box = card && card.box;
          return <>{box ? `${box} (${record.cards[0].no})` : ""}</>;
        }
      },
      {
        align: "center",
        width: 130,
        key: "letterTracking",
        title: <>Delivery No.</>,
        render: (cell, record) => <>{record.letterTracking}</>
      },
      {
        key: "firstname",
        align: "center",
        width: 200,
        title: (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.firstname}</div>
            <div>{record.lastname}</div>
          </>
        )
      },
      {
        key: "lastNameChinese",
        align: "center",
        width: 200,
        title: (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => {
          if (record.businessId !== businessIds.GREENBOX.id) {
            return (
              <div>
                <div>{record.firstNameChinese}</div>
                <div>{record.lastNameChinese}</div>
              </div>
            );
          } else {
            return <div></div>;
          }
        }
      },
      {
        key: "phoneNumber",
        width: 200,
        align: "center",
        title: "電話番号",
        dataIndex: "phoneNumber"
      },
      {
        key: "buildingName",
        align: "center",
        title: "住所",
        width: 400,
        render: (cell, item) => {
          const title = formatAddress(item);
          return (
            <Tooltip placement="topLeft" title={title}>
              {title}
            </Tooltip>
          );
        }
      },
      {
        key: "postCode",
        width: 120,
        align: "center",
        title: "郵便番号",
        dataIndex: "postCode"
      },
      {
        key: "email",
        width: 300,
        align: "center",
        title: "メールアドレス",
        dataIndex: "email"
      },
      {
        key: "gender",
        align: "center",
        width: 140,
        title: (
          <>
            性別
            <br />
            生年月日
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.gender ? "Male" : "Female"}</div>
            <div>{record.birthday}</div>
          </>
        )
      },
      {
        key: "KYC.KYC_NUMBER",
        align: "center",
        width: 250,
        title: "パスポート番号",
        render: (item, record) => <div>{record.KYC.KYC_NUMBER}</div>
      },
      {
        key: "KYC.kycIssueDate",
        align: "center",
        width: 140,
        title: (
          <>
            発行日
            <br />
            有効期限
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.KYC.kycIssueDate}</div>
            <div>
              {record.KYC.kycExpiredDate.trim()
                ? record.KYC.kycExpiredDate
                : "长期"}
            </div>
          </>
        )
      },
      {
        key: "KYC.kycPlaceIssued",
        align: "center",
        width: 120,
        title: "旅券発行地",
        render: (item, record) => <div>{record.KYC.kycPlaceIssued}</div>
      },
      {
        key: "createdAt",
        align: "center",
        width: 140,
        dataIndex: "createdAt",
        title: "カード申請日"
      },
      {
        key: "sgpExchangeUserId",
        align: "center",
        width: 250,
        title: "SGPアカウント",
        dataIndex: "sgpExchangeUserId"
      },
      {
        key: "_id",
        align: "center",
        width: 300,
        title: "問い合わせ番号",
        dataIndex: "_id"
      },
      {
        key: "changeStatusTimestamp",
        align: "center",
        width: 300,
        title: "最終更新",
        render: (item, record) => <div>{formatDateTimeFull(record.changeStatusTimestamp)}</div>
      }
    ];

    if (
      !listPermission.includes(PERMISSION.UPDATE_STATUS_SENT_TO_CONFIRMING) &&
      !listPermission.includes(PERMISSION.EXPORT_EXCEL_SENT_LIST)
    )
      columns = columns.filter(item => item.key !== "isChecked");

    return (
      <Translate>
        {({ translate }) => (
          <React.Fragment>
            <div className="before-confirm-address card-list">
              {this.renderUserFormDialog()}
              <div className="top-action-box">
                <div className="button-group">
                  <Select
                    className="group-select"
                    options={businessOptions}
                    placeholder="ビジネスID"
                    modern
                    name="businessId"
                    size="small"
                    onChange={this._onBussinessType}
                    value={params.businessId}
                    allowClear
                  />
                  <PaginationBar
                    current={params.page}
                    total={params.total}
                    onChange={this._onPaginationBarChange}
                    pageSize={params.limit}
                  />
                </div>
                <div className="button-group">
                  {(listPermission.includes(
                    PERMISSION.UPDATE_STATUS_SENT_TO_CONFIRMING
                  ) ||
                    listPermission.includes(
                      PERMISSION.EXPORT_EXCEL_SENT_LIST
                    )) && (
                    <Checkbox
                      onChange={this._onCheckAllChange}
                      checked={check.checkAll}
                      indeterminate={check.indeterminateCheckAll}
                    >
                      全てをチェックする
                    </Checkbox>
                  )}
                  {listPermission.includes(
                    PERMISSION.UPDATE_STATUS_SENT_TO_CONFIRMING
                  ) && (
                    <Button
                      type="primary"
                      size="small"
                      disabled={check.checkRows.length === 0}
                      onClick={() => this.setState({ isOpenConfirm: true })}
                    >
                      STATUS UPDATE (7-1)
                    </Button>
                  )}
                  {listPermission.includes(
                    PERMISSION.EXPORT_EXCEL_SENT_LIST
                  ) && (
                    <Button
                      className="btn-export-csv"
                      type="primary"
                      size="small"
                      disabled={check.checkRows.length === 0}
                      loading={status === STATUSES.CSV_APPLICATION_EXPORTING}
                      onClick={() =>
                        this.props.handleExportCSV({
                          status: STATUS.SENT,
                          data: check.checkRows
                        })
                      }
                    >
                      CSVエクスポート
                    </Button>
                  )}
                  {listPermission.includes(
                      PERMISSION.EXPORT_EXCEL_SENT_LIST
                  ) && (
                      <Button
                          className="btn-export-csv"
                          type="primary"
                          size="small"
                          loading={status === STATUSES.EXPORT_USER_TRIPLE_PROGRESS}
                          onClick={() =>
                              this.props.handleExportUserTriple()
                          }
                      >
                        Triple Export CSV Confirm Address
                      </Button>
                  )}

                </div>
              </div>
              <Table
                loading={submitting === ACTIONS.GET_APPLICATIONS_REQUEST}
                rowKey={(row, index) => index}
                columns={columns}
                dataSource={users}
                scroll={{ x: 4200, y: "calc(100vh - 270px)" }}
              />
            </div>
            {this.state.isOpenConfirm && (
              <Alert
                title="確認ダイアログ"
                content="次のステータスに移動しますか？"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ isOpenConfirm: false });
                  this.props.changeStatusToConfirming({
                    status: STATUS.CONFIRMING,
                    fromStatus: STATUS.SENT,
                    data: check.checkRows
                  });
                  this._onCheckAllChange({ target: { checked: false } });
                }}
                onCancel={() => {
                  this.setState({ isOpenConfirm: false });
                }}
              />
            )}
          </React.Fragment>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    applicationStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getApplications: (data, callback) => {
      dispatch(getApplications(data, callback));
    },
    changeStatusToConfirming: data => {
      dispatch(changeApplicationStatus(data));
    },
    handleExportCSV: data => {
      dispatch(exportCSVApplication(data));
    },
    handleExportUserTriple: () => {
      dispatch(handleExportUserTriple());
    },
    resetUpdatedStatus: () => {
      dispatch(resetUpdatedStatus());
    },
    setApplicationStoreParams: data => {
      dispatch(setApplicationStoreParams(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BeforeConfirmAddress);
