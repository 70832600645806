import React from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import {
  getApiServices,
  deleteApiApp,
  createApiApp
} from "../../../../actions/adminActions";
import { Translate } from "react-localize-redux";
import { STATUS as STATUSES } from "../../../../reducers/adminReducer";
import Alert from "../../../../components/Alert";
import "./style.css";
import {
  Table,
  Button,
  Input,
  Column,
  Page,
  Container
} from "../../../../components/antd";
import { PERMISSION } from "../../../../utils/commonUtils";

class ManageAPIService extends React.Component {
  constructor() {
    super();
    this.state = {
      apiServices: [],
      selectedApiService: {},
      isShowModal: false,
      alertAck: true,
      appName: ""
    };

    this._columns = [
      {
        title: "No",
        align: "center",
        width: 70,
        dataIndex: "no",
        key: "no",
        render: (cell, record, index) => index + 1
      },
      Column({
        key: "_id",
        width: 200,
        data: [{ title: "IDのアプリ", value: "_id" }]
      }),
      Column({
        key: "apiKey",
        width: 200,
        data: [{ title: "APIキー", value: "apiKey" }]
      }),
      Column({
        key: "appName",
        width: 200,
        data: [{ title: "名前のアプリ", value: "appName" }]
      }),
      {
        title: "行動",
        align: "center",
        key: "delete",
        width: 120,
        render: (text, record) => (
          <div className="action-cell">
            {this.props.listPermission &&
              this.props.listPermission.includes(
                PERMISSION.API_SERVICE
              ) && (
                <Button
                  type="delete"
                  icon="delete"
                  onClick={() =>
                    this.setState({
                      isShowModal: true,
                      edit_permission: true,
                      selectedApiService: record
                    })
                  }
                >
                  削除
                </Button>
              )}
          </div>
        )
      }
    ];
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.alertAck();
    this.props.handleGetApiServices();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.apiServices !== this.props.apiServices) {
      this.setState({ apiServices: nextProps.apiServices });
    }
  }

  toggleDialog = () => {
    this.setState({ isShowModal: false, selectedApiService: {} });
  };

  handleSubmitBtn = (e, actionName) => {
    this.alertAck();
    const { selectedApiService, appName } = this.state;
    e.preventDefault();
    this.setState({ isShowModal: false });
    switch (actionName) {
      case "Delete":
        this.props.handleDeleteApiApp({
          _id: selectedApiService && selectedApiService._id
        });
        break;
      case "Create":
        this.props.handleCreateApiApp({
          appName: appName
        });
        break;
      default:
        break;
    }
  };

  renderForm() {
    const { selectedApiService } = this.state;
    const { listPermission } = this.props;
    return (
      <Modal
        size={"xl"}
        isOpen={this.state.isShowModal}
        toggle={this.toggleDialog}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggleDialog}>
          {selectedApiService && selectedApiService._id
            ? "項目を削除してよろしいですか?"
            : "新規登録"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="AppName" sm={2}>
                アプリ名
              </Label>
              <Col sm={10}>
                <Input
                  type="input"
                  name="AppName"
                  id="AppName"
                  value={
                    (selectedApiService && selectedApiService.appName) ||
                    this.state.appName
                  }
                  onChange={e => this.setState({ appName: e.target.value })}
                  placeholder="名前のアプリ"
                  disabled={!!(selectedApiService && selectedApiService._id)}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="default" onClick={this.toggleDialog}>
            キャンセル
          </Button>{" "}
          {listPermission.includes(PERMISSION.API_SERVICE) &&
            (selectedApiService && selectedApiService._id ? (
              <Button onClick={e => this.handleSubmitBtn(e, "Delete")}>
                削除
              </Button>
            ) : (
              <Button onClick={e => this.handleSubmitBtn(e, "Create")}>
                登録
              </Button>
            ))}
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { apiServices, status } = this.props;
    const columns = this._columns;

    return (
      <Translate>
        {({ translate }) => (
          <Page className="manage-api-service-container">
            <Container>
              {this.renderForm()}
              <div className="title mb-4">
                <h4>APIサービスを管理する</h4>
              </div>
              <div className="mb-4">
                <Button
                  onClick={() =>
                    this.setState({
                      isShowModal: true,
                      selectedApiService: {},
                      appName: ""
                    })
                  }
                >
                  新規登録
                </Button>
              </div>

              <Table
                loading={status === STATUSES.GET_ROLE_PROGRESS}
                rowKey={(row, index) => index}
                columns={columns}
                dataSource={apiServices}
                scroll={{ x: 1000, y: "calc(100vh - 280px)" }}
              />

              {[
                STATUSES.CREATE_API_APP_FAIL,
                STATUSES.DELETE_API_APP_FAIL,
                STATUSES.GET_API_SERVICE_FAIL
              ].indexOf(status) >= 0 &&
                !this.state.alertAck && (
                  <Alert
                    title="Error"
                    content={translate(
                      this.props.error && "errors." + this.props.error.code
                    )}
                    okTitle="OK"
                    onOK={() => {
                      this.setState({ alertAck: true });
                    }}
                  />
                )}
            </Container>
          </Page>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    apiServices: state.admin.apiServices,
    error: state.admin.error,
    status: state.admin.status,
    listPermission: state.admin.listPermission
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleGetApiServices: () => {
      dispatch(getApiServices());
    },
    handleCreateApiApp: data => {
      dispatch(createApiApp(data));
    },
    handleDeleteApiApp: data => {
      dispatch(deleteApiApp(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAPIService);
