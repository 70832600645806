import React, { Component } from "react";
import { FormGroup, Input, Button, Form } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";

export default class TradingHistory extends Component {
    renderPeriodTime() {
        return (
            <div className="period-time">
                <Form className="d-flex justify-content-end mt-2 mb-2" inline>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Input type="date" name="date" id="exampleDate" placeholder="date placeholder" />
                    </FormGroup>
                    <div>~</div>
                    <FormGroup className="mb-2 ml-2 mb-sm-0">
                        <Input type="date" name="date" id="exampleDate" placeholder="date placeholder" />
                    </FormGroup>
                </Form>
            </div>
        )
    }

    render() {
        return (
            <div className="trading-history">
                <div className="bread-crumb d-flex">
                    <Link to="/admin/card-issuer"><div className="title">Card issuer</div></Link>
                    <div className="ml-2 mr-2 title">></div>
                    <Link to="/admin/card-issuer/detail"> <div className="title">Detail</div></Link>
                    <div className="ml-2 mr-2 title">></    div>
                    <div className="title">Trading history</div>
                </div>
                <div className="d-flex justify-content-start mt-4">
                    <Button outline color="secondary" className="mr-2 custom-btn" >Total trading history</Button>
                    <Button outline color="secondary" className="mr-2 custom-btn">POS・WEB trading history</Button>
                    <Button outline color="secondary" className="custom-btn">ATM trading history</Button>
                </div>
                <div className="d-flex justify-content-start mt-2 align-items-center mt-4">
                    <div className="mr-2">期間選択</div>
                    {this.renderPeriodTime()}
                    <div className="ml-2">
                        <Button color="primary">Search</Button>
                    </div>
                </div>
                <div className="mt-4">Trading performance</div>
                <div className="d-flex justify-content-between">
                    <FormGroup className="row col-4">
                        <Input className="right-content" type="text" placeholder="1000$" />
                    </FormGroup>
                    <Button outline color="secondary" className="h-40 custom-btn">CSV出力</Button>
                </div>

            </div >
        )
    }
}