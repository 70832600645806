import React, { Component } from "react";
import { connect } from "react-redux";
import {
  changeApplicationStatus,
  resetUpdatedStatus,
  getApplications,
  setApplicationStoreParams,
  ACTIONS
} from "../../../../../actions/adminActions";
import RegistrationForm from "../../../../RegistrationForm";
import {
  STATUS,
  formatAddress,
  formatInforUser,
  arrayImage,
  objectImage,
  PERMISSION
} from "../../../../../utils/commonUtils";
import "./style.css";
import Alert from "../../../../../components/Alert";
import { Translate } from "react-localize-redux";
import PassportImage from "../../../../../components/PassportImage";
import {
  Column,
  PaginationBar,
  Table,
  Button,
  Modal,
  Select,
  Checkbox
} from "../../../../../components/antd";
import { Tooltip, Icon } from "antd";
import {
  businessIds,
  businessOptions
} from "../../../../../constants/commonData";

class PaymentCompletedPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
      isShowModal: false,
      isOpenConfirm: false
    };

    props.setTitle("カード申し込み者リスト（決済済）");
    props.setShowBox(false);
  }

  _getApplications = (newParams, type) => {
    const {
      getApplications,
      setApplicationStoreParams,
      applicationStore: { params }
    } = this.props;

    let payload = {
      ...params,
      ...newParams,
      status: STATUS.DONE,
      type: type ? type : "status"
    };
    setApplicationStoreParams({
      ...newParams,
      status: STATUS.DONE,
      type: "params"
    });

    getApplications(payload);
  };

  _onChangeCheckboxRow = (e, record) => {
    const {
      applicationStore: {
        users,
        check: { checkRows }
      },
      setApplicationStoreParams
    } = this.props;
    let newCheckRows = [...checkRows];
    let indeterminateCheckAll = false;
    let checkAll = false;

    if (e.target.checked) {
      newCheckRows.push(record._id);
    } else {
      const indexRemove = newCheckRows.findIndex(item => item === record._id);
      newCheckRows.splice(indexRemove, 1);
    }

    if (newCheckRows.length) {
      indeterminateCheckAll = newCheckRows.length < users.length;
      checkAll = newCheckRows.length === users.length;
    }
    setApplicationStoreParams({
      checkRows: newCheckRows,
      indeterminateCheckAll,
      checkAll,
      type: "check"
    });
  };

  _onCheckAllChange = e => {
    const {
      applicationStore: { users },
      setApplicationStoreParams
    } = this.props;
    const checkAll = e.target.checked;
    const checkRows = [];
    if (checkAll) {
      users.forEach(item => {
        if (item && item._id) {
          checkRows.push(item._id);
        }
      });
    }
    setApplicationStoreParams({
      checkAll,
      checkRows,
      indeterminateCheckAll: false,
      type: "check"
    });
  };

  _onPaginationBarChange = (type, value) => {
    if (type === "page") {
      this._getApplications({ page: value }, "search");
    } else {
      this._getApplications({ page: 1, limit: value }, "search");
    }
  };

  componentDidMount() {
    this._getApplications({
      page: 1,
      limit: 100,
      str: "",
      box: null,
      businessId: null
    });
  }

  handleEditUser(e, item) {
    e.preventDefault();
    this.setState({ selectedUser: item, isShowModal: true });
  }

  closeModal = () => {
    this.props.resetUpdatedStatus();
    this.setState({ isShowModal: !this.state.isShowModal, selectedUser: null });
  };

  updateImage(linkImage, nameImage, selectedUser) {
    const newData = objectImage(nameImage, linkImage);
    this.setState({
      selectedUser: {
        ...selectedUser,
        ...newData
      }
    });
  }

  renderUserFormDialog() {
    const { selectedUser } = this.state;
    const {
      applicationStore: { listPermission }
    } = this.props;
    if (selectedUser) {
      let newSelectedUser = {
        ...selectedUser,
        ...formatInforUser(selectedUser),
        note: selectedUser.note,
        isOpenConfirm: false,
        alertAck: true,
        isOpenConfirmToChecked: false,
        statusUpdate: STATUS.RECONFIRM,
        statusUpdateChecked: STATUS.CHECKED
      };
      return (
        <Modal
          onCancel={this.closeModal}
          title="Detail User"
          width={1200}
          visible={this.state.isShowModal}
          className="registration-form-modal show-passport"
        >
          <div className="row">
            <div className="col-md-6 scroll-detail">
              <RegistrationForm
                status={selectedUser.status}
                data={newSelectedUser}
                isUpdate={true}
                update={listPermission.includes(PERMISSION.EDIT_DONE_LIST)}
                showNote={true}
                closeButton={true}
                reconfirming={listPermission.includes(
                  PERMISSION.UPDATE_STATUS_DONE_TO_RECONFIRM
                )}
                toChecked={listPermission.includes(
                  PERMISSION.UPDATE_STATUS_DONE_TO_CHECKED
                )}
                cancel={listPermission.includes(
                  PERMISSION.UPDATE_STATUS_DONE_TO_REFUNDING
                )}
                editMail={true}
                onClose={() => this.closeModal()}
                changeImage={({ linkImage, nameImage }) =>
                  this.updateImage(linkImage, nameImage, selectedUser)
                }
              />
            </div>
            <div className="col-md-6">
              <PassportImage passportImage={arrayImage(newSelectedUser)} />
            </div>
          </div>
        </Modal>
      );
    } else {
      return null;
    }
  }

  _onBussinessType = e => {
    this._getApplications(
      {
        [e.target.name]: e.target.value,
        page: 1,
        type: "params"
      },
      "search"
    );
  };

  render() {
    const {
      applicationStore: { users, params, submitting, check, listPermission }
    } = this.props;
    let columns = [
      {
        title: "No",
        align: "center",
        width: 100,
        key: "no",
        className: "again",
        render: (cell, record, index) => (
          <div className={record.isExistApplication ? "exist" : ""}>
            {index + 1}
          </div>
        )
      },
      {
        title: "",
        width: 100,
        align: "center",
        key: "isChecked",
        render: (cell, record) => {
          const {
            applicationStore: {
              check: { checkRows }
            }
          } = this.props;
          const isCheckRow = checkRows.some(item => item === record._id);
          return (
            <Checkbox
              checked={isCheckRow}
              onChange={e => this._onChangeCheckboxRow(e, record)}
            />
          );
        }
      },
      {
        title: "行動",
        width: 100,
        align: "center",
        key: "action",
        render: (cell, record) => (
          <div className="style-icon">
            <Icon
              onClick={e => this.handleEditUser(e, record)}
              className="cell-edit-button"
              type="edit"
              theme="filled"
            />
          </div>
        )
      },
      {
        key: "businessMemberId",
        width: 150,
        align: "center",
        title: "メンバーID",
        dataIndex: "businessMemberId"
      },
      {
        key: "firstname",
        align: "center",
        width: 200,
        title: (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.firstname}</div>
            <div>{record.lastname}</div>
          </>
        )
      },
      {
        key: "lastNameChinese",
        align: "center",
        width: 200,
        title: (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => {
          if (record.businessId !== businessIds.GREENBOX.id) {
            return (
              <div>
                <div>{record.firstNameChinese}</div>
                <div>{record.lastNameChinese}</div>
              </div>
            );
          } else {
            return <div></div>;
          }
        }
      },
      {
        key: "phoneNumber",
        width: 200,
        align: "center",
        title: "電話番号",
        dataIndex: "phoneNumber"
      },
      {
        key: "buildingName",
        align: "center",
        title: "住所",
        width: 400,
        render: (cell, item) => {
          const title = formatAddress(item);
          return (
            <Tooltip placement="topLeft" title={title}>
              {title}
            </Tooltip>
          );
        }
      },
      {
        key: "postCode",
        width: 120,
        align: "center",
        title: "郵便番号",
        dataIndex: "postCode"
      },
      {
        key: "email",
        width: 300,
        align: "center",
        title: "メールアドレス",
        dataIndex: "email"
      },
      {
        key: "gender",
        align: "center",
        width: 140,
        title: (
          <>
            性別
            <br />
            生年月日
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.gender ? "Male" : "Female"}</div>
            <div>{record.birthday}</div>
          </>
        )
      },
      {
        key: "KYC.KYC_NUMBER",
        align: "center",
        width: 250,
        title: "パスポート番号",
        render: (item, record) => <div>{record.KYC.KYC_NUMBER}</div>
      },
      {
        key: "KYC.kycIssueDate",
        align: "center",
        width: 140,
        title: (
          <>
            発行日
            <br />
            有効期限
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.KYC.kycIssueDate}</div>
            <div>
              {record.KYC.kycExpiredDate.trim()
                ? record.KYC.kycExpiredDate
                : "长期"}
            </div>
          </>
        )
      },
      {
        key: "KYC.kycPlaceIssued",
        align: "center",
        width: 120,
        title: "旅券発行地",
        render: (item, record) => <div>{record.KYC.kycPlaceIssued}</div>
      },
      {
        key: "createdAt",
        align: "center",
        width: 140,
        dataIndex: "createdAt",
        title: "カード申請日"
      },
      {
        key: "sgpExchangeUserId",
        align: "center",
        width: 250,
        title: "SGPアカウント",
        dataIndex: "sgpExchangeUserId"
      },
      {
        key: "_id",
        align: "center",
        width: 300,
        title: "問い合わせ番号",
        dataIndex: "_id"
      }
    ];

    if (!listPermission.includes(PERMISSION.UPDATE_STATUS_DONE_TO_CHECKED))
      columns = columns.filter(item => item.key !== "isChecked");

    return (
      <Translate>
        {({ translate }) => (
          <React.Fragment>
            <div className="payment-completed-pending card-list">
              {this.renderUserFormDialog()}
              <div className="top-action-box">
                <div className="button-group">
                  <Select
                    className="group-select"
                    options={businessOptions}
                    placeholder="ビジネスID"
                    modern
                    name="businessId"
                    size="small"
                    onChange={this._onBussinessType}
                    value={params.businessId}
                    allowClear
                  />
                  <PaginationBar
                    current={params.page}
                    total={params.total}
                    onChange={this._onPaginationBarChange}
                    pageSize={params.limit}
                  />
                </div>
                <div className="button-group">
                  {listPermission.includes(
                    PERMISSION.UPDATE_STATUS_DONE_TO_CHECKED
                  ) && (
                    <React.Fragment>
                      <Checkbox
                        onChange={this._onCheckAllChange}
                        checked={check.checkAll}
                        indeterminate={check.indeterminateCheckAll}
                      >
                        全てをチェックする
                      </Checkbox>
                      <Button
                        type="primary"
                        size="small"
                        disabled={check.checkRows.length === 0}
                        onClick={() => this.setState({ isOpenConfirm: true })}
                      >
                        STATUS UPDATE (4)
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>
              <Table
                loading={submitting === ACTIONS.GET_APPLICATIONS_REQUEST}
                rowKey={(row, index) => index}
                columns={columns}
                dataSource={users}
                scroll={{ x: 3200, y: "calc(100vh - 270px)" }}
                onChange={this._handleTableChange}
              />
            </div>
            {this.state.isOpenConfirm && (
              <Alert
                title="確認ダイアログ"
                content="次のステータスに移動しますか？"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ isOpenConfirm: false });
                  this.props.changeStatus({
                    status: STATUS.CHECKED,
                    fromStatus: STATUS.DONE,
                    data: check.checkRows
                  });
                }}
                onCancel={() => {
                  this.setState({ isOpenConfirm: false });
                }}
              />
            )}
          </React.Fragment>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    applicationStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getApplications: (data, callback) => {
      dispatch(getApplications(data, callback));
    },
    setApplicationStoreParams: data => {
      dispatch(setApplicationStoreParams(data));
    },
    changeStatus: data => {
      dispatch(changeApplicationStatus(data));
    },
    resetUpdatedStatus: () => {
      dispatch(resetUpdatedStatus());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentCompletedPending);
