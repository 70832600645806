import React, { PureComponent } from "react";
import { Input } from "reactstrap";
import { connect } from "react-redux";
import cx from "classnames";
import { Tooltip, Icon, Row, Col } from "antd";
import InputMask from "react-input-mask";
import moment from "moment";
import lodash from "lodash";
import { Translate } from "react-localize-redux";
import {
  changeApplicationStatus,
  resetUpdatedStatus,
  updateApplicationInfo,
  getApplications,
  setApplicationStoreParams,
  ACTIONS
} from "../../../../../actions/adminActions";
import {
  Table,
  PaginationBar,
  Button,
  Modal,
  Select,
  Checkbox
} from "../../../../../components/antd";
import {
  formatCardNumber,
  formatDateTimeFull,
  STATUS,
  formatAddress,
  PERMISSION
} from "../../../../../utils/commonUtils";
import { STATUS as STATUSES } from "../../../../../reducers/adminReducer";
import Alert from "../../../../../components/Alert";
import "./style.css";
import Creatable from "react-select/lib/Creatable";
import {
  businessOptions,
  businessIds
} from "../../../../../constants/commonData";

const regex = /[^A-Za-z0-9]+/;

class InputCardsApplication extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
      isShowModal: false,
      isShowReserve: false,
      isOpenConfirm: false,
      fullyChecked: false,
      cards: null,
      isShowTick: false,
      validateCode: false,
      showValidateCode: false,
      isShowInput1stCode: false,
      isShowInput2ndCode: false,
      statusUpdate: ""
    };
    props.setTitle("カード申し込み者リスト（決済済）");
    props.setShowBox(true);
  }

  componentDidMount() {
    this._getApplications({
      page: 1,
      limit: 100,
      str: "",
      box: null,
      businessId: null
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      applicationStore: { getAllApplicationAgain }
    } = this.props;
    if (
      getAllApplicationAgain &&
      !prevProps.applicationStore.getAllApplicationAgain
    ) {
      this._getApplications();
      this.props.resetUpdatedStatus();
      this.setState({ isShowTick: true });
    }
  }

  _onChangeCheckboxRow = (e, record) => {
    const {
      applicationStore: {
        users,
        check: { checkRows }
      },
      setApplicationStoreParams
    } = this.props;
    let newCheckRows = [...checkRows];
    let indeterminateCheckAll = false;
    let checkAll = false;

    if (e.target.checked) {
      newCheckRows.push(record._id);
    } else {
      const indexRemove = newCheckRows.findIndex(item => item === record._id);
      newCheckRows.splice(indexRemove, 1);
    }

    if (newCheckRows.length) {
      indeterminateCheckAll = newCheckRows.length < users.length;
      checkAll = newCheckRows.length === users.length;
    }
    setApplicationStoreParams({
      checkRows: newCheckRows,
      indeterminateCheckAll,
      checkAll,
      type: "check"
    });
  };

  _onCheckAllChange = e => {
    const {
      applicationStore: { users },
      setApplicationStoreParams
    } = this.props;
    const checkAll = e.target.checked;
    const checkRows = [];
    if (checkAll) {
      users.forEach(item => {
        if (item && item._id) {
          checkRows.push(item._id);
        }
      });
    }
    setApplicationStoreParams({
      checkAll,
      checkRows,
      indeterminateCheckAll: false,
      type: "check"
    });
  };

  _onPaginationBarChange = (type, value) => {
    if (type === "page") {
      this._getApplications({ page: value }, "search");
    } else {
      this._getApplications({ page: 1, limit: value }, "search");
    }
  };

  _getApplications = (newParams, type) => {
    const {
      getApplications,
      setApplicationStoreParams,
      applicationStore: { params }
    } = this.props;

    let payload = {
      ...params,
      ...newParams,
      status: STATUS.INPUT,
      type: type ? type : "status"
    };
    setApplicationStoreParams({
      ...newParams,
      status: STATUS.INPUT,
      type: "params"
    });

    getApplications(payload);
  };

  handleEditUser(item) {
    this.setState(
      {
        selectedUser: item,
        cards: [...item.cards],
        isShowModal: true,
        isShowInput1stCode: !item.cards[0].cardNumber,
        isShowInput2ndCode: !lodash.get(item, "cards[1].cardNumber")
      },
      () => {
        if (item.cards.length > 1) {
          if (this._input1stCode) this._input1stCode.focus();
          else if (this._input2ndCode) this._input2ndCode.focus();
        } else {
          if (this._inputCode) this._inputCode.focus();
        }
      }
    );
  }

  closeModal = () => {
    this.props.resetUpdatedStatus();
    this._getApplications();
    this.setState({
      isShowModal: !this.state.isShowModal,
      selectedUser: null,
      isShowTick: false
    });
  };

  handleCardNumber(index) {
    let { cards } = this.state;
    return e => {
      if (e.target) {
        const newCard = cards.map((card, id) =>
          id === index ? { ...card, cardNumber: e.target.value } : card
        );
        this.setState({ cards: newCard }, () => {
          this.checkData();
        });
      }
    };
  }

  handleCode(index) {
    let { cards, selectedUser } = this.state;
    return e => {
      if (e.target) {
        const partCode = e.target.value.split("^");
        const cardNumber =
          partCode[0] && partCode[0].slice(2, partCode[0].length);
        const nameCard = partCode[1] && partCode[1].trim();
        const expiredDay =
          partCode[2] &&
          partCode[2].slice(2, 4) + "/" + partCode[2].slice(0, 2);
        if (
          (partCode[0] && partCode[0].slice(0, 2) !== "%B") ||
          cardNumber.length !== 16 ||
          isNaN(cardNumber) ||
          nameCard !== `${selectedUser.firstname} ${selectedUser.lastname}`
        )
          this.setState({
            validateCode: true,
            showValidateCode: true
          });
        else this.setState({ validateCode: false });
        const newCard = cards.map((card, id) =>
          id === index
            ? {
                ...card,
                ...{
                  code: e.target.value,
                  cardNumber: cardNumber,
                  expiredDay: expiredDay
                }
              }
            : card
        );

        this.setState({ cards: newCard }, () => {
          this.checkData();
        });
      }
    };
  }

  handleBox = (newValue, actionMeta, index) => {
    let { cards } = this.state;
    const newCard = cards.map((card, id) =>
      id === index ? { ...card, box: newValue.value } : card
    );
    this.setState({ cards: newCard }, () => {
      this.checkData();
    });
  };

  handleExpiredDay(index) {
    let { cards } = this.state;
    return e => {
      if (e.target) {
        const newCard = cards.map((card, id) =>
          id === index ? { ...card, expiredDay: e.target.value } : card
        );
        this.setState({ cards: newCard }, () => {
          this.checkData();
        });
      }
    };
  }

  checkData() {
    const { cards } = this.state;
    let fullyChecked =
      (cards[0].cardNumber &&
        cards[0].expiredDay &&
        !isNaN(cards[0].cardNumber.slice(0, 4)) &&
        !isNaN(cards[0].cardNumber.slice(5, 9)) &&
        !isNaN(cards[0].cardNumber.slice(10, 14)) &&
        !isNaN(cards[0].cardNumber.slice(15, 19)) &&
        !isNaN(cards[0].expiredDay.slice(0, 2)) &&
        !isNaN(cards[0].expiredDay.slice(3, 5)) &&
        cards[0].box &&
        cards[0].box.trim() &&
        !regex.test(cards[0].box) &&
        moment(cards[0].expiredDay, "MM/YY").isValid()) ||
      (cards[1] &&
        cards[1].cardNumber &&
        cards[1].expiredDay &&
        !isNaN(cards[1].cardNumber.slice(0, 4)) &&
        !isNaN(cards[1].cardNumber.slice(5, 9)) &&
        !isNaN(cards[1].cardNumber.slice(10, 14)) &&
        !isNaN(cards[1].cardNumber.slice(15, 19)) &&
        !isNaN(cards[1].expiredDay.slice(0, 2)) &&
        !isNaN(cards[1].expiredDay.slice(3, 5)) &&
        cards[1].box &&
        cards[1].box.trim() &&
        !regex.test(cards[1].box) &&
        moment(cards[1].expiredDay, "MM/YY").isValid());
    this.setState({ fullyChecked: fullyChecked });
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = {
      status: this.state.selectedUser && this.state.selectedUser.status,
      _id: this.state.selectedUser && this.state.selectedUser._id,
      cards: this.state.cards
    };
    for (let i = 0; i < data.cards.length; i++) {
      if (data.cards[i].cardNumber && data.cards[i].cardNumber.length === 16) {
        data.cards[i].cardNumber = `${data.cards[i].cardNumber.slice(
          0,
          4
        )}-${data.cards[i].cardNumber.slice(4, 8)}-${data.cards[
          i
        ].cardNumber.slice(8, 12)}-${data.cards[i].cardNumber.slice(12, 16)}`;
      }
    }
    this.props.updateCards(data);
    this.setState({ fullyChecked: false, validateCode: false });
  }

  renderSwitch(param) {
    const {
      cards,
      selectedUser,
      isShowInput1stCode,
      isShowInput2ndCode
    } = this.state;
    const options = [];
    this.props.applicationStore.boxes.forEach(value => {
      options.push({ value: value, label: value });
    });
    const {
      applicationStore: { listPermission }
    } = this.props;
    switch (param) {
      case 1:
        return (
          <Row className="card">
            <Col xs={24} className="field">
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  Input Code
                </Col>
                <Col xs={17}>
                  {isShowInput1stCode ? (
                    <Input
                      innerRef={ref => (this._inputCode = ref)}
                      className="input-style form-control"
                      value={cards[0].code}
                      onChange={this.handleCode(0)}
                      placeholder="Enter code"
                    />
                  ) : (
                    listPermission.includes(PERMISSION.EDIT_INPUT_LIST) && (
                      <Button
                        className="custom-btn"
                        onClick={() =>
                          this.setState({ isShowInput1stCode: true })
                        }
                      >
                        Edit
                      </Button>
                    )
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} className="field">
              <Col xs={7} className="label">
                Card Number
              </Col>
              <Col xs={17}>
                <InputMask
                  disabled
                  className="input-style form-control bold"
                  mask="9999-9999-9999-9999"
                  value={cards[0].cardNumber}
                  onChange={this.handleCardNumber(0)}
                  placeholder="Enter card number"
                />
              </Col>
            </Col>
            <Col xs={24} className="field">
              <Col xs={7} className="label">
                Card Expired
              </Col>
              <Col xs={17}>
                <InputMask
                  disabled
                  mask="99/99"
                  className="input-style form-control bold"
                  value={cards[0].expiredDay}
                  onChange={this.handleExpiredDay(0)}
                  placeholder="Enter expired day (mm/yy)"
                />
              </Col>
            </Col>
            <Col xs={24} className="field">
              <Col xs={7} className="label">
                Full Name
              </Col>
              <Col xs={17}>
                <Input
                  className="input-style bold"
                  value={selectedUser.firstname + " " + selectedUser.lastname}
                  disabled
                />
              </Col>
            </Col>
            <Col xs={24} className="field">
              <Col xs={7} className="label">
                Box
              </Col>
              <Col xs={17}>
                <Creatable
                  placeholder="Box"
                  backspaceRemovesValue
                  defaultValue={{
                    value: cards[0].box,
                    label: cards[0].box
                      ? `${cards[0].box} (${cards[0].no})`
                      : ""
                  }}
                  options={options}
                  onChange={(newValue, actionMeta) =>
                    this.handleBox(newValue, actionMeta, 0)
                  }
                />
              </Col>
            </Col>
          </Row>
        );
      case 2:
        return (
          <Row gutter={16}>
            <Col xs={24} lg={12} className="card">
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  Input 1st Code
                </Col>
                <Col xs={17}>
                  {isShowInput1stCode ? (
                    <Input
                      innerRef={ref => (this._input1stCode = ref)}
                      className="input-style form-control"
                      value={cards[0].code}
                      onChange={this.handleCode(0)}
                      placeholder="Enter code"
                    />
                  ) : (
                    listPermission.includes(PERMISSION.EDIT_INPUT_LIST) && (
                      <Button
                        className="custom-btn"
                        onClick={() =>
                          this.setState({ isShowInput1stCode: true })
                        }
                      >
                        Edit
                      </Button>
                    )
                  )}
                </Col>
              </Row>
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  1st Card Number
                </Col>
                <Col xs={17}>
                  <InputMask
                    disabled
                    className="input-style form-control bold"
                    onChange={this.handleCardNumber(0)}
                    mask="9999-9999-9999-9999"
                    value={cards[0].cardNumber}
                    placeholder="Enter card number"
                  />
                </Col>
              </Row>
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  1st Card Expired
                </Col>
                <Col xs={17}>
                  <InputMask
                    disabled
                    className="input-style form-control bold"
                    onChange={this.handleExpiredDay(0)}
                    mask="99/99"
                    value={cards[0].expiredDay}
                    placeholder="Enter expired day (mm/yy)"
                  />
                </Col>
              </Row>
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  Full Name
                </Col>
                <Col xs={17}>
                  <Input
                    className="input-style bold"
                    value={selectedUser.firstname + " " + selectedUser.lastname}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  1st Box
                </Col>
                <Col xs={17}>
                  <Creatable
                    backspaceRemovesValue
                    placeholder="Box"
                    defaultValue={{
                      value: cards[0].box,
                      label: cards[0].box
                        ? `${cards[0].box} (${cards[0].no})`
                        : ""
                    }}
                    options={options}
                    onChange={(newValue, actionMeta) =>
                      this.handleBox(newValue, actionMeta, 0)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={12} className="card">
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  Input 2nd Code
                </Col>
                <Col xs={17}>
                  {isShowInput2ndCode ? (
                    <Input
                      innerRef={ref => (this._input2ndCode = ref)}
                      className="input-style form-control"
                      value={(cards[1] && cards[1].code) || ""}
                      onChange={this.handleCode(1)}
                      placeholder="Enter code"
                    />
                  ) : (
                    listPermission.includes(PERMISSION.EDIT_INPUT_LIST) && (
                      <Button
                        className="custom-btn"
                        onClick={() =>
                          this.setState({ isShowInput2ndCode: true })
                        }
                      >
                        Edit
                      </Button>
                    )
                  )}
                </Col>
              </Row>
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  2nd Card Number
                </Col>
                <Col xs={17}>
                  <InputMask
                    disabled
                    className="input-style form-control bold"
                    onChange={this.handleCardNumber(1)}
                    mask="9999-9999-9999-9999"
                    value={(cards[1] && cards[1].cardNumber) || ""}
                    placeholder="Enter card number"
                  />
                </Col>
              </Row>
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  2nd Card Expired
                </Col>
                <Col xs={17}>
                  <InputMask
                    disabled
                    className="input-style form-control bold"
                    onChange={this.handleExpiredDay(1)}
                    mask="99/99"
                    value={(cards[1] && cards[1].expiredDay) || ""}
                    placeholder="Enter expired day (mm/yy)"
                  />
                </Col>
              </Row>
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  Full Name
                </Col>
                <Col xs={17}>
                  <Input
                    className="input-style bold"
                    value={selectedUser.firstname + " " + selectedUser.lastname}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="field" align="middle" type="flex">
                <Col xs={7} className="label">
                  2nd Box
                </Col>
                <Col xs={17}>
                  <Creatable
                    backspaceRemovesValue
                    placeholder="Box"
                    defaultValue={{
                      value: (cards[1] && cards[1].box) || "",
                      label:
                        cards[1] && cards[1].box
                          ? `${cards[1] && cards[1].box} (${cards[1] &&
                              cards[1].no})`
                          : ""
                    }}
                    options={options}
                    onChange={(newValue, actionMeta) =>
                      this.handleBox(newValue, actionMeta, 1)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  }

  renderUserFormDialog() {
    const {
      selectedUser,
      fullyChecked,
      validateCode,
      isShowTick,
      isShowModal
    } = this.state;
    const {
      applicationStore: { status, listPermission }
    } = this.props;
    if (selectedUser) {
      return (
        <Modal
          visible={isShowModal}
          className={cx(
            "updated-cards-form-modal",
            selectedUser.cards.length > 1 && "medium"
          )}
          onCancel={this.closeModal}
          title="Update card number for application"
        >
          <div className="cards-box">
            {this.renderSwitch(selectedUser.cards.length)}
          </div>
          <div className="text-center mt-4">
            {listPermission.includes(PERMISSION.EDIT_INPUT_LIST) && (
              <Button
                className="custom-btn"
                disabled={!fullyChecked || validateCode}
                onClick={e => this.handleSubmit(e)}
                isUpdate={true}
                loading={status === STATUSES.UPDATE_APPLICATION_INFO_PROGRESS}
                icon={isShowTick ? "check" : null}
              >
                Update
              </Button>
            )}
            {listPermission.includes(
              PERMISSION.UPDATE_STATUS_FROM_INPUT_TO_RESERVE
            ) && (
              <Button
                className="custom-btn ml-2"
                onClick={() => this.setState({ isShowReserve: true, statusUpdate: STATUS.RESERVE })}
                loading={status === STATUSES.CHANGE_APPLICATION_STATUS_PROGRESS}
              >
                To Reserve List (5-1)
              </Button>
            )}
             {listPermission.includes(
              PERMISSION.UPDATE_STATUS_FROM_INPUT_TO_CARD_UPDATE
            ) && (
              <Button
                className="custom-btn ml-2"
                onClick={() => this.setState({ isShowReserve: true, statusUpdate: STATUS.CARD_UPDATE })}
                loading={status === STATUSES.CHANGE_APPLICATION_STATUS_PROGRESS}
              >
                To Card Update List (5-2)
              </Button>
            )}
          </div>
        </Modal>
      );
    } else return null;
  }

  _onBussinessType = e => {
    this._getApplications(
      {
        [e.target.name]: e.target.value,
        page: 1,
        type: "params"
      },
      "search"
    );
  };

  render() {
    const {
      applicationStore: {
        users,
        params,
        submitting,
        check,
        status,
        listPermission
      }
    } = this.props;
    let columns = [
      {
        title: "No",
        align: "center",
        width: 100,
        key: "no",
        className: "again",
        render: (cell, record, index) => (
          <div className={record.isExistApplication ? "exist" : ""}>
            {index + 1}
          </div>
        )
      },
      {
        title: "",
        width: 100,
        align: "center",
        key: "isChecked",
        render: (cell, record) => {
          const {
            applicationStore: {
              check: { checkRows }
            }
          } = this.props;
          const isCheckRow = checkRows.some(item => item === record._id);
          return (
            <Checkbox
              checked={isCheckRow}
              onChange={e => this._onChangeCheckboxRow(e, record)}
            />
          );
        }
      },
      {
        title: "行動",
        width: 100,
        align: "center",
        key: "action",
        render: (cell, record) => (
          <div className="style-icon">
            <Icon
              onClick={() => this.handleEditUser(record)}
              className="cell-edit-button"
              type="edit"
              theme="filled"
            />
          </div>
        )
      },
      {
        key: "note",
        width: 300,
        title: "理由",
        align: "center",
        dataIndex: "note"
      },
      {
        key: "businessMemberId",
        width: 130,
        align: "center",
        title: "メンバーID",
        dataIndex: "businessMemberId"
      },
      {
        key: "cards",
        width: 140,
        align: "center",
        title: "申し込み枚数",
        render: (item, record) =>
          Array.isArray(record.cards) ? (
            <div>{record.cards.length}</div>
          ) : (
            <div></div>
          )
      },
      {
        align: "center",
        width: 300,
        dataIndex: "cardNumber1",
        key: "cardNumber1",
        title: (
          <>
            カード番号１
            <br />
            有効期限
          </>
        ),
        render: (cell, record) => {
          const card = Array.isArray(record.cards) && record.cards[0];
          const cardNumber = card && card.cardNumber;
          const expiredDay = card && card.expiredDay;
          return (
            <>
              {formatCardNumber(cardNumber)}
              <br />
              {expiredDay && `(${expiredDay})`}
            </>
          );
        }
      },
      {
        align: "center",
        width: 130,
        key: "box",
        title: <>Box No.</>,
        render: (cell, record) => {
          const card = Array.isArray(record.cards) && record.cards[0];
          const box = card && card.box;
          return <>{box ? `${box} (${record.cards[0].no})` : ""}</>;
        }
      },
      {
        align: "center",
        width: 130,
        key: "letterTracking",
        title: <>Delivery No.</>,
        render: (cell, record) => <>{record.letterTracking}</>
      },
      {
        key: "lastname",
        width: 200,
        align: "center",
        title: () => (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => {
          return (
            <div>
              <div>{record.firstname}</div>
              <div>{record.lastname}</div>
            </div>
          );
        }
      },
      {
        key: "lastNameChinese",
        width: 200,
        align: "center",
        title: () => (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => {
          if (record.businessId !== businessIds.GREENBOX.id) {
            return (
              <div>
                <div>{record.firstNameChinese}</div>
                <div>{record.lastNameChinese}</div>
              </div>
            );
          } else {
            return <div></div>;
          }
        }
      },
      {
        key: "phoneNumber",
        width: 200,
        title: "電話番号",
        align: "center",
        dataIndex: "phoneNumber"
      },
      {
        key: "buildingName",
        width: 400,
        title: "住所",
        align: "center",
        render: (cell, item) => {
          const title = formatAddress(item);
          return (
            <Tooltip placement="topLeft" title={title}>
              <span className="address-two-line">{title}</span>
            </Tooltip>
          );
        }
      },
      {
        key: "postCode",
        width: 120,
        align: "center",
        title: "郵便番号",
        dataIndex: "postCode"
      },
      {
        key: "email",
        align: "center",
        width: 300,
        dataIndex: "email",
        title: "メールアドレス"
      },
      {
        key: "gender",
        align: "center",
        width: 140,
        title: (
          <>
            性別
            <br />
            生年月日
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.gender ? "Male" : "Female"}</div>
            <div>{record.birthday}</div>
          </>
        )
      },
      {
        key: "KYC.KYC_NUMBER",
        align: "center",
        width: 250,
        title: "パスポート番号",
        render: (item, record) => <div>{record.KYC.KYC_NUMBER}</div>
      },
      {
        key: "KYC.kycIssueDate",
        align: "center",
        width: 140,
        title: (
          <>
            発行日
            <br />
            有効期限
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.KYC.kycIssueDate}</div>
            <div>
              {record.KYC.kycExpiredDate.trim()
                ? record.KYC.kycExpiredDate
                : "长期"}
            </div>
          </>
        )
      },
      {
        key: "KYC.kycPlaceIssued",
        width: 140,
        align: "center",
        title: "旅券発行地",
        render: (item, record) => <div>{record.KYC.kycPlaceIssued}</div>
      },
      {
        key: "createdAt",
        width: 140,
        align: "center",
        title: "カード申請日",
        dataIndex: "createdAt"
      },
      {
        key: "sgpExchangeUserId",
        width: 250,
        align: "center",
        title: "SGPアカウント",
        dataIndex: "sgpExchangeUserId"
      },
      {
        align: "center",
        key: "_id",
        width: 300,
        title: "問い合わせ番号",
        dataIndex: "_id"
      },
      {
        key: "changeStatusTimestamp",
        align: "center",
        width: 300,
        title: "最終更新",
        render: (item, record) => (
          <div>{formatDateTimeFull(record.changeStatusTimestamp)}</div>
        )
      }
    ];

    const showSelectedRow = listPermission.some(permission => {
      return [
        PERMISSION.UPDATE_STATUS_FROM_INPUT_TO_PRINTED,
        PERMISSION.UPDATE_STATUS_INPUT_TO_SENT
      ].includes(permission);
    });

    if (!showSelectedRow)
      columns = columns.filter(column => column.key !== "isChecked");

    return (
      <Translate>
        {({ translate }) => (
          <div>
            <div className="input-cards-application">
              {this.renderUserFormDialog()}
              <div className="card-list">
                <div className="top-action-box">
                  <div className="button-group">
                    <Select
                      className="group-select"
                      options={businessOptions}
                      placeholder="ビジネスID"
                      modern
                      name="businessId"
                      size="small"
                      onChange={this._onBussinessType}
                      value={params.businessId}
                      allowClear
                    />
                    <PaginationBar
                      current={params.page}
                      total={params.total}
                      onChange={this._onPaginationBarChange}
                      pageSize={params.limit}
                    />
                  </div>
                  <div className="button-group">
                    {(listPermission.includes(
                      PERMISSION.UPDATE_STATUS_FROM_INPUT_TO_PRINTED
                    ) ||
                      listPermission.includes(
                        PERMISSION.UPDATE_STATUS_INPUT_TO_SENT
                      )) && (
                      <Checkbox
                        onChange={this._onCheckAllChange}
                        checked={check.checkAll}
                        indeterminate={check.indeterminateCheckAll}
                      >
                        全てをチェックする
                      </Checkbox>
                    )}
                    {listPermission.includes(
                      PERMISSION.UPDATE_STATUS_FROM_INPUT_TO_PRINTED
                    ) && (
                      <Button
                        type="primary"
                        size="small"
                        disabled={
                          status ===
                            STATUSES.CHANGE_APPLICATION_STATUS_PROGRESS ||
                          check.checkRows.length === 0
                        }
                        onClick={() =>
                          this.setState({
                            isOpenConfirm: true,
                            statusUpdate: STATUS.PRINTED
                          })
                        }
                      >
                        TO PRINTED LIST (5-0)
                      </Button>
                    )}
                    {listPermission.includes(
                      PERMISSION.UPDATE_STATUS_INPUT_TO_SENT
                    ) && (
                      <Button
                        type="primary"
                        size="small"
                        disabled={
                          status ===
                            STATUSES.CHANGE_APPLICATION_STATUS_PROGRESS ||
                          check.checkRows.length === 0
                        }
                        onClick={() =>
                          this.setState({
                            isOpenConfirm: true,
                            statusUpdate: STATUS.SENT
                          })
                        }
                      >
                        eZpayへデータ送信 (7-0)
                      </Button>
                    )}
                  </div>
                </div>
                <Table
                  loading={submitting === ACTIONS.GET_APPLICATIONS_REQUEST}
                  rowKey={(row, index) => index}
                  columns={columns}
                  dataSource={users}
                  scroll={{ x: 4500, y: "calc(100vh - 270px)" }}
                />
              </div>
            </div>
            {this.state.isOpenConfirm && (
              <Alert
                title="確認ダイアログ"
                content="次のステータスに移動しますか？"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ isOpenConfirm: false });
                  this.props.changeApplicationStatus({
                    status: this.state.statusUpdate,
                    fromStatus: STATUS.INPUT,
                    data: check.checkRows
                  });
                  this._onCheckAllChange({ target: { checked: false } });
                }}
                onCancel={() => {
                  this.setState({ isOpenConfirm: false, statusUpdate: "" });
                }}
              />
            )}
            {this.state.isShowReserve && (
              <Alert
                title="確認ダイアログ"
                content="次のステータスに移動しますか？"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ isShowReserve: false, isShowModal: false });
                  this.props.changeApplicationStatus({
                    status: this.state.statusUpdate,
                    fromStatus: STATUS.INPUT,
                    data: this.state.selectedUser && [
                      this.state.selectedUser._id
                    ]
                  });
                }}
                onCancel={() => {
                  this.setState({ isShowReserve: false });
                }}
              />
            )}
            {this.state.showValidateCode && (
              <Alert
                title="確認ダイアログ"
                content="コード間違えました、も一度確認してください。"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ showValidateCode: false });
                }}
                onCancel={() => {
                  this.setState({ showValidateCode: false });
                }}
              />
            )}
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    applicationStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeApplicationStatus: data => {
      dispatch(changeApplicationStatus(data));
    },
    resetUpdatedStatus: () => {
      dispatch(resetUpdatedStatus());
    },
    updateCards: data => {
      dispatch(updateApplicationInfo(data));
    },
    setApplicationStoreParams: data => {
      dispatch(setApplicationStoreParams(data));
    },
    getApplications: (data, callback) => {
      dispatch(getApplications(data, callback));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputCardsApplication);
