import React from "react";
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import "./style.css";

function VerifyTokenFail(props) {
  const { error } = props;
  return (
    <Translate>
      {({translate}) => (
          <div className="page-verify-token-fail">
          {error === `APPLICATION_INFO_REGISTERED` ?
            <p>
              {translate('Im sorry')}
              <br/>
              {translate('This account has already')}
              <br/>
              {translate('I cannot reply')}
            </p>
          : 
            <p>{translate('errors.VERIFY_TOKEN_FAIL')}</p>
          }
          </div>
      )}
    </Translate>
  );
}

const mapStateToProp = (state) => {
  return {
    error: state.registration.error,
  }
}

export default connect( mapStateToProp, null )(VerifyTokenFail);
