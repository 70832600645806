import React, {Component} from "react";
import {Button, Input} from "reactstrap";
import Select from "react-select";
import "./style.css";

export default class Search extends Component {
    constructor() {
        super();
        this.state = {
            value: "",
            box: ""
        };
    }

    handleChange = newValue => {
        this.setState({box: (newValue && newValue.value) || ""});
    };
    submitSearch = () => {
        this.props.boxes ? this.props.onSubmit(this.state) : this.props.onSubmit(this.state.value)
    };
    onKeyPress = (e) => {
        if (e.which === 13) {
            this.submitSearch();
        }
    };

    render() {
        const options = [];
        const {boxes} = this.props;
        boxes && boxes.forEach(value => {
            options.push({value: value, label: value});
        });
        return (
            <div className="com-search d-flex ">
                {boxes && (
                    <Select
                        className="basic-select"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={this.handleChange}
                        isSearchable={true}
                        name="color"
                        options={options}
                        placeholder="選択ボックス"
                    />
                )}
                <Input
                    placeholder={this.props.placeholder}
                    className="input-search ml-2"
                    onChange={e => this.setState({value: e.target.value})}
                    onKeyPress={this.onKeyPress}
                />
                <Button
                    outline
                    color="secondary"
                    className="ml-2 h-40 custom-btn"
                    onClick={this.submitSearch}
                >
                    検索
                </Button>
            </div>
        );
    }
}
