import React from 'react';
import {Paper, Button} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import './style.css';

export default class Dialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {visible: this.props.visible};
		this.handleClose = this.handleClose.bind(this);
	}
	
	handleClose() {
		this.setState({visible: !this.state.visible});
		if (this.props.onClose) this.props.onClose();
	}
	
	render() {
		return (
			<div>
				{this.state.visible ? (
					<div className="component-Dialog">
						<Paper className="dialog">
							<div className="header">
								<span/>
								<span className="title">{this.props.title}</span>
								<span>
							{/* <Button className="close" onClick={() => {
								this.handleClose()
							}}>
								<Close/>
							</Button> */}
						</span>
							</div>
							<div className="content">
								{this.props.children}
							</div>
						</Paper>
					</div>
				) : null}
			</div>
		)
	};
}