import React from 'react';
import queryString from 'querystring';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {CircularProgress} from '@material-ui/core';
import {getLatestSuccessStep} from '../../../../utils/commonUtils';
import NarrowLayout from "../../../../components/_layouts/NarrowLayout";
import {parseUrlQuery} from "../../../../utils/commonUtils";
import {STATUS, init, verifyToken} from "../../../../actions/registrationActions";

import RegisterStep1 from '../Step1';
import RegisterStep2 from '../Step2';
import RegisterStep3 from '../Step3';
import RegisterStep4 from '../Step4';
import RegisterStep5 from '../Step5';
import Header from "../../../../components/Header";
import Menu from "../../../../components/Menu";
import "./style.css"

const getParamsFromQuery = (query) => {
    if (query.indexOf('?') >= 0) query = query.substr(query.indexOf('?') + 1);
    return queryString.parse(query);
};

class Register extends React.Component {
    state = { businessId: null };

    componentDidMount() {
        let params = parseUrlQuery(this.props.location.search);
        let businessToken = params.token;
        let businessId = params.businessId || '';
      
        if (!params.step) {
            this.props.init({token: params.token || null, businessId: params.businessId || ''});
            this.props.verifyToken({ businessToken, businessId });
        }

        this.setState({businessId})
    }

    renderRegisterGreenbox = () => {
        let tobeRendered;
        let params = getParamsFromQuery(this.props.location.search);
        let step = Number(params.step || '1') || 1;
        let purchaseId = params['purchase_id'];
        if (step === 4 && !!purchaseId) {
            tobeRendered = <RegisterStep4/>;
        }
        else if (step > getLatestSuccessStep(this.props.stepSuccess)) {
            step = getLatestSuccessStep(this.props.stepSuccess);
            tobeRendered = (<Redirect to={"/registers?step=" + step}/>);
        } else {
            switch (step) {
                case 1:
                    tobeRendered = <RegisterStep1/>;
                    break;
                case 2:
                    tobeRendered = <RegisterStep2/>;
                    break;
                case 3:
                    tobeRendered = <RegisterStep3/>;
                    break;
                case 4:
                    tobeRendered = <RegisterStep4/>;
                    break;
                case 5:
                    tobeRendered = <RegisterStep5/>;
                    break;
                default:
                    tobeRendered = <Redirect to="/404"/>;
            }
        }
        return tobeRendered;
    };

    render() {
        const { status } = this.props;
        if (status === STATUS.STEP1.TOKEN_VERIFY_PROGRESS) {
            tobeRendered = (
                <div className="d-flex justify-content-center align-items-center waiting-point">
                    <CircularProgress size={40}/>
                </div>
            );
        }

        const tobeRendered = this.renderRegisterGreenbox();
        
        return (
            <NarrowLayout>
                <div className="container-form-register-page">
                    <Header/>
                    <Menu/>
                    {tobeRendered}
                </div>
            </NarrowLayout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.registration.status,
        currentStep: state.registration.currentStep,
        stepSuccess: state.registration.stepSuccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        verifyToken: (data) => {
            dispatch(verifyToken(data));
        },
        init: (initData) => {
            dispatch(init(initData));
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));
