import React, { Component } from "react";
import { Form, FormGroup, Label, Col, Input } from "reactstrap";
import { sendEmailToEachUser } from "../../../../actions/adminActions";
import { connect } from "react-redux";
import { STATUS } from "../../../../reducers/adminReducer";
import CKEditor from "react-ckeditor-component";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

import "./style.css";
import Alert from "../../../../components/Alert";
import { Translate } from "react-localize-redux";
import { Validation, PERMISSION } from "../../../../utils/commonUtils";
import Select from "react-select";
import { Button } from "../../../../components/antd";

class SendMailToEachUser extends Component {
  constructor() {
    super();
    this.status = [
      { value: "pending", label: "1 カード申込者(未決済）" },
      { value: "done", label: "2 カード申込者(決済済)(未チェック)" },
      { value: "reconfirm", label: "3-0 再申請(未チェック用）" },
      { value: "reconfirmed", label: "3-1 再申請(チェック済用)" },
      { value: "checked", label: "4 カード申込者(決済済)(チェック済)" },
      { value: "printed", label: "5-0 カード印字" },
      { value: "reserve", label: "5-1 カード送付保留" },
      { value: "input", label: "6 カード最終チェック" },
      { value: "sent", label: "7-0 住所確認前(送付前)" },
      { value: "confirming", label: "7-1 住所確認前(送付済)" },
      { value: "confirmed", label: "8 住所確認済(カード未送付)" },
      { value: "confirmed_preparing_shipment", label: "8-1 カード発送準備中" },
      { value: "completed", label: "9 カード送付完了(送付)" },
      { value: "refunding", label: "0-0 未返金" },
      { value: "refunded", label: "0-1 返金済" },
      { value: "statistic_number_card", label: "申込者数" },
      { value: "card_valid", label: "10 有効カード" }
    ];
    this.state = {
      title: "",
      content: "",
      status: [],
      emails: [],
      alertAck: true,
      check: false,
      isOpenConfirm: false
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };
  handleChange = data => {
    if (Array.isArray(data) && data.length > 0 && data[0].indexOf(",") > -1) {
      this.setState({ emails: data[0].split(",") }, () => {
        this.checkEmail();
      });
    } else {
      this.setState(
        {
          emails: data
        },
        () => {
          this.checkEmail();
        }
      );
    }
  };
  checkEmail = () => {
    let check = true;
    let emails = this.state.emails;
    for (let i = 0; i < emails.length; i++) {
      check = check && !!Validation.validateEmail(emails[i]);
    }
    this.setState({ check });
  };
  onChange = evt => {
    let newContent = evt.editor.getData();
    this.setState({
      content: newContent
    });
  };
  onChangeStatus = statuses => {
    let statusSendMail = [];
    statuses.forEach(status => statusSendMail.push(status.value));
    this.setState({ status: statusSendMail });
  };

  render() {
    const loading =
      this.props.admin.status === STATUS.SEND_MAIL_EACH_USER_PROGRESS;
    const { listPermission } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <div className="system-send-mail-each-user">
            <div className="title">ユーザーグルプにメール送信</div>
            <Form className="mt-5 mb-5">
              <FormGroup row>
                <Label for="emailTitle" sm={2} className="lbl-right">
                  タイトル:
                </Label>
                <Col sm={8}>
                  <Input
                    type="input"
                    name="emailTitle"
                    id="emailTitle"
                    onChange={e => this.setState({ title: e.target.value })}
                    placeholder="タイトル"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="emailContent" sm={2} className="lbl-right">
                  情報:
                </Label>
                <Col sm={8}>
                  <CKEditor
                    activeClass="p10"
                    content={this.state.content}
                    events={{ change: this.onChange }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="emails" sm={2} className="lbl-right">
                  送る先:
                </Label>
                <Col sm={8}>
                  <TagsInput
                    value={this.state.emails}
                    onChange={this.handleChange}
                    inputProps={{ placeholder: "送る先" }}
                    addOnBlur={this.handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="emails" sm={2} className="lbl-right">
                  申し込み状況:
                </Label>
                <Col sm={8}>
                  <Select
                    closeMenuOnSelect={false}
                    defaultValue={["", ""]}
                    isMulti
                    options={this.status}
                    onChange={newValue => this.onChangeStatus(newValue)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={10}>
                  {listPermission.includes(
                      PERMISSION.MAIL_SERVICE
                    ) && (
                      <Button
                        className="btn-send"
                        disabled={
                          loading ||
                          !this.state.title.trim() ||
                          !this.state.content.trim() ||
                          (this.state.emails.length !== 0 && !this.state.check)
                        }
                        onClick={() => {
                          this.setState({ isOpenConfirm: true });
                        }}
                        variant="contained"
                        loading={loading}
                      >
                        送る
                      </Button>
                    )}
                </Col>
              </FormGroup>
            </Form>
            {this.state.isOpenConfirm && (
              <Alert
                title="確認ダイアログ"
                content="メールを送りますか？"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ isOpenConfirm: false });
                  this.alertAck();
                  this.props.handleSendMail({
                    title: this.state.title,
                    content: this.state.content,
                    data: this.state.emails,
                    status: this.state.status
                  });
                }}
                onCancel={() => {
                  this.setState({ isOpenConfirm: false });
                }}
              />
            )}
            {this.props.admin.status === STATUS.SEND_MAIL_EACH_USER_SUCCESS &&
              !this.state.alertAck && (
                <Alert
                  title="成功"
                  content="システムは正常なEメールを送信しました。"
                  okTitle="OK"
                  onOK={() => {
                    this.setState({ alertAck: true });
                  }}
                />
              )}
            {this.props.admin.status === STATUS.SEND_MAIL_EACH_USER_FAIL &&
              !this.state.alertAck && (
                <Alert
                  title="Error"
                  content={translate(
                    this.props.error && "errors." + this.props.error.code
                  )}
                  okTitle="OK"
                  onOK={() => {
                    this.setState({ alertAck: true });
                  }}
                />
              )}
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    admin: state.admin,
    error: state.admin.error,
    listPermission: state.admin.listPermission
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSendMail: data => {
      dispatch(sendEmailToEachUser(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMailToEachUser);
