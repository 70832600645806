import React, { Component } from "react";
import { Table, Input, Button, Form, FormGroup, Label } from "reactstrap";
import "./style.css";

export default class ChargeRequestStepSecond extends Component {
    renderHeaderTable(checkTable) {
        return (
            <tr>
                {checkTable === 1 ? "" : <th></th>}
                <th>Green Box ID</th>
                <th>Applicant</th>
                <th>Charge Card</th>
                <th>GBT Quantity</th>
                <th>Application date and time</th>
                {checkTable === 1 ? <th></th> : ""}
            </tr>
        )
    }

    renContentTable(checkTable) {
        return (
            <tr >
                {checkTable === 1 ? "" : <td><Input type="checkbox" className="checkbox-item" /></td>}
                <td>1</td>
                <td>minnv</td>
                <td>111xxx</td>
                <td>100GBT</td>
                <td>21/7/2018</td>
                {checkTable === 1 ? <td><Button outline color="secondary" className="button-space custom-btn"> Reapply</Button><Button outline color="secondary" className="custom-btn"> Refund</Button></td> : ""}
            </tr>
        )
    }

    renderTable(checkTable) {
        return (
            <Table bordered>
                <thead>
                    {this.renderHeaderTable(checkTable)}
                </thead>
                <tbody>
                    {this.renContentTable(checkTable)}
                </tbody>
            </Table>
        )
    }

    renderPeriodTime() {
        return (
            <div className="period-time">
                <Form className="d-flex justify-content-end mt-2 mb-2" inline>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Input type="date" name="date" id="exampleDate" placeholder="date placeholder" />
                    </FormGroup>
                    <div>~</div>
                    <FormGroup className="mb-2 ml-2 mb-sm-0">
                        <Input type="date" name="date" id="exampleDate" placeholder="date placeholder" />
                    </FormGroup>
                </Form>
            </div>
        )
    }

    render() {
        return (
            <div className="container-charge-request-step-second">
                <div className="d-flex justify-content-between">
                    <div className="title-page">Charge request STEP 2</div>
                    <Button outline color="secondary" className="custom-btn">Upload request</Button>
                </div>
                {this.renderPeriodTime()}
                <div className="d-flex justify-content-between mb-2">
                    <div className="check-all">
                        <Label check><Input type="checkbox" />  {' '}Check All</Label>
                    </div>
                    <div>
                        <Button outline color="secondary">Select period</Button>
                    </div>
                </div>
                {this.renderTable()}
                <div>Processing error list</div>
                {this.renderTable(1)}
            </div>
        )
    }
}