import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import registration from "./reducers/registrationReducer";
import auth from "./reducers/authReducer";
import admin from "./reducers/adminReducer";
import member from "./reducers/memberReducer";
import permission from "./reducers/permissionReducer";
import cardManagement from "./reducers/cardManagementReducer";
import statisticApplication from "./reducers/statisticApplicationReducer";
import ui from "./reducers/uiReducer";
import registrationTripple from "./reducers/registrationTrippleReducer";
import compare from './reducers/compareDataReducer';

const composeEnhancers = (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)  || compose

const initialState = {};

export default () => {
  return createStore(
    combineReducers({
      registration,
      auth,
      admin,
      member,
      permission,
      cardManagement,
      statisticApplication,
      ui,
      registrationTripple,
      compare
    }),
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
};
