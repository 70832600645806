import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";

import Register from "./containers/pages/register/Register";
import LoginPage from "./containers/pages/LoginPage";
import UpdateInfo from "./containers/pages/updateInfo/UpdateInfo";
import Admin from "./containers/pages/Admin/";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import RegisterTriple from "./containers/pages/register/RegisterTriple";

import "./utils/commonStyles.css";
import "./App.css";

import jpTranslation from "./assets/translations/jp.json";
import enTranslation from "./assets/translations/en.json";
import cnTranslation from "./assets/translations/cn.json";

import ContactPage from "./containers/pages/Contact";
import HomePage from "./containers/pages/HomePage";
import Err404Page from "./containers/pages/Err404Page";
import VerifyTokenFailPage from "./containers/pages/VerifyTokenFailPage";
import { getTokenErrors } from "./selectors/getTokenErr";
import { logout } from "./actions/authActions";
import connect from "react-redux/es/connect/connect";
import { compose } from "redux";

class App extends Component {
  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [{ code: "jp" }, { code: "en" }, { code: "cn" }],
      options: { renderToStaticMarkup }
    });
    this.props.addTranslationForLanguage(jpTranslation, "jp");
    this.props.addTranslationForLanguage(enTranslation, "en");
    this.props.addTranslationForLanguage(cnTranslation, "cn");
  }

  handleLogout() {
    this.props.logout();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tokenErrors !== this.props.tokenErrors) {
      this.handleLogout();
    }
  }

  render() {
    return (
      <div className="app">
        <CssBaseline />
        <div className="body-content">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/login" component={LoginPage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/admin" component={Admin} />
            <Route exact path="/registers-info" component={RegisterTriple} />
            <Route exact path="/update_info" component={UpdateInfo} />
            <Route path="/404" component={Err404Page} />
            <Route path="/robin-token-fail" component={VerifyTokenFailPage} />
            <Route exact path="/registers" component={Register} />
            <Redirect to="/404" />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    tokenErrors: getTokenErrors(store, props)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(logout());
    }
  };
};
export default compose(
  withLocalize,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(App);
