import {callApi} from "../utils/apiUtils";
import {STATUS} from '../reducers/cardManagementReducer';

const ACTIONS = {
    UPDATE_STATUS: "UPDATE_STATUS",
};

const updateStatus = (status, statusInfo) => {
    return {
        type: ACTIONS.UPDATE_STATUS,
        data: {
            status,
            statusInfo
        }
    }
};


const exportCard = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        isFileDownload: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.CARDS_EXPORTING));
        callApi('/api/export_card_number',
            config,
            null,
            (data) => {
                data.blob().then((bin) => {
                    const url = window.URL.createObjectURL(bin);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'cards.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    dispatch(updateStatus(STATUS.CARDS_EXPORTED));
                });
            },
            (err) => {
                dispatch(updateStatus(STATUS.CARDS_FAILED, {error: err}));
            }
        );
    };
};

const getCards = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            filter: data.filter,
            page: data.page,
            limit: data.limit,
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_CARDS_PROGRESS, {
            loading: true,
        }));
        callApi('/api/get_card_number',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_CARDS_SUCCESS, {
                    cards: data.list,
                    pages: data.pages,
                    unusedCard: data.unusedCard,
                    checkedApplication: data.checkedApplication,
                    total: data.total,
                    loading: false
                }))
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_CARDS_FAIL, {error: err}));
            }
        )
    }
};

const editCard = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.EDIT_CARD_PROGRESS));
        callApi('/api/edit_card_number',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.EDIT_CARD_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.EDIT_CARD_FAIL, {error: err}));
            }
        )
    }
};

const searchCardNumber = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            cardNumber: data.cardNumber.replace(/-/g, '')
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.SEARCH_CARD_PROGRESS));
        callApi('/api/search_card_number',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.SEARCH_CARD_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SEARCH_CARD_FAIL, {error: err}));
            }
        )
    }
};

const updateCard = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.UPDATE_CARD_PROGRESS));
        callApi('/api/update_card_number',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.UPDATE_CARD_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.UPDATE_CARD_FAIL, {error: err}));
            }
        )
    }
};

const importCard = (data) => {
    let formData = new FormData();
    formData.append("file", data.file);
    let config = {
        method: 'post',
        body: formData,
        isFormData: true,
        isAuthorization: true,
        isFileDownload: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.IMPORT_CARD_PROGRESS));
        callApi('/api/import_card_number',
            config,
            null,
            (data) => {
                const addCardCount = +data.headers.get("addCardCount")
                data.blob().then((bin) => {
                    const url = window.URL.createObjectURL(bin);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'wrong-cards.xlsx');
                    document.body.appendChild(link);
                    link.click();
                });
                dispatch(updateStatus(STATUS.IMPORT_CARD_SUCCESS, {addCardCount}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.IMPORT_CARD_FAILED, {error: err}));
            }
        );
    };
};

const getAllCardsUnused = () => {
    let config = {
        method: "get",
        isAuthorization: true
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_CARDS_UNUSED_PROGRESS, {
            loading: true,
        }));
        callApi('/api/get_all_card_unused',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_CARDS_UNUSED_SUCCESS, {
                    listCards: data.listCard
                }))
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_CARDS_UNUSED_FAIL, {error: err}));
            }
        )
    }
};

const updateStatusCard = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.UPDATE_STATUS_CARD_PROGRESS));
        callApi('/api/update_status_card_update',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.UPDATE_STATUS_CARD_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.UPDATE_STATUS_CARD_FAIL, {error: err}));
            }
        )
    }
};

const resetStatus = () => {
    return (dispatch) => {
        dispatch(updateStatus(STATUS.RESET_UPDATED_STATUS));
    }
};

export {
    ACTIONS,
    importCard,
    updateCard,
    getCards,
    exportCard,
    editCard,
    searchCardNumber,
    resetStatus,
    getAllCardsUnused,
    updateStatusCard,
};
