import { callApi, callApiSGP } from '../utils/apiUtils';
import _ from 'lodash';

const ACTIONS = {
  VALIDATE_REGISTER_IMAGE_SIGN: 'VALIDATE_REGISTER_IMAGE_SIGN',
  RESET_REGISTER_TRIPPLE_PAGE: 'RESET_REGISTER_TRIPPLE_PAGE',
  RESET_REGISTER_TRIPPLE_ERROR: 'RESET_REGISTER_TRIPPLE_ERROR',
  SET_APPLICATION_STORE_PARAMS: 'SET_APPLICATION_STORE_PARAMS',
  SEND_VERIFY_PHONE_NUMBER_REQUEST: 'SEND_VERIFY_PHONE_NUMBER_REQUEST',
  SEND_VERIFY_PHONE_NUMBER_SUCCESS: 'SEND_VERIFY_PHONE_NUMBER_SUCCESS',
  SEND_VERIFY_PHONE_NUMBER_FAILURE: 'SEND_VERIFY_PHONE_NUMBER_FAILURE',
  VERIFY_PHONE_CODE_REQUEST: 'VERIFY_PHONE_CODE_REQUEST',
  VERIFY_PHONE_CODE_SUCCESS: 'VERIFY_PHONE_CODE_SUCCESS',
  VERIFY_PHONE_CODE_FAILURE: 'VERIFY_PHONE_CODE_FAILURE',
  CHANGE_STEP_REGISTER_TRIPPLE: 'CHANGE_STEP_REGISTER_TRIPPLE',
  VALIDATE_INFO_CARD_REQUEST: 'VALIDATE_INFO_CARD_REQUEST',
  VALIDATE_INFO_CARD_SUCCESS: 'VALIDATE_INFO_CARD_SUCCESS',
  VALIDATE_INFO_CARD_FAILURE: 'VALIDATE_INFO_CARD_FAILURE',
  VALIDATE_REGISTER_IMAGE_REQUEST: 'VALIDATE_REGISTER_IMAGE_REQUEST',
  VALIDATE_REGISTER_IMAGE_SUCCESS: 'VALIDATE_REGISTER_IMAGE_SUCCESS',
  VALIDATE_REGISTER_IMAGE_FAILURE: 'VALIDATE_REGISTER_IMAGE_FAILURE',
  CREATE_ACCOUNT_SGP_REQUEST: 'CREATE_ACCOUNT_SGP_REQUEST',
  CREATE_ACCOUNT_SGP_SUCCESS: 'CREATE_ACCOUNT_SGP_SUCCESS',
  CREATE_ACCOUNT_SGP_FAILURE: 'CREATE_ACCOUNT_SGP_FAILURE',
  CREATE_APPLICATION_TRIPLE_REQUEST: 'CREATE_APPLICATION_TRIPLE_REQUEST',
  CREATE_APPLICATION_TRIPLE_SUCCESS: 'CREATE_APPLICATION_TRIPLE_SUCCESS',
  CREATE_APPLICATION_TRIPLE_FAILURE: 'CREATE_APPLICATION_TRIPLE_FAILURE',
  ACTIVE_ACCOUNT_SGP_REQUEST: 'ACTIVE_ACCOUNT_SGP_REQUEST',
  ACTIVE_ACCOUNT_SGP_SUCCESS: 'ACTIVE_ACCOUNT_SGP_SUCCESS',
  ACTIVE_ACCOUNT_SGP_FAILURE: 'ACTIVE_ACCOUNT_SGP_FAILURE',
  LOGIN_TO_SGP_REQUEST: 'LOGIN_TO_SGP_REQUEST',
  LOGIN_TO_SGP_SUCCESS: 'LOGIN_TO_SGP_SUCCESS',
  LOGIN_TO_SGP_FAILURE: 'LOGIN_TO_SGP_FAILURE',
  CHANGE_TEMP_DATA_TRIPLE: 'CHANGE_TEMP_DATA_TRIPLE'
};

const sendVerifyPhoneNumber = (params, callback = () => null) => {
  const config = { method: 'post', body: params };
  return dispatch => {
    dispatch({ type: ACTIONS.SEND_VERIFY_PHONE_NUMBER_REQUEST });
    callApi(
      '/api/send_verify_phone_number',
      config,
      null,
      payload => {
        dispatch({
          type: ACTIONS.SEND_VERIFY_PHONE_NUMBER_SUCCESS,
          payload,
          data: params
        });
        callback(ACTIONS.SEND_VERIFY_PHONE_NUMBER_SUCCESS, payload);
      },
      err => {
        dispatch({
          type: ACTIONS.SEND_VERIFY_PHONE_NUMBER_FAILURE,
          payload: { error: err }
        });
        callback(ACTIONS.SEND_VERIFY_PHONE_NUMBER_FAILURE, err);
      }
    );
  };
};

const verifyPhoneCode = (params, callback = () => null) => {
  const config = { method: 'post', body: params };
  return dispatch => {
    dispatch({ type: ACTIONS.VERIFY_PHONE_CODE_REQUEST });
    callApi(
      '/api/verify_phone_code',
      config,
      null,
      payload => {
        dispatch({
          type: ACTIONS.VERIFY_PHONE_CODE_SUCCESS,
          payload,
          data: params
        });
        callback(ACTIONS.VERIFY_PHONE_CODE_SUCCESS, payload);
      },
      err => {
        dispatch({
          type: ACTIONS.VERIFY_PHONE_CODE_FAILURE,
          payload: { error: err }
        });
        callback(ACTIONS.VERIFY_PHONE_CODE_FAILURE, err);
      }
    );
  };
};

const validateInfoCard = (params, callback = () => null) => {
  const config = { method: 'post', body: params };
  return dispatch => {
    dispatch({ type: ACTIONS.VALIDATE_INFO_CARD_REQUEST });
    callApi(
      '/api/validate_info_card',
      config,
      null,
      payload => {
        dispatch({
          type: ACTIONS.VALIDATE_INFO_CARD_SUCCESS,
          payload,
          data: params
        });
        callback(ACTIONS.VALIDATE_INFO_CARD_SUCCESS, payload);
      },
      err => {
        dispatch({
          type: ACTIONS.VALIDATE_INFO_CARD_FAILURE,
          payload: { error: err }
        });
        callback(ACTIONS.VALIDATE_INFO_CARD_FAILURE, err);
      }
    );
  };
};

const validateRegisterImage = (params, callback = () => null) => {
  const formData = new FormData();

  if (!_.isEmpty(params)) {
    Object.keys(params).forEach(key => {
      formData.append(key, params[key]);
    });
  }

  const config = {
    method: 'post',
    body: formData,
    isFormData: true
  };

  return dispatch => {
    dispatch({ type: ACTIONS.VALIDATE_REGISTER_IMAGE_REQUEST });
    callApi(
      '/api/validate_register_image',
      config,
      null,
      payload => {
        dispatch({
          type: ACTIONS.VALIDATE_REGISTER_IMAGE_SUCCESS,
          data: params
        });
        callback(ACTIONS.VALIDATE_REGISTER_IMAGE_SUCCESS);
      },
      err => {
        dispatch({
          type: ACTIONS.VALIDATE_REGISTER_IMAGE_FAILURE,
          payload: { error: err }
        });
        callback(ACTIONS.VALIDATE_REGISTER_IMAGE_FAILURE, err);
      }
    );
  };
};

const createAccountSGP = (params, callback = () => null) => {
  const config = {
    method: 'post',
    body: params
  };
  return dispatch => {
    dispatch({ type: ACTIONS.CREATE_ACCOUNT_SGP_REQUEST });
    callApi(
      '/api/create_account_sgp',
      config,
      null,
      payload => {
        dispatch({
          type: ACTIONS.CREATE_ACCOUNT_SGP_SUCCESS,
          payload,
          data: params
        });
        callback(ACTIONS.CREATE_ACCOUNT_SGP_SUCCESS, payload);
      },
      err => {
        dispatch({
          type: ACTIONS.CREATE_ACCOUNT_SGP_FAILURE,
          payload: { error: { code: err, ...err } }
        });

        callback(ACTIONS.CREATE_ACCOUNT_SGP_FAILURE, err);
      }
    );
  };
};

const activeAccountSGP = (params, callback = () => null) => {
  const config = {
    method: 'post',
    body: params
  };

  return dispatch => {
    dispatch({ type: ACTIONS.ACTIVE_ACCOUNT_SGP_REQUEST });
    callApi(
      '/api/active_account_sgp',
      config,
      null,
      payload => {
        dispatch({ type: ACTIONS.ACTIVE_ACCOUNT_SGP_SUCCESS, data: params });
        callback(ACTIONS.ACTIVE_ACCOUNT_SGP_SUCCESS);
      },
      err => {
        dispatch({
          type: ACTIONS.ACTIVE_ACCOUNT_SGP_FAILURE,
          payload: { error: { code: err.message, ...err } }
        });

        callback(ACTIONS.ACTIVE_ACCOUNT_SGP_FAILURE, err);
      }
    );
  };
};

const loginToSGP = (params, callback = () => null) => {
  const config = {
    method: 'post',
    body: params
  };
  return dispatch => {
    dispatch({ type: ACTIONS.LOGIN_TO_SGP_REQUEST });
    callApi(
      '/api/login_account_sgp',
      config,
      null,
      payload => {
        dispatch({
          type: ACTIONS.LOGIN_TO_SGP_SUCCESS,
          payload,
          data: params
        });
        callback(ACTIONS.LOGIN_TO_SGP_SUCCESS, payload);
      },
      err => {
        dispatch({
          type: ACTIONS.LOGIN_TO_SGP_FAILURE,
          payload: { error: err }
        });
        callback(ACTIONS.LOGIN_TO_SGP_FAILURE, err);
      }
    );
  };
};

const registerApplication = params => {
  const formData = new FormData();
  if (!_.isEmpty(params)) {
    Object.keys(params).forEach(key => {
      formData.append(key, params[key]);
    });
  }

  const config = {
    method: 'post',
    body: formData,
    isFormData: true
  };

  return (dispatch, callback = () => null) => {
    dispatch({ type: ACTIONS.CREATE_APPLICATION_TRIPLE_REQUEST });
    callApi(
      '/api/create_application_triple',
      config,
      null,
      payload => {
        dispatch({
          type: ACTIONS.CREATE_APPLICATION_TRIPLE_SUCCESS,
          data: params
        });
        callback(ACTIONS.CREATE_APPLICATION_TRIPLE_SUCCESS);
      },
      err => {
        dispatch({
          type: ACTIONS.CREATE_APPLICATION_TRIPLE_FAILURE,
          payload: { error: { code: err.message, ...err } }
        });
        callback(ACTIONS.CREATE_APPLICATION_TRIPLE_FAILURE, err);
      }
    );
  };
};

const changeStepRegisterTripple = payload => {
  return dispatch => {
    dispatch({ type: ACTIONS.CHANGE_STEP_REGISTER_TRIPPLE, payload });
  };
};
const resetRegisterTrippleError = () => dispatch => {
  dispatch({ type: ACTIONS.RESET_REGISTER_TRIPPLE_ERROR });
};

const changeTempDataTriple = (data) => dispatch => {
  dispatch({ type: ACTIONS.CHANGE_TEMP_DATA_TRIPLE, data });
};

export { ACTIONS };

export default {
  sendVerifyPhoneNumber,
  changeStepRegisterTripple,
  verifyPhoneCode,
  resetRegisterTrippleError,
  validateInfoCard,
  validateRegisterImage,
  createAccountSGP,
  loginToSGP,
  registerApplication,
  activeAccountSGP,
  changeTempDataTriple
};
