import { Card, DatePicker, Form, Icon, Select } from 'antd';
import { withFormik } from 'formik';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import * as Yup from 'yup';
import actions, { ACTIONS } from '../../../../../../actions/registrationTrippleActions';
import { Button, Checkbox, Input } from '../../../../../../components/antd';
import CodeInput from '../../../../../../components/antd/code-input';
import { allCountries, applicationInfoOnLocal, getBase64File } from '../../../../../../utils/commonUtils';
import { countryUsing } from "../../../../../../constants/commonData";
import './style.css';

const ALLOW_MONTH_PASSPORT_EXPIRE_FROM_NOW = 7;
const THIS_STEP = 4;
const { Item } = Form;
const { Option } = Select;
const passportOfChina = ['TWN', 'MAC'];

const allField = [
  'idNumber',
  'expiredDate',
  'issueDate',
  'placeIssued',
  'imageFont',
  'imageBack',
  'imageSelfie',
  'imageBankStatement'
];

const allFieldUpload = [
  {
    label: 'ID Card - Font',
    labelPassport: 'Passport',
    name: 'imageFont'
  },
  {
    label: 'ID Card - Back',
    name: 'imageBack'
  },
  {
    label: 'ID Card - Selfie',
    labelPassport: 'Passport - Selfie',
    name: 'imageSelfie'
  },
  {
    label: 'Bank Statement',
    labelPassport: 'Bank Statement',
    name: 'imageBankStatement'
  }
];

const Compress = require('compress.js')
const compress = new Compress()

class RegistrationStep4 extends Component {
  constructor(props) {
    super(props);

    const application = applicationInfoOnLocal(props.registerTrippleStore.businessMemberId);
    this.nationality = application.nationality;
    this.countryUsingCard = countryUsing.identityCard.includes(this.nationality);
    const { registerTrippleStore: { businessMemberId } } = props;
    const applicationLocal = applicationInfoOnLocal(businessMemberId);

    this.state = {
      longTerm: application.expiredDate === '' && this.countryUsingCard,
      imageFont : null,
      imageBack : null,
      imageSelfie : null,
      imageBankStatement : null,
      initialValues: {
        quantity: 1,
        baseCurrency: 'USD',
        businessMemberId,
        ...applicationLocal,
      },
      noProvince: applicationLocal.prefecture === ''
    };
  }

  componentDidMount() {
    this.props.validateForm(this.props.values);
  }

  setTouched(fieldName) {
    const touched = this.props.touched;
    touched[fieldName] = true;
    this.props.setTouched({ ...touched });
  }

  getTouched(fieldName) {
    return this.props.touched[fieldName];
  }

  handleSubmit(values) {
    const application = applicationInfoOnLocal(this.props.registerTrippleStore.businessMemberId);
    const { businessId } = this.props;
    if(!this.countryUsingCard)
      delete values.imageBack;

    let expiredDate = moment(values.expiredDate).format('YYYY-M-D');
    if(this.state.longTerm)
      expiredDate = '';

    const params = {
      ...values,
      expiredDate,
      issueDate: moment(values.issueDate).format('YYYY-M-D'),
      businessId,
      firstname: application.firstname,
      lastname: application.lastname,
      businessMemberId: application.businessMemberId,
      birthday: moment(application.birthday).format('YYYY-M-D'),
      nationality: application.nationality
    };

    this.props.validateRegisterImage(params, status => {
      if (status !== ACTIONS.VALIDATE_REGISTER_IMAGE_SUCCESS) {
        return;
      }
      let save = {};

      for (let i = 0; i < 4; i++) {
        save[allField[i]] = params[allField[i]];
      }

      this.props.nextStep(save);
    });
  }

  handleChange = async e => {
    const file = e.target.files[0];

    if (!file) return;
    const fieldName = e.target.name;

    const files = [...e.target.files];
    let uploadableFiles = [];

    compress.compress([...e.target.files], {
      quality: 1, // the quality of the image, max is 1,
    }).then(async (modFiles) => {
      // returns an array of compressed images

      for (let i = modFiles.length - 1; i >= 0; i--) {
        let previewPassportImage = await getBase64File(Compress.convertBase64ToFile(modFiles[i].data, modFiles[i].ext))
        let newFile = Compress.convertBase64ToFile(modFiles[i].data, modFiles[i].ext);
        let filename = file.size;
        let fileType = file.type;
        let fileLastMod = files[i].lastModified;

        uploadableFiles.push(new File([newFile], filename, {type: fileType, lastModified: fileLastMod}));
        file.preview = await getBase64File(file);
        uploadableFiles[0].preview = previewPassportImage;
        this.props.setFieldValue(fieldName, uploadableFiles[0]);
        this.setState({
          [fieldName]: file.size
        })
      }
    })
  };
  renderText(translate, nationality, name) {
    if (name === 'imageFont' && !this.countryUsingCard)
      return (
        <p>{translate('Require more than senven month')}</p>
      );

    if (name === 'imageBankStatement' && (this.countryUsingCard || passportOfChina.includes(nationality)))
      return (
        <div className='image-text'>
          <p>{translate('Bank Statement must include the following items')}</p>
          <p>{translate('1. ID card number')}</p>
          <p>{translate('2. Name')}</p>
          <p>{translate('3. Current residential address')}</p>
          <p>{translate('4. Bank certification stamp')}</p>
          <p>{translate('*Bank Statement must have been issued within three months prior to making the application.')}</p>
        </div>
      );

    if (name === 'imageBankStatement' && nationality === 'JPN')
      return (
        <div className='image-text'>
          <p>{translate('Please upload a document that can be confirmed your residential address, referring to the followings.')}</p>
          <p>{translate('Drivers licence')}</p>
          <p>{translate('Utility bill')}</p>
          <p>{translate('Invoices issued by public institutions')}</p>
          <p>{translate('Bank statement')}</p>
          <p>{translate('*Certificate of residence you provide, excepts drivers licence, has to be issued within three months prior to the application date.')}</p>
        </div>
      );

  }

  renderFieldUpload(translate) {
    const { initialValues } = this.state;

    return allFieldUpload.map((item, index) => {
      let preview = this.props.values[item.name]
        ? this.props.values[item.name].preview
        : index === 2 && ["JPN", "MAC", "TWN"].includes(initialValues.nationality)
          ? 'assets/images/triple/cardImage/img-3.png'
          :`assets/images/triple/cardImage/img${index + 1}.png`;

      if(!this.countryUsingCard && item.name === 'imageBack')
        return;

      const image1 = `assets/images/triple/cardImage/img1.png`;
      if (preview === image1 && item.name === 'imageFont' && !this.countryUsingCard)
        preview = `assets/images/triple/cardImage/imgPassport.png`;

      const label = this.countryUsingCard ? item.label : item.labelPassport;

      return (
        <>
          <Item
            required
            key={index}
            label={translate(label)}
            validateStatus={
              this.getTouched(item.name) &&
              this.props.errors[item.name] &&
              'error'
            }
            colon={false}
            help={this.getTouched(item.name) && this.props.errors[item.name] && translate(this.props.errors[item.name])}
          >
            { this.renderText(translate, this.nationality, item.name) }
            {preview && (
              <Card>
                <img style={{ width: '100%' }} src={preview} alt="Preview" />
                <label className="image-info">
                  {this.props.values[item.name] &&
                    `${translate('Filename')}: ${this.props.values[item.name].name}, ${translate('Size')}: ${this.state[item.name]} Bytes`}
                </label>
              </Card>
            )}
            <input
              id={`ID-${item.name}`}
              name={item.name}
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={this.handleChange}
            />
            <div className="content-button-upload">
              <label
                className="upload-input"
                htmlFor={`ID-${item.name}`}
                onClick={() => this.setTouched(item.name)}
              >
                <Icon type="upload" />
                <span>{translate('Upload Image')}</span>
              </label>
            </div>
          </Item>
          {index === 2 && ["JPN", "MAC", "TWN"].includes(initialValues.nationality) &&
          <Item
            label={" "}
            className='box-img-error'
          >
            <Card className="img-error">
              <img src="assets/images/triple/cardImage/img-error.png" alt=""/>
            </Card>
          </Item>}
        </>
      );
    });
  }

  renderFormCardInfo() {
    const errors = this.props.errors;
    const issuePlaceJapan = allCountries.find(c => c.code === 'JPN');
    if(this.state.longTerm)
      delete errors.expiredDate;

    if(!this.countryUsingCard)
      delete errors.imageBack;

    const disabled = !isEmpty(errors);
    const { businessId } = this.props;
    const { type } = this.props.registerTrippleStore;
    return (
      <Translate>
        {({translate}) => (
          <div className="page-card-info">
            <div className="page-card-content">
              <Item
                label={translate(this.countryUsingCard ? 'Card number' : 'Passport number')}
                required
                validateStatus={
                  this.getTouched('idNumber') &&
                  this.props.errors.idNumber &&
                  'error'
                }
                colon={false}
                help={this.getTouched('idNumber') && this.props.errors.idNumber && translate(this.props.errors.idNumber)}
                className="id-number-input"
              >
                {
                  this.countryUsingCard ?
                    <CodeInput
                      name="idNumber"
                      limit={5}
                      count={4}
                      field={{
                        value: this.props.values.idNumber,
                        name:'idNumber',
                        onBlur: e => this.props.handleBlur(e),
                        onChange: event => {
                          const value = event.target.value.toUpperCase();
                          this.props.setFieldValue('idNumber', value);
                        }
                      }}
                      colon={false}
                    /> :
                    <Input
                      name="idNumber"
                      value={this.props.values.idNumber}
                      onBlur={() => this.setTouched('idNumber')}
                      onChange={ event => {
                          const value = event.target.value.toUpperCase();
                          this.props.setFieldValue('idNumber', value);
                        }
                      }
                      colon={false}
                      className='passport-number-input'
                    >

                    </Input>
                }
              </Item>
              <Item
                label={translate('Date of expiry')}
                required
                validateStatus={
                  this.getTouched('expiredDate') &&
                  this.props.errors.expiredDate &&
                  'error'
                }
                help={
                  this.getTouched('expiredDate') && this.props.errors.expiredDate && translate(this.props.errors.expiredDate)
                }
                colon={false}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  name="expiredDate"
                  format="YYYY-MM-DD"
                  className="card-expiry-date-input"
                  placeholder={translate('Choose date of expiry. YYYY-MM-DD')}
                  disabled={this.state.longTerm}
                  onOpenChange={() => this.setTouched('expiredDate')}
                  value={ this.props.values.expiredDate != '' ? this.props.values.expiredDate : null}
                  onChange={ value => {
                      value = value ? value : '';
                      this.props.setFieldValue('expiredDate', value);
                    }
                  }
                />
                {
                  this.countryUsingCard &&
                  <Checkbox
                    required
                    onChange={e => {
                      const longTerm = e.target.checked;
                      this.setState({ longTerm });
                      const expiredDate = longTerm ? null : '';
                      this.props.setFieldValue('expiredDate', expiredDate)
                      this.setTouched('expiredDate')
                    }}
                    checked={this.state.longTerm}
                    className="check-box-expiry-date"
                  >
                    {translate('Long term')}
                  </Checkbox>
                }

              </Item>
              <Item
                required
                label={translate('Issue date')}
                validateStatus={
                  this.getTouched('issueDate') &&
                  this.props.errors.issueDate &&
                  'error'
                }
                help={this.getTouched('issueDate') && this.props.errors.issueDate && translate(this.props.errors.issueDate)}
                colon={false}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  name="issueDate"
                  format="YYYY-MM-DD"
                  className="card-issue-date-input"
                  placeholder={translate('Choose issue date. YYYY-MM-DD')}
                  onOpenChange={isOpen =>
                    !isOpen ? this.setTouched('issueDate') : ''
                  }
                  value={this.props.values.issueDate}
                  onChange={value => this.props.setFieldValue('issueDate', value) }
                />
              </Item>
              <Item
                required
                label={translate('Issue place')}
                validateStatus={
                  this.getTouched('placeIssued') &&
                  this.props.errors.placeIssued &&
                  'error'
                }
                help={
                  this.getTouched('placeIssued') && this.props.errors.placeIssued && translate(this.props.errors.placeIssued)
                }
                colon={false}
              >
                <Select
                  className="country-selection"
                  name="placeIssued"
                  showSearch
                  style={{ height: 42 }}
                  placeholder={translate('Select a country')}
                  value={this.props.values.placeIssued}
                  onBlur={() => this.setTouched('placeIssued')}
                  onChange={value => {
                    this.props.setFieldValue('placeIssued', value);
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    allCountries.map((item, index) => {
                      return (
                        <Option key={index} value={item.code}>
                          {item.name}
                        </Option>
                      );
                    })
                  }
                </Select>
              </Item>
              <div className="all-image-card">{this.renderFieldUpload(translate)}</div>
              <div className="group-button-submit">
                <Button type="delete" onClick={this.props.prevStep}>
                  {translate('Back')}
                </Button>
                <Button
                  disabled={disabled}
                  loading={type === ACTIONS.VALIDATE_REGISTER_IMAGE_REQUEST}
                  onClick={() => {
                    const values = this.props.values;
                    this.handleSubmit(values);
                  }}
                >
                  {translate('Next')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }

  render() {
    return (
      <div className="step-registration-4">
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 15 }}>
          {this.renderFormCardInfo()}
        </Form>
      </div>
    );
  }
}

const validateImageFile = Yup.mixed()
  .required('A image file is required')
  .test('fileFormat', 'Only image file', value => {
    if (!value) return;
    return value && value.type.includes('image');
  })
  .test(
    'fileType',
    `File only allowed one of [jpg, JPG, jpeg, JPEG]`,
    value => {
      if (!value) return;

      const validImage = ['jpg', 'JPG', 'jpeg', 'JPEG'];
      if (validImage.includes(value.type.substr(6))) return true;
    }
  )
  .test('fizeSize', 'File size must from 300KB to 4MB', value => {
    if (!value) return;
    return value.name > 300 * 1024 && value.name < 4 * 1024 * 1024;
  });

RegistrationStep4 = withFormik({
  mapPropsToValues(props) {
    const initValue = {};
    const { maxStep, businessMemberId } = props.registerTrippleStore;
    if (maxStep >= THIS_STEP) {
      const applicationInfo = applicationInfoOnLocal(businessMemberId);
      if (applicationInfo && Array.isArray(allField)) {
        allField.forEach(key => {
          initValue[key] = applicationInfo[key];

          if (props.registerTrippleStore[key])
            initValue[key] = props.registerTrippleStore[key];
        });
      }
    }

    return {
      idNumber: '',
      expiredDate: '',
      issueDate: '',
      placeIssued: '',
      imageFont: '',
      imageBack: '',
      imageSelfie: '',
      imageBankStatement: '',
      ...initValue
    };
  },
  validationSchema: Yup.object().shape({
    idNumber: Yup.string()
      .required('Value is required')
      .test('validate', 'Value invalid', value => {
        if (!value) return;

        return /^([0-9A-Z]*)$/i.test(value);
      }),
    expiredDate: Yup.date().nullable()
    .test('validateExpiredDate', `Expired date must more than ${ALLOW_MONTH_PASSPORT_EXPIRE_FROM_NOW} months`, value => {
      if(!value) return true;

      const currentDate = moment().add(ALLOW_MONTH_PASSPORT_EXPIRE_FROM_NOW, 'months').format('YYYY-MM-DD');
      return value != 'undefined' && moment(value).format('YYYY-MM-DD') > currentDate;
    })
    .test('required', 'Value is required', value => {
      if(value === undefined)
        return false;

      return true;
    }),
    issueDate: Yup.date().nullable().required('Value is required')
    .test('validateIssueDate', 'Issue date must less than current date', value => {
      if(!value) return true;

      const currentDate = moment().format('YYYY-MM-DD');
      return value != 'undefined' && moment(value).format('YYYY-MM-DD') < currentDate;
    }),
    placeIssued: Yup.string().required('Value is required'),
    imageFont: validateImageFile,
    imageBack: validateImageFile,
    imageSelfie: validateImageFile,
    imageBankStatement: validateImageFile
  })
})(RegistrationStep4);

export default connect(
  state => ({
    registerTrippleStore: state.registrationTripple
  }),
  {
    validateRegisterImage: actions.validateRegisterImage
  }
)(RegistrationStep4);
