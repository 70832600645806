import {ACTIONS} from '../actions/memberAction';

const initialState = {
    status: null,
    users: [],
    memberError: null,
    info: null,
};

const STATUS = {
    GET_USER_PROGRESS: "GET_USER_PROGRESS",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_FAILED: "GET_USER_FAILED",
    DELETE_USER_PROGRESS: "DELETE_USER_PROGRESS",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_USER_FAILED: "DELETE_USER_FAILED",
    CREATE_USER_PROGRESS: "CREATE_USER_PROGRESS",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAILED: "CREATE_USER_FAILED",
    UPDATE_USER_PROGRESS: "UPDATE_USER_PROGRESS",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
    GET_INFO_PROGRESS: "GET_INFO_PROGRESS",
    GET_INFO_SUCCESS: "GET_INFO_SUCCESS",
    GET_INFO_FAILED: "GET_INFO_FAILED",
};
export {STATUS};
export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_STATUS:
            switch (action.data.status) {
                case STATUS.GET_USER_PROGRESS:
                case STATUS.DELETE_USER_PROGRESS:
                case STATUS.CREATE_USER_PROGRESS:
                case STATUS.UPDATE_USER_PROGRESS:
                case STATUS.GET_INFO_PROGRESS:
                    return {
                        ...state,
                        status: action.data.status
                    };
                case STATUS.GET_USER_SUCCESS:
                    return {
                        ...state,
                        status: action.data.status,
                        users: action.data.statusInfo.users
                    };
                case STATUS.GET_INFO_SUCCESS:
                    return {
                        ...state,
                        status: action.data.status,
                        info: action.data.statusInfo.info
                    };
                case STATUS.CREATE_USER_SUCCESS:
                    return {
                        ...state,
                        status: action.data.status,
                        users: [...state.users, action.data.statusInfo.user]
                    };
                case STATUS.DELETE_USER_SUCCESS:
                    let userIndex = [...state.users].findIndex(item => item._id === action.data.statusInfo.data._id);
                    return {
                        ...state,
                        status: action.data.status,
                        users: [
                            ...state.users.slice(0, userIndex),
                            ...state.users.slice(userIndex + 1)
                        ]
                    };
                case STATUS.UPDATE_USER_SUCCESS:
                    let user = [...state.users].findIndex(item => item._id === action.data.statusInfo.data._id);
                    return {
                        ...state,
                        status: action.data.status,
                        users: [
                            ...state.users.slice(0, user),
                            Object.assign({}, state.users[user], action.data.statusInfo.data),
                            ...state.users.slice(user + 1)
                        ]
                    };

                case STATUS.GET_USER_FAILED:
                case STATUS.DELETE_USER_FAILED:
                case STATUS.CREATE_USER_FAILED:
                case STATUS.UPDATE_USER_FAILED:
                case STATUS.GET_INFO_FAILED:
                    return {
                        ...state,
                        status: action.data.status,
                        memberError: action.data.statusInfo.error
                    };
                default:
                    return state;
            }
        default:
            return state;
    }
}


