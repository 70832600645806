import React from 'react';

import {
    Button, Dialog, DialogContent,
    DialogActions, DialogContentText,
    DialogTitle
} from '@material-ui/core/';

export default class Alert extends React.Component {
    render() {
        return (
            <div className="alert-dialog">
                <Dialog fullWidth={true}
                        open={true}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        {this.props.content}
                    </DialogContent>
                    <DialogActions>
                        {
                            this.props.cancelTitle &&
                            <Button color="primary" onClick={() => {
                                this.props.onCancel && this.props.onCancel();
                            }}>
                                {this.props.cancelTitle}
                            </Button>
                        }
                        <Button onClick={() => {
                            this.props.onOK && this.props.onOK();
                        }} color="primary" autoFocus>
                            {this.props.okTitle}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
