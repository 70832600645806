import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "querystring";
import {Translate} from "react-localize-redux";
import {
    STATUS,
    updateWrongUserInfo,
} from "../../../../actions/registrationActions";
import "./style.css";
import {TYPE_SEND_MAIL} from "../../../../utils/commonUtils"
import {saveUserRegisterToken} from "../../../../utils/apiUtils";
import {Button, CircularProgress, TextField} from "@material-ui/core";
import RegisterForm from "../Form";
import Alert from "../../../../components/Alert";

class FullName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            alertAck: false,
            purchaseId: ''
        };

        let query = props.location.search;
        if (query.indexOf("?") >= 0) query = query.substr(query.indexOf("?") + 1);
        let parsed = queryString.parse(query);
        if (parsed["purchase_id"]) {
            const token = parsed["token"];
            this.state.purchaseId = parsed['purchase_id'];
            saveUserRegisterToken({token: token});
        }
    }

    handleChange(name) {
        return (e) => {
            if (e.target) {
                this.setState({[name]: e.target.value});
            }
        }
    }

    validateName = value => {
        const re = /^[A-Z ]+$/;
        let result = value.match(re);
        return result && result[0] === value;
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.stepData !== nextProps.stepData) {
            this.setState({stepData: nextProps.stepData});
        }
    }

    render() {
        return (
            <Translate>
                {({translate}) => (
                    <RegisterForm>
                        <div className="full-name-update">
                            <div className="content">
                                <div className="row">
                                    <div className="col-12 col-sm-3">
                                        <div className="content-title">
                                            <label htmlFor="other">First Name</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-9">
                                        <div className="content-value">
                                            <TextField
                                                placeholder="First Name (Example:TANAKA)"
                                                className="input"
                                                id="firstname"
                                                label="First Name"
                                                value={this.state.firstname}
                                                onChange={this.handleChange('firstname')}
                                                error={!this.state.firstname || !this.validateName(this.state.firstname)}
                                                required={!this.state.firstname || !this.validateName(this.state.firstname)}
                                                margin="normal"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-3">
                                        <div className="content-title">
                                            <label htmlFor="other">Last Name</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-9">
                                        <div className="content-value">
                                            <TextField
                                                placeholder="Last Name (Example:KEN)"
                                                className="input"
                                                id="lastname"
                                                label="Last Name"
                                                value={this.state.lastname}
                                                onChange={this.handleChange('lastname')}
                                                required={!this.state.lastname || !this.validateName(this.state.lastname)}
                                                error={!this.state.lastname || !this.validateName(this.state.lastname)}
                                                margin="normal"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {
                                        this.setState({alertAck: false});
                                        this.props.updatePassport({
                                            type: TYPE_SEND_MAIL.WRONG_FULL_NAME,
                                            applicationId: this.state.purchaseId,
                                            firstname: this.state.firstname,
                                            lastname: this.state.lastname
                                        });
                                    }}
                                    disabled={
                                        !this.state.firstname || !this.validateName(this.state.firstname) ||
                                        !this.state.lastname || !this.validateName(this.state.lastname) ||
                                        this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS
                                    }
                                    variant="contained"
                                    color="primary">
                                    {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS && (
                                        <CircularProgress className="margin-right-20px" size={20}/>
                                    )}
                                    <span>Update Full Name</span>
                                </Button>
                            </div>
                            {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_FAIL && !this.state.alertAck && (
                                <Alert title="Error"
                                       content={translate("errors." + this.props.stepData.statusInfo.error.code)}
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                            {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_SUCCESS && !this.state.alertAck && (
                                <Alert title="Success"
                                       content="Update full name success. Thank for your support"
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                        </div>
                    </RegisterForm>
                )}
            </Translate>
        );
    }
}

const mapStateToProps = state => {
    return {
        stepData: state.registration.stepData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePassport: data => {
            dispatch(updateWrongUserInfo(data));
        },
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FullName)
);
