import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import _ from 'lodash';

const BoxInput = styled(Input)`
	width: ${props => (props.limit || 1) * 23 + 26}px;
	height: 42px;
  line-height: 30px;
  font-size: 30px;
  font-weight: bold;
  padding: 0;
  text-align: center;
	margin-right: 6px;
	text-transform: uppercase;
	&:hover, &:focus {
    border: solid 1px #d7d7d7;
    box-shadow: none;
	}
`;

const Box = styled.div`
  input:last-child {
    margin-right: 0;
	}
`;

class SingleInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {value: this.props.value};
	}
	
	handleValueChange = (value) => {
		const { onValueFilled, onValueChange, limit } = this.props;
		if (value.length >= limit) {
			onValueFilled();
		}
		if (value.length <= limit) {
			this.setState({ value });
			onValueChange(value);
		}
	}
	
	handleKeyPress = (e) => {
		const { onBackspace, onFocusNext, onFocusPrev } = this.props;
		const { value } = this.state;
		const key = e.keyCode;
		
		if (key === 8 && !value && onBackspace) {
			onBackspace();
		}
		const start = e.target.selectionStart;
		const end = e.target.selectionEnd;
		if (key === 37 && start === 0) {
			onFocusPrev();
		}
		if (key === 39 && value.length === start) {
			onFocusNext();
		}
	}
	
	render() {
		const { limit, onClickFocus } = this.props;
		const { value } = this.state;

		return (
			<BoxInput
				disabled={this.props.disabled}
				ref={(input) => {
					this.props.onRefLoad(input);
				}}
				onChange={(e) => {
					this.handleValueChange(e.target.value);
				}}
				onBlur={this.props.onBlur}
				className="input"
				value={value}
				onKeyDown={(e) => {
					this.handleKeyPress(e);
				}}
				limit={limit}
				onClick={onClickFocus}
			/>
		);
	}
}

export default class CodeInput extends React.Component {
	constructor(props) {
		super(props);
		const { field, limit } = props;
		let _state = {
			input: []
		};
		this.inputRefs = [];
		for (let i = 0; i < this.props.count; i++) {
			const value = field.value ? field.value.slice(i * limit, (i + 1) * limit) : '';
			_state.input.push({ value });
			this.inputRefs.push({ref: null});
		}
		this.state = _state;
	}
	
	handleBlur(e) {
		const field  = this.props.field;
		if(field && field.onBlur) {
			e.target.name = field.name;
			field.onBlur(e);
		}
	}

	handleValueChange = (index, value) => {
		let newState = {
			...this.state,
			input: [...this.state.input.slice(0, index), {value}, ...this.state.input.slice(index + 1)]
		};
		this.checkInputFinish(newState);
		this.setState(newState);
		const values = newState.input.map(item => item.value).join('');
		this.props.field.onChange({
			target: {
				value: values,
				name: this.props.field.name
			}
		});
	}
	
	checkInputFinish = (state) => {
		let notFinished = state.input.some((input) => {
			return !input.value
		});
		(!notFinished && this.props.onInputFinish) && this.props.onInputFinish(state.input.reduce((c, v) => {
			return c + v.value;
		}, ''));
		(notFinished && this.props.onInputNotFinish) && this.props.onInputNotFinish();
	}

	onClickFocus = (e) => {
		const indexBlankInput = _.findIndex(this.state.input, item => !item.value.trim());
		if (indexBlankInput !== -1) {
			this.inputRefs[indexBlankInput].ref.focus();
		}
	}
	
	render() {
		const { limit, disabled } = this.props;

		return (
			<Box>
				{this.state.input.map((input, index) => (
					<SingleInput
						key={index}
						type={this.props.type}
						value={input.value}
						onBlur={e => this.handleBlur(e)}
						onValueChange={(value) => {
							this.handleValueChange(index, value);
						}}
						onValueFilled={() => {
							if (index < this.inputRefs.length - 1)
								this.inputRefs[index + 1].ref.focus();
						}}
						onBackspace={() => {							
							if (index > 0) {
								this.inputRefs[index - 1].ref.focus();
							}
						}}
						onFocusPrev={() => {							
							if (index > 0) {
								this.inputRefs[index - 1].ref.focus();
								const currentValueLength = this.state.input[index - 1].value.length;
								this.inputRefs[index - 1].ref.input.selectionStart = currentValueLength;
								this.inputRefs[index - 1].ref.input.selectionEnd = currentValueLength;
							}
						}}
						onFocusNext={() => {
							if (index !== (this.inputRefs.length - 1)) {
								this.inputRefs[index + 1].ref.focus();
								this.inputRefs[index + 1].ref.input.selectionStart = 0;
								this.inputRefs[index + 1].ref.input.selectionEnd = 0;
							}
						}}
						onRefLoad={(ref) => {
							this.inputRefs[index].ref = ref;
						}}
						disabled={disabled}
						limit={limit}
						onClickFocus={this.props.field.name !== 'firstname' && this.onClickFocus}
					/>
				))}
			</Box>
		);
	}
}
