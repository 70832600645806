import React, { Component } from "react";
import { Form, FormGroup, Label, Col, Input } from "reactstrap";
import { systemSendMailToAllUser } from "../../../../actions/adminActions";
import { connect } from "react-redux";
import { STATUS } from "../../../../reducers/adminReducer";
import { Validation, PERMISSION } from "../../../../utils/commonUtils";
import CKEditor from "react-ckeditor-component";
import "react-tagsinput/react-tagsinput.css";
import { Translate } from "react-localize-redux";
import Alert from "../../../../components/Alert";
import "./style.css";
import { Button } from "../../../../components/antd";

class SystemSendMail extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      content: "",
      notifyEmail: "",
      alertAck: true,
      isOpenConfirm: false
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };
  onChange = evt => {
    let newContent = evt.editor.getData();
    this.setState({
      content: newContent
    });
  };

  render() {
    const loading =
      this.props.admin.status === STATUS.SYSTEM_SEND_MAIL_PROGRESS;
    const { listPermission } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <div className="system-send-mail">
            <div className="title">一斉送信</div>
            <Form className="mt-5 mb-5">
              <FormGroup row>
                <Label for="emailTitle" sm={2} className="lbl-right">
                  タイトル:
                </Label>
                <Col sm={8}>
                  <Input
                    type="input"
                    name="emailTitle"
                    id="emailTitle"
                    onChange={e => this.setState({ title: e.target.value })}
                    placeholder="タイトル"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="emailContent" sm={2} className="lbl-right">
                  情報:
                </Label>
                <Col sm={8}>
                  <CKEditor
                    activeClass="p10"
                    content={this.state.content}
                    events={{ change: this.onChange }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="notifyEmail" sm={2} className="lbl-right">
                  お知らせされるメール:
                </Label>
                <Col sm={8}>
                  <Input
                    type="input"
                    name="email"
                    id="email"
                    onChange={e =>
                      this.setState({ notifyEmail: e.target.value })
                    }
                    placeholder="お知らせされるメール"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={10}>
                  {listPermission.includes(
                      PERMISSION.MAIL_SERVICE
                    ) && (
                      <Button
                        className="btn-send"
                        disabled={
                          loading ||
                          !this.state.title.trim() ||
                          !this.state.content.trim() ||
                          !this.state.notifyEmail ||
                          !Validation.validateEmail(this.state.notifyEmail)
                        }
                        onClick={() => {
                          this.setState({ isOpenConfirm: true });
                        }}
                        variant="contained"
                        loading={loading}
                      >
                        送る
                      </Button>
                    )}
                </Col>
              </FormGroup>
            </Form>
            {this.props.admin.status === STATUS.SYSTEM_SEND_MAIL_SUCCESS &&
              !this.state.alertAck && (
                <Alert
                  title="成功"
                  content=" システムが全てのユーザーにメールを送信致しました。"
                  okTitle="OK"
                  onOK={() => {
                    this.setState({ alertAck: true });
                  }}
                />
              )}
            {this.state.isOpenConfirm && (
              <Alert
                title="確認ダイアログ"
                content="メールを送りますか？"
                okTitle="OK"
                cancelTitle="Cancel"
                onOK={() => {
                  this.setState({ isOpenConfirm: false });
                  this.alertAck();
                  this.props.handleSendMail({
                    notifyEmail: this.state.notifyEmail,
                    title: this.state.title,
                    content: this.state.content
                  });
                }}
                onCancel={() => {
                  this.setState({ isOpenConfirm: false });
                }}
              />
            )}
            {this.props.admin.status === STATUS.SYSTEM_SEND_MAIL_FAIL &&
              !this.state.alertAck && (
                <Alert
                  title="Error"
                  content={translate(
                    this.props.error && "errors." + this.props.error.code
                  )}
                  okTitle="OK"
                  onOK={() => {
                    this.setState({ alertAck: true });
                  }}
                />
              )}
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    admin: state.admin,
    error: state.admin.error,
    listPermission: state.admin.listPermission
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSendMail: data => {
      dispatch(systemSendMailToAllUser(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemSendMail);
