import { Button as ButtonAnt } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { ACTIONS } from "../../../../../../actions/registrationTrippleActions";
import { Button } from "../../../../../../components/antd";
import "./style.css";

function RegistrationStep5(props) {
  const { registerTrippleStore: { type }, prevStep } = props;
  const signRef = useRef(null);
  const [widthSign, setWidthSign] = useState(null);

  useEffect(() => {
    updateDimensions();
  }, []);

  const updateDimensions = useCallback(() => {
    if (window.innerWidth < 768) {
      if (widthSign !== 208) {
        setWidthSign(208);
      }
    } else {
      if (widthSign !== 590) {
        setWidthSign(590);
      }
    }
  });

  const handleSubmitSign = () => {
    let signBase64 = signRef.current.getTrimmedCanvas().toDataURL('image/png');
    const { nextStep, validateImageSign } = props;
    validateImageSign(signBase64)
    nextStep();
  };

  const handleClickReloadSign = () => {
    signRef.current.clear();
  };

  return (
    <Translate>
      {({translate}) => (
        <div className="registration-triple-step-5 el-card">
          <p className="title-step">{translate('Please sign here')}</p>
          <div className="sign-container">
            <SignatureCanvas
              penColor='black'
              canvasProps={{ height: 200, width: widthSign, className: 'sign-canvas' }}
              ref={signRef}
            />
          </div>
          <div className="sign-container-button">
            <ButtonAnt
              className="reload-button"
              shape="circle"
              icon="sync"
              onClick={handleClickReloadSign}
            />
          </div>
          <div className="group-button-submit">
            <Button
              type="delete"
              onClick={prevStep}
              className="submit"
              disabled={type === ACTIONS.VERIFY_PHONE_CODE_REQUEST}
            >
              {translate('Back')}
            </Button>
            <Button
              type="default"
              onClick={handleSubmitSign}
              className="submit"
              loading={type === ACTIONS.VERIFY_PHONE_CODE_REQUEST}
            >
              {translate('Submit')}
            </Button>
          </div>
        </div>
      )}
    </Translate>
  );
}

export default connect(
  state => ({
    registerTrippleStore: state.registrationTripple,
    registrationStore: state.registration,
  }),
  dispatch => ({
    validateImageSign: imageSign => {
      return dispatch({ type: ACTIONS.VALIDATE_REGISTER_IMAGE_SIGN, data: {imageSign}});
    }
  })
)(RegistrationStep5);
