import {ACTIONS, STATUS} from "../actions/registrationActions"

const initialState = {
	stepSuccess: [false, false, false, false, false],
	stepData: {
		state: null,
	},
	currentStep: 1,
	armToken: '',
	token: '',
	businessMemberId: '',
	businessId: '',
	status: null,
	isFree: null,
	error: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.REG_UPDATE_STATUS:
			switch (action.data.status) {
				case STATUS.STEP1.TOKEN_VERIFY_PROGRESS:
					return {
						...state,
						status: action.data.status,
					};
				case STATUS.STEP1.TOKEN_VERIFY_SUCCESS:
					return {
						...state,
						businessMemberId: action.data.statusInfo.businessMemberId,
						status: action.data.status,
					};
				case STATUS.STEP1.TOKEN_VERIFY_FAILED:
					return {
						...state,
						businessMemberId: null,
						status: action.data.status,
						error: action.data.statusInfo.code
					};
				case STATUS.STEP1.INIT:
					return {
						...state,
						businessToken: action.data.statusInfo.token,
						businessId: action.data.statusInfo.businessId,
					};
				case STATUS.STEP1.ACKNOWLEDGE:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
						},
						stepSuccess: [...state.stepSuccess.slice(0, 0), true, ...state.stepSuccess.slice(1)]
					};
				case STATUS.STEP2.CORRECT_REGISTER:
				case STATUS.STEP2.REGISTERING:
				case STATUS.STEP2.REGISTER_SUCCESS:
				case STATUS.STEP2.VERIFYING:
				case STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
						}
					};
				case STATUS.STEP2.VERIFY_SUCCESS:
					return {
						...state,
						token: action.data.token,
						stepData: {
							...state.stepData,
							status: action.data.status,
						},
						stepSuccess: [...state.stepSuccess.slice(0, 1), true, ...state.stepSuccess.slice(2)]
					};
				
				case STATUS.STEP2.REGISTER_FAILED:
				case STATUS.STEP2.VERIFY_FAILED:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
							statusInfo: {
								error: action.data.statusInfo.error
							}
						}
					};
				
				case STATUS.STEP3.MODIFY:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
						}
					};
				case STATUS.STEP3.UPDATING:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
							applicationInfo: action.data.statusInfo
						},
					};
				case STATUS.STEP3.UPDATED:
					let stepSuccess = [...state.stepSuccess.slice(0, 2), true, ...state.stepSuccess.slice(3)];
					if (action.data.statusInfo.isFree)
						stepSuccess = [...stepSuccess.slice(0, 3), true, ...stepSuccess.slice(4)];
					return {
						...state,
                        isFree: action.data.statusInfo.isFree,
						stepData: {
							...state.stepData,
							status: action.data.status,
							applicationInfo: {
								...state.stepData.applicationInfo,
								_id: action.data.statusInfo._id,
							},
						},
						stepSuccess
					};
				case STATUS.STEP3.UPDATE_FAILED:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
							statusInfo: {
								error: action.data.statusInfo.error
							}
						}
					};
				case STATUS.STEP3.GET_USER_SUCCESS:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
							userInfo: action.data.statusInfo
						}
					};
				case STATUS.STEP3.GET_USER_FAILED:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.error
						}
					};
				case STATUS.STEP4.SEND_MAIL_PROGRESS:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status
						}
					};
				case STATUS.STEP4.SEND_MAIL_SUCCESS:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status
						},
						stepSuccess: [...state.stepSuccess.slice(0, 3), true, ...state.stepSuccess.slice(4)]
					};
				case STATUS.STEP4.SEND_MAIL_FAILED:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
							statusInfo: {
								error: action.data.statusInfo.error
							}
						}
					};
				case STATUS.STEP4.INIT_PAYMENT:
					return {
						...state,
						stepSuccess: [true, true, true, false, false]
					};
				case STATUS.STEP4.LOGIN_PROGRESS:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
							armId: action.data.statusInfo.armId,
						},
					};
				case STATUS.STEP4.LOGIN_SUCCESS:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status
						},
						armToken: action.data.statusInfo.armToken
					};
				case STATUS.STEP4.LOGIN_FAILED:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
							statusInfo: {
								error: action.data.statusInfo.error
							}
						}
					};
				
				case STATUS.STEP4.FETCH_WALLETS:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status
						}
					};
				case STATUS.STEP4.FETCH_COMPLETED:
					return {
						...state,
						stepData: {
							...state.stepData,
							statusInfo: action.data.statusInfo,
							status: action.data.status
						}
					};
				case STATUS.STEP4.FETCH_FAILED:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
							statusInfo: {
								error: action.data.statusInfo.error
							}
						}
					};
				
				case STATUS.STEP4.PAYMENT_PROGRESS:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status
						}
					};
				case STATUS.STEP4.PAYMENT_FINISHED:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status
						},
						stepSuccess: [...state.stepSuccess.slice(0, 3), true, ...state.stepSuccess.slice(4)]
					};
                case STATUS.STEP4.CHECK_TOKEN_PROGRESS:
                case STATUS.STEP4.CHECK_TOKEN_SUCCESS:
                case STATUS.UPDATE_INFO.UPDATE_INFO_SUCCESS:
                    return {
                        ...state,
                        stepData: {
                            ...state.stepData,
                            status: action.data.status
                        }
                    };
                case STATUS.STEP4.CHECK_TOKEN_FAILED:
                case STATUS.UPDATE_INFO.UPDATE_INFO_FAIL:
                    return {
                        ...state,
                        stepData: {
                            ...state.stepData,
                            status: action.data.status,
                            statusInfo: {
                                ...state.stepData.statusInfo,
                                error: action.data.statusInfo.error
                            }
                        }
                    };
				case STATUS.STEP4.PAYMENT_FAILED:
					return {
						...state,
						stepData: {
							...state.stepData,
							status: action.data.status,
							statusInfo: {
								...state.stepData.statusInfo,
								error: action.data.statusInfo.error
							}
						}
					};
				default:
					return state;
			}
		default:
			return state;
	}
};
