import React from 'react';
import queryString from 'querystring';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import NarrowLayout from "../../../../components/_layouts/NarrowLayout";

import Passport from '../Passport';
import NewPassport from '../NewPassport';
import FullName from '../FullName';
import PhoneNumber from '../PhoneNumber';
import Header from "../../../../components/Header";
import Menu from "../../../../components/Menu";
import "./style.css"

const getParamsFromQuery = (query) => {
    if (query.indexOf('?') >= 0) query = query.substr(query.indexOf('?') + 1);
    return queryString.parse(query);
};

class UpdateInfo extends React.Component {

    render() {
        let tobeRendered;
        let params = getParamsFromQuery(this.props.location.search);
        //-- check type to render
        let type = Number(params.type);
        switch (type) {
            case 1:
                tobeRendered = <Passport/>;
                break;
            case 2:
                tobeRendered = <FullName/>;
                break;
            case 3:
                tobeRendered = <NewPassport/>;
                break;
            case 4:
                tobeRendered = <PhoneNumber/>;
                break;
            case 5:
                tobeRendered = <Passport/>;
                break;
            case 6:
                tobeRendered = <Passport/>;
                break;
            default:
                tobeRendered = null;
                break
        }
        return (
            <NarrowLayout>
                <div className="container-form-register-page">
                    <Header/>
                    <Menu/>
                    {tobeRendered}
                </div>
            </NarrowLayout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentStep: state.registration.currentStep,
        stepSuccess: state.registration.stepSuccess,
    };
};

export default withRouter(connect(mapStateToProps, null)(UpdateInfo));
