import { Form } from 'antd';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import * as Yup from 'yup';
import * as moment from 'moment';
import actions, { ACTIONS } from "../../../../../../actions/registrationTrippleActions";
import { Button, FieldItem, Input } from '../../../../../../components/antd';
import { applicationInfoOnLocal, convertBase64toFile } from '../../../../../../utils/commonUtils';
import { countryUsing } from "../../../../../../constants/commonData";
import './style.css';

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Value is required'),
});

class RegistrationStep9 extends Component {
  _renderForm = ({ handleSubmit, ...form }) => {
    const { prevStep, registerTrippleStore: { type } } = this.props;
    
    const disabled = [
      ACTIONS.ACTIVE_ACCOUNT_SGP_REQUEST,
      ACTIONS.CREATE_APPLICATION_TRIPLE_REQUEST
    ].includes(type)

    return (
      <Translate>
        {({translate}) => (
          <Form className="form" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <FieldItem form={form} component={Input} name="code" label="Enter verify code" required />
            <div className="group-button-submit">
              <Button
                type="delete"
                onClick={prevStep}
                className="submit"
                disabled={disabled}
              >
                {translate('Back')}
              </Button>
              <Button
                htmlType="submit"
                onClick={handleSubmit}
                className="submit"
                loading={disabled}
              >
                {translate('Submit')}
              </Button>
            </div>
          </Form>
        )}
      </Translate>
    )
  }

  _onSubmit = (values) => {
    const { 
      registerApplication, 
      activeAccountSGP, 
      registerTrippleStore: {
        businessMemberId, 
        passwordSGP 
      },
      businessId
    } = this.props;

    const application = applicationInfoOnLocal(businessMemberId);

    const params = { 
      codeActive: values.code && values.code.trim(),
      tokenSGP: application.tokenSGP
    }

    activeAccountSGP(params, async status => {
      if(status !== ACTIONS.ACTIVE_ACCOUNT_SGP_SUCCESS) {
        return;
      }
      let {
        imageFont,
        imageBack,
        imageSelfie,
        imageBankStatement,
        imageSign,
      } = this.props.registerTrippleStore;
      
      const params = {
        ...application,
        imageFont,
        imageBack,
        imageSelfie,
        imageBankStatement,
        imageSign,
        email: application.email,
        passwordSGP,
        gender: application.gender === 'male',
        birthday: application.birthday && application.birthday.format('YYYY-M-D'),
        expiredDate: application.expiredDate === '' ? '' : moment(application.expiredDate).format('YYYY-M-D'),
        issueDate: moment(application.issueDate).format('YYYY-M-D'),
        businessId
      }

      delete params.tokenSGP;
      if(!countryUsing.identityCard.includes(application.nationality))
        delete params.imageBack;

      registerApplication(params);
    })
  }

  render() {
    const initialValues = {
      code: '',
    };

    return (
      <div className='registration-triple-step9'>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={this._onSubmit}
          component={this._renderForm}
        />
      </div> 
    )
  }
}

export default connect(
  state => ({
    registerTrippleStore: state.registrationTripple,
  }),
  {
    activeAccountSGP: actions.activeAccountSGP,
    registerApplication: actions.registerApplication
  }
)(RegistrationStep9);
