import React, {Component } from 'react';
import "./style.css";

class NarrowLayout extends Component {
    render (){
        return (
        <div className ="com-layout-narrow-layout">
                <div className= "layout-content">
                {this.props.children}
                </div>
            </div>
        )
    }
}

export default NarrowLayout;

