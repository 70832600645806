import "./style.css";
import * as moment from "moment";
import { withFormik } from "formik";
import React, { Component } from "react";
import { allCountries } from "../../../../../../utils/commonUtils";
import { Form, DatePicker, Select, Card, Input } from "antd";
import CodeInput from "../../../../../../components/antd/code-input";
import { FieldItem, Button } from "../../../../../../components/antd";
import { applicationInfoOnLocal } from "../../../../../../utils/commonUtils";
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import { countryUsing } from "../../../../../../constants/commonData";

const { Option } = Select;
const { Item } = Form;

const allFieldUpload = [
  {
    label: 'ID Card - Font',
    labelPassport: 'Passport',
    name: 'imageFont'
  },
  {
    label: 'ID Card - Back',
    name: 'imageBack'
  },
  {
    label: 'ID Card - Selfie',
    labelPassport: 'Passport - Selfie',
    name: 'imageSelfie'
  },
  {
    label: 'Bank Statement',
    labelPassport: 'Bank Statement',
    name: 'imageBankStatement'
  }
];

class RegistrationStep7 extends Component {
  constructor (props) {
    super(props);
    this.nationality = applicationInfoOnLocal(props.registerTrippleStore.businessMemberId).nationality;
    this.countryUsingCard = countryUsing.identityCard.includes(this.nationality);
  }

  renderFieldUpload(translate) {

    return allFieldUpload.map((item, index) => {
      const imageFile = this.props.registerTrippleStore[item.name];
      const preview = imageFile ? imageFile.preview : null;

      if(!this.countryUsingCard && item.name === 'imageBack')
        return;

      const label = this.countryUsingCard ? item.label : item.labelPassport;

      return (
        <Item
          key={index}
          label={translate(label)}
          validateStatus={this.props.errors[item.name] && "error"}
          help={this.props.errors[item.name]}
          required
          colon={false}
        >
          {preview && (
            <Card>
              <img style={{ width: "100%" }} src={preview} alt="Preview" />
            </Card>
          )}
        </Item>
      );
    });
  }

  renderFormCardInfo() {
    const { nextStep, prevStep } = this.props;
    
    return (
      <Translate>
        {({translate}) => (
          <div className="page-card-info">
            <div className="page-card-content">
              <Item
                label={translate(this.countryUsingCard ? 'Card number' : 'Passport number')}
                required
                colon={false}
                className="id-number-input"
              >
                {
                  this.countryUsingCard ?
                  <CodeInput
                    name="idNumber"
                    limit={5}
                    count={4}
                    disabled
                    field={{
                      value: this.props.values.idNumber,
                    }}
                  /> :
                  <Input
                    name="idNumber"
                    value={this.props.values.idNumber}
                    className='passport-number-input'
                    disabled
                  >
                  </Input>
                }
              </Item>
              <Item
                label={translate('Date of expiry')}
                required
                colon={false}
              >
                <DatePicker
                  style={{width: '100%'}}
                  name="expiredDate"
                  disabled
                  format="YYYY-MM-DD"
                  className="card-expiry-date-input"
                  placeholder={translate('Date of expiry is long term')}
                  defaultValue={
                    this.props.values.expiredDate &&
                    moment(this.props.values.expiredDate) || null
                  }
                />
              </Item>
              <Item
                label={translate('Issue date')}
                required
                colon={false}
              >
                <DatePicker
                  style={{width: '100%'}}
                  name="issueDate"
                  disabled
                  format="YYYY-MM-DD"
                  className="card-issue-date-input"
                  placeholder={translate('Choose issue date. YYYY-MM-DD')}
                  defaultValue={
                    this.props.values.issueDate &&
                    moment(this.props.values.issueDate) || null
                  }
                />
              </Item>
              <Item
                label={translate('Issue place')}
                required
                colon={false}
              >
                <Select
                  name="placeIssued"
                  showSearch
                  disabled
                  style={{ height: 42 }}
                  placeholder={translate('Select a country')}
                  value={this.props.values.placeIssued}
                  className='place-issued'
                >
                  {allCountries.map((item, index) => {
                    return (
                      <Option key={index} value={item.code}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Item>
              <div className="all-image-card">{this.renderFieldUpload(translate)}</div>
              <div className="group-button-submit">
                <Button type="delete" onClick={() => prevStep()}>
                  {translate('Back')}
                </Button>
                <Button onClick={() => nextStep()}>{translate('Next')}</Button>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }

  render() {
    return (
      <div className="step-registration-7">
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
          {this.renderFormCardInfo()}
        </Form>
      </div>
    );
  }
}

RegistrationStep7 = withFormik({
  mapPropsToValues(props) {
    const { 	
      businessMemberId,
      imageFont,
      imageBack,
      imageSelfie,
      imageBankStatement
    } = props.registerTrippleStore
        
    return {
      ...applicationInfoOnLocal(businessMemberId),
      imageFont,
      imageBack,
      imageSelfie,
      imageBankStatement
    };
  }
})(RegistrationStep7);

export default connect(
  state => ({
    registerTrippleStore: state.registrationTripple,
  })
)(RegistrationStep7);
