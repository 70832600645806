import { ACTIONS } from '../actions/registrationTrippleActions';
import { TRIPLE_STEPS } from '../constants/commonData';

const initialState = {
  step: 1,
  error: null,
  type: null,
  maxStep: 1,
  passwordSGP: null,
  businessMemberId: null,
  imageFont: null,
  imageBack: null,
  imageSelfie: null,
  imageBankStatement: null,
  imageSign: null,
  tempData: {}
};

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case ACTIONS.SEND_VERIFY_PHONE_NUMBER_REQUEST:
    case ACTIONS.VERIFY_PHONE_CODE_REQUEST:
    case ACTIONS.VALIDATE_INFO_CARD_REQUEST:
    case ACTIONS.VALIDATE_REGISTER_IMAGE_REQUEST:
    case ACTIONS.CREATE_ACCOUNT_SGP_REQUEST:
    case ACTIONS.CREATE_APPLICATION_TRIPLE_REQUEST:
    case ACTIONS.ACTIVE_ACCOUNT_SGP_REQUEST:
    case ACTIONS.LOGIN_TO_SGP_REQUEST:
      return { ...state, type };
    case ACTIONS.CHANGE_STEP_REGISTER_TRIPPLE: {
      const maxStep =
        action.payload.step > state.maxStep
          ? action.payload.step
          : state.maxStep;
      return {
        ...state,
        step: action.payload.step,
        maxStep,
        businessMemberId: action.payload.businessMemberId
      };
    }
    case ACTIONS.VERIFY_PHONE_CODE_SUCCESS:
      return { ...state, type };
    case ACTIONS.SEND_VERIFY_PHONE_NUMBER_SUCCESS:
      return { ...state, type };
    case ACTIONS.CHANGE_TEMP_DATA_TRIPLE:
      return { ...state, tempData: { ...state.tempData, ...action.data } };
    case ACTIONS.CREATE_ACCOUNT_SGP_SUCCESS:
      return {
        ...state,
        type,
        passwordSGP: action.data.password
      };
    case ACTIONS.LOGIN_TO_SGP_SUCCESS:
      return { ...state, type };
    case ACTIONS.VALIDATE_INFO_CARD_SUCCESS:
      return { ...state, type };
    case ACTIONS.VALIDATE_REGISTER_IMAGE_SIGN: {
      return {
        ...state,
        type,
        imageSign: action.data.imageSign
      };
    }
    case ACTIONS.CREATE_APPLICATION_TRIPLE_SUCCESS: {
      const REGISTRATION_TRIPLE = JSON.parse(
        localStorage.getItem('REGISTRATION_TRIPLE')
      );
      delete REGISTRATION_TRIPLE[state.businessMemberId];

      localStorage.setItem(
        'REGISTRATION_TRIPLE',
        JSON.stringify(REGISTRATION_TRIPLE)
      );

      return {
        ...state,
        type,
        step: TRIPLE_STEPS.length + 1,
        maxStep: TRIPLE_STEPS.length + 1,
        passwordSGP: null,
        businessMemberId: null,
        imageFont: null,
        imageBack: null,
        imageSelfie: null,
        imageBankStatement: null,
        imageSign: null,
        tempData: {}
      };
    }

    case ACTIONS.VALIDATE_REGISTER_IMAGE_SUCCESS: {
      const {
        imageFont,
        imageBack,
        imageSelfie,
        imageBankStatement
      } = action.data;

      return {
        ...state,
        type,
        imageFont,
        imageBack,
        imageSelfie,
        imageBankStatement
      };
    }
    case ACTIONS.ACTIVE_ACCOUNT_SGP_FAILURE:
    case ACTIONS.VALIDATE_REGISTER_IMAGE_FAILURE:
    case ACTIONS.VALIDATE_INFO_CARD_FAILURE: {
      return {
        ...state,
        type,
        error: action.payload.error
      };
    }
    case ACTIONS.SEND_VERIFY_PHONE_NUMBER_FAILURE:
    case ACTIONS.VERIFY_PHONE_CODE_FAILURE:
    case ACTIONS.CREATE_ACCOUNT_SGP_FAILURE:
    case ACTIONS.CREATE_APPLICATION_TRIPLE_FAILURE:
    case ACTIONS.LOGIN_TO_SGP_FAILURE:
      return {
        ...state,
        type,
        error: action.payload.error
      };
    case ACTIONS.RESET_REGISTER_TRIPPLE_ERROR:
      return { ...state, type, error: null };
    default:
      return state;
  }
};
