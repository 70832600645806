import React from 'react';
import {FormControlLabel, Checkbox} from '@material-ui/core';
import './style.css'

class ConfirmItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: this.props.checked
		};
		this.handleChange = this.handleChange.bind(this);
	}
	
	handleChange() {
		let newState = {...this.state, checked: !this.state.checked};
		this.setState(newState);
		this.props.onChange(newState.checked);
	}
	
	render() {
		return (
			<div className="item">
				<div className="header">
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.checked}
								onChange={this.handleChange}
								color="primary"
							/>
						}
						label={this.props.title}
						disabled={this.props.disabled}
					/>
				</div>
				<div className="content">
					{this.props.content}
				</div>
			</div>
		);
	}
}

export default class ConfirmList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			check: props.list.map((item) => {
				return item.checked;
			})
		};
		this.handleItemChange = this.handleItemChange.bind(this);
	}
	
	handleItemChange(index, checked) {
		let newState = {
			...this.state,
			check: [...this.state.check.slice(0, index), checked, ...this.state.check.slice(index + 1)]
		};
		let notFullyChecked = newState.check.some((item) => {
			return !item;
		});
		this.props.onItemChange && this.props.onItemChange(index, checked);
		if (!notFullyChecked && this.props.onFullyChecked) this.props.onFullyChecked();
		if (notFullyChecked && this.props.onNotFullyChecked) this.props.onNotFullyChecked();
		this.setState(newState);
	}
	
	render() {
		return (
			<div className="component-ConfirmList">
				{this.props.list && this.props.list.length &&
				this.props.list.map((item, index) => {
					return (
						<ConfirmItem
							key={index}
							checked={item.checked} disabled={this.props.disabled}
							onChange={(checked) => {
								this.handleItemChange(index, checked);
							}} title={item.title} content={item.content}/>
					)
				})
				}
			</div>
		);
	}
}