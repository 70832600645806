import React, { Component } from "react";
import { FormGroup, Input, Button, Table } from "reactstrap";
import AgencyFom from "./AgencyFom";
import "./style.css";

export default class AgencySettings extends Component {
    constructor() {
        super()
        this.state = {
            isShowFrom: false,
            selectedAgency: {}
        }
    }

    renderHeaderTable() {
        return (
            <tr>
                <th></th>
                <th>Agency code</th>
                <th>Agency name</th>
                <th>Agency address</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Card name</th>
                <th></th>
            </tr>
        )
    }

    renContentTable() {
        return (
            <tr >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="d-flex justify-content-center">
                    <Button color="primary" className="h-40" onClick={() => this.setState({ isShowFrom: true })}>Edit</Button>
                </td>
            </tr>
        )
    }

    renderTable() {
        return (
            <Table bordered>
                <thead>
                    {this.renderHeaderTable()}
                </thead>
                <tbody>
                    {this.renContentTable()}
                </tbody>
            </Table>
        )
    }

    render() {
        return (
            <div className="agency-setting-page">
                <div className="d-flex align-items-center">
                    <div className="title h-40">Agency settings</div>
                    <FormGroup className="ml-4 mr-4 agency-option">
                        <Input type="select" name="select" id="exampleSelect" className="h-40">
                            <option>Agency code</option>
                        </Input>
                    </FormGroup>
                    <Button outline color="secondary" className="h-40 custom-btn" onClick={() => this.setState({ isShowFrom: true })}>Create New</Button>
                </div>
                <div className="agency-table mt-4 mb-4">
                    {this.renderTable()}
                </div>
                {
                    this.state.isShowFrom &&
                    <div className="agency-form mt-4 mb-4">
                        <AgencyFom />
                    </div>
                }
                <div className="d-flex justify-content-center">
                    {
                        this.state.isShowFrom ? this.state.selectedAgency && this.state.selectedAgency._id ?
                            <Button outline color="secondary" className="h-40 custom-btn" >Save</Button>
                            : <Button outline color="secondary" className="h-40 custom-btn" >Create</Button>
                            : null
                    }
                </div>
            </div>
        )
    }
}