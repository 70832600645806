import React, { Component } from "react";
import "./style.css";
import ezpLogo from "../../assets/images/logo.png";

class Header extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="component-header-home-page">
        <img src={ezpLogo} alt="logo" />
      </div>
    );
  }
}

export default Header;
