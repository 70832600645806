import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "querystring";
import {Translate} from "react-localize-redux";
import {
    STATUS,
    updateWrongUserInfo,
} from "../../../../actions/registrationActions";
import "./style.css";
import {TYPE_SEND_MAIL, Validation} from "../../../../utils/commonUtils"
import {saveUserRegisterToken} from "../../../../utils/apiUtils";
import {Button, CircularProgress} from "@material-ui/core";
import RegisterForm from "../Form";
import Alert from "../../../../components/Alert";
import {commonFunctions} from "../../../../constants/commonData";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';

class PhoneNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: '',
            alertAck: false,
            purchaseId: ''
        };

        let query = props.location.search;
        if (query.indexOf("?") >= 0) query = query.substr(query.indexOf("?") + 1);
        let parsed = queryString.parse(query);
        if (parsed["purchase_id"]) {
            const token = parsed["token"];
            this.state.purchaseId = parsed['purchase_id'];
            saveUserRegisterToken({token: token});
        }
    }

    handleChange(name) {
        return (e) => {
            if (e.target) {
                this.setState({[name]: e.target.value});
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.stepData !== nextProps.stepData) {
            this.setState({stepData: nextProps.stepData});
        }
    }

    render() {
        const {phoneNumber} = this.state;
        return (
            <Translate>
                {({translate}) => (
                    <RegisterForm>
                        <div className="phone-number-update">
                            <div className="content">
                                <div className="row">
                                    <div className="col-12 col-sm-3">
                                        <div className="content-title">
                                            <label htmlFor="other">Phone Number</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-9">
                                        <div className="content-value">
                                            <PhoneInput
                                                placeholder="Phone number"
                                                value={phoneNumber}
                                                country="JP"
                                                error="Exactly phone number is required"
                                                indicateInvalid={
                                                    !phoneNumber ||
                                                    phoneNumber.length > 18 ||
                                                    !commonFunctions.isNumeric(phoneNumber) ||
                                                    !Validation.validatePhoneNumber(phoneNumber)
                                                }
                                                onChange={(phoneNumber) => {
                                                    this.setState({phoneNumber});
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {
                                        this.setState({alertAck: false});
                                        this.props.updatePassport({
                                            type: TYPE_SEND_MAIL.WRONG_PHONE_NUMBER,
                                            applicationId: this.state.purchaseId,
                                            phoneNumber: phoneNumber,
                                        });
                                    }}
                                    className="button-send-mail"
                                    disabled={
                                        (phoneNumber && phoneNumber.length > 18) ||
                                        !phoneNumber || !commonFunctions.isNumeric(phoneNumber) ||
                                        !Validation.validatePhoneNumber(phoneNumber) ||
                                        this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS
                                    }
                                    variant="contained"
                                    color="primary">
                                    {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS && (
                                        <CircularProgress className="margin-right-20px" size={20}/>
                                    )}
                                    <span>Update</span>
                                </Button>
                            </div>
                            {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_FAIL && !this.state.alertAck && (
                                <Alert title="Error"
                                       content={translate("errors." + this.props.stepData.statusInfo.error.code)}
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                            {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_SUCCESS && !this.state.alertAck && (
                                <Alert title="Success"
                                       content="Update phone number success. Thank for your support"
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                        </div>
                    </RegisterForm>
                )}
            </Translate>
        );
    }
}

const mapStateToProps = state => {
    return {
        stepData: state.registration.stepData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePassport: data => {
            dispatch(updateWrongUserInfo(data));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PhoneNumber));
