import React, { PureComponent } from "react";
import {
  Modal,
  Form,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col
} from "reactstrap";
import {
  getRoles,
  deleteRole,
  createRole,
  updatePermission
} from "../../../../actions/permissionAction";
import { connect } from "react-redux";
import "./style.css";
import Alert from "../../../../components/Alert";
import { STATUS as ACTIONS } from "../../../../reducers/permissionReducer";
import { PERMISSION, ROLE_SUPPER_ADMIN } from "../../../../utils/commonUtils";
import { Translate } from "react-localize-redux";
import PermissionItem from "../../../../components/PermissionItem";
import _ from "lodash";
import {
  Table,
  Button,
  Page,
  Container,
  Input,
  Checkbox
} from "../../../../components/antd";

const permissionData = {
  "Setting Permission": [
    {
      name: PERMISSION.PERMISSION,
      text: "Allow to View and Setting Permission"
    },
    { name: PERMISSION.MEMBER, text: "Allow to View and Setting Member" }
  ],
  "Un-pay List Page (1)": [
    { name: PERMISSION.VIEW_UNPAY_LIST, text: "View" },
    { name: PERMISSION.EDIT_UNPAY_LIST, text: "Edit" }
  ],
  "Uncheck List Page (2)": [
    { name: PERMISSION.VIEW_DONE_LIST, text: "View" },
    { name: PERMISSION.EDIT_DONE_LIST, text: "Edit" },
    {
      name: PERMISSION.UPDATE_STATUS_DONE_TO_REFUNDING,
      text: "Cancel Application (0-0)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_DONE_TO_RECONFIRM,
      text: "Reconfirm (3-0)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_DONE_TO_CHECKED,
      text: "Status Update (4)"
    }
  ],
  "Reconfirm List Page (3-0)": [
    { name: PERMISSION.VIEW_RECONFIRM_LIST, text: "View" },
    { name: PERMISSION.EDIT_RECONFIRM_LIST, text: "Edit" },
    {
      name: PERMISSION.UPDATE_STATUS_REFUNDING_RECONFIRM_TO_REFUNDING,
      text: "Cancel Application (0-0)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_RECONFIRM_LIST_FROM_RECONFIRM_TO_CHECKED,
      text: "Status Update (4)"
    }
  ],
  "Reconfirm for Checked List Page (3-1)": [
    { name: PERMISSION.VIEW_RECONFIRMED_LIST, text: "View" },
    { name: PERMISSION.EDIT_RECONFIRMED_LIST, text: "Edit" },
    {
      name: PERMISSION.UPDATE_STATUS_RECONFIRMED_TO_CHECKED,
      text: "To Checked List (4)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_RECONFIRMED_TO_INPUT,
      text: "To Input Card Number (6)"
    }
  ],
  "Checked List Page (4)": [
    { name: PERMISSION.VIEW_CHECKED_LIST, text: "View" },
    { name: PERMISSION.EDIT_CHECKED_LIST, text: "Edit" },
    {
      name: PERMISSION.UPDATE_STATUS_CHECKED_TO_PRINTED,
      text: "Status Update (5-0)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_CHECKED_TO_RECONFIRMED,
      text: "To Reconfirm for Checked (3-1)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_CHECKED_TO_RESERVE,
      text: "To Reserve List (5-1)"
    }
  ],
  "Print Card List Page (5-0)": [
    { name: PERMISSION.VIEW_PRINTED_LIST, text: "View" },
    {
      name: PERMISSION.UPDATE_STATUS_PRINTED_TO_INPUT,
      text: "Update Status (6)"
    },
    { name: PERMISSION.EXPORT_EXCEL_PRINTED_LIST, text: "Allow to Export CSV" },
    {name: PERMISSION.UPDATE_STATUS_PRINTED_TO_CARD_UPDATE_LIST, text: 'To Card Update List (5-2)'}
  ],
  "Reserve List Page (5-1)": [
    { name: PERMISSION.VIEW_RESERVE_LIST, text: "View" },
    { name: PERMISSION.EDIT_RESERVE_LIST, text: "Edit" },
    {
      name: PERMISSION.UPDATE_STATUS_RESERVE_TO_INPUT,
      text: "To Input List (6)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_RESERVE_TO_CHECKED,
      text: "To Checked List (4)"
    }
  ],
  'Card update List Page (5-2)': [
      {name: PERMISSION.VIEW_CARD_UPDATE_LIST, text: 'View'},
      {name: PERMISSION.EDIT_CARD_UPDATE_LIST, text: 'Edit Card Unused'},
      {name: PERMISSION.EXPORT_EXCEL_CARD_UPDATE_LIST, text: 'Allow to Export CSV'},
      {name: PERMISSION.UPDATE_STATUS_CARD_UPDATE_TO_INPUT_LIST, text: 'To Input List (6)'}
  ],
  "Card Input List Page (6)": [
    { name: PERMISSION.VIEW_INPUT_LIST, text: "View" },
    { name: PERMISSION.EDIT_INPUT_LIST, text: "Edit" },
    {
      name: PERMISSION.UPDATE_STATUS_INPUT_TO_SENT,
      text: "Allow to sent Ezp API (7-0)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_FROM_INPUT_TO_RESERVE,
      text: "To Reserve List (5-1)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_FROM_INPUT_TO_PRINTED,
      text: "To Printed List (5-0)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_FROM_INPUT_TO_CARD_UPDATE,
      text: "To Card Update List (5-2)"
    }
  ]
};
const permissionsData = {
  "Address Confirmation (sent) List Page (7-0)": [
    { name: PERMISSION.VIEW_SENT_LIST, text: "View" },
    { name: PERMISSION.EDIT_SENT_LIST, text: "Edit" },
    { name: PERMISSION.EXPORT_EXCEL_SENT_LIST, text: "Allow to Export CSV" },
    {
      name: PERMISSION.UPDATE_STATUS_SENT_TO_CONFIRMING,
      text: "Status Update (7-1)"
    }
  ],
  "Address Confirmation (confirming) List Page (7-1)": [
    { name: PERMISSION.VIEW_CONFIRMING_LIST, text: "View" },
    { name: PERMISSION.EDIT_CONFIRMING_LIST, text: "Edit" },
    {
      name: PERMISSION.EXPORT_EXCEL_CONFIRMING_LIST,
      text: "Allow to Export CSV"
    },
    {
      name: PERMISSION.UPDATE_STATUS_CONFIRMING_TO_CONFIRMING,
      text: "Resend Mail Confirm Address"
    }
  ],
  "Address Confirmation (confirmed) List Page (8)": [
    { name: PERMISSION.VIEW_CONFIRMED_LIST, text: "View" },
    { name: PERMISSION.EDIT_CONFIRMED_LIST, text: "Edit" },
    {
      name: PERMISSION.EXPORT_EXCEL_CONFIRMED_LIST,
      text: "Allow to Export CSV"
    },
    {
      name: PERMISSION.UPDATE_STATUS_CONFIRMED_TO_CONFIRMED_PREPARING_SHIPMENT,
      text: "Status Update (8-1)"
    },
    { name: PERMISSION.EXPORT_PDF_CONFIRMED_LIST, text: "Export PDF" },
    {
      name: PERMISSION.UPDATE_STATUS_CONFIRMED_TO_CONFIRMING,
      text: "To Confirming (7-1)"
    }
  ],
  "Confirmed Preparing Shipment List Page (8-1)": [
    { name: PERMISSION.VIEW_CONFIRMED_PREPARING_SHIPMENT_LIST, text: "View" },
    { name: PERMISSION.EDIT_CONFIRMED_PREPARING_SHIPMENT_LIST, text: "Edit" },
    {
      name: PERMISSION.EXPORT_EXCEL_CONFIRMED_PREPARING_SHIPMENT_LIST,
      text: "Allow to Export CSV"
    },
    {
      name: PERMISSION.UPDATE_STATUS_CONFIRMED_PREPARING_SHIPMENT_TO_COMPLETED,
      text: "Status Update (9)"
    },
    { name: PERMISSION.EXPORT_PDF_CONFIRMED_PREPARING_SHIPMENT_LIST, text: "Export PDF" }
  ],
  "Confirmed_return (8-2)": [
    { name: PERMISSION.VIEW_CONFIRMED_RETURN, text: "View" },
    { name: PERMISSION.EDIT_CONFIRMED_RETURN_LIST, text: "Edit" },
    {
      name: PERMISSION.UPDATE_STATUS_CONFIRMED_RETURN_TO_CONFIRMED_PREPARING_RESHIPMENT,
      text: "Status Update (8-3)"
    },
    { name: PERMISSION.EXPORT_PDF_CONFIRMED_RETURN_LIST, text: "Export PDF" },
  ],
  "Confirmed preparing reshipment (8-3)": [
    { name: PERMISSION.VIEW_CONFIRMED_PREPARING_RESHIPMENT, text: "View" },
    { name: PERMISSION.EDIT_CONFIRMED_PREPARING_RESHIPMENT_LIST, text: "Edit" },
    {
      name: PERMISSION.UPDATE_STATUS_CONFIRMED_PREPARING_RESHIPMENT_TO_COMPLETED,
      text: "Status Update (9)"
    },
    { name: PERMISSION.EXPORT_PDF_CONFIRMED_PREPARING_RESHIPMENT_LIST, text: "Export PDF" },
    { name: PERMISSION.EXPORT_CSV_CONFIRMED_PREPARING_RESHIPMENT_LIST, text: "Export CSV" },
  ],
  "Done list page (9)": [
    { name: PERMISSION.VIEW_COMPLETED_LIST, text: "View" },
    { name: PERMISSION.EDIT_COMPLETED_LIST, text: "Edit" },
    {
      name: PERMISSION.EXPORT_EXCEL_COMPLETED_LIST,
      text: "Allow to Export CSV"
    },
    { name: PERMISSION.EXPORT_PDF_COMPLETED_LIST, text: "Export PDF" },
    {
      name: PERMISSION.UPDATE_STATUS_COMPLETED_TO_CARD_VALID,
      text: "Import CSV (10)"
    },
    {
      name: PERMISSION.UPDATE_STATUS_COMPLETED_TO_CONFIRMED_RETURN,
      text: "Status Update (8-2)"
    },
  ],

  "Refunding list page (0-0)": [
    { name: PERMISSION.VIEW_REFUNDING_LIST, text: "View" },
    {
      name: PERMISSION.UPDATE_STATUS_REFUNDING_TO_REFUNDED,
      text: "Status Update (0-1)"
    }
  ],
  "Refunded list page (0-1)": [{ name: PERMISSION.VIEW_REFUNDED_LIST, text: "View" }],
  "Card valid list page (10)": [
    { name: PERMISSION.VIEW_CARD_VALID_LIST, text: "View" },
    { name: PERMISSION.EDIT_CARD_VALID_LIST, text: "Edit" }
  ],

  "Statistic number application": [
    { name: PERMISSION.VIEW_STATISTIC_NUMBER_APPLICATION, text: "View" },
    { name: PERMISSION.EXPORT_EXCEL_STATISTIC_NUMBER_APPLICATION, text: "Export CSV" }
  ],

  "Export Excel page": [
    { name: PERMISSION.ALLOW_EXPORT_EXCEL, text: "Allow Export" }
  ],
  "Registration List Page": [
    { name: PERMISSION.VIEW_ALL_REGISTRATION, text: "View" },
    { name: PERMISSION.EDIT_ALL_REGISTRATION, text: "Edit" }
  ],
  "Passport Image Export": [
    { name: PERMISSION.PASSPORT_IMAGE_EXPORT, text: "Allow Export" }
  ],
  "API service": [{ name: PERMISSION.API_SERVICE, text: "API system" }],
  "Mail Service": [{ name: PERMISSION.MAIL_SERVICE, text: "Send mail system" }],
  "Statistic Application": [
    {
      name: PERMISSION.STATISTIC_APPLICATION_BY_YEAR,
      text: "Statistic by year"
    },
    {
      name: PERMISSION.STATISTIC_APPLICATION_BY_DAYS,
      text: "Statistic by days"
    }
  ]
};

class PermissionSetting extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      role: props.role,
      selectedRole: {},
      name: "",
      alertAck: true,
      edit_permission: true,
      isShowModal: false
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.alertAck();
    this.props.getRoles();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.role !== this.state.role) {
      this.setState({
        role: nextProps.role
      });
    }
  }

  renderContentTable() {
    const { role } = this.props;
    if (Array.isArray(role) && role.length > 0) {
      return role.map((data, index) => {
        return (
          <tr key={index}>
            <td className="no-border">
              <Input
                className="input-label-style"
                disabled={true}
                value={data.name}
              />
            </td>
            <td className="no-border">
              {data.name !== ROLE_SUPPER_ADMIN && (
                <Button
                  color="primary"
                  className="button-style"
                  onClick={() =>
                    this.setState({
                      isShowModal: true,
                      name: data.name,
                      selectedRole: { ...data },
                      edit_permission: false
                    })
                  }
                >
                  編集
                </Button>
              )}
            </td>
            <td className="no-border">
              {data.name !== ROLE_SUPPER_ADMIN && (
                <Button
                  color="danger"
                  className="button-style"
                  onClick={() =>
                    this.setState({
                      isShowModal: true,
                      edit_permission: true,
                      selectedRole: data
                    })
                  }
                >
                  削除
                </Button>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={5}>
            <div className="d-flex justify-content-center">
              <span>表示するデータがありません</span>
            </div>
          </td>
        </tr>
      );
    }
  }

  handleCheckboxChange({ e, roleCurrent, roleFirst, numberChecked }) {
    let dataPermission = [...this.state.selectedRole.permission];
    if (dataPermission.includes(roleCurrent)) {
      if (
        roleFirst.slice(0, 18) === "get_user_by_status" &&
        numberChecked > 1 &&
        roleFirst === roleCurrent
      )
        return;
      dataPermission.splice(dataPermission.indexOf(roleCurrent), 1);
    } else {
      dataPermission.push(roleCurrent);
      const allPermission = { ...permissionData, ...permissionsData };
      if (roleFirst.slice(0, 18) === "get_user_by_status") {
        dataPermission.push(roleFirst);
      }
      Object.keys(allPermission).forEach(keyGroupRole => {
        for (let i = 0; i < allPermission[keyGroupRole].length; i++) {
          let role = allPermission[keyGroupRole][i].name;
          if (role === roleCurrent) {
            if (
              allPermission[keyGroupRole][0].name.slice(0, 18) ===
                "get_user_by_status" &&
              !dataPermission.includes(allPermission[keyGroupRole][0].name)
            )
              dataPermission.push(allPermission[keyGroupRole][0].name);
            break;
          }
        }
      });
    }

    dataPermission = [...new Set(dataPermission)];
    this.setState({
      selectedRole: {
        ...this.state.selectedRole,
        permission: dataPermission
      }
    });
  }

  checkAllBox(data) {
    let check = true;
    let dataPermission = [...this.state.selectedRole.permission];
    data.forEach(val => {
      check = check && dataPermission.includes(val.name);
    });
    return check;
  }

  checkBoxCount(data) {
    let check = 0;
    let dataPermission = [...this.state.selectedRole.permission];
    for (let i = 0; i < data.length; i++) {
      if (dataPermission.includes(data[i].name)) {
        check++;
      }
    }
    return check;
  }

  handleCheckbox(e, data) {
    let dataCheckBox = [];
    data.forEach(val => dataCheckBox.push(val.name));
    let dataPermission = [...this.state.selectedRole.permission];
    if (_.difference(dataCheckBox, dataPermission).length === 0) {
      dataPermission = _.pullAll(dataPermission, dataCheckBox);
    } else
      dataCheckBox.forEach(val => {
        if (!dataPermission.includes(val)) dataPermission.push(val);
      });
    this.setState({
      selectedRole: {
        ...this.state.selectedRole,
        permission: dataPermission
      }
    });
  }

  handleSubmitBtn = (e, actionName) => {
    e.preventDefault();
    this.alertAck();
    this.setState({ isShowModal: false });
    const { selectedRole, name } = this.state;
    switch (actionName) {
      case "Delete":
        this.props.handleDeleteRole({
          _id: selectedRole && selectedRole._id
        });
        break;
      case "Create":
        this.props.handleCreateRole({
          name: name.trim(),
          permission: []
        });
        break;
      default:
        break;
    }
  };
  handleUpdatePermission = () => {
    this.alertAck();
    const { selectedRole, name } = this.state;
    this.props.updatePermission({
      _id: selectedRole._id,
      permission: selectedRole.permission,
      name: name.trim(),
      oldName: selectedRole.name
    });
    this.setState({ name: name.trim() });
  };

  toggleDialog = () => {
    this.setState({ isShowModal: false, selectedRole: {}, name: "" });
  };

  renderForm() {
    const {
      adminStore: { listPermission }
    } = this.props;
    const { selectedRole, edit_permission, isShowModal } = this.state;
    const permission = selectedRole && selectedRole.permission;
    return (
      <Modal
        size={edit_permission ? "ls" : "xl"}
        isOpen={isShowModal}
        toggle={this.toggleDialog}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggleDialog}>
          {edit_permission
            ? selectedRole && selectedRole._id
              ? "\n" + "このロールを削除しますか？"
              : "下記で新規ロールを入力してください"
            : "権限リスト"}
        </ModalHeader>
        <ModalBody className="permission-setting">
          <Form>
            {edit_permission ? (
              <FormGroup
                row
                className="d-flex justify-content-between align-items-center"
              >
                <div className="modal-title ml-4" sm={4}>
                  ロール名
                </div>
                <Col sm={8}>
                  <Input
                    type="input"
                    name="name"
                    id="name"
                    value={
                      (selectedRole && selectedRole.name) || this.state.name
                    }
                    onChange={e => this.setState({ name: e.target.value })}
                    placeholder="名前"
                    disabled={!!(selectedRole && selectedRole._id)}
                  />
                </Col>
              </FormGroup>
            ) : (
              permission && (
                <div>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>タイトル</div>
                      <Input
                        className="input-label-style ml-4 w-auto"
                        type="input"
                        name="title"
                        id="title"
                        placeholder="タイトル"
                        value={this.state.name}
                        onChange={e => this.setState({ name: e.target.value })}
                      />
                    </div>
                    {listPermission.includes(PERMISSION.PERMISSION) && (
                      <Button
                        disabled={!this.state.name}
                        align="center"
                        onClick={this.handleUpdatePermission}
                      >
                        更新
                      </Button>
                    )}
                  </div>
                  <hr></hr>
                  <FormGroup row className="permission-view">
                    <Col sm={6}>
                      {Object.keys(permissionData).map((item, index) => {
                        const numberChecked = this.checkBoxCount(
                          permissionData[item]
                        );
                        const checkAll = this.checkAllBox(permissionData[item]);
                        return (
                          <React.Fragment key={index}>
                            <div className="permission-page d-flex align-items-center">
                              <Checkbox
                                indeterminate={numberChecked > 0 && !checkAll}
                                checked={checkAll || false}
                                onChange={e =>
                                  this.handleCheckbox(e, permissionData[item])
                                }
                              ></Checkbox>
                              {item}
                            </div>
                            {permissionData[item].map((value, key) => (
                              <PermissionItem
                                key={key}
                                checked={
                                  permission.includes(value.name) || false
                                }
                                onChange={e =>
                                  this.handleCheckboxChange({
                                    e,
                                    roleCurrent: value.name,
                                    roleFirst: permissionData[item][0].name,
                                    numberChecked
                                  })
                                }
                                text={value.text}
                              />
                            ))}
                          </React.Fragment>
                        );
                      })}
                    </Col>
                    <Col sm={6}>
                      {Object.keys(permissionsData).map((item, index) => {
                        const numberChecked = this.checkBoxCount(
                          permissionsData[item]
                        );
                        const checkAll = this.checkAllBox(
                          permissionsData[item]
                        );
                        return (
                          <React.Fragment key={index}>
                            <div className="permission-page d-flex align-items-center">
                              <Checkbox
                                indeterminate={numberChecked > 0 && !checkAll}
                                checked={checkAll || false}
                                onChange={e =>
                                  this.handleCheckbox(e, permissionsData[item])
                                }
                              />
                              {item}
                            </div>
                            {permissionsData[item].map((value, key) => (
                              <PermissionItem
                                key={key}
                                checked={
                                  permission.includes(value.name) || false
                                }
                                onChange={e =>
                                  this.handleCheckboxChange({
                                    e,
                                    roleCurrent: value.name,
                                    roleFirst: permissionsData[item][0].name,
                                    numberChecked
                                  })
                                }
                                text={value.text}
                              />
                            ))}
                          </React.Fragment>
                        );
                      })}
                    </Col>
                  </FormGroup>
                </div>
              )
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.toggleDialog}>閉じる</Button>{" "}
          {edit_permission ? (
            selectedRole &&
            selectedRole._id &&
            listPermission.includes(PERMISSION.PERMISSION) ? (
              <Button
                disabled={!selectedRole.name}
                onClick={e => this.handleSubmitBtn(e, "Delete")}
              >
                削除
              </Button>
            ) : (
              <Button
                disabled={!this.state.name.trim()}
                onClick={e => this.handleSubmitBtn(e, "Create")}
              >
                作成
              </Button>
            )
          ) : null}
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const {
      adminStore: { listPermission }
    } = this.props;
    const { role } = this.props;
    const { permission } = this.props;

    const columns = [
      {
        align: "center",
        width: 60,
        dataIndex: "name",
        key: "name",
        render: (text, record) => <Input value={record.name} readOnly />
      },
      {
        align: "center",
        dataIndex: "option",
        key: "option",
        width: 20,
        render: (text, record) => (
          <div className="action-cell">
            {record.name !== ROLE_SUPPER_ADMIN &&
              listPermission.includes(PERMISSION.PERMISSION) && (
                <Button
                  type="edit"
                  icon="edit"
                  onClick={() =>
                    this.setState({
                      isShowModal: true,
                      name: record.name,
                      selectedRole: { ...record },
                      edit_permission: false
                    })
                  }
                >
                  編集
                </Button>
              )}
            {record.name !== ROLE_SUPPER_ADMIN &&
              listPermission.includes(PERMISSION.PERMISSION) && (
                <Button
                  type="delete"
                  icon="delete"
                  onClick={() =>
                    this.setState({
                      isShowModal: true,
                      edit_permission: true,
                      selectedRole: record
                    })
                  }
                >
                  削除
                </Button>
              )}
          </div>
        )
      }
    ];
    return (
      <Translate>
        {({ translate }) => (
          <Page className="permission-setting">
            <Container>
              <div className="title">権限設定</div>
              <div className="action-box">
                {this.renderForm()}
                {listPermission.includes(PERMISSION.PERMISSION) && (
                  <Button
                    icon="plus-circle"
                    size="small"
                    className="add-btn"
                    onClick={() =>
                      this.setState({
                        isShowModal: true,
                        edit_permission: true,
                        selectedRole: {}
                      })
                    }
                  >
                    新規権限追加
                  </Button>
                )}
              </div>

              <Table
                className="main-table"
                loading={permission === ACTIONS.GET_ROLE_PROGRESS}
                rowKey={(row, index) => index}
                columns={columns}
                dataSource={role}
                scroll={{ x: 1550, y: "calc(100vh - 245px)" }}
              />
              {[
                ACTIONS.CREATE_ROLE_FAILED,
                ACTIONS.DELETE_ROLE_FAILED,
                ACTIONS.GET_ROLE_FAILED,
                ACTIONS.UPDATE_ROLE_FAILED
              ].indexOf(this.props.permission) >= 0 &&
                !this.state.alertAck && (
                  <Alert
                    title="Error"
                    content={translate(
                      this.props.permissionError &&
                        "errors." + this.props.permissionError.code
                    )}
                    okTitle="OK"
                    onOK={() => {
                      this.setState({ alertAck: true });
                    }}
                  />
                )}
              {this.props.permission === ACTIONS.CREATE_ROLE_SUCCESS &&
                !this.state.alertAck && (
                  <Alert
                    title="成功"
                    content="ロールを正常に作成"
                    okTitle="OK"
                    onOK={() => {
                      this.setState({ alertAck: true });
                    }}
                  />
                )}
              {this.props.permission === ACTIONS.UPDATE_ROLE_SUCCESS &&
                !this.state.alertAck && (
                  <Alert
                    title="成功"
                    content="権限ロールを正常に更新します"
                    okTitle="OK"
                    onOK={() => {
                      this.setState({ alertAck: true });
                    }}
                  />
                )}
              {this.props.permission === ACTIONS.DELETE_ROLE_SUCCESS &&
                !this.state.alertAck && (
                  <Alert
                    title="成功"
                    content="役割を正常に削除"
                    okTitle="OK"
                    onOK={() => {
                      this.setState({ alertAck: true });
                    }}
                  />
                )}
            </Container>
          </Page>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    permission: state.permission.status,
    permissionError: state.permission.permissionError,
    role: state.permission.role,
    adminStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRoles: () => {
      dispatch(getRoles());
    },
    handleDeleteRole: data => {
      dispatch(deleteRole(data));
    },
    handleCreateRole: data => {
      dispatch(createRole(data));
    },
    updatePermission: data => {
      dispatch(updatePermission(data));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PermissionSetting);
