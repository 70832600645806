import {callApi} from "../utils/apiUtils";
import {STATUS} from '../reducers/memberReducer';

const ACTIONS = {
    UPDATE_STATUS: "UPDATE_STATUS",
};

const updateStatus = (status, statusInfo) => {
    return {
        type: ACTIONS.UPDATE_STATUS,
        data: {
            status,
            statusInfo
        }
    }
};
const getUsers = () => {
    let config = {
        method: 'get',
        isAuthorization: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_USER_PROGRESS));
        callApi('/api/get_all_users',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_USER_SUCCESS, {users: data}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_USER_FAILED, {error: err}));
            }
        );
    };
};
const getInfo = () => {
    let config = {
        method: 'get',
        isAuthorization: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_INFO_PROGRESS));
        callApi('/api/get_info_sign_up',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_INFO_SUCCESS, {info: data}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_INFO_FAILED, {error: err}));
            }
        );
    };
};

const deleteMember = data => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.DELETE_USER_PROGRESS));
        callApi('/api/delete_member',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.DELETE_USER_SUCCESS, {data}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.DELETE_USER_FAILED, {error: err}));
            }
        );
    };
};

const createUser = data => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.CREATE_USER_PROGRESS));
        callApi('/api/add_member',
            config,
            null,
            (result) => {
                dispatch(updateStatus(STATUS.CREATE_USER_SUCCESS, {user: result}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.CREATE_USER_FAILED, {error: err}));
            }
        );
    };
};

const editMember = data => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.UPDATE_USER_PROGRESS));
        callApi('/api/edit_member',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.UPDATE_USER_SUCCESS, {data}));
            },
            (err) => {

                dispatch(updateStatus(STATUS.UPDATE_USER_FAILED, {error: err}));
            }
        );
    };
};

export {ACTIONS, getUsers, deleteMember, createUser, editMember, getInfo};
