import React, { Component } from "react";
import { Form, FormGroup, Label, Col, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";

export default class CardDetail extends Component {
    renderBalance() {
        return (
            <div className="d-flex justify-content-end">
                <FormGroup className="row justify-content-end">
                    <FormGroup className="col-3 balancy-item">
                        <Label for="RIA">RIA</Label>
                        <Input className="text-align-right" type="text" name="RIA" id="RIA" value="17,800" />
                    </FormGroup>
                    <FormGroup className="col-3 balancy-item">
                        <Label for="GBT">GBT</Label>
                        <Input className="text-align-right" type="text" name="GBT" id="GBT" value="580" />
                    </FormGroup>
                    <FormGroup className="col-3 balancy-item">
                        <Label for="ETHT">ETHT</Label>
                        <Input className="text-align-right" type="text" name="ETHT" id="ETHT" value="0" />
                    </FormGroup>
                </FormGroup>
            </div>
        )
    }

    renderFirstContent() {
        return (
            <Form className="row border-bottom-2 ml-2">
                <FormGroup className="col-6">
                    <div className="row justify-content-start mb-4 mt-4">
                        <Button color="primary edit-btn">Edit</Button>
                    </div>
                    <FormGroup className="row m-b-0">
                        <Label for="greenBoxID" className="col-sm-3 name">Green Box ID</Label>
                        <Col sm={8} className="p-l-5">
                            <Input type="text" className="text-align-right" name="greenBoxID" id="greenBoxID" value="A123456" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row m-b-0">
                        <Label for="fullName" className="col-sm-3 name">Fullname</Label>
                        <Col sm={8} className="p-l-5">
                            <Input type="text" value="SEIJI AZUMA" className="text-align-right" name="fullName" id="fullName" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row  m-b-0">
                        <Label for="email" className="col-sm-3 name">Email</Label>
                        <Col sm={8} className="p-l-5">
                            <Input type="text" value="syobu123@gmail.com" className="text-align-right" name="email" id="email" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row  m-b-0">
                        <Label for="phoneNumber" className="col-sm-3 name">Phone number</Label>
                        <Col sm={8} className="p-l-5">
                            <Input type="text" value="080-1234-5678" className="text-align-right" name="phoneNumber" id="phoneNumber" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row  m-b-0">
                        <Label for="birthDate" className="col-sm-3 name">Birth Date</Label>
                        <Col sm={8} className="p-l-5">
                            <Input type="text" value="1971/07/22" className="text-align-right" name="birthDate" id="birthDate" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row  m-b-0">
                        <Label for="postCode" className="col-sm-3 name">Postcode</Label>
                        <Col sm={8} className="p-l-5">
                            <Input type="text" value="5470000" className="text-align-right" name="postCode" id="postCode" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row  m-b-0">
                        <Label for="address" className="col-sm-3 name">Address</Label>
                        <Col sm={8} className="p-l-5">
                            <Input type="text" value="HIRANOKU,OSAKASHI,OSAKA,JAPAN" className="text-align-right" name="address" id="address" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row  m-b-5">
                        <Col className="p-l-5 col-sm-8 offset-sm-3">
                            <Input type="text" value="4-8-10 KIREHIGASHI" className="text-align-right" name="address" id="address" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row  m-b-5">
                        <Col className="p-l-5 col-sm-8 offset-sm-3">
                            <Input type="text" value="101 KOOP" className="text-align-right" name="address" id="address" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row  m-b-0">
                        <Label for="certificationImage" className="col-sm-3 name">Certification image</Label>
                        <Col sm={8} className="p-l-5">
                            <Input type="text" value="http://www.ezpay-card.com/passport/a1234567" className="text-align-right" name="certificationImage" id="certificationImage" />
                        </Col>
                    </FormGroup>

                </FormGroup>
                <FormGroup className="col-6 p-r-0">
                    {
                        [1, 2].map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="d-flex justify-content-end mb-4 mt-4">
                                        <Link to='/admin/card-issuer/detail/trading-history'>
                                            <Button outline color="secondary" className="custom-btn">Trading History</Button>
                                        </Link>
                                    </div>
                                    <FormGroup className="row  m-b-0">
                                        <Label for="cardNumber" className="col-sm-3 offset-sm-1 name">Card Number</Label>
                                        <Col sm={8} className="p-l-5">
                                            <Input type="text" className="text-align-right" name="cardNumber" id="cardNumber" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row  m-b-0">
                                        <Label for="cardBalance" className="col-sm-3 offset-sm-1 name">Card Balance</Label>
                                        <Col sm={8} className="p-l-5">
                                            <Input type="text" className="text-align-right" name="cardBalance" id="cardBalance" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row  m-b-0">
                                        <Label for="cardExpirationDate" className="col-sm-3 offset-sm-1 name">Card expiration date</Label>
                                        <Col sm={8} className="p-l-5">
                                            <Input type="text" className="text-align-right" name="cardExpirationDate" id="cardExpirationDate" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row  m-b-0">
                                        <Label for="totalCharge" className="col-sm-3 offset-sm-1 name">Total charge</Label>
                                        <Col sm={8} className="p-l-5">
                                            <Input type="text" className="text-align-right" name="totalCharge" id="totalCharge" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row  m-b-0">
                                        <Label for="cardStatus" className="col-sm-3 offset-sm-1 name">Card status</Label>
                                        <Col sm={8} className="p-l-5">
                                            <Input type="text" className="text-align-right" name="cardStatus" id="cardStatus" />
                                        </Col>
                                    </FormGroup>
                                </React.Fragment>
                            )
                        })
                    }
                </FormGroup>
            </Form>
        )
    }
    renderHistory() {
        return (
            <div className="mt-4">
                <Label for="history" className="small-title">History</Label>
                <FormGroup >
                    <Label for="recentChargeHistory" >Recent Charge History</Label>
                    <Input type="text" name="recentChargeHistory" id="recentChargeHistory" />
                </FormGroup>
                <FormGroup >
                    <Label for="posTransactionHistory" >Most recent POS transaction history</Label>
                    <Input type="text" name="posTransactionHistory" id="posTransactionHistory" />
                </FormGroup>
                <FormGroup >
                    <Label for="atmTransactionHistory" >Most recent ATM transaction history</Label>
                    <Input type="text" name="atmTransactionHistory" id="atmTransactionHistory" />
                </FormGroup>
            </div>
        )
    }

    renderContactHistory() {
        return (
            <div className="mt-4">
                <Label for="history" className="small-title  mb-2">Contact History</Label>
                <div className="d-flex justify-content-end plus-btn mr-4">+</div>
                <FormGroup >
                    <Input type="text" value="2018/05/15 at email　Can not charge card　　Confirmed　Azuma" name="recentChargeHistory" id="recentChargeHistory" />
                </FormGroup>
                <FormGroup >
                    <Input type="text" value="Respone　Confirmed by Mastercard no problem　Reply 2018/05/17　　Corresponding person　Azuma" name="posTransactionHistory" id="posTransactionHistory" />
                </FormGroup>
                <FormGroup >
                    <Input type="text" name="atmTransactionHistory" id="atmTransactionHistory" />
                </FormGroup>
            </div>
        )
    }
    render() {
        return (
            <div className="card-issuer-detail">
                <div className="bread-crumb d-flex">
                    <Link to="/admin/card-issuer"><div className="title">Card issuer</div></Link>
                    <div className="ml-2 mr-2 title">></div>
                    <div className="title">Detail</div>
                </div>
                <div className="d-flex justify-content-end">
                    <Label className="title">WALLET Balance</Label>
                </div>
                {this.renderBalance()}
                {this.renderFirstContent()}
                {this.renderHistory()}
                {this.renderContactHistory()}
            </div>
        )
    }
}