import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";

import "antd/dist/antd.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import App from "./App";
import configStore from "./store";
import * as serviceWorker from './serviceWorker';

let store = configStore();

ReactDOM.render(
  <LocalizeProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </LocalizeProvider>,
  document.getElementById("root")
);
serviceWorker.unregister();
