import React from "react";
import { Checkbox } from "antd";
import styled from "styled-components";

const StyledCheckbox = styled(Checkbox)`
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #505050;
  user-select: none;
  display: ${props => props.position === 'right' ? 'inline-block' : 'flex'};
  width: ${props => props.fullWidth ? '100%' : 'auto'};
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  > span {
    &.ant-checkbox {
      bottom: 0;
      top: ${props => props.position === 'right' ? 6 : 0}px;
      float: ${props => props.position === 'right' ? 'right' : 'unset'};
      .ant-checkbox-inner {
        width: 18px;
        height: 18px;
        border: 2px solid #cacaca;

        &::after {
          left: 3px;
        }
      }

      &.ant-checkbox-checked .ant-checkbox-inner {
        background-color: #005398;
        border-color: #005398;
      }

      &.ant-checkbox-indeterminate .ant-checkbox-inner:after {
        left: 50%;
        width: 9px;
        height: 9px;
        background-color: #005398;
      }
    }

    &:last-child {
      padding: 0;
    }
  }
`;

export default ({ children, field, form, ...props }) => (
  <StyledCheckbox
    checked={(field && field.value) || props.checked}
    {...props}
    {...field}
  >
    {children}
  </StyledCheckbox>
);
