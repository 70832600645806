import React, {Component} from "react";
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import RegistrationForm from "../../../RegistrationForm";
import {STATUS} from '../../../../actions/registrationActions';
import Form from "../Form";
import "./style.css";

class Step3 extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        return (

            <Form>
                {this.props.stepData.status === STATUS.STEP4.PAYMENT_FINISHED && (
                    <Redirect to="/registers?step=5"/>
                )}
                {this.props.stepData.status === STATUS.STEP3.UPDATED && (
                    <Redirect to="/registers?step=4"/>
                )}
                {[STATUS.STEP2.VERIFY_SUCCESS].indexOf(this.props.stepData.status) >= 0 && (
                    <div className='scroll-detail-register'>
                        <RegistrationForm register={true}/>
                    </div>
                )}
                {[STATUS.STEP3.MODIFY, STATUS.STEP3.UPDATING, STATUS.STEP3.UPDATE_FAILED].indexOf(this.props.stepData.status) >= 0 && (
                    <div className='scroll-detail-register'>
                        <RegistrationForm register={true} data={this.props.stepData.applicationInfo}/>
                    </div>
                )}
            </Form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentStep: state.registration.currentStep,
        stepSuccess: state.registration.stepSuccess,
        stepData: state.registration.stepData,
    }
};

export default connect(mapStateToProps, null)(Step3);
