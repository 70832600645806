import {callApi} from "../utils/apiUtils";
import {STATUS} from '../reducers/statisticApplicationReducer';

const ACTIONS = {
    UPDATE_STATUS: "UPDATE_STATUS",
    CHANGE_FILTER: "CHANGE_FILTER"
};

const updateStatus = (status, statusInfo) => {
    return {
        type: ACTIONS.UPDATE_STATUS,
        data: {
            status,
            statusInfo
        }
    }
};


const statisticOfYear = data => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.STATISTIC_APPLICATION_PROGRESS));
        callApi('/api/statistic_of_year',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STATISTIC_APPLICATION_SUCCESS, {data}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.STATISTIC_APPLICATION_FAILED, {error: err}));
            }
        );
    };
};
const statisticOfDateRange = data => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.STATISTIC_APPLICATION_PROGRESS));
        callApi('/api/statistic_days',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STATISTIC_APPLICATION_SUCCESS, {data}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.STATISTIC_APPLICATION_FAILED, {error: err}));
            }
        );
    };
};

const changeFilter = (data) => {
    return (dispatch) => dispatch({ type: ACTIONS.CHANGE_FILTER, data })
}

export {ACTIONS, statisticOfYear, statisticOfDateRange, changeFilter};
