import {ACTIONS} from '../actions/cardManagementActions';
import {STATUS_CARD_NUMBER} from "../utils/commonUtils";

const initialState = {
    status: null,
    error: null,
    dataSearch: null,
    unusedCard: null,
    checkedApplication: null,
    cards: [],
    total: 0,
    loading: false,
    addCardCount: 0
};

const STATUS = {
    RESET_UPDATED_STATUS: "RESET_UPDATED_STATUS",
    IMPORT_CARD_PROGRESS: "IMPORT_CARD_PROGRESS",
    IMPORT_CARD_SUCCESS: "IMPORT_CARD_SUCCESS",
    IMPORT_CARD_FAILED: "IMPORT_CARD_FAILED",
    GET_CARDS_PROGRESS: "GET_CARDS_PROGRESS",
    GET_CARDS_SUCCESS: "GET_CARDS_SUCCESS",
    GET_CARDS_FAIL: "GET_CARDS_FAIL",
    CARDS_EXPORTING: "CARDS_EXPORTING",
    CARDS_EXPORTED: "CARDS_EXPORTED",
    CARDS_FAILED: "CARDS_FAILED",
    EDIT_CARD_PROGRESS: "EDIT_CARD_PROGRESS",
    EDIT_CARD_SUCCESS: "EDIT_CARD_SUCCESS",
    EDIT_CARD_FAIL: "EDIT_CARD_FAIL",
    SEARCH_CARD_PROGRESS: "SEARCH_CARD_PROGRESS",
    SEARCH_CARD_SUCCESS: "SEARCH_CARD_SUCCESS",
    SEARCH_CARD_FAIL: "SEARCH_CARD_FAIL",
    UPDATE_CARD_PROGRESS: "UPDATE_CARD_PROGRESS",
    UPDATE_CARD_SUCCESS: "UPDATE_CARD_SUCCESS",
    UPDATE_CARD_FAIL: "UPDATE_CARD_FAIL",
    GET_CARDS_UNUSED_PROGRESS: "GET_CARDS_UNUSED_PROGRESS",
    GET_CARDS_UNUSED_SUCCESS: "GET_CARDS_UNUSED_SUCCESS",
    GET_CARDS_UNUSED_FAIL: "GET_CARDS_UNUSED_FAIL",
    UPDATE_STATUS_CARD_PROGRESS: "UPDATE_STATUS_CARD_PROGRESS",
    UPDATE_STATUS_CARD_SUCCESS: "UPDATE_STATUS_CARD_SUCCESS",
    UPDATE_STATUS_CARD_FAIL: "UPDATE_STATUS_CARD_FAIL",
};

export {STATUS};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_STATUS:
            switch (action.data.status) {
                case STATUS.IMPORT_CARD_PROGRESS:
                case STATUS.EDIT_CARD_PROGRESS:
                case STATUS.SEARCH_CARD_PROGRESS:
                case STATUS.UPDATE_CARD_PROGRESS:
                case STATUS.UPDATE_CARD_SUCCESS:
                case STATUS.CARDS_EXPORTING:
                case STATUS.CARDS_EXPORTED:
                case STATUS.UPDATE_STATUS_CARD_PROGRESS:
                case STATUS.UPDATE_STATUS_CARD_SUCCESS:
                    return {
                        ...state,
                        status: action.data.status
                    };
                case STATUS.IMPORT_CARD_SUCCESS: {                    
                    return {
                        ...state,
                        status: action.data.status,
                        addCardCount: action.data.statusInfo.addCardCount
                    }
                }
                case STATUS.RESET_UPDATED_STATUS:
                    return {
                        ...state,
                        status: action.data.status,
                        dataSearch: null
                    };
                case STATUS.GET_CARDS_UNUSED_PROGRESS:
                case STATUS.GET_CARDS_PROGRESS:
                    return {
                        ...state,
                        loading: true,
                    }     
                case STATUS.GET_CARDS_SUCCESS:
                    return {
                        ...state,
                        status: action.data.status,
                        cards: action.data.statusInfo.cards,
                        unusedCard: action.data.statusInfo.unusedCard,
                        checkedApplication: action.data.statusInfo.checkedApplication,
                        pages: action.data.statusInfo.pages,
                        total: action.data.statusInfo.total,
                        loading: action.data.loading
                    };
                case STATUS.GET_CARDS_UNUSED_SUCCESS:
                    return {
                        ...state,
                        listCards: action.data.statusInfo.listCards,
                    };
                case STATUS.SEARCH_CARD_SUCCESS:
                    return {
                        ...state,
                        status: action.data.status,
                        dataSearch: action.data.statusInfo.data,
                    };
                case STATUS.EDIT_CARD_SUCCESS:
                    let indexCard = state.cards.findIndex(u => u._id === action.data.statusInfo.data._id);
                    let unusedCard = state.unusedCard;
                    const cardInfo = action.data.statusInfo.data;
                    let listCard = [
                        ...state.cards.slice(0, indexCard),
                        Object.assign({}, state.cards[indexCard], cardInfo),
                        ...state.cards.slice(indexCard + 1)
                    ]

                    if (action.data.statusInfo.data.status === STATUS_CARD_NUMBER.REJECTED) {
                        unusedCard = Number(state.unusedCard) - 1;
                        listCard = [...listCard.slice(0, indexCard) , ...listCard.slice(indexCard + 1)];
                    }
                    else if (action.data.statusInfo.data.status === STATUS_CARD_NUMBER.UNUSED) {
                        unusedCard = Number(state.unusedCard) + 1;
                        listCard = [...listCard.slice(0, indexCard) , ...listCard.slice(indexCard + 1)];
                    }
                    

                    return {
                        ...state,
                        status: action.data.status,
                        unusedCard,
                        cards: listCard
                    };

                case STATUS.IMPORT_CARD_FAILED:
                case STATUS.GET_CARDS_FAIL:
                case STATUS.EDIT_CARD_FAIL:
                case STATUS.UPDATE_CARD_FAIL:
                case STATUS.CARDS_FAILED:
                case STATUS.GET_CARDS_UNUSED_FAIL:
                case STATUS.UPDATE_STATUS_CARD_FAIL:
                    return {
                        ...state,
                        status: action.data.status,
                        error: action.data.statusInfo.error
                    };
                case STATUS.SEARCH_CARD_FAIL:
                    return {
                        ...state,
                        dataSearch: null,
                        status: action.data.status,
                        error: action.data.statusInfo.error
                    };

                default:
                    return state;
            }
        default:
            return state;
    }
}
