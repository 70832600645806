import { Field } from 'formik';
import { Form } from 'antd';
import lodash from 'lodash';
import React from 'react';
import { Translate } from "react-localize-redux";
import styled from 'styled-components'

const { Item } = Form;

const Box = styled(Item)`
  label {
    float: left;
    text-align: left;
    line-height: 22px;
    margin-top: 12px;
  }
  .ant-form-item-label > label::after {
    content: '';
  }
`

export default ({
  component: Component,
  translate,
  form,
  name,
  label,
  hasFeedback,
  required,
  labelCol,
  wrapperCol,
  isTranslate = true,
  ...props
}) => {
  const colProps = labelCol && wrapperCol ? {
    labelCol,
    wrapperCol
  } : {};

  const dirty = form.touched[name] || form.submitCount;
  return (
    <Translate>
      {({translate}) => (
        <Box
          label={isTranslate ? translate(label) : label}
          validateStatus={dirty && form.errors[name] ? 'error' : ''}
          help={dirty && form.errors[name] && translate(form.errors[name], form.errors[name])}
          hasFeedback={hasFeedback}
          required={required}
          {...colProps}
        >
          <Field {...props} name={name} component={Component} />
        </Box>
      )}
    </Translate>
  );
};
