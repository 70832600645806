import React from 'react';
import './style.css';
import { Button, Input } from "../antd";

export default class PassportImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rotation: 0,
            key: 0,
            width: null,
            height: 'auto'
        };

        this.rotate = this.rotate.bind(this);
        this.minZoom = 150;
        this.maxZoom = 900;
    }

    rotate (item) {
        let newRotation = item.rotate + 90;
        if (newRotation >= 360) {
            newRotation = -360;
        }

        item.rotate = newRotation;
        this.setState({
            key: this.state.key
        })

        let boxImg = document.querySelector('figure');
        let widthImg = boxImg.clientWidth;

        this.setState({
            width: widthImg
        })
    }

    handleMouseMove = (item, e) => {
        const {left, top, width, height, right, bottom} = e.target.getBoundingClientRect();
        const angelTypeOne = [0, -360];
        const angelTypeTwo = [90, -270];
        const angelTypeThree = [180, -180];
        const angelTypeFour = [270, -90];
        let x, y;

        if (angelTypeOne.includes(item.rotate)) {
            x = (e.pageX - left) / width * 100;
            y = (e.pageY - top) / height * 100;
        }

        if (angelTypeTwo.includes(item.rotate)) {
            x = (e.pageY - top) / height * 100;
            y = (right - e.pageX) / width * 100;
        }

        if (angelTypeThree.includes(item.rotate)) {
            x = (right - e.pageX) / width * 100;
            y = (bottom - e.pageY) / height * 100;
        }

        if (angelTypeFour.includes(item.rotate)) {
            x = (bottom - e.pageY) / height * 100;
            y = (e.pageX - left) / width * 100;
        }

        this.setState({zom: `${x}% ${y}%`})
    };

    zoomIn = (item) => {
        item.zoom = item.zoom + this.minZoom;
        if (item.zoom > this.maxZoom)
            item.zoom = this.maxZoom;
        this.setState({ key: this.state.key });
    }
    
    zoomOut = (item) => {
        item.zoom = item.zoom - this.minZoom;
        if (item.zoom < this.minZoom)
            item.zoom = this.minZoom;

        this.setState({ key: this.state.key });
    }

    reset = (item) => {
        item.zoom = this.minZoom;
        item.rotate = 0;
        this.setState({ key: this.state.key });
    }

    render() {
        const arrImage = this.props.passportImage;
        const {width, height} = this.state;

        return arrImage.map((item, index) => {
            return (
                <div className="passport-image-component" key={index}>
                    <div key={this.state.key} className="image-passport-cover d-flex justify-content-center align-items-center">
                        <figure className="figure" onMouseMove={(e) => this.handleMouseMove(item, e)} style={{
                            transform: `rotate(${item.rotate}deg)`, perspective: 'none',
                            backgroundImage: `url(${item.image})`,
                            backgroundPosition: `${this.state.zom}`,
                            backgroundSize: `${item.zoom}%`,
                            height: [90,270, -90, -270].includes(item.rotate) && width,
                            width: [90,270, -90, -270].includes(item.rotate) && height
                        }}>
                            <img alt=""
                                onClick={() => this.rotate(item)}
                                className="image-passport"
                                src={item.image}
                                 style={{
                                     height:[90,270, -90, -270].includes(item.rotate) && width,
                                     width: [90,270, -90, -270].includes(item.rotate) && height
                                 }}
                            >
                            </img>
                        </figure>
                    </div>
                    <div className="btns-image">
                        <div className="btn-option-image">
                            <Button className="btn-zoom" onClick={() => this.zoomIn(item)}><span>&#43;</span></Button>
                        </div>
                        <div className="btn-option-image input">
                            <Input className="btn-zoom" className="input-zoom" value={item.zoom} disabled/>
                        </div>
                        <div className="btn-option-image">
                            <Button className="btn-zoom" onClick={() => this.zoomOut(item)}><span>&#8722;</span></Button>
                        </div>
                        <div className="btn-option-image">
                            <Button className="btn-zoom" onClick={() => this.reset(item)}><span>&#10227;</span></Button>
                        </div>
                    </div>
                </div>
            )
        });
    };
}
