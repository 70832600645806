let currentYear = new Date();
currentYear = currentYear.getFullYear();

export const DateTime = {
  getYearsDuration: function() {
    let years = [];
    for (let i = currentYear; i <= currentYear + 30; i++) {
      years.push({ id: 1, value: i });
    }
    return years;
  },
  getYears: function() {
    let years = [];
    for (let i = 1920; i <= currentYear; i++) {
      years.push({ id: i, value: i });
    }
    return years;
  },
  getYearsExpiry: function() {
    let years = [];
    for (let i = currentYear; i <= currentYear + 50; i++) {
      years.push({ id: i, value: i });
    }
    return years;
  },
  getMonths: function() {
    let months = [];
    for (let i = 1; i <= 12; i++) {
      months.push({ id: i, value: i });
    }
    return months;
  },
  getDates: function() {
    let dates = [];
    for (let i = 1; i <= 31; i++) {
      dates.push({ id: i, value: i });
    }
    return dates;
  }
};

export const payment = {
  currency: "GBT"
};

export const commonFunctions = {
  isNumeric: function(num) {
    return !isNaN(num);
  }
};

export const cardData = {
  quantity: [{ id: 1, value: 1 }],
  quantities: [{ id: 1, value: 1 }, { id: 2, value: 2 }],
  currencies: [{ id: 1, value: "USD" }]
};

export const confirmDataList = [
  {
    title: "Confirm",
    content:
      "The information provided in this Application Form is in all respects complete, true and accurate, the Application shall bear the legal responsibility for the above information."
  },
  {
    title: "Confirm",
    content:
      "The Applicants agrees and authorizes the Company to review the accuracy and authenticity of all the the personal information on this Application Form."
  },
  {
    title: "Confirm",
    content:
      "The Applicants has read, understood and confirmed, and agrees and accepts to be bound by all the provisions of this Application Form and charges and clause of the «Services of Rules»."
  },
  {
    title: "Confirm",
    content:
      "The Applicants understands and agrees to the relevant principles on the Data (Privacy) Ordinance (Cap.486). Data and information help by the Company relating to an Applicant will be kept confidential but the Company may provide such information to any entity, agency, regulatory or goverment body in any jurisdiction if required by law or pursuant to any court orders, rules or regulations to which the Company is subject. In such cases, the Company is usually under a duty of secrecy and will not be able to notify an Applicant or seek his or her consent in relation to such release of data and information."
  },
  {
    title: "Confirm",
    content:
      "The Applicant agrees the Company to pass all such personal information to eZpay Limited for his or her application final approval."
  }
];

export const businessIds = {
  GREENBOX: {
    id: "01",
    name: "GREENBOX"
  },
  TRIPLE: {
    id: "02",
    name: "TRIPLE"
  }
};

//export const businessOptions = Object.values(businessIds).map(item => ({ name: item.name, value: item.id }));

export const businessOptions = [
  {
    name: "All",
    value: null
  },
  {
    name: "GREENBOX (A)",
    value: "A"
  },
  {
    name: "TRIPLE (GT)",
    value: "GT"
  },
  {
    name: "TRIPLE (GN)",
    value: "GN"
  },
]

export const statusCard = {
    DONE: {
      id: "done",
      name: "DONE"
    },
    RECONFIRM: {
      id: "reconfirm",
      name: "RECONFIRM"
    },
    RECONFIRMED: {
      id: "reconfirmed",
      name: "RECONFIRMED"
    },
    CHECKED: {
      id: "checked",
      name: "CHECKED"
    },
    PRINTED: {
      id: "printed",
      name: "PRINTED"
    },
    CARD_UPDATE: {
      id: "card_update",
      name: "CARD_UPDATE"
    },
    RESERVE: {
      id: "reserve",
      name: "RESERVE"
    },
    INPUT: {
      id: "input",
      name: "INPUT"
    },
    SENT: {
      id: "sent",
      name: "SENT"
    },
    CONFIRMING: {
      id: "confirming",
      name: "CONFIRMING"
    },
    CONFIRMED: {
      id: "confirmed",
      name: "CONFIRMED"
    },
    COMPLETED: {
      id: "completed",
      name: "COMPLETED"
    },
    CARD_VALID: {
      id: "card_valid",
      name: "CARD_VALID"
    }
}

export const statusOptions = Object.values(statusCard).map(item => ({ name: item.name, value: item.id }));

export const TRIPLE_STEPS = [
  {
    step: 1,
    title: "Terms of use",
    description: "Terms of use"
  },
  {
    step: 2,
    title: "SMS certification",
    description: "Confirm your phone number"
  },
  {
    step: 3,
    title: "Application",
    description: "Information user"
  },
  {
    step: 4,
    title: "Application",
    description: "Information card/passport"
  },
  {
    step: 5,
    title: "Application",
    description: "Sign your name here"
  },
  {
    step: 6,
    title: "Application",
    description: "Confirm information user again"
  },
  {
    step: 7,
    title: "Application",
    description: "Confirm information card/passpost again"
  },
  {
    step: 8,
    title: "Application",
    description: "Confirm account SGP to register"
  },
  {
    step: 9,
    title: "Current SGP account ID",
    description: "Active SGP account to register application"
  }
];

export const countryUsing = {
  identityCard: ['CHN', 'HKG'],
  passport: ['TWN', 'MAC', 'JPN']
}
