import {callApi} from "../utils/apiUtils";
import {STATUS} from '../reducers/permissionReducer';

const ACTIONS = {
    UPDATE_STATUS: "UPDATE_STATUS",
};

const updateStatus = (status, statusInfo) => {
    return {
        type: ACTIONS.UPDATE_STATUS,
        data: {
            status,
            statusInfo
        }
    }
};


const getRoles = () => {
    let config = {
        method: 'get',
        isAuthorization: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_ROLE_PROGRESS));
        callApi('/api/get_all_role',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_ROLE_SUCCESS, {role: data}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_ROLE_FAILED, {error: err}));
            }
        );
    };
};

const deleteRole = data => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.DELETE_ROLE_PROGRESS));
        callApi('/api/delete_role',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.DELETE_ROLE_SUCCESS, {data}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.DELETE_ROLE_FAILED, {error: err}));
            }
        );
    };
};

const createRole = data => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.CREATE_ROLE_PROGRESS));
        callApi('/api/create_role',
            config,
            null,
            (result) => {
                dispatch(updateStatus(STATUS.CREATE_ROLE_SUCCESS, {role: result}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.CREATE_ROLE_FAILED, {error: err}));
            }
        );
    };
};

const updatePermission = data => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.UPDATE_ROLE_PROGRESS));
        callApi('/api/edit_role',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.UPDATE_ROLE_SUCCESS, {data}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.UPDATE_ROLE_FAILED, {error: err}));
            }
        );
    };
};

export {ACTIONS, getRoles, deleteRole, createRole, updatePermission};
