import React from 'react';
import {Link} from 'react-router-dom';
import Form from '../Form';
import {Button} from '@material-ui/core';
import './style.css';

export default () => (
    <Form>
        <div className="component-register-page5">
            <div className="content">
                We accepted your application. To the registered email address<br/>
                We sent the following content:<br/><br/>

                - Receipt number<br/><br/>

                Please keep this email carefully until issuing the card.<br/>
                Also, when making an inquiry, please be sure to inquiry the reception number, name, registered email
                address.<br/>
                Please let me know.<br/>
            </div>
            <div className="text-center">
                <Link to="/">
                    <Button variant="contained" color="primary">OK</Button>
                </Link>
            </div>
        </div>
    </Form>
);