import { Icon, Tooltip } from "antd";
import React, { Component } from "react";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import {
  ACTIONS,
  getApplications,
  resetApplicationError,
  setApplicationStoreParams,
  resetAdminPage
} from "../../../../actions/adminActions";
import Alert from "../../../../components/Alert";
import { formatAddress, formatInforUser } from "../../../../utils/commonUtils";
import {
  Container,
  Page,
  PaginationBar,
  Table,
  Input,
  Button,
  Modal
} from "../../../../components/antd";
import RegistrationForm from "../../../RegistrationForm";
import "./style.css";
import { businessIds } from "../../../../constants/commonData";

class RawRegistrationList extends Component {
  constructor() {
    super();
    this.state = {
      selectedUser: null,
      isShowModal: false,
      isSearching: false
    };
    this._columns = [
      {
        title: "No",
        align: "center",
        width: 100,
        key: "no",
        render: (cell, record, index) => index + 1
      },
      {
        title: "行動",
        width: 100,
        align: "center",
        key: "action",
        render: (cell, record) => (
          <Icon
            onClick={() => this.handleEditUser(record)}
            className="cell-edit-button"
            type="edit"
            theme="filled"
          />
        )
      },
      {
        key: "businessMemberId",
        width: 130,
        title: "メンバーID",
        align: "center",
        dataIndex: "businessMemberId"
      },
      {
        key: "firstname",
        align: "center",
        width: 200,
        title: (
          <>
            名
            <br />姓
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.firstname}</div>
            <div>{record.lastname}</div>
          </>
        )
      },
      {
        key: "phoneNumber",
        width: 200,
        align: "center",
        title: "電話番号",
        dataIndex: "phoneNumber"
      },
      {
        key: "buildingName",
        align: "center",
        title: "住所",
        width: 400,
        render: (cell, item) => {
          const title = formatAddress(item);
          return (
            <Tooltip placement="topLeft" title={title}>
              {title}
            </Tooltip>
          );
        }
      },
      {
        key: "status",
        align: "center",
        width: 150,
        title: "ステータス",
        render: (item, record) => (
          <Translate>{({ translate }) => translate(record.status)}</Translate>
        )
      },
      {
        key: "postCode",
        width: 120,
        align: "center",
        title: "郵便番号",
        dataIndex: "postCode"
      },
      {
        key: "email",
        width: 300,
        align: "center",
        title: "メールアドレス",
        dataIndex: "email"
      },
      {
        key: "gender",
        align: "center",
        width: 140,
        title: (
          <>
            性別
            <br />
            生年月日
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.gender ? "Male" : "Female"}</div>
            <div>{record.birthday}</div>
          </>
        )
      },
      {
        key: "passportNumber",
        align: "center",
        width: 250,
        title: "パスポート番号",
        dataIndex: "passportNumber"
      },
      {
        key: "passportIssue",
        align: "center",
        width: 140,
        title: (
          <>
            発行日
            <br />
            有効期限
          </>
        ),
        render: (item, record) => (
          <>
            <div>{record.passportIssue}</div>
            <div>{record.passportExpired}</div>
          </>
        )
      },
      {
        key: "passportPlaceIssued",
        width: 140,
        align: "center",
        title: "旅券発行地",
        dataIndex: "passportPlaceIssued"
      },
      {
        key: "createdAt",
        align: "center",
        width: 140,
        title: "カード申請日",
        dataIndex: "createdAt"
      },
      {
        key: "_id",
        align: "center",
        width: 300,
        title: "問い合わせ番号",
        dataIndex: "_id"
      }
    ];
  }

  componentDidMount() {
    this._getApplications({
      page: 1,
      limit: 100,
      str: "",
      box: null
    });
  }

  componentWillUnmount() {
    const { resetAdminPage } = this.props;
    resetAdminPage();
  }

  handleEditUser(item) {
    this.setState({ selectedUser: item, isShowModal: true });
  }

  closeModal = () => {
    this.setState({ isShowModal: !this.state.isShowModal, selectedUser: null });
  };

  _onPaginationBarChange = (type, value) => {
    if (type === "page") {
      this._getApplications({ page: value });
    } else {
      this._getApplications({ page: 1, limit: value });
    }
  };

  _getApplications = newParams => {
    const {
      getApplications,
      setApplicationStoreParams,
      applicationStore: { params }
    } = this.props;

    let payload = {
      ...params,
      ...newParams,
      status: null,
      type: "get_raw"
    };
    setApplicationStoreParams({
      ...newParams,
      status: null,
      type: "params"
    });

    getApplications(payload);
  };

  renderUserFormDialog() {
    const { selectedUser } = this.state;
    if (selectedUser) {
      let newSelectedUser = {
        ...selectedUser,
        ...formatInforUser(selectedUser)
      };
      return (
        <Modal
          size="ls"
          visible={this.state.isShowModal}
          onCancel={this.closeModal}
          className="registration-form-modal"
          title="Detail User"
        >
          <RegistrationForm
            status={selectedUser.status}
            data={newSelectedUser}
            update={true}
            isUpdate={true}
            editable={true}
            onClose={() => this.closeModal()}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  _onSearchFieldChange = e => {
    const { setApplicationStoreParams } = this.props;

    setApplicationStoreParams({
      [e.target.name]: e.target.value,
      type: "params"
    });
  };

  _onSearch = () => {
    const { applicationStore, getApplications, setApplicationStoreParams } = this.props;
    this.setState({ isSearching: true });
    setApplicationStoreParams({
      page: 1,
      type: "params"
    });
    let data = {
      ...applicationStore.params,
      page: 1,
      type: "get_raw"
    };
    getApplications(data, () => {
      this.setState({ isSearching: false });
    });
  };

  render() {
    const { isSearching } = this.state;
    const {
      resetApplicationError,
      applicationStore: { users, params, submitting, error }
    } = this.props;

    const columns = this._columns;

    return (
      <Translate>
        {({ translate }) => (
          <Page className="raw-registration-list">
            <Container>
              {this.renderUserFormDialog()}
              <div className="tool-box">
                <p className="title">原文</p>
                <div className="right-box">
                  <Input
                    modern
                    className="search-input"
                    placeholder="ID, MEMBERID, EMAIL, NAME, PASSPORT NUMBER"
                    name="str"
                    onChange={this._onSearchFieldChange}
                    value={params.str}
                  />
                  <Button loading={isSearching} onClick={this._onSearch}>
                    検索
                  </Button>
                </div>
              </div>
              {!!params.total && (
                <div className="top-action-box">
                  <div className="button-group">
                    <PaginationBar
                      current={params.page}
                      total={params.total}
                      onChange={this._onPaginationBarChange}
                      pageSize={params.limit}
                    />
                  </div>
                </div>
              )}

              <Table
                loading={submitting === ACTIONS.GET_APPLICATIONS_REQUEST}
                rowKey={(row, index) => index}
                columns={columns}
                dataSource={users}
                scroll={{ x: 2900, y: "calc(100vh - 280px)" }}
                onChange={this._handleTableChange}
              />
              {ACTIONS.GET_APPLICATIONS_FAILURE === submitting && (
                <Alert
                  title="Error"
                  content={translate(error && "errors." + error.code)}
                  okTitle="OK"
                  onOK={() => {
                    resetApplicationError();
                  }}
                />
              )}
            </Container>
          </Page>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    applicationStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setApplicationStoreParams: data => {
      dispatch(setApplicationStoreParams(data));
    },
    resetApplicationError: () => {
      dispatch(resetApplicationError());
    },
    getApplications: (data, callback) => {
      dispatch(getApplications(data, callback));
    },
    resetAdminPage: () => {
      dispatch(resetAdminPage());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RawRegistrationList);
