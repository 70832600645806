import React from "react";
import { connect } from "react-redux";
import {
  statisticOfYear,
  statisticOfDateRange,
  changeFilter
} from "../../../../actions/statisticApplicationAction";
import { getCurrentUser } from "../../../../actions/adminActions";
import { Translate } from "react-localize-redux";
import { STATUS as STATUSES } from "../../../../reducers/statisticApplicationReducer";
import Alert from "../../../../components/Alert";
import "./style.css";
import TableStatistic from "../../../../components/TableStatistic";
import { Input } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Button as ButtonAnt } from "../../../../components/antd";
import { PERMISSION } from "../../../../utils/commonUtils";

class StatisticApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "2018",
      statistic: [],
      startDate: null,
      alertAck: true,
      endDate: null
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };
  componentDidMount() {
    const { changeFilter, getCurrentUser } = this.props;
    getCurrentUser(null, () => {
      const { listPermission } = this.props;
      if (listPermission.includes(PERMISSION.STATISTIC_APPLICATION_BY_YEAR))
        changeFilter("byYear");
      else if (listPermission.includes(PERMISSION.STATISTIC_APPLICATION_BY_DAYS))
        changeFilter("byDateRange");
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.statistic !== this.state.statistic) {
      this.setState({ statistic: nextProps.statistic });
    }
  }

  handleStatistic = () => {
    const { year, startDate, endDate } = this.state;
    const { currentFilter } = this.props;
    this.alertAck();
    if (currentFilter === "byYear") {
      this.props.statisticOfYear({ year });
    } else {
      this.props.statisticOfDateRange({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      });
    }
  };

  renderStatisticYear = () => {
    let years = [];
    for (let i = 0; i <= new Date().getFullYear() - 2018; i++) {
      years.push(2018 + i);
    }
    const { listPermission } = this.props;
    const loading =
      this.props.status === STATUSES.STATISTIC_APPLICATION_PROGRESS;
    return (
      <React.Fragment>
        <Input
          type="select"
          className="w-auto"
          onChange={e => {
            this.setState({ year: e.target.value });
          }}
          name="select"
          id="group-select"
          value={this.state.year}
        >
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </Input>
        {listPermission.includes(PERMISSION.STATISTIC_APPLICATION_BY_YEAR) && (
          <ButtonAnt
            className="statistic-btn"
            disabled={loading}
            onClick={this.handleStatistic}
            loading={loading}
          >
            Statistic
          </ButtonAnt>
        )}
      </React.Fragment>
    );
  };

  handleChangeStart(date) {
    this.setState({
      startDate: date
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date
    });
  }

  renderStatisticDateRange = () => {
    const { startDate, endDate } = this.state;
    const { listPermission } = this.props;
    const loading = this.props.status === STATUSES.STATISTIC_APPLICATION_PROGRESS;
    
    return (
      <div className="d-flex">
        <DatePicker
          customInput={<Input />}
          className="w-auto mr-2"
          selected={startDate}
          selectsStart
          placeholderText="YYYY-MM-DD"
          dateFormat="yyyy/MM/dd"
          startDate={startDate}
          endDate={endDate}
          onChange={this.handleChangeStart}
        />
        <DatePicker
          customInput={<Input />}
          className="w-auto"
          selected={endDate}
          selectsEnd
          placeholderText="YYYY-MM-DD"
          dateFormat="yyyy/MM/dd"
          startDate={startDate}
          endDate={endDate}
          onChange={this.handleChangeEnd}
        />
        {listPermission.includes(PERMISSION.STATISTIC_APPLICATION_BY_DAYS) && (
          <ButtonAnt
            className="statistic-btn"
            disabled={loading || !startDate || !endDate}
            onClick={this.handleStatistic}
            loading={loading}
          >
            Statistic
          </ButtonAnt>
        )}
      </div>
    );
  };

  render() {
    const { statistic } = this.state;
    const { listPermission, currentFilter } = this.props;
    const loading = this.props.status === STATUSES.STATISTIC_APPLICATION_PROGRESS;
    return (
      <Translate>
        {({ translate }) => (
          <div className="statistic-application-container">
            <div className="title">申し込み状況一覧表</div>
            <div className="content">
              {currentFilter && (
                <React.Fragment>
                  <p>Filter by:</p>
                  <Input
                    type="select"
                    onChange={e => {
                      this.props.changeFilter(e.target.value);
                    }}
                    value={currentFilter}
                    name="select"
                    id="filter"
                    className="mr-2 ml-2 w-auto"
                  >
                    {listPermission.includes(
                      PERMISSION.STATISTIC_APPLICATION_BY_YEAR
                    ) && <option value="byYear">Year</option>}
                    {listPermission.includes(
                      PERMISSION.STATISTIC_APPLICATION_BY_DAYS
                    ) && <option value="byDateRange">Date Range</option>}
                  </Input>
                </React.Fragment>
              )}
              {currentFilter &&
                currentFilter === "byYear" &&
                this.renderStatisticYear()}
              {currentFilter &&
                currentFilter === "byDateRange" &&
                this.renderStatisticDateRange()}
            </div>
            {statistic.length !== 0 &&
              statistic.map((data, index) => (
                <TableStatistic
                  className="table-statistic"
                  key={index}
                  title={index}
                  data={data}
                />
              ))}
            {[STATUSES.STATISTIC_APPLICATION_FAILED].indexOf(
              this.props.status
            ) >= 0 &&
              !this.state.alertAck && (
                <Alert
                  title="Error"
                  content={translate(
                    this.props.error && "errors." + this.props.error.code
                  )}
                  okTitle="OK"
                  onOK={() => {
                    this.setState({ alertAck: true });
                  }}
                />
              )}
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    statistic: state.statisticApplication.statistic,
    error: state.statisticApplication.error,
    status: state.statisticApplication.status,
    listPermission: state.admin.listPermission,
    currentFilter: state.statisticApplication.currentFilter
  };
};

const mapDispatchToProps = dispatch => {
  return {
    statisticOfYear: data => {
      dispatch(statisticOfYear(data));
    },
    statisticOfDateRange: data => {
      dispatch(statisticOfDateRange(data));
    },
    changeFilter: data => {
      dispatch(changeFilter(data));
    },
    getCurrentUser: (data, callback) => {
      dispatch(getCurrentUser(data, callback));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticApplication);
