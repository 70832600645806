import React, {Component} from "react";
import {Radio, TextField, FormControlLabel} from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import {connect} from "react-redux";
import {Translate} from 'react-localize-redux';
import {DateTime, commonFunctions} from "../../../../constants/commonData";
import ContentPanel from "../../../../components/ContentPanel";
import Alert from "../../../../components/Alert";
import { Button as ButtonAntd, Input as InputAntd } from "../../../../components/antd";
import {
    createApplication,
    checkBusinessId,
    loginUserByID,
    cloneApplication,
    resetApplicationError
} from "../../../../actions/adminActions";
import {STATUS as ADMIN_STATUS} from "../../../../reducers/adminReducer";
import {cardData} from "../../../../constants/commonData";
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import "./style.css";
import {Validation, allProfession, allCountries} from "../../../../utils/commonUtils";
import { ROLE_SUPPER_ADMIN } from '../../../../utils/commonUtils'
import { Input, Modal } from 'antd';
import {getEnv} from '../../../../env';

const initState =
    {
        businessMemberId: "",
        mail:null,
        password: null,
        firstname: "",
        lastname: "",
        maleGender: "male",
        birthYear: -1,
        birthMonth: -1,
        birthDate: -1,
        phoneNumber: "",

        country: -1,
        postCode: "",
        prefecture: "",
        city: "",
        afterAddress: "",
        buildingName: "",
        profession: "",
        greenBoxId: "",
        email: "",

        passportNumber: "",
        passportIssueYear: -1,
        passportIssueMonth: -1,
        passportIssueDate: -1,
        passportExpiryYear: -1,
        passportExpiryMonth: -1,
        passportExpiryDate: -1,
        passportPlaceIssued: -1,
        passportImage: null,
        showPin1: false,
        showPin2: false,
        showConfirmPin1: false,
        showConfirmPin2: false,
        showPasswordCard: false,
        showConfirmPasswordCard: false,
        passwordCard: "",
        confirmPasswordCard: "",
        cards: [{baseCurrency: "USD", pin: "", confirmPin: ""}],

        fullyChecked: false,
        isSubmitted: false,
        alertAck: true,
        showForm: false,
        loadingCheck: false
    };

class RegistrationFormAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {...initState};
        this.countries = allCountries;
        this.passportCountries = allCountries;
    }

    alertAck = () => {
        this.setState({alertAck: false});
    };

    handleChange(name) {
        let {cards} = this.state;
        return (e) => {
            if (e.target) {
                this.setState({[name]: e.target.value}, () => {
                    this.validateRegisterForm();
                });
            }

            if (e.target.files) {
                this.setState({[name]: e.target.files && e.target.files.length ? e.target.files[0] : null});
            }

            if (name === "selectedCard") {
                if (e.target.value === "1") {
                    cards = [{baseCurrency: "USD", pin: "", confirmPin: ""}];
                }

                if (e.target.value === "2") {
                    cards = [
                        {baseCurrency: "USD", pin: "", confirmPin: ""},
                        {baseCurrency: "USD", pin: "", confirmPin: ""}
                    ]
                }
                this.setState({cards: cards});
            }
        }
    }

    handlePINChange(index) {
        let {cards} = this.state;
        return (e) => {
            if (e.target) {
                cards[index].pin = e.target.value;
                this.setState({cards: cards}, () => {
                    this.validateRegisterForm();
                });
            }
        }
    }

    handleConfirmPINChange(index) {
        let {cards} = this.state;
        return (e) => {
            if (e.target) {
                cards[index].confirmPin = e.target.value;
                this.setState({cards: cards}, () => {
                    this.validateRegisterForm();
                });
            }
        }
    }

    handleSubmit(e) {
        this.alertAck();
        e.preventDefault();
        let data = {
            firstname: this.state.firstname.trim(),
            lastname: this.state.lastname.trim(),
            maleGender: this.state.maleGender,
            birthYear: this.state.birthYear,
            birthMonth: this.state.birthMonth,
            birthDate: this.state.birthDate,
            phoneNumber: this.state.phoneNumber,
            profession: this.state.profession,

            country: this.state.country,
            postCode: this.state.postCode,
            prefecture: this.state.prefecture,
            city: this.state.city,
            afterAddress: this.state.afterAddress,
            buildingName: this.state.buildingName,

            passportNumber: this.state.passportNumber,
            passportIssueYear: this.state.passportIssueYear,
            passportIssueMonth: this.state.passportIssueMonth,
            passportIssueDate: this.state.passportIssueDate,
            passportExpiryYear: this.state.passportExpiryYear,
            passportExpiryMonth: this.state.passportExpiryMonth,
            passportExpiryDate: this.state.passportExpiryDate,
            passportPlaceIssued: this.state.passportPlaceIssued,
            passportImage: this.state.passportImage,

            passwordCard: this.state.passwordCard,
            confirmPasswordCard: this.state.confirmPasswordCard,
            cards: this.state.cards,
            email: this.state.email,
            businessCreatedAt: this.state.businessCreatedAt,
            greenBoxId: this.state.greenBoxId,
        };

        this.setState({isSubmitted: true});
        this.state.linkPassportImage ? this.props.cloneApplication(data) : this.props.updateInfo(data);
    }

    validateRegisterForm() {
        let {
            firstname, lastname, birthYear, birthMonth, birthDate, country, phoneNumber, postCode,
            prefecture, city, afterAddress, passportNumber, passportIssueYear, passportIssueMonth,
            passportIssueDate, passportExpiryYear, passportExpiryMonth, passportExpiryDate, passportPlaceIssued,
            passportImage, passwordCard, cards, confirmPasswordCard, profession
        } = this.state;

        let item = {
            firstname,
            lastname,
            birthYear,
            birthMonth,
            birthDate,
            country,
            phoneNumber,
            postCode,
            prefecture: prefecture && prefecture.trim(),
            city: city && city.trim(),
            profession: profession && profession.trim(),
            afterAddress: afterAddress && afterAddress.trim(),
            passportNumber,
            passportIssueYear,
            passportIssueMonth,
            passportIssueDate,
            passportExpiryYear,
            passportExpiryMonth,
            passportExpiryDate,
            passportPlaceIssued,
            passportImage: passportImage || this.state.linkPassportImage,
            passwordCard,
            confirmPasswordCard,
            cards,
            email: this.state.email,
            greenBoxId: this.state.greenBoxId,
        };

        let fullyChecked = !Object.keys(item).some((key) => {
            return item[key] === '' || item[key] === null || item[key] === undefined || item[key] === -1 || item[key] === "-1";
        });


        fullyChecked = fullyChecked
            && commonFunctions.isNumeric(postCode)
            && Validation.validatePostCode(postCode)
            && commonFunctions.isNumeric(phoneNumber)
            && Validation.validateEmail(this.state.email)
            && Validation.validateName(firstname)
            && phoneNumber.length <= 18
            && this.state.greenBoxId.length === 8
            && Validation.validateName(lastname)
            && Validation.validateAfterAddress(afterAddress)
            && Validation.validateAddress(prefecture)
            && Validation.validateAddress(city)
            && Validation.validateAddress(this.state.buildingName)
            && Validation.checkDate(birthYear, birthMonth, birthDate)
            && Validation.checkDate(passportIssueYear, passportIssueMonth, passportIssueDate)
            && Validation.checkDate(passportExpiryYear, passportExpiryMonth, passportExpiryDate);

        for (let i = 0; i < cards.length; i++) {
            fullyChecked = fullyChecked &&
                cards[i].pin.length === 6 &&
                cards[i].confirmPin.length === 6 &&
                Validation.validateNumber(cards[i].pin) &&
                Validation.validateNumber(cards[i].confirmPin) &&
                Validation.checkSamePassword(cards[i].pin, cards[i].confirmPin);
        }
        fullyChecked = fullyChecked
            && passwordCard.length === 8
            && Validation.validatePasswordCard(passwordCard)
            && confirmPasswordCard.length === 8
            && Validation.validatePasswordCard(confirmPasswordCard)
            && Validation.validatePhoneNumber(phoneNumber)
            && Validation.checkSamePassword(passwordCard, confirmPasswordCard);

        if (!this.state.linkPassportImage)
            fullyChecked = fullyChecked && passportImage && Validation.validateUploadFileSize(passportImage) === 0;

        this.setState({fullyChecked: fullyChecked});
        return fullyChecked;
    }

    renderMainInfo(translate) {
        const {isSubmitted, firstname, lastname, maleGender, birthYear, birthMonth, birthDate, phoneNumber, profession} = this.state;
        return (
            <ContentPanel header="Personal information">
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Name</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <TextField
                                        placeholder="Given name (Example: HANAKO)"
                                        className="input"
                                        id="firstname"
                                        label="First Name"
                                        value={firstname}
                                        onChange={this.handleChange('firstname')}
                                        error={!firstname || !Validation.validateName(firstname)}
                                        required={!firstname || !Validation.validateName(firstname)}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <TextField
                                        placeholder="Surname (Example: TANAKA)"
                                        className="input"
                                        id="lastname"
                                        label="Last Name"
                                        value={lastname}
                                        onChange={this.handleChange('lastname')}
                                        required={!lastname || !Validation.validateName(lastname)}
                                        error={!lastname || !Validation.validateName(lastname)}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Gender</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-12 col-sm-3">
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={maleGender === "male"}
                                                onChange={this.handleChange('maleGender')}
                                                disabled={isSubmitted}
                                                value="male"
                                            />
                                        }
                                        className=""
                                        label="Male"
                                    />
                                </div>
                                <div className="col-12 col-sm-3">
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={maleGender === "female"}
                                                onChange={this.handleChange('maleGender')}
                                                disabled={isSubmitted}
                                                value="female"
                                            />
                                        }
                                        label="Female"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Birthday</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Year"
                                        value={birthYear}
                                        onChange={this.handleChange('birthYear')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!birthYear || birthYear === -1 || birthYear === "-1"}
                                        error={!Validation.checkDate(birthYear, birthMonth, birthDate) || !birthYear || birthYear === -1 || birthYear === "-1"}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getYears().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Month"
                                        value={birthMonth}
                                        onChange={this.handleChange('birthMonth')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {className: ""}
                                        }}
                                        required={!birthMonth || birthMonth === -1 || this.state.birthMonth === "-1"}
                                        error={!Validation.checkDate(birthYear, birthMonth, birthDate) || !birthMonth || birthMonth === -1 || this.state.birthMonth === "-1"}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getMonths().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Date"
                                        value={birthDate}
                                        onChange={this.handleChange('birthDate')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {className: ""}
                                        }}
                                        required={!birthDate || birthDate === -1 || birthDate === "-1"}
                                        error={!Validation.checkDate(birthYear, birthMonth, birthDate) || !birthDate || birthDate === -1 || birthDate === "-1"}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getDates().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Mobile Phone</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <PhoneInput
                                placeholder="Phone number"
                                value={phoneNumber}
                                country="JP"
                                error="Exactly phone number is required"
                                indicateInvalid={
                                    !phoneNumber || phoneNumber.length > 18 ||
                                    !commonFunctions.isNumeric(phoneNumber) ||
                                    !Validation.validatePhoneNumber(phoneNumber)
                                }
                                disabled={isSubmitted}
                                onChange={(phoneNumber) => {
                                    this.setState({phoneNumber}, () => {
                                        this.validateRegisterForm();
                                    });
                                }}/>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Profession</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                className="input"
                                id="select-profession"
                                select
                                label="Profession"
                                value={profession}
                                onChange={this.handleChange('profession')}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {className: ""}
                                }}
                                required={!profession}
                                error={!profession}
                                disabled={isSubmitted}
                                margin="normal"
                            >
                                <option value={-1}/>
                                {allProfession.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                        </div>
                    </div>
                </div>
            </ContentPanel>
        )
    }

    renderAddressInfo(translate) {
        const {isSubmitted} = this.state;
        return (
            <ContentPanel header="Address" subHeader="It must be an address to receive postal items">
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Country</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="select-currency-native"
                                select
                                className="input"
                                value={this.state.country}
                                onChange={this.handleChange('country')}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                        className: "",
                                    },
                                }}
                                required={!this.state.country || this.state.country === -1 || this.state.country === "-1"}
                                error={!this.state.country || this.state.country === -1 || this.state.country === "-1"}
                                disabled={isSubmitted}
                                margin="normal"
                                label="Country"
                            >
                                <option value={-1}/>
                                {this.countries.map(option => (
                                    <option key={option.code} value={option.name}>
                                        {option.name}
                                    </option>
                                ))}
                            </TextField>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Postal Code</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                className="input"
                                id="postCode"
                                label="Postal Code"
                                value={this.state.postCode}
                                inputProps={{maxLength: 7}}
                                onChange={this.handleChange('postCode')}
                                required={!this.state.postCode || !commonFunctions.isNumeric(this.state.postCode) || !Validation.validatePostCode(this.state.postCode)}
                                error={!this.state.postCode || !commonFunctions.isNumeric(this.state.postCode) || !Validation.validatePostCode(this.state.postCode)}
                                disabled={isSubmitted}
                                margin="normal"
                                placeholder="Postal Code (Example: 5370003)"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Prefectures</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="prefecture"
                                value={this.state.prefecture}
                                className="input"
                                onChange={this.handleChange('prefecture')}
                                required={!this.state.prefecture || !Validation.validateAddress(this.state.prefecture)}
                                error={!this.state.prefecture || !Validation.validateAddress(this.state.prefecture)}
                                disabled={isSubmitted}
                                margin="normal"
                                label="Prefectures"
                                placeholder="Prefectures (Example: Aichi)"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">City</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="city"
                                label="City"
                                value={this.state.city}
                                className="input"
                                onChange={this.handleChange('city')}
                                required={!this.state.city || !Validation.validateAddress(this.state.city)}
                                error={!this.state.city || !Validation.validateAddress(this.state.city)}
                                disabled={isSubmitted}
                                margin="normal"
                                placeholder="City (Example: Toyota)"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">After address</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="afterAddress"
                                label="After Address"
                                value={this.state.afterAddress}
                                className="input"
                                onChange={this.handleChange('afterAddress')}
                                required={!this.state.afterAddress || !Validation.validateAfterAddress(this.state.afterAddress)}
                                error={!this.state.afterAddress || !Validation.validateAfterAddress(this.state.afterAddress)}
                                disabled={isSubmitted}
                                margin="normal"
                                placeholder="Address (Example: 1-2-3, Shinmachi, Nishi-ku)"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Building name/room number</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="buildingName"
                                label="Building name"
                                value={this.state.buildingName}
                                required={!Validation.validateAddress(this.state.buildingName)}
                                error={!Validation.validateAddress(this.state.buildingName)}
                                className="input"
                                onChange={this.handleChange('buildingName')}
                                disabled={isSubmitted}
                                margin="normal"
                                placeholder="Room number and Building name (Example: 1203 Park Tower)"
                            />
                        </div>
                    </div>
                </div>
            </ContentPanel>
        )
    }

    renderCertificateInfo(translate) {
        const {isSubmitted, passportNumber, passportIssueYear, passportIssueMonth, passportIssueDate, passportExpiryYear, passportExpiryMonth, passportExpiryDate, passportPlaceIssued, linkPassportImage, passportImage} = this.state;
        return (
            <ContentPanel header="Certificate information">
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Passport number</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="passportNumber"
                                label="Passport number"
                                value={passportNumber}
                                className="input"
                                onChange={this.handleChange('passportNumber')}
                                required={!passportNumber}
                                error={!passportNumber}
                                disabled={isSubmitted}
                                margin="normal"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Passport of Issue</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Year"
                                        value={passportIssueYear}
                                        onChange={this.handleChange('passportIssueYear')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!passportIssueYear || passportIssueYear === -1 || passportIssueYear === "-1"}
                                        error={!Validation.checkDate(passportIssueYear, passportIssueMonth, passportIssueDate) || !passportIssueYear || passportIssueYear === -1 || passportIssueYear === "-1"}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getYears().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Month"
                                        value={passportIssueMonth}
                                        onChange={this.handleChange('passportIssueMonth')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!passportIssueMonth || passportIssueMonth === -1 || passportIssueMonth === "-1"}
                                        error={!Validation.checkDate(passportIssueYear, passportIssueMonth, passportIssueDate) || !passportIssueMonth || passportIssueMonth === -1 || passportIssueMonth === "-1"}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getMonths().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Date"
                                        value={passportIssueDate}
                                        onChange={this.handleChange('passportIssueDate')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!passportIssueDate || passportIssueDate === -1 || passportIssueDate === "-1"}
                                        error={!Validation.checkDate(passportIssueYear, passportIssueMonth, passportIssueDate) || !passportIssueDate || passportIssueDate === -1 || passportIssueDate === "-1"}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getDates().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Passport of Expiry</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Year"
                                        value={passportExpiryYear}
                                        onChange={this.handleChange('passportExpiryYear')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!passportExpiryYear || passportExpiryYear === -1 || passportExpiryYear === "-1"}
                                        error={!Validation.checkDate(passportExpiryYear, passportExpiryMonth, passportExpiryDate) || !passportExpiryYear || passportExpiryYear === -1 || passportExpiryYear === "-1"}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getYearsExpiry().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Month"
                                        value={passportExpiryMonth}
                                        onChange={this.handleChange('passportExpiryMonth')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!passportExpiryMonth || passportExpiryMonth === -1 || passportExpiryMonth === "-1"}
                                        error={!Validation.checkDate(passportExpiryYear, passportExpiryMonth, passportExpiryDate) || !passportExpiryMonth || passportExpiryMonth === -1 || passportExpiryMonth === "-1"}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getMonths().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Date"
                                        value={passportExpiryDate}
                                        onChange={this.handleChange('passportExpiryDate')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!passportExpiryDate || passportExpiryDate === -1 || passportExpiryDate === "-1"}
                                        error={!Validation.checkDate(passportExpiryYear, passportExpiryMonth, passportExpiryDate) || !passportExpiryDate || passportExpiryDate === -1 || passportExpiryDate === "-1"}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getDates().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Passport issuing place</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="select-currency-native"
                                select
                                className="input"
                                label="Passport issuing place"
                                value={passportPlaceIssued}
                                onChange={this.handleChange('passportPlaceIssued')}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                        className: "",
                                    },
                                }}
                                required={!passportPlaceIssued || passportPlaceIssued === -1 || passportPlaceIssued === "-1"}
                                error={!passportPlaceIssued || passportPlaceIssued === -1 || passportPlaceIssued === "-1"}
                                disabled={isSubmitted}
                                margin="normal"
                            >
                                <option value={-1}/>
                                {this.passportCountries.map(option => (
                                    <option key={option.code} value={option.name}>
                                        {option.name}
                                    </option>
                                ))}
                            </TextField>
                        </div>
                    </div>
                </div>

                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Passport image upload</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div>
                                <div className="text-danger">
                                    <p>Required to have more than seven months' validity remaining on your
                                        passport.</p>
                                </div>
                                {!linkPassportImage && <React.Fragment>
                                    <div>
                                        <input
                                            accept="image/*"
                                            multiple={false}
                                            required={true}
                                            placeholder="Please upload image"
                                            onChange={this.handleChange("passportImage")}
                                            className="upload-image"
                                            type="file"
                                            name="fileToUpload"
                                            ref="fileInput"
                                            id="fileToUpload"/>
                                    </div>
                                    <div>
                                        Required size: 300Kb ~ 4Mb
                                    </div>
                                    {passportImage && Validation.validateUploadFileSize(passportImage) !== 0 &&
                                    <div className="text-danger">
                                        File size is not valid
                                        (currently {Math.round(passportImage.size / 1024)} Kb)
                                    </div>}
                                </React.Fragment>}
                                {linkPassportImage &&
                                <a data-lightbox="example-set" href={linkPassportImage}
                                   className="m-5 image-item">
                                    <figure>
                                        <img style={{width: "100%"}} src={linkPassportImage}
                                             alt=""/>
                                    </figure>
                                </a>}
                            </div>
                        </div>
                    </div>
                </div>
            </ContentPanel>
        )
    }

    renderCardInfo(translate) {
        const {isSubmitted} = this.state;
        return (
            <ContentPanel header="Card information" subHeader="The number of items you can apply for up to two persons">
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Number Of Card</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                select
                                id="Quantities"
                                label="Quantities"
                                value={this.state.cards.length}
                                className="input"
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                        className: "mr-t-10",
                                    },
                                }}
                                disabled={isSubmitted}
                                margin="normal"
                                onChange={this.handleChange("selectedCard")}
                            >
                                {
                                    this.state.cards.length === 1 ?
                                        cardData.quantity.map(option => (
                                            <option key={option.value} value={option.id}>
                                                {option.value}
                                            </option>
                                        )) :
                                        cardData.quantities.map(option => (
                                            <option key={option.value} value={option.id}>
                                                {option.value}
                                            </option>
                                        ))
                                }
                            </TextField>
                        </div>
                    </div>
                </div>
                {this.state.cards.map((item, index) => {
                    return (
                        <div key={index} className="row content-wrapper">
                            <div className="col-12 col-sm-3">
                                <div className="content-title">
                                    <label htmlFor="other">
                                        {(index === 1 ? `${index + 1}nd ` : '') + "Card base"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-5">
                                <div className="content-value">
                                    <TextField
                                        select
                                        id="Currencies"
                                        label="Currencies"
                                        value={item.baseCurrency}
                                        className="input"
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    >
                                        {cardData.currencies.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Create a eZpay password (8 Digits)</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                type={this.state.showPasswordCard ? "text" : "password"}
                                id="passwordCard"
                                label="Create a password"
                                value={this.state.passwordCard}
                                inputProps={{maxLength: 8}}
                                className="input"
                                onChange={this.handleChange('passwordCard')}
                                required={!this.state.passwordCard}
                                error={!this.state.passwordCard || this.state.passwordCard.length !== 8 || !Validation.validatePasswordCard(this.state.passwordCard)}
                                disabled={isSubmitted}
                                margin="normal"
                            />
                        </div>
                    </div>
                    <div className="show-password">
                        <i onClick={() => this.setState({showPasswordCard: !this.state.showPasswordCard})}
                           className={` ${this.state.showPasswordCard ? "fas fa-eye" : "fas fa-eye-slash"}`}/>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Confirm eZpay password</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                type={this.state.showConfirmPasswordCard ? "text" : "password"}
                                id="confirmPasswordCard"
                                label="Confirm password"
                                value={this.state.confirmPasswordCard}
                                inputProps={{maxLength: 8}}
                                className="input"
                                onChange={this.handleChange('confirmPasswordCard')}
                                required={!this.state.confirmPasswordCard}
                                error={!this.state.confirmPasswordCard || this.state.confirmPasswordCard.length !== 8 || !Validation.validatePasswordCard(this.state.confirmPasswordCard) || !Validation.checkSamePassword(this.state.passwordCard, this.state.confirmPasswordCard)}
                                disabled={isSubmitted}
                                margin="normal"
                            />
                        </div>
                    </div>
                    <div className="show-password">
                        <i onClick={() => this.setState({showConfirmPasswordCard: !this.state.showConfirmPasswordCard})}
                           className={` ${this.state.showConfirmPasswordCard ? "fas fa-eye" : "fas fa-eye-slash"}`}/>
                    </div>
                </div>
                {this.state.cards.map((item, index) => {
                    return <React.Fragment key={index}>
                        <div className="row content-wrapper">
                            <div className="col-12 col-sm-3">
                                <div className="content-title">
                                    <label htmlFor="other">
                                        {(index === 1 ? `${index + 1}nd ` : '') + "Card PIN Number (6 Digits)"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-5">
                                <div className="content-value">
                                    <TextField
                                        type={index === 1 ? this.state.showPin1 ? "text" : "password" : this.state.showPin2 ? "text" : "password"}
                                        label="Card PIN number"
                                        value={this.state.cards[index].pin}
                                        inputProps={{maxLength: 6}}
                                        className="input"
                                        onChange={this.handlePINChange(index)}
                                        required={!this.state.cards[index].pin}
                                        error={!this.state.cards[index].pin || this.state.cards[index].pin.length !== 6 || !Validation.validateNumber(this.state.cards[index].pin)}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    />
                                </div>
                            </div>
                            <div className="show-password">
                                {index === 1 ?
                                    <i onClick={() => this.setState({showPin1: !this.state.showPin1})}
                                       className={` ${this.state.showPin1 ? "fas fa-eye" : "fas fa-eye-slash"}`}/> :
                                    <i onClick={() => this.setState({showPin2: !this.state.showPin2})}
                                       className={` ${this.state.showPin2 ? "fas fa-eye" : "fas fa-eye-slash"}`}/>}
                            </div>
                        </div>
                        <div className="row content-wrapper">
                            <div className="col-12 col-sm-3">
                                <div className="content-title">
                                    <label htmlFor="other">
                                        {"Confirm " + (index === 1 ? `${index + 1}nd ` : '') + "Card PIN Number (6 Digits)"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-5">
                                <div className="content-value">
                                    <TextField
                                        type={index === 1 ? this.state.showConfirmPin1 ? "text" : "password" : this.state.showConfirmPin2 ? "text" : "password"}
                                        label="Confirm Card PIN number"
                                        value={this.state.cards[index].confirmPin}
                                        inputProps={{maxLength: 6}}
                                        className="input"
                                        onChange={this.handleConfirmPINChange(index)}
                                        required={!this.state.cards[index].confirmPin}
                                        error={!this.state.cards[index].confirmPin || !Validation.checkSamePassword(this.state.cards[index].pin, this.state.cards[index].confirmPin)}
                                        disabled={isSubmitted}
                                        margin="normal"
                                    />

                                </div>
                            </div>
                            <div className="show-password">
                                {index === 1 ?
                                    <i onClick={() => this.setState({showConfirmPin1: !this.state.showConfirmPin1})}
                                       className={` ${this.state.showConfirmPin1 ? "fas fa-eye" : "fas fa-eye-slash"}`}/> :
                                    <i onClick={() => this.setState({showConfirmPin2: !this.state.showConfirmPin2})}
                                       className={` ${this.state.showConfirmPin2 ? "fas fa-eye" : "fas fa-eye-slash"}`}/>}
                            </div>
                        </div>
                    </React.Fragment>
                })}
            </ContentPanel>
        )
    }

    renderEmailInfo(translate) {
        const {isSubmitted} = this.state;
        return (
            <ContentPanel header="Robin information">
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Green Box ID</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                type="text"
                                id="greenboxId"
                                label="Green Box Id"
                                value={this.state.greenBoxId}
                                inputProps={{maxLength: 8}}
                                className="input"
                                onChange={this.handleChange('greenBoxId')}
                                required={!this.state.greenBoxId}
                                error={!this.state.greenBoxId || this.state.greenBoxId.length !== 8 || !Validation.validateGreenBox(this.state.greenBoxId)}
                                disabled={true}
                                margin="normal"
                                placeholder="Green Box Id"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Email</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                className="input"
                                id="email"
                                label="Email"
                                value={this.state.email}
                                onChange={this.handleChange('email')}
                                required={!this.state.email || !Validation.validateEmail(this.state.email)}
                                error={!this.state.email || !Validation.validateEmail(this.state.email)}
                                disabled={isSubmitted}
                                margin="normal"
                                placeholder="Email"
                            />
                        </div>
                    </div>
                </div>
            </ContentPanel>
        )
    }

    handleCheck(data){
        if (data.password){
            this.loginUserByID(data)
        }else {
            this.checkBusinessId(data.businessMemberId)
        }
    }

    checkBusinessId(businessMemberId){
        this.alertAck();
        this.props.checkBusinessId({
            businessMemberId
        })
    }

    loginUserByID(data){
        this.props.loginUserByID(data)
        this.alertAck();
    }

    render() {
        const {businessMemberId, password, showForm, mail} = this.state;
        const {adminStepData, currentUser, token} = this.props;
        const loadingCheck = adminStepData === ADMIN_STATUS.CHECK_BUSINESS_MEMBER_ID_PROGRESS;
        let urlRegister= "";

        if (businessMemberId.charAt(0)==="A"){
            urlRegister=`${getEnv('DOMAIN_EZP')}/registers-info?token=${token}&businessId=01`
        }else {
            urlRegister=`${getEnv('DOMAIN_EZP')}/registers-info?token=${token}&email=${mail}&businessId=02`
        }

        let disabled;
        if (showForm){
            if (businessMemberId.charAt(0)==="A"){
                disabled=!password
            }else {
                disabled=!(password && mail && Validation.validateEmail(mail))
            }
        }

        return (
            <Translate>
                {({translate}) => (
                    <React.Fragment>
                        <div className="registration-form-admin-container">
                            <div className="title-page mb-4">Create application for admin</div>
                            <div className="head-input">
                                <label className="mr-4" style={{width:"160px"}}>Business Member ID:</label>
                                {
                                    currentUser && currentUser.name === ROLE_SUPPER_ADMIN && (
                                        <React.Fragment>
                                            <InputAntd
                                                placeholder="Business member id"
                                                value={businessMemberId}
                                                type="businessMemberId"
                                                className="business-member"
                                                onChange={(e) => {
                                                    this.setState({businessMemberId: e.target.value, mail:null, password:null, showForm: null})
                                                }}
                                            />

                                            <ButtonAntd
                                                className="check-btn"
                                                disabled={
                                                    loadingCheck ||
                                                    !businessMemberId ||
                                                    businessMemberId.length !== 8 ||
                                                    !Validation.validateGreenBox(businessMemberId) ||
                                                    disabled
                                                }
                                                onClick={() => {
                                                    this.handleCheck({businessMemberId, mail, password});
                                                }}
                                                loading={loadingCheck}
                                            >
                                                Check
                                            </ButtonAntd>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                            {[ADMIN_STATUS.CREATE_APPLICATION_FAIL, ADMIN_STATUS.CREATE_APPLICATION_PROGRESS, ADMIN_STATUS.CHECK_BUSINESS_MEMBER_ID_SUCCESS].indexOf(this.props.adminStepData) >= 0
                            && !mail && !password && (
                                <Modal
                                    title="Warning"
                                    style={{top:"50%", marginTop: "-50px"}}
                                    visible={!this.state.alertAck}
                                    maskClosable={false}
                                    onOk={() => {
                                        this.setState({alertAck: true, showForm: true});
                                    }}
                                    onCancel={() => {
                                        this.setState({alertAck: true, showForm: false});
                                    }}
                                >
                                    <p>カードが登録されていません。登録しますか？</p>
                                </Modal>
                            )}
                            { showForm && (
                                <div>
                                    { businessMemberId.charAt(0) === "G" &&
                                        <div className="mb-3">
                                            <label style = {{ width:"160px", marginRight: "24px" }}>Email:</label>
                                            <Input
                                                className="business-member"
                                                style={{ marginRight: "12px", height:"42px"}}
                                                type="email"
                                                placeholder="Email"
                                                onChange={(e) => {
                                                    this.setState({mail: e.target.value})
                                                }}
                                            />
                                        </div>
                                    }
                                    <div className="d-flex mb-3">
                                        <label style = {{ width:"160px", margin: "0 24px 0 0", lineHeight: "42px" }}>Password:</label>
                                        <Input.Password
                                            className="business-member"
                                            style={{ marginRight: "12px", height:"42px"}}
                                            type="password"
                                            placeholder="Password"
                                            onChange={(e) => {
                                                this.setState({password: e.target.value})
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {token && password && this.props.adminStepData === ADMIN_STATUS.LOGIN_BY_USER_ID_SUCCESS && <a href={urlRegister} target="_blank" style={{width: "75%", display: "block", wordBreak: "break-all"}}>
                                {urlRegister}
                            </a>}

                            {[ADMIN_STATUS.CREATE_APPLICATION_FAIL,ADMIN_STATUS.LOGIN_BY_USER_ID_FAIL, ADMIN_STATUS.CHECK_BUSINESS_MEMBER_ID_FAIL].indexOf(this.props.adminStepData) >= 0 && !this.state.alertAck && (
                                <Alert title="Error"
                                       content={translate(this.props.adminError && ("errors." + this.props.adminError.code))}
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true, isSubmitted: false});
                                           this.props.resetApplicationError()
                                       }}/>
                            )}
                            {this.props.adminStepData === ADMIN_STATUS.CREATE_APPLICATION_SUCCESS && !this.state.alertAck && (
                                <Alert title="Success"
                                       content="Create application successfully"
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({
                                               ...initState,
                                               passwordCard: '',
                                               cards: [{baseCurrency: "USD", pin: "", confirmPin: ""}]
                                           });
                                           this.setState({alertAck: true, isSubmitted: false});
                                       }}/>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </Translate>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        adminStepData: state.admin.status,
        checkBusiness: state.admin.checkBusiness,
        loading: state.admin.loading,
        adminError: state.admin.error,
        currentUser: state.admin.currentUser,
        token: state.admin.token
    }
};

function mapDispatchToProps(dispatch) {
    return {
        updateInfo: (data) => {
            dispatch(createApplication(data))
        },
        cloneApplication: (data) => {
            dispatch(cloneApplication(data))
        },
        checkBusinessId: (data) => {
            dispatch(checkBusinessId(data))
        },
        loginUserByID: async (data) => {
            await dispatch(loginUserByID(data))
        },
        resetApplicationError: async (data) => {
            await dispatch(resetApplicationError(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormAdmin);


