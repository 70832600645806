import { Divider, Form } from 'antd';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import * as Yup from 'yup';
import actions, { ACTIONS } from "../../../../../../actions/registrationTrippleActions";
import { Button, DatePicker, FieldItem, Input, Select, Checkbox } from '../../../../../../components/antd';
import CodeInput from "../../../../../../components/antd/code-input";
import {
  allCountries,
  Validation,
  professionOptions,
  applicationInfoOnLocal 
} from "../../../../../../utils/commonUtils";
import './style.css';
import { businessIds } from '../../../../../../constants/commonData'

const { Item } = Form;
const genderOptions = [{ value: 'male', name: 'Male'}, { value: 'female', name: 'Female'},];

const nationalityOptionsTriple = [
  { value: 'CHN', name: 'China'},
  { value: 'TWN', name: 'Taiwan'},
  { value: 'HKG', name: 'Hongkong'},
  { value: 'MAC', name: 'Macao'},
  { value: 'JPN', name: 'Japan' }
];

const nationalityOptionRobin = [
  { value: 'JPN', name: 'Japan' }
];

const CHINESE_REGEX = /^(?:[\u2E80-\u2FD5\u3041-\u3096\u30A0-\u30FF\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A\u2E80-\u2FD5\uFF5F-\uFF9F\u31F0-\u31FF\u3220-\u3243\u3280-\u337F\u3040-\u309f\u30a0-\u30ff\uff66-\uff9f\u4e00-\u9faf]{0,100}|)$/;
const MESSAGE = {
  chinese: 'Value is only Japanese or Chinese Characters',
  uAlphabet: 'Value must be alphabet uppercase',
  space: 'Value must not contain space character',
  required: 'Value is required',
  postCode: 'Value must be an integer number',
  max: (max) => `Value must be at most ${max} characters`,
  valueInvalid: 'Value invalid',
  birthday: "Birthday must be less current day!",
};

const validationSchema = Yup.object().shape({
  businessMemberId: Yup.string().required(MESSAGE.required),
  gender: Yup.string().required(MESSAGE.required),
  nationality: Yup.string().required(MESSAGE.required),
  firstname: Yup.string().required(MESSAGE.required)
    .max(13, MESSAGE.max(13))
    // .matches(/^\S+$/, { message: MESSAGE.space})
    .matches(/^[A-Z a-z]*$/, { message: MESSAGE.uAlphabet}),
  lastname: Yup.string().required(MESSAGE.required)
    .max(13, MESSAGE.max(13))
    .matches(/^[A-Za-z]*$/, { message: MESSAGE.uAlphabet})
    .matches(/^\S+$/, { message: MESSAGE.space}),
  middlename: Yup.string()
    .max(13, MESSAGE.max(13))
    .matches(/^[A-Za-z]*$/, { message: MESSAGE.uAlphabet})
    .matches(/^\S+$/, { message: MESSAGE.space}),
  country: Yup.string().required(MESSAGE.required),
  prefecture: Yup.string().nullable().required(MESSAGE.required).trim().max(100, MESSAGE.max(100))
    .test('validateAddress', MESSAGE.valueInvalid, value => {
      if(!value) return true;

      return Validation.validateAddress(value);
    }),
  city: Yup.string().trim().required(MESSAGE.required).max(100, MESSAGE.max(100))
  .test('validateAddress', MESSAGE.valueInvalid, value => {
      if(!value) return true;

      return Validation.validateAddress(value);
    }),
  afterAddress: Yup.string().required(MESSAGE.required).trim().max(200, MESSAGE.max(200))
    .test('validateAfterAddress', MESSAGE.valueInvalid, value => {
      if(!value) return true;

      return Validation.validateAfterAddress(value);
    }),
  buildingName: Yup.string().trim().max(100, MESSAGE.max(100))
    .test('validateAddress', MESSAGE.valueInvalid, value => {
      if(!value) return true;

      return Validation.validateAddress(value);
    }),
  profession: Yup.string().required(MESSAGE.required),
  birthday: Yup.date().nullable().required(MESSAGE.required)
    .test('validateBirthday', MESSAGE.birthday, birthday => {
      if(!birthday) return;

      const currentDate = moment().format('YYYY-MM-DD');
      birthday = moment(birthday).format('YYYY-MM-DD');
      
      return birthday < currentDate;
    })
});

const validatetionTriple = Yup.object().shape({
  firstNameChinese: Yup.string().required(MESSAGE.required)
    .max(100, MESSAGE.max(100))
    .matches(CHINESE_REGEX, { message: MESSAGE.chinese }),
  lastNameChinese: Yup.string().required(MESSAGE.required)
    .max(100, MESSAGE.max(100))
    .matches(CHINESE_REGEX, { message: MESSAGE.chinese }),
  middleNameChinese: Yup.string()
    .max(100, MESSAGE.max(100))
    .matches(CHINESE_REGEX, { message: MESSAGE.chinese }),
  postCode: Yup.string().required(MESSAGE.required).max(10, MESSAGE.max(10))
    .matches(/^[0-9-]*$/, { message: MESSAGE.postCode}),
});

const validatetionRobin = Yup.object().shape({
  postCode: Yup.string().required(MESSAGE.required).max(7, MESSAGE.max(7))
    .matches(/^[0-9-]*$/, { message: MESSAGE.postCode})
})

const validationTripleField = validationSchema.concat(validatetionTriple);
const validationRobinField = validationSchema.concat(validatetionRobin)

class RegistrationStep3 extends Component {
  constructor(props) {
    super(props);
    const { registerTrippleStore: { businessMemberId } } = props;
    const applicationLocal = applicationInfoOnLocal(businessMemberId);
    this.state = {
      initialValues: {
        quantity: 1,
        baseCurrency: 'USD',
        businessMemberId,
        ...applicationLocal,
      },
      noProvince: applicationLocal.prefecture === ''
    }
  }

  _renderForm = ({ handleSubmit, ...form }) => {
    const { prevStep, registerTrippleStore: { type }, businessId } = this.props;

    const errors = form.errors;
    if( this.state.noProvince)
      delete errors.prefecture;

    return (
      <Translate>
        {({translate}) => (
          <Form className="form" labelCol={{ span: 5 }} wrapperCol={{ span: 5 }}>
            <p className="sub-title">{translate('User Information')}</p>
            <FieldItem 
              form={form} 
              component={Input} 
              name="businessMemberId" 
              label="Business Member Id" 
              required 
              disabled
              className="input-field"
            />
            <FieldItem 
              form={form} 
              component={Input} 
              name="emailBusiness" 
              label="Email" 
              required 
              disabled
              className="input-field"
            />
            <FieldItem
              form={form}
              name="gender"
              label="Gender"
              required
              component={Select}
              options={genderOptions}
              className="input-field"
            />
            <FieldItem
              form={form}
              name="firstname"
              label="Given Name"
              required
              component={CodeInput}
              count={13}
              limit={1}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
            />
            <FieldItem
              form={form}
              name="middlename"
              label="Middle Name"
              component={CodeInput}
              count={13}
              limit={1}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
            />
            <FieldItem
              form={form}
              name="lastname"
              label="Family Name"
              required
              component={CodeInput}
              count={13}
              limit={1}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
            />
            {
              businessId === businessIds.TRIPLE.id && <>
                <FieldItem
                  form={form}
                  component={Input}
                  name="firstNameChinese"
                  label={<>{translate('Given Name')}<br/>({translate('Chinese')})</>}
                  isTranslate={false}
                  required
                  className="input-field"
                />
                <FieldItem
                  form={form}
                  component={Input}
                  name="middleNameChinese"
                  label={<>{translate('Middle Name')}<br/>({translate('Chinese')})</>}
                  isTranslate={false}
                  className="input-field"
                />
                <FieldItem
                  form={form}
                  component={Input}
                  name="lastNameChinese"
                  label={<>{translate('Family Name')}<br/>({translate('Chinese')})</>}
                  isTranslate={false}
                  required
                  className="input-field"
                />
              </>
            }
            <FieldItem
              form={form}
              component={DatePicker}
              name="birthday"
              required
              label="Birthday"
              disabledDate={dateValue => {
                return dateValue ? dateValue.valueOf() >= moment().startOf('day').valueOf() : false;
              }}
              className="input-field date-input-birthday"
            />
            <FieldItem
              form={form}
              name="nationality"
              label="Nationality"
              required
              component={Select}
              options={businessId === businessIds.TRIPLE.id ? nationalityOptionsTriple : nationalityOptionRobin}
              className="input-field"
            />
            <FieldItem
              form={form}
              name="country"
              label="Country"
              required
              component={Select}
              options={allCountries}
              optionBinding={{
                value: 'code',
                name: 'name'
              }}
              showSearch
              className="input-field"
            />
            { businessId === businessIds.TRIPLE.id ? 
              <Item
                label={translate('Province')}
                required
                validateStatus={
                  form.touched.prefecture &&
                  form.errors.prefecture &&
                  'error'
                }
                colon={false}
                help={form.touched.prefecture && form.errors.prefecture && translate(form.errors.prefecture)}
                className='province-input'
              >
                <Input
                  name='prefecture'
                  value={form.values.prefecture}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  disabled={this.state.noProvince}
                  className="input-field"
                >
                </Input>
                <Checkbox
                  className='checkbox-province'
                  checked={this.state.noProvince}
                  onChange={e => {
                    const noProvince = e.target.checked;
                    if(noProvince) {
                      form.setFieldValue('prefecture', '');
                    } else {
                      errors.prefecture = MESSAGE.required;
                    }
                    this.setState({noProvince});                  
                  }}
                >
                  {translate('No province')}
                </Checkbox>
              </Item>
            : 
              <FieldItem form={form} component={Input} name="prefecture" label='Province' required className="input-field" />
            }
            <FieldItem form={form} component={Input} name="postCode" label="Post code" required className="input-field" />
            <FieldItem form={form} component={Input} name="city" label="City" required  className="input-field" />
            <FieldItem form={form} component={Input} name="afterAddress" label="Street address" required className="input-field" />
            <FieldItem form={form} component={Input} name="buildingName" label="Building Name" className="input-field" />
            <FieldItem
              form={form}
              name="profession"
              label="Occupation"
              required
              component={Select}
              options={professionOptions}
              showSearch
              className="input-field"
            />
            <Divider />
            <Divider />
            <p className="sub-title">{translate('Card Information')}</p>
            <FieldItem form={form} component={Input} name="quantity" label="Quantity" disabled className="input-field"/>
            <FieldItem form={form} component={Input} name="baseCurrency" label="Base Currency" disabled className="input-field"/>
            <div className="group-button-submit">
              <Button
                type="delete"
                onClick={prevStep}
                className="submit"
                disabled={type === ACTIONS.VERIFY_PHONE_CODE_REQUEST}
              >
                {translate('Back')}
              </Button>
              <Button
                htmlType="submit"
                onClick={handleSubmit}
                disabled={!isEmpty(form.errors) || !form.values.firstname}
                className="submit"
                loading={type === ACTIONS.VALIDATE_INFO_CARD_REQUEST}
              >
                {translate('Submit')}
              </Button>
            </div>
          </Form>
        )}
      </Translate>
    )
  }

  _onSubmit = (values) => {
    const { nextStep, validateInfoCard, businessId } = this.props;
    const payload = {
      ...values,
      gender: values.gender === 'male',
      firstname: values.firstname.toUpperCase().trim().split(" ").filter(x => x).join(" "),
      middlename: values.middlename ? values.middlename.toUpperCase() : '',
      lastname: values.lastname.toUpperCase(),
      cards: JSON.stringify([{baseCurrency: values.baseCurrency}]),
      birthday: values.birthday.format('YYYY-M-D'),
      businessId,
    };
    
    delete payload.expiredDate;
    delete payload.issueDate;
    
    validateInfoCard(payload, (status) => {      
      if(status === ACTIONS.VALIDATE_INFO_CARD_SUCCESS)
        nextStep(payload);
    });
  }

  render() {    
    const { businessId } = this.props;

    return (
      <div className='registration-triple-step3'>
        <Formik
          enableReinitialize
          validationSchema={businessId === businessIds.TRIPLE.id ? validationTripleField : validationRobinField }
          initialValues={this.state.initialValues}
          onSubmit={this._onSubmit}
          component={this._renderForm}
        />
      </div> 
    )
  }
}

export default connect(
  state => ({
    registerTrippleStore: state.registrationTripple,
  }),
  {
    validateInfoCard: actions.validateInfoCard
  }
)(RegistrationStep3);
