import {callApi} from "../utils/apiUtils";
import {STATUS} from '../reducers/adminReducer';

const ACTIONS = {
	GET_DATA_COMPARE_REQUEST: "GET_DATA_COMPARE_REQUEST",
	GET_DATA_COMPARE_SUCCESS: "GET_DATA_COMPARE_SUCCESS",
	GET_DATA_COMPARE_FAILURE: "GET_DATA_COMPARE_FAILURE",
	UPDATE_STATUS: "UPDATE_STATUS",
	RESET_ADMIN_PAGE: "RESET_ADMIN_PAGE",
	RESET_APPLICATIONS_ERROR: "RESET_APPLICATIONS_ERROR",
	SET_APPLICATION_STORE_PARAMS: "SET_APPLICATION_STORE_PARAMS",
	GET_APPLICATIONS_REQUEST: "GET_APPLICATIONS_REQUEST",
	GET_APPLICATIONS_SUCCESS: "GET_APPLICATIONS_SUCCESS",
    GET_APPLICATIONS_FAILURE: "GET_APPLICATIONS_FAILURE",
    IMPORT_AND_UPDATE_STATUS_REQUEST: "IMPORT_AND_UPDATE_STATUS_REQUEST",
    IMPORT_AND_UPDATE_STATUS_SUCCESS: "IMPORT_AND_UPDATE_STATUS_SUCCESS",
    IMPORT_AND_UPDATE_STATUS_FAILURE: "IMPORT_AND_UPDATE_STATUS_FAILURE",
    GET_CURRENT_USER_REQUEST: "GET_CURRENT_USER_REQUEST",
    GET_CURRENT_USER_SUCCESS: "GET_CURRENT_USER_SUCCESS",
    GET_CURRENT_USER_FAILURE: "GET_CURRENT_USER_FAILURE",
    GET_STATISTIC_NUMBER_CARD_REQUEST: "GET_STATISTIC_NUMBER_CARD_REQUEST",
    GET_STATISTIC_NUMBER_CARD_SUCCESS: "GET_STATISTIC_NUMBER_CARD_SUCCESS",
    GET_STATISTIC_NUMBER_CARD_FAILURE: "GET_STATISTIC_NUMBER_CARD_FAILURE",
    EXPORT_STATISTIC_NUMBER_APPLICATION_REQUEST: "EXPORT_STATISTIC_NUMBER_APPLICATION_REQUEST",
    EXPORT_STATISTIC_NUMBER_APPLICATION_SUCCESS: "EXPORT_STATISTIC_NUMBER_APPLICATION_SUCCESS",
    EXPORT_STATISTIC_NUMBER_APPLICATION_FAIL: "EXPORT_STATISTIC_NUMBER_APPLICATION_FAIL",
};

const updateStatus = (status, statusInfo) => {
    return {
        type: ACTIONS.UPDATE_STATUS,
        data: {
            status,
            statusInfo
        }
    }
};

const exportExcel = (exportFilter) => {
    let config = {
        method: 'post',
        body: {
            startTime: exportFilter.startTime,
            endTime: exportFilter.endTime,
            status: exportFilter.status,
            fields: exportFilter.fields
        },
        isAuthorization: true,
        isFileDownload: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.EXCEL_EXPORTING));
        callApi('/api/excel_export',
            config,
            null,
            (data) => {
                data.blob().then((bin) => {
                    const url = window.URL.createObjectURL(bin);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ezpay-registrations.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    dispatch(updateStatus(STATUS.EXCEL_EXPORTED));
                });
            },
            (err) => {
                dispatch(updateStatus(STATUS.EXCEL_EXPORT_FAILED, {error: err}));
            }
        );
    };
};


const exportExcelEzp = (exportFilter) => {
    let config = {
        method: 'post',
        body: {
            startTime: exportFilter.startTime,
            endTime: exportFilter.endTime,
            status: exportFilter.status,
        },
        isAuthorization: true,
        isFileDownload: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.EXCEL_EZP_EXPORTING));
        callApi('/api/excel_export_ezp',
            config,
            null,
            (data) => {
                data.blob().then((bin) => {
                    const url = window.URL.createObjectURL(bin);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ezpay-registrations.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    dispatch(updateStatus(STATUS.EXCEL_EZP_EXPORTED));
                });
            },
            (err) => {
                dispatch(updateStatus(STATUS.EXCEL_EXPORT_EZP_FAILED, {error: err}));
            }
        );
    };
};

const exportCSVApplication = (data) => {
    let config = {
        method: 'post',
        body: data,
        isAuthorization: true,
        isFileDownload: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.CSV_APPLICATION_EXPORTING));
        callApi('/api/excel_export_application',
            config,
            null,
            (data) => {
                data.blob().then((bin) => {
                    const url = window.URL.createObjectURL(bin);
                    const link = document.createElement('a');
                    const name_excel = (config.body.status === "printed" || config.body.status === "card_update") ? "Excel_export_application.xlsx" : "CSV_excel_export_application.xlsx";
                    link.href = url;
                    link.setAttribute('download', name_excel);
                    document.body.appendChild(link);
                    link.click();
                    dispatch(updateStatus(STATUS.CSV_APPLICATION_EXPORTED));
                });
            },
            (err) => {
                dispatch(updateStatus(STATUS.CSV_APPLICATION_EXPORT_FAILED, {error: err}));
            }
        );
    };
};

const handleExportUserTriple = () => {
    let config = {
        method: 'post',
        body: {},
        isAuthorization: true,
        isFileDownload: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.EXPORT_USER_TRIPLE_PROGRESS));
        callApi('/api/excel_export_user_triple',
            config,
            null,
            (data) => {
                data.blob().then((bin) => {
                    const url = window.URL.createObjectURL(bin);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Export_user_triple_confirm_address.csv');
                    document.body.appendChild(link);
                    link.click();
                    dispatch(updateStatus(STATUS.EXPORT_USER_TRIPLE_SUCCESS));
                });
            },
            (err) => {
                dispatch(updateStatus(STATUS.EXPORT_USER_TRIPLE_FAIL, {error: err}));
            }
        );
    };
};


const exportExcelPrinted = (data) => {
    let config = {
        method: 'post',
        body: data,
        isAuthorization: true,
        isFileDownload: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.EXCEL_PRINTED_EXPORTING));
        callApi('/api/excel_export_application',
            config,
            null,
            (data) => {
                data.blob().then((bin) => {
                    const url = window.URL.createObjectURL(bin);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'MDB_excel_export_application.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    dispatch(updateStatus(STATUS.EXCEL_PRINTED_EXPORTED));
                });
            },
            (err) => {
                dispatch(updateStatus(STATUS.EXCEL_PRINTED_EXPORT_FAILED, {error: err}));
            }
        );
    };
};

const getPassportImage = (data) => {
    let formData = new FormData();
    formData.append("file", data.file);
    formData.append("notifyEmail", data.notifyEmail);
    let config = {
        method: 'post',
        body: formData,
        isFormData: true,
        isAuthorization: true
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.PASSPORT_IMAGE_EXPORTING));
        callApi('/api/download_image_passport',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.PASSPORT_IMAGE_EXPORTED, {linkPassportImage: data}));
            },
            (err) => {
                dispatch(updateStatus(STATUS.PASSPORT_IMAGE_EXPORT_FAILED, {error: err}));
            }
        );
    };
};

const getAllInfoOfUser = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            page: data,
            limit: 10
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_ALL_USER_PROGRESS));
        callApi('/api/get_user',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_ALL_USER_SUCCESS, {users: data.list, pages: data.pages}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_ALL_USER_FAIL, {error: err}));
            }
        )
    }
};
const getAllRawApplication = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            page: data.page,
            str: data.str || undefined,
            limit: 10
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_RAW_APPLICATION_PROGRESS));
        callApi('/api/get_all_raw_application',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_RAW_APPLICATION_SUCCESS, {users: data.list, pages: data.pages}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_RAW_APPLICATION_FAIL, {error: err}));
            }
        )
    }
};

const getAllInfoOfUserByStatus = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            page: data.page,
            status: data.status,
            limit: 100
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_ALL_USER_PROGRESS));
        callApi('/api/get_user_by_status',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_ALL_USER_SUCCESS, {
                    users: data.list,
                    boxes: data.boxes,
                    unusedCard: data.unusedCard,
                    checkedApplication: data.checkedApplication,
                    pages: data.pages
                }))
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_ALL_USER_FAIL, {error: err}));
            }
        )
    }
};

const getAllInfoOfUserBySearchData = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            page: data.page,
            limit: 100,
            str: data.str,
            box: data.box,
            status: data.status
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_ALL_USER_PROGRESS_BY_SEARCH));
        callApi('/api/search_user_by_status',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_ALL_USER_SUCCESS_BY_SEARCH, {users: data.list, pages: data.pages}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_ALL_USER_FAIL_BY_SEARCH, {error: err}));
            }
        )
    }
};

const updateApplicationInfo = (data) => {
    let formData = new FormData();
    Object.keys(data).map(val =>
        data[val] !== undefined && data[val] !== null && val !== 'cards' && formData.append(val, data[val])
    );

    if (data.cards)
        formData.append("cards", JSON.stringify(data.cards));

    let config = {
        method: "post",
        isAuthorization: true,
        body: formData,
        isFormData: true,
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.UPDATE_APPLICATION_INFO_PROGRESS, {updateUserData: data}));
        callApi('/api/update_application_info',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.UPDATE_APPLICATION_INFO_SUCCESS))
            },
            (err) => {
                dispatch(updateStatus(STATUS.UPDATE_APPLICATION_INFO_FAIL, {error: err}));
            }
        )
    }
};

const sentMailConfirmAddress = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.SEND_MAIL_CONFIRM_ADDRESS_PROGRESS));
        callApi('/api/change_application_status',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.SEND_MAIL_CONFIRM_ADDRESS_SUCCESS))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_CONFIRM_ADDRESS_FAIL, {error: err}));
            }
        )
    }
};


const systemSendMailToAllUser = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.SYSTEM_SEND_MAIL_PROGRESS, {updateUserData: data}));
        callApi('/api/send_all',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.SYSTEM_SEND_MAIL_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SYSTEM_SEND_MAIL_FAIL, {error: err}));
            }
        )
    }
};

const noteSubmit = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.SAVE_NOTE_PROGRESS));
        callApi('/api/add_note_to_application',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.SAVE_NOTE_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SAVE_NOTE_FAIL, {error: err}));
            }
        )
    }
};

const sendMailBlurSelfie = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.SEND_MAIL_BLUR_SELFIE_PROGRESS));
        callApi('/api/send_mail_wrong_passport',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.SEND_MAIL_BLUR_SELFIE_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_BLUR_SELFIE_FAIL, {error: err}));
            }
        )
    }
};

const sendMailWrongAddressDoc = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_ADDRESS_DOC_PROGRESS));
        callApi('/api/send_mail_wrong_passport',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_ADDRESS_DOC_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_ADDRESS_DOC_FAIL, {error: err}));
            }
        )
    }
};

const sendMailBlurPassport = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.SEND_MAIL_BLUR_PASSPORT_PROGRESS));
        callApi('/api/send_mail_wrong_passport',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.SEND_MAIL_BLUR_PASSPORT_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_BLUR_PASSPORT_FAIL, {error: err}));
            }
        )
    }
};
const sendMailWrongFullName = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_FULL_NAME_PROGRESS));
        callApi('/api/send_mail_wrong_passport',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_FULL_NAME_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_FULL_NAME_FAIL, {error: err}));
            }
        )
    }
};

const sendMailWrongPhoneNumber = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_PHONE_NUMBER_PROGRESS));
        callApi('/api/send_mail_wrong_passport',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_PHONE_NUMBER_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_PHONE_NUMBER_FAIL, {error: err}));
            }
        )
    }
};

const sendMailWrongPassportExpired = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_PASSPORT_EXPIRED_PROGRESS));
        callApi('/api/send_mail_wrong_passport',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_PASSPORT_EXPIRED_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_WRONG_PASSPORT_EXPIRED_FAIL, {error: err}));
            }
        )
    }
};

const changeApplicationStatus = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.CHANGE_APPLICATION_STATUS_PROGRESS));
        callApi('/api/change_application_status',
            config,
            null,
            (result) => {
                dispatch(updateStatus(STATUS.CHANGE_APPLICATION_STATUS_SUCCESS,
                    {
                        applicationSuccess: handleRemoveData(data, result.applicationUpdateFails),
                        applicationUpdateFails: result.applicationUpdateFails,
                        errorNotEnoughCard: result.errorNotEnoughCard
                    }))
            },
            (err) => {
                dispatch(updateStatus(STATUS.CHANGE_APPLICATION_STATUS_FAIL, {error: err}));
            }
        )
    }
};

const handleRemoveData = (data, applicationUpdateFails) => {
    let listData = data;
    if (Array.isArray(applicationUpdateFails) && applicationUpdateFails.length > 0) {
        for (let i = 0; i < applicationUpdateFails.length; i++) {
            listData.data = listData.data.filter(item => item !== applicationUpdateFails[i]);
        }
    }
    return listData;
};


const exportPdfApplication = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.EXPORT_PDF_APPLICATION_PROGRESS));
        callApi('/api/export_pdf',
            config,
            null,
            (result) => {
                dispatch(updateStatus(STATUS.EXPORT_PDF_APPLICATION_SUCCESS, {linkExportPdf: result.link, data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.EXPORT_PDF_APPLICATION_FAIL, {error: err}));
            }
        )
    }
};

const getApiServices = () => {
    let config = {
        method: "post",
        isAuthorization: true,
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_API_SERVICE_PROGRESS));
        callApi('/api/get_all_api_app',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_API_SERVICE_SUCCESS, {apiServices: data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_API_SERVICE_FAIL, {error: err}));
            }
        )
    }
};

const createApiApp = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.CREATE_API_APP_PROGRESS));
        callApi('/api/create_api_app',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.CREATE_API_APP_SUCCESS, {apiServices: data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.CREATE_API_APP_FAIL, {error: err}));
            }
        )
    }
};
const sendEmailToEachUser = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.SEND_MAIL_EACH_USER_PROGRESS, {updateUserData: data}));
        callApi('/api/send_mail_to_user',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_EACH_USER_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_EACH_USER_FAIL, {error: err}));
            }
        )
    }
};
const sendEmailPaymentAgain = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.SEND_MAIL_PAYMENT_PROGRESS, {updateUserData: data}));
        callApi('/api/send_mail_payment_again',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_PAYMENT_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.SEND_MAIL_PAYMENT_FAIL, {error: err}));
            }
        )
    }
};

const deleteApiApp = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.DELETE_API_APP_PROGRESS));
        callApi('/api/delete_api_app',
            config,
            null,
            () => {
                dispatch(updateStatus(STATUS.DELETE_API_APP_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.DELETE_API_APP_FAIL, {error: err}));
            }
        )
    }
};

const getAllLog = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            str: data.str,
            page: data.page,
            limit: data.limit || 100,
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_LOG_PROGRESS));
        callApi('/api/get_log',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_LOG_SUCCESS, data))
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_LOG_FAIL, {error: err}));
            }
        )
    }
};

const getAllLocation = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            page: data.page,
            limit: data.limit || 50,
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.GET_LOG_LOCATION_PROGRESS));
        callApi('/api/get_all_location',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.GET_LOG_LOCATION_SUCCESS, data))
            },
            (err) => {
                dispatch(updateStatus(STATUS.GET_LOG_LOCATION_FAIL, {error: err}));
            }
        )
    }
};

const checkBusinessId = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            businessMemberId: data.businessMemberId
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.CHECK_BUSINESS_MEMBER_ID_PROGRESS));
        callApi('/api/check_business_exist',
            config,
            null,
            (result) => {
                dispatch(updateStatus(STATUS.CHECK_BUSINESS_MEMBER_ID_SUCCESS, {result}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.CHECK_BUSINESS_MEMBER_ID_FAIL, {error: err}));
            }
        )
    }
};

const loginUserByID = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            businessMemberId: data.businessMemberId,
            password: data.password
        }
    };
    return async (dispatch) => {
        dispatch(updateStatus(STATUS.LOGIN_BY_USER_ID_PROGRESS));
        await callApi(`/api/check_login`,
            config,
            null,
            (result) => {
                dispatch(updateStatus(STATUS.LOGIN_BY_USER_ID_SUCCESS, {result}))
            },
            (err) => {
                dispatch(updateStatus(STATUS.LOGIN_BY_USER_ID_FAIL, {error: err}));
            }
        )
    }
};

const createApplication = (data) => {

    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("greenBoxId", data.greenBoxId);
    formData.append("firstname", data.firstname.trim());
    formData.append("lastname", data.lastname.trim());
    formData.append("gender", data.maleGender === "male");
    formData.append("birthday", data.birthYear + '-' + data.birthMonth + '-' + data.birthDate);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("profession", data.profession);
    formData.append("country", data.country);
    formData.append("prefecture", data.prefecture.trim());
    formData.append("city", data.city.trim());
    formData.append("postCode", String(data.postCode));
    formData.append("afterAddress", data.afterAddress.trim());
    data.buildingName && formData.append("buildingName", data.buildingName.trim());
    formData.append("passportNumber", data.passportNumber.trim());
    formData.append("passportIssue", data.passportIssueYear + '-' + data.passportIssueMonth + '-' + data.passportIssueDate);
    formData.append("passportExpired", data.passportExpiryYear + '-' + data.passportExpiryMonth + '-' + data.passportExpiryDate);
    formData.append("passportPlaceIssued", data.passportPlaceIssued);
    formData.append("passwordCard", data.passwordCard);
    formData.append("passportImage", data.passportImage);
    formData.append("businessCreatedAt", data.businessCreatedAt);
    formData.append("cards", JSON.stringify(data.cards));
    let config = {
        method: 'post',
        body: formData,
        isFormData: true,
        isAuthorization: true,
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.CREATE_APPLICATION_PROGRESS));
        callApi('/api/create_application',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.CREATE_APPLICATION_SUCCESS, data));
            },
            (err) => {
                dispatch(updateStatus(STATUS.CREATE_APPLICATION_FAIL, {error: err}));
            }
        );
    };
};

const cloneApplication = (data) => {

    let formData = new FormData();
    formData.append("businessMemberId", data.greenBoxId);
    formData.append("passwordCard", data.passwordCard);
    formData.append("cards", JSON.stringify(data.cards));
    let config = {
        method: 'post',
        body: formData,
        isFormData: true,
        isAuthorization: true,
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.CREATE_APPLICATION_PROGRESS));
        callApi('/api/clone_application',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.CREATE_APPLICATION_SUCCESS, data));
            },
            (err) => {
                dispatch(updateStatus(STATUS.CREATE_APPLICATION_FAIL, {error: err}));
            }
        );
    };
};

const resetUpdatedStatus = () => {
    return (dispatch) => {
        dispatch(updateStatus(STATUS.RESET_UPDATED_STATUS));
    }
};

const resetApplicationError = () => {
	return (dispatch) => {
		dispatch({ type: ACTIONS.RESET_APPLICATIONS_ERROR });
	}
};

const setApplicationStoreParams = (data) => {
	return (dispatch) => {
		dispatch({ type: ACTIONS.SET_APPLICATION_STORE_PARAMS, data });
	}
};

const resetAdminPage = () => {
	return (dispatch) => {
		dispatch({ type: ACTIONS.RESET_ADMIN_PAGE });
	}
};

const getApplications = (params, callback = () => null) => {
    const { type, ...data } = params;
    let url = ''
	switch (type) {
		case 'search':
			url = '/api/search_user_by_status'
			break;
		case 'status':
			url = '/api/get_user_by_status'
			break;
		case 'get':
			url = '/api/get_user'
			break;
		case 'get_raw':
			url = '/api/get_all_raw_application'
            break;
		default:
	}

	const body = {
		page: data.page,
		limit: data.limit,
		str: data.str,
		status: data.status,
		box: data.box,
		businessId: data.businessId,
	}

	let config = {
		method: "post",
		isAuthorization: true,
		body
	};
	return (dispatch) => {
		dispatch({ type: ACTIONS.GET_APPLICATIONS_REQUEST });
		callApi(url,
			config,
			null,
			(payload) => {
				dispatch({ type: ACTIONS.GET_APPLICATIONS_SUCCESS, payload, data: params });
				callback();
			},
			(err) => {
				dispatch({ type: ACTIONS.GET_APPLICATIONS_FAILURE, payload: {error: err} });
				callback();
			}
		)
	}
}

const getDataCompare = (params, callback = () => null) => {
    const { ...data } = params;
    const url = '/api/compare_appication_data';

	const config = {
		method: "post",
		isAuthorization: true,
		body: data
  };

	return (dispatch) => {
		dispatch({ type: ACTIONS.GET_DATA_COMPARE_REQUEST });
		callApi(url,
			config,
			null,
			(payload) => {
				dispatch({ type: ACTIONS.GET_DATA_COMPARE_SUCCESS, payload, data: params });
				callback();
			},
			(err) => {
				dispatch({ type: ACTIONS.GET_DATA_COMPARE_FAILURE, payload: {error: err} });
				callback();
			}
		)
	}
}

const exportOldNew = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        isFileDownload: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.EXPORT_OLD_NEW_PROGRESS));
        callApi('/api/export_old_new',
            config,
            null,
            (data) => {
                data.blob().then((bin) => {
                    const url = window.URL.createObjectURL(bin);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'CSV Sign up old and new.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    dispatch(updateStatus(STATUS.EXPORT_OLD_NEW_SUCCESS));
                });
            },
            (err) => {
                dispatch(updateStatus(STATUS.EXPORT_OLD_NEW_FAIL, {error: err}));
            }
        );
    };
}

const changeCompletedToCardValid = (data) => {
    let formData = new FormData();
    formData.append("fileCSV", data.file);
    let config = {
        method: 'post',
        body: formData,
        isFormData: true,
        isAuthorization: true
    };

    return (dispatch) => {
        dispatch({ type: ACTIONS.IMPORT_AND_UPDATE_STATUS_REQUEST});
        callApi('/api/completed_to_card_valid',
            config,
            null,
            (payload) => {
                dispatch({ type: ACTIONS.IMPORT_AND_UPDATE_STATUS_SUCCESS, payload });
            },
            (err) => {
                dispatch({ type: ACTIONS.IMPORT_AND_UPDATE_STATUS_FAILURE, payload: { error: err }});
            }
        );
    };
};

const getCurrentUser = (data, callback = () => null) => {
    let config = {
        method: 'post',
        isAuthorization: true
    };

    return (dispatch) => {
        dispatch({ type: ACTIONS.GET_CURRENT_USER_REQUEST});
        callApi('/api/get_current_user',
            config,
            null,
            (payload) => {
                dispatch({ type: ACTIONS.GET_CURRENT_USER_SUCCESS, payload });
                callback();
            },
            (err) => {
                dispatch({ type: ACTIONS.GET_CURRENT_USER_FAILURE, payload: { error: err }});
            }
        );
    };
};

const getStatisticNumberCard = () => {
    let config = {
        method: 'get',
        isAuthorization: true
    };
    return (dispatch) => {
        dispatch({ type: ACTIONS.GET_STATISTIC_NUMBER_CARD_REQUEST});
        callApi('/api/statistic_number_card',
            config,
            null,
            (payload) => {
                dispatch({ type: ACTIONS.GET_STATISTIC_NUMBER_CARD_SUCCESS, payload });
            },
            (err) => {
                dispatch({ type: ACTIONS.GET_STATISTIC_NUMBER_CARD_FAILURE, payload: { error: err }});
            }
        );
    };
}

const handleExportStatisticCSV = () => {
    let config = {
        method: 'post',
        isAuthorization: true,
        isFileDownload: true,
        body: {}
    };
    return (dispatch) => {
        dispatch({ type: ACTIONS.EXPORT_STATISTIC_NUMBER_APPLICATION_REQUEST});
        callApi('/api/statistic_number_card',
            config,
            null,
            (data) => {
                data.blob().then((bin) => {
                    const url = window.URL.createObjectURL(bin);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'CSV Statistic number application.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    dispatch({ type: ACTIONS.EXPORT_STATISTIC_NUMBER_APPLICATION_SUCCESS });
                });
            },
            (err) => {
                dispatch({ type: ACTIONS.EXPORT_STATISTIC_NUMBER_APPLICATION_FAIL, payload: { error: err }});
            }
        );
    };
}

export {
	ACTIONS,
	exportExcel,
	exportExcelEzp,
	getAllInfoOfUser,
	getAllInfoOfUserByStatus,
	updateApplicationInfo,
	getAllInfoOfUserBySearchData,
	getPassportImage,
	getApiServices,
	deleteApiApp,
	createApiApp,
	exportCSVApplication,
    handleExportUserTriple,
	exportPdfApplication,
	systemSendMailToAllUser,
	sendEmailToEachUser,
	handleRemoveData,
	changeApplicationStatus,
	sendEmailPaymentAgain,
	noteSubmit,
	sendMailBlurPassport,
	sendMailWrongFullName,
	sendMailWrongPhoneNumber,
	sendMailWrongPassportExpired,
	resetUpdatedStatus,
	sentMailConfirmAddress,
	getAllRawApplication,
	getAllLog,
	createApplication,
	checkBusinessId,
    loginUserByID,
	cloneApplication,
	getAllLocation,
	exportExcelPrinted,
	setApplicationStoreParams,
  getApplications,
  resetApplicationError,
  resetAdminPage,
  changeCompletedToCardValid,
  getCurrentUser,
  sendMailBlurSelfie,
  sendMailWrongAddressDoc,
  getDataCompare,
    exportOldNew,
    getStatisticNumberCard,
    handleExportStatisticCSV
};
