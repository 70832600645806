import _ from 'lodash';

import { ACTIONS } from '../actions/adminActions';
import { TYPE_SEND_MAIL } from '../utils/commonUtils';

const initialState = {
  listStatisticNumberCard: [],
  users: [],
  boxes: [],
  apiServices: [],
  applicationUpdateFails: [],
  data: {},
  error: null,
  status: null,
  linkPassportImage: null,
  checkBusiness: null,
  logIp: null,
  sendBlurPassportMail: false,
  sendBlurSelfieMail: false,
  sendWrongAddressDocMail: false,
  sendWrongPassportExpiredMail: false,
  sendWrongNameMail: false,
  sendWrongPhoneNumberMail: false,
  sentMailConfirmAdd: false,
  getAllApplicationAgain: false,
  pages: 0,
  unusedCard: 0,
  checkedApplication: 0,
  note: '',
  params: {
    status: '',
    page: 1,
    limit: 100,
    total: 0,
    sort: '',
    str: '',
    businessId: '',
    isChecked: false,
    box: null
  },
  check: {
    checkAll: false,
    checkRows: [],
    indeterminateCheckAll: false
  },
  submitting: null,
  loading: false,
  currentUser: null,
  listPermission: [],
  token:null
};

const STATUS = {
  EXCEL_EXPORTING: 'EXCEL_EXPORTING',
  EXCEL_EXPORTED: 'EXCEL_EXPORTED',
  EXCEL_EXPORT_FAILED: 'EXCEL_EXPORT_FAILED',
  PASSPORT_IMAGE_EXPORTING: 'PASSPORT_IMAGE_EXPORTING',
  PASSPORT_IMAGE_EXPORTED: 'PASSPORT_IMAGE_EXPORTED',
  PASSPORT_IMAGE_EXPORT_FAILED: 'PASSPORT_IMAGE_EXPORT_FAILED',
  GET_ALL_USER_PROGRESS: 'GET_ALL_USER_PROGRESS',
  GET_ALL_USER_SUCCESS: 'GET_ALL_USER_SUCCESS',
  GET_ALL_USER_FAIL: 'GET_ALL_USER_FAIL',
  UPDATE_APPLICATION_INFO_PROGRESS: 'UPDATE_APPLICATION_INFO_PROGRESS',
  UPDATE_APPLICATION_INFO_SUCCESS: 'UPDATE_APPLICATION_INFO_SUCCESS',
  UPDATE_APPLICATION_INFO_FAIL: 'UPDATE_APPLICATION_INFO_FAIL',
  GET_ALL_USER_PROGRESS_BY_SEARCH: 'GET_ALL_USER_PROGRESS_BY_SEARCH',
  GET_ALL_USER_SUCCESS_BY_SEARCH: 'GET_ALL_USER_SUCCESS_BY_SEARCH',
  GET_ALL_USER_FAIL_BY_SEARCH: 'GET_ALL_USER_FAIL_BY_SEARCH',
  GET_API_SERVICE_PROGRESS: 'GET_API_SERVICE_PROGRESS',
  GET_API_SERVICE_SUCCESS: 'GET_API_SERVICE_SUCCESS',
  GET_API_SERVICE_FAIL: 'GET_API_SERVICE_FAIL',
  CREATE_API_APP_PROGRESS: 'CREATE_API_APP_PROGRESS',
  CREATE_API_APP_SUCCESS: 'CREATE_API_APP_SUCCESS',
  CREATE_API_APP_FAIL: 'CREATE_API_APP_FAIL',
  DELETE_API_APP_PROGRESS: 'DELETE_API_APP_PROGRESS',
  DELETE_API_APP_SUCCESS: 'DELETE_API_APP_SUCCESS',
  DELETE_API_APP_FAIL: 'DELETE_API_APP_FAIL',
  CSV_APPLICATION_EXPORTING: 'CSV_APPLICATION_EXPORTING',
  CSV_APPLICATION_EXPORTED: 'CSV_APPLICATION_EXPORTED',
  CSV_APPLICATION_EXPORT_FAILED: 'CSV_APPLICATION_EXPORT_FAILED',
  EXPORT_PDF_APPLICATION_PROGRESS: 'EXPORT_PDF_APPLICATION_PROGRESS',
  EXPORT_PDF_APPLICATION_SUCCESS: 'EXPORT_PDF_APPLICATION_SUCCESS',
  EXPORT_PDF_APPLICATION_FAIL: 'EXPORT_PDF_APPLICATION_FAIL',
  SYSTEM_SEND_MAIL_PROGRESS: 'SYSTEM_SEND_MAIL_PROGRESS',
  SYSTEM_SEND_MAIL_SUCCESS: 'SYSTEM_SEND_MAIL_SUCCESS',
  SYSTEM_SEND_MAIL_FAIL: 'SYSTEM_SEND_MAIL_FAIL',
  SEND_MAIL_EACH_USER_PROGRESS: 'SEND_MAIL_EACH_USER_PROGRESS',
  SEND_MAIL_EACH_USER_SUCCESS: 'SEND_MAIL_EACH_USER_SUCCESS',
  SEND_MAIL_EACH_USER_FAIL: 'SEND_MAIL_EACH_USER_FAIL',
  SEND_MAIL_PAYMENT_PROGRESS: 'SEND_MAIL_PAYMENT_PROGRESS',
  SEND_MAIL_PAYMENT_SUCCESS: 'SEND_MAIL_PAYMENT_SUCCESS',
  SEND_MAIL_PAYMENT_FAIL: 'SEND_MAIL_PAYMENT_FAIL',
  CHANGE_APPLICATION_STATUS_PROGRESS: 'CHANGE_APPLICATION_STATUS_PROGRESS',
  CHANGE_APPLICATION_STATUS_SUCCESS: 'CHANGE_APPLICATION_STATUS_SUCCESS',
  CHANGE_APPLICATION_STATUS_FAIL: 'CHANGE_APPLICATION_STATUS_FAIL',
  SAVE_NOTE_PROGRESS: 'SAVE_NOTE_PROGRESS',
  SAVE_NOTE_SUCCESS: 'SAVE_NOTE_SUCCESS',
  SAVE_NOTE_FAIL: 'SAVE_NOTE_FAIL',
  SEND_MAIL_BLUR_PASSPORT_PROGRESS: 'SEND_MAIL_BLUR_PASSPORT_PROGRESS',
  SEND_MAIL_BLUR_PASSPORT_SUCCESS: 'SEND_MAIL_BLUR_PASSPORT_SUCCESS',
  SEND_MAIL_BLUR_PASSPORT_FAIL: 'SEND_MAIL_BLUR_PASSPORT_FAIL',
  SEND_MAIL_WRONG_FULL_NAME_PROGRESS: 'SEND_MAIL_WRONG_FULL_NAME_PROGRESS',
  SEND_MAIL_WRONG_FULL_NAME_SUCCESS: 'SEND_MAIL_WRONG_FULL_NAME_SUCCESS',
  SEND_MAIL_WRONG_FULL_NAME_FAIL: 'SEND_MAIL_WRONG_FULL_NAME_FAIL',
  SEND_MAIL_WRONG_PHONE_NUMBER_PROGRESS:
    'SEND_MAIL_WRONG_PHONE_NUMBER_PROGRESS',
  SEND_MAIL_WRONG_PHONE_NUMBER_SUCCESS: 'SEND_MAIL_WRONG_PHONE_NUMBER_SUCCESS',
  SEND_MAIL_WRONG_PHONE_NUMBER_FAIL: 'SEND_MAIL_WRONG_PHONE_NUMBER_FAIL',
  SEND_MAIL_BLUR_SELFIE_PROGRESS: 'SEND_MAIL_BLUR_SELFIE_PROGRESS',
  SEND_MAIL_BLUR_SELFIE_SUCCESS: 'SEND_MAIL_BLUR_SELFIE_SUCCESS',
  SEND_MAIL_BLUR_SELFIE_FAIL: 'SEND_MAIL_BLUR_SELFIE_FAIL',
  SEND_MAIL_WRONG_ADDRESS_DOC_PROGRESS: 'SEND_MAIL_WRONG_ADDRESS_DOC_PROGRESS',
  SEND_MAIL_WRONG_ADDRESS_DOC_SUCCESS: 'SEND_MAIL_WRONG_ADDRESS_DOC_SUCCESS',
  SEND_MAIL_WRONG_ADDRESS_DOC_FAIL: 'SEND_MAIL_WRONG_ADDRESS_DOC_FAIL',
  RESET_UPDATED_STATUS: 'RESET_UPDATED_STATUS',
  SEND_MAIL_WRONG_PASSPORT_EXPIRED_PROGRESS:
    'SEND_MAIL_WRONG_PASSPORT_EXPIRED_PROGRESS',
  SEND_MAIL_WRONG_PASSPORT_EXPIRED_SUCCESS:
    'SEND_MAIL_WRONG_PASSPORT_EXPIRED_SUCCESS',
  SEND_MAIL_WRONG_PASSPORT_EXPIRED_FAIL:
    'SEND_MAIL_WRONG_PASSPORT_EXPIRED_FAIL',
  SEND_MAIL_CONFIRM_ADDRESS_PROGRESS: 'SEND_MAIL_CONFIRM_ADDRESS_PROGRESS',
  SEND_MAIL_CONFIRM_ADDRESS_SUCCESS: 'SEND_MAIL_CONFIRM_ADDRESS_SUCCESS',
  SEND_MAIL_CONFIRM_ADDRESS_FAIL: 'SEND_MAIL_CONFIRM_ADDRESS_FAIL',
  EXCEL_EZP_EXPORTING: 'EXCEL_EZP_EXPORTING',
  EXCEL_EZP_EXPORTED: 'EXCEL_EZP_EXPORTED',
  EXCEL_EXPORT_EZP_FAILED: 'EXCEL_EXPORT_EZP_FAILED',
  GET_RAW_APPLICATION_PROGRESS: 'GET_RAW_APPLICATION_PROGRESS',
  GET_RAW_APPLICATION_SUCCESS: 'GET_RAW_APPLICATION_SUCCESS',
  GET_RAW_APPLICATION_FAIL: 'GET_RAW_APPLICATION_FAIL',
  GET_LOG_PROGRESS: 'GET_LOG_PROGRESS',
  GET_LOG_SUCCESS: 'GET_LOG_SUCCESS',
  GET_LOG_FAIL: 'GET_LOG_FAIL',
  CREATE_APPLICATION_PROGRESS: 'CREATE_APPLICATION_PROGRESS',
  CREATE_APPLICATION_SUCCESS: 'CREATE_APPLICATION_SUCCESS',
  CREATE_APPLICATION_FAIL: 'CREATE_APPLICATION_FAIL',
  CHECK_BUSINESS_MEMBER_ID_PROGRESS: 'CHECK_BUSINESS_MEMBER_ID_PROGRESS',
  CHECK_BUSINESS_MEMBER_ID_SUCCESS: 'CHECK_BUSINESS_MEMBER_ID_SUCCESS',
  CHECK_BUSINESS_MEMBER_ID_FAIL: 'CHECK_BUSINESS_MEMBER_ID_FAIL',
  LOGIN_BY_USER_ID_PROGRESS: 'LOGIN_BY_USER_ID_PROGRESS',
  LOGIN_BY_USER_ID_SUCCESS: 'LOGIN_BY_USER_ID_SUCCESS',
  LOGIN_BY_USER_ID_FAIL: 'LOGIN_BY_USER_ID_FAIL',
  GET_LOG_LOCATION_PROGRESS: 'GET_LOG_LOCATION_PROGRESS',
  GET_LOG_LOCATION_SUCCESS: 'GET_LOG_LOCATION_SUCCESS',
  GET_LOG_LOCATION_FAIL: 'GET_LOG_LOCATION_FAIL',
  EXCEL_PRINTED_EXPORTING: 'EXCEL_PRINTED_EXPORTING',
  EXCEL_PRINTED_EXPORTED: 'EXCEL_PRINTED_EXPORTED',
  EXCEL_PRINTED_EXPORT_FAILED: 'EXCEL_PRINTED_EXPORT_FAILED',
  EXPORT_OLD_NEW_PROGRESS: "EXPORT_OLD_NEW_PROGRESS",
  EXPORT_OLD_NEW_SUCCESS: "EXPORT_OLD_NEW_SUCCESS",
  EXPORT_OLD_NEW_FAIL: "EXPORT_OLD_NEW_FAIL",
  EXPORT_USER_TRIPLE_PROGRESS: "EXPORT_USER_TRIPLE_PROGRESS",
  EXPORT_USER_TRIPLE_SUCCESS: "EXPORT_USER_TRIPLE_SUCCESS",
  EXPORT_USER_TRIPLE_FAIL: "EXPORT_USER_TRIPLE_FAIL",
};

export { STATUS };

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_APPLICATIONS_REQUEST:
    case ACTIONS.IMPORT_AND_UPDATE_STATUS_REQUEST:
    case ACTIONS.GET_CURRENT_USER_REQUEST:
    case ACTIONS.GET_STATISTIC_NUMBER_CARD_REQUEST:
    case ACTIONS.EXPORT_STATISTIC_NUMBER_APPLICATION_REQUEST:
    case ACTIONS.EXPORT_STATISTIC_NUMBER_APPLICATION_SUCCESS:
      return {
        ...state,
        submitting: action.type
      };
    case ACTIONS.UPDATE_STATUS:
      switch (action.data.status) {
        case STATUS.EXCEL_EXPORTING:
        case STATUS.EXCEL_EXPORTED:
        case STATUS.EXCEL_EZP_EXPORTING:
        case STATUS.EXCEL_PRINTED_EXPORTING:
        case STATUS.EXCEL_EZP_EXPORTED:
        case STATUS.PASSPORT_IMAGE_EXPORTING:
        case STATUS.GET_API_SERVICE_PROGRESS:
        case STATUS.CREATE_API_APP_PROGRESS:
        case STATUS.DELETE_API_APP_PROGRESS:
        case STATUS.CSV_APPLICATION_EXPORTING:
        case STATUS.EXPORT_PDF_APPLICATION_PROGRESS:
        case STATUS.SYSTEM_SEND_MAIL_PROGRESS:
        case STATUS.SEND_MAIL_EACH_USER_PROGRESS:
        case STATUS.SEND_MAIL_PAYMENT_PROGRESS:
        case STATUS.CHANGE_APPLICATION_STATUS_PROGRESS:
        case STATUS.SAVE_NOTE_PROGRESS:
        case STATUS.SEND_MAIL_BLUR_PASSPORT_PROGRESS:
        case STATUS.SEND_MAIL_WRONG_FULL_NAME_PROGRESS:
        case STATUS.SEND_MAIL_WRONG_PHONE_NUMBER_PROGRESS:
        case STATUS.SEND_MAIL_WRONG_PASSPORT_EXPIRED_PROGRESS:
        case STATUS.SEND_MAIL_CONFIRM_ADDRESS_PROGRESS:
        case STATUS.SEND_MAIL_BLUR_SELFIE_PROGRESS:
        case STATUS.SEND_MAIL_WRONG_ADDRESS_DOC_PROGRESS:
        case STATUS.GET_LOG_PROGRESS:
        case STATUS.CREATE_APPLICATION_PROGRESS:
        case STATUS.EXPORT_OLD_NEW_PROGRESS:
        case STATUS.EXPORT_OLD_NEW_SUCCESS:
        case STATUS.EXPORT_USER_TRIPLE_PROGRESS:
        case STATUS.EXPORT_USER_TRIPLE_SUCCESS:
          return {
            ...state,
            loading: true,
            status: action.data.status
          }
        case STATUS.CHECK_BUSINESS_MEMBER_ID_PROGRESS:
        case STATUS.CREATE_APPLICATION_SUCCESS:
          return {
            ...state,
            loading: false,
            status: action.data.status,
            token: null
          }
        case STATUS.GET_LOG_LOCATION_PROGRESS:
          return {
            ...state,
            status: action.data.status
          };
        case STATUS.CSV_APPLICATION_EXPORTED:
          return {
            ...state,
            status: action.data.status,
            check: {
              checkAll: false,
              checkRows: [],
              indeterminateCheckAll: false
            }
          };
        case STATUS.EXCEL_PRINTED_EXPORTED:
          return {
            ...state,
            status: action.data.status,
            check: {
              checkAll: false,
              checkRows: [],
              indeterminateCheckAll: false
            }
          };

        case STATUS.RESET_UPDATED_STATUS:
          return {
            ...state,
            status: action.data.status,
            sendBlurPassportMail: false,
            sendWrongNameMail: false,
            sendWrongPhoneNumberMail: false,
            sendWrongPassportExpiredMail: false,
            sentMailConfirmAdd: false,
            getAllApplicationAgain: false,
            sendBlurSelfieMail: false,
            sendWrongAddressDocMail: false
          };
        case STATUS.CHANGE_APPLICATION_STATUS_SUCCESS:
          let applicationSuccess =
            action.data.statusInfo.applicationSuccess.data;
          const errorNotEnoughCard = action.data.statusInfo.errorNotEnoughCard;
          return {
            ...state,
            status: action.data.status,
            userUpdated: null,
            applicationUpdateFails:
              action.data.statusInfo.applicationUpdateFails,
            errorNotEnoughCard: errorNotEnoughCard ? errorNotEnoughCard : false,
            users: handleRemoveItem([...state.users], applicationSuccess)
          };
        case STATUS.SYSTEM_SEND_MAIL_SUCCESS:
        case STATUS.SEND_MAIL_EACH_USER_SUCCESS:
        case STATUS.SEND_MAIL_PAYMENT_SUCCESS:
          return {
            ...state,
            status: action.data.status,
            data: action.data.statusInfo.data
          };

        case STATUS.CHECK_BUSINESS_MEMBER_ID_SUCCESS:
          return {
            ...state,
            status: action.data.status,
            checkBusiness: action.data.statusInfo.result
          };
        case STATUS.LOGIN_BY_USER_ID_SUCCESS:
          return {
            ...state,
            status: action.data.status,
            token: action.data.statusInfo.result ? action.data.statusInfo.result.token : null
          };
        case STATUS.PASSPORT_IMAGE_EXPORTED:
          return {
            ...state,
            status: action.data.status,
            linkPassportImage: action.data.statusInfo.linkPassportImage
          };
        case STATUS.EXPORT_PDF_APPLICATION_SUCCESS:
          return {
            ...state,
            status: action.data.status,
            linkExportPdf: action.data.statusInfo.linkExportPdf,
            users: handleAddTimePdfExport(
              [...state.users],
              action.data.statusInfo.data
            ),
            check: {
              checkAll: false,
              checkRows: [],
              indeterminateCheckAll: false
            }
          };
        case STATUS.EXCEL_EXPORT_FAILED:
        case STATUS.EXCEL_EXPORT_EZP_FAILED:
        case STATUS.PASSPORT_IMAGE_EXPORT_FAILED:
        case STATUS.GET_API_SERVICE_FAIL:
        case STATUS.CREATE_API_APP_FAIL:
        case STATUS.DELETE_API_APP_FAIL:
        case STATUS.CSV_APPLICATION_EXPORT_FAILED:
        case STATUS.EXPORT_PDF_APPLICATION_FAIL:
        case STATUS.SYSTEM_SEND_MAIL_FAIL:
        case STATUS.SEND_MAIL_EACH_USER_FAIL:
        case STATUS.SEND_MAIL_PAYMENT_FAIL:
        case STATUS.CHANGE_APPLICATION_STATUS_FAIL:
          return {
            ...state,
            status: action.data.status,
            error: action.data.statusInfo.error
          };
        case STATUS.SAVE_NOTE_FAIL:
        case STATUS.SEND_MAIL_BLUR_PASSPORT_FAIL:
        case STATUS.SEND_MAIL_WRONG_FULL_NAME_FAIL:
        case STATUS.SEND_MAIL_WRONG_PHONE_NUMBER_FAIL:
        case STATUS.SEND_MAIL_WRONG_PASSPORT_EXPIRED_FAIL:
        case STATUS.SEND_MAIL_CONFIRM_ADDRESS_FAIL:
        case STATUS.SEND_MAIL_BLUR_SELFIE_FAIL:
        case STATUS.SEND_MAIL_WRONG_ADDRESS_DOC_FAIL:
        case STATUS.GET_LOG_FAIL:
        case STATUS.CREATE_APPLICATION_FAIL:
          return {
            ...state,
            status: action.data.status,
            error: action.data.statusInfo.error,
            loading: false
          }
        case STATUS.EXCEL_PRINTED_EXPORT_FAILED:
        case STATUS.CHECK_BUSINESS_MEMBER_ID_FAIL:
        case STATUS.LOGIN_BY_USER_ID_FAIL:
        case STATUS.GET_LOG_LOCATION_FAIL:
        case STATUS.EXPORT_OLD_NEW_FAIL:
        case STATUS.EXPORT_USER_TRIPLE_FAIL:
          return {
            ...state,
            status: action.data.status,
            error: action.data.statusInfo.error
          };

        case STATUS.GET_ALL_USER_PROGRESS:
        case STATUS.GET_RAW_APPLICATION_PROGRESS:
        case STATUS.GET_ALL_USER_PROGRESS_BY_SEARCH:
          return {
            ...state,
            status: action.data.status
          };
        case STATUS.SAVE_NOTE_SUCCESS:
          let user = state.users.findIndex(
            u => u._id === action.data.statusInfo.data._id
          );
          return {
            ...state,
            status: action.data.status,
            users: [
              ...state.users.slice(0, user),
              {
                ...state.users[user],
                note: action.data.statusInfo.data.note
              },
              ...state.users.slice(user + 1)
            ]
          };
        case STATUS.SEND_MAIL_CONFIRM_ADDRESS_SUCCESS:
          return {
            ...state,
            status: action.data.status,
            sentMailConfirmAdd: true
          };
        case STATUS.SEND_MAIL_BLUR_PASSPORT_SUCCESS:
        case STATUS.SEND_MAIL_WRONG_FULL_NAME_SUCCESS:
        case STATUS.SEND_MAIL_WRONG_PASSPORT_EXPIRED_SUCCESS:
        case STATUS.SEND_MAIL_WRONG_PHONE_NUMBER_SUCCESS:
        case STATUS.SEND_MAIL_BLUR_SELFIE_SUCCESS:
        case STATUS.SEND_MAIL_WRONG_ADDRESS_DOC_SUCCESS:
          let userSendMail = state.users.findIndex(
            u => u._id === action.data.statusInfo.data._id
          );
          let returnData = {
            ...state,
            status: action.data.status,
            users: [
              ...state.users.slice(0, userSendMail),
              Object.assign({}, state.users[userSendMail], {
                sentMailWrongPassport: [
                  ...(state.users[userSendMail].sentMailWrongPassport || []),
                  action.data.statusInfo.data.typeMail
                ]
              }),
              ...state.users.slice(userSendMail + 1)
            ]
          };
          switch (action.data.statusInfo.data.typeMail) {
            case TYPE_SEND_MAIL.FUZZY_PASSPORT:
              returnData.sendBlurPassportMail = true;
              break;
            case TYPE_SEND_MAIL.WRONG_FULL_NAME:
              returnData.sendWrongNameMail = true;
              break;
            case TYPE_SEND_MAIL.WRONG_PASSPORT_EXPIRED:
              returnData.sendWrongPassportExpiredMail = true;
              break;
            case TYPE_SEND_MAIL.WRONG_PHONE_NUMBER:
              returnData.sendWrongPhoneNumberMail = true;
              break;
            case TYPE_SEND_MAIL.FUZZY_SELFIE:
              returnData.sendBlurSelfieMail = true;
              break;
            case TYPE_SEND_MAIL.WRONG_ADDRESS_DOC:
              returnData.sendWrongAddressDocMail = true;
              break;
            default:
              break;
          }
          return returnData;

        case STATUS.GET_ALL_USER_SUCCESS:
          return {
            ...state,
            status: action.data.status,
            users: action.data.statusInfo.users,
            boxes: action.data.statusInfo.boxes,
            unusedCard: action.data.statusInfo.unusedCard,
            checkedApplication: action.data.statusInfo.checkedApplication,
            pages: action.data.statusInfo.pages
          };

        case STATUS.GET_LOG_SUCCESS: {
          const { list, ...rest } = action.data.statusInfo;
          return {
            ...state,
            status: action.data.status,
            logs: list,
            params: {
              ...state.params,
              ...rest
            }
          };
        }
        case STATUS.GET_LOG_LOCATION_SUCCESS: {
          const { list, ...rest } = action.data.statusInfo;
          return {
            ...state,
            status: action.data.status,
            logIp: list,
            params: {
              ...state.params,
              ...rest
            }
          };
        }
        case STATUS.GET_RAW_APPLICATION_SUCCESS:
          return {
            ...state,
            status: action.data.status,
            users: action.data.statusInfo.users,
            pages: action.data.statusInfo.pages
          };
        case STATUS.GET_ALL_USER_SUCCESS_BY_SEARCH:
          return {
            ...state,
            status: action.data.status,
            users: action.data.statusInfo.users,
            pages: action.data.statusInfo.pages
          };
        case STATUS.GET_ALL_USER_FAIL:
        case STATUS.GET_RAW_APPLICATION_FAIL:
        case STATUS.GET_ALL_USER_FAIL_BY_SEARCH:
          return {
            ...state,
            status: action.data.status,
            error: action.data.statusInfo.error,
            users: []
          };

        case STATUS.UPDATE_APPLICATION_INFO_PROGRESS:
          const updateUserData = action.data.statusInfo.updateUserData;
          if (!updateUserData.status)
            delete updateUserData.status;
          return {
            ...state,
            status: action.data.status,
            updateUserData
          };
        case STATUS.GET_API_SERVICE_SUCCESS:
          return {
            ...state,
            status: action.data.status,
            apiServices: action.data.statusInfo.apiServices
          };

        case STATUS.CREATE_API_APP_SUCCESS:
          return {
            ...state,
            apiServices: [
              ...state.apiServices,
              action.data.statusInfo.apiServices
            ]
          };
        case STATUS.DELETE_API_APP_SUCCESS:
          let apisServiceIndex = [...state.apiServices].findIndex(
            item => item._id === action.data.statusInfo.data._id
          );
          return {
            ...state,
            status: action.data.status,
            apiServices: [
              ...state.apiServices.slice(0, apisServiceIndex),
              ...state.apiServices.slice(apisServiceIndex + 1)
            ]
          };

        case STATUS.UPDATE_APPLICATION_INFO_SUCCESS:
          let boxes = [];
          let userIndex = state.users.findIndex(
            u => u._id === state.updateUserData._id
          );
          let data = Object.assign(
            state.users[userIndex],
            state.updateUserData
          );
          data.linkPassportImage = data.linkPassportImage + '&xxx=34322223333';
          data.updatedCards =
            data.cards && data.cards[0].cardNumber ? true : '';
          data.cards && data.cards.forEach(val => boxes.push(val.box));
          data.KYC = {
            kycExpiredDate: data.passportExpired,
            kycIssueDate: data.passportIssue,
            kycPlaceIssued: data.passportPlaceIssued,
            KYC_NUMBER: data.passportNumber,
            KYC_1: data.KYC_1,
            KYC_2: data.KYC_2,
            KYC_3: data.KYC_3,
            KYC_4: data.KYC_4
          };
          return {
            ...state,
            status: action.data.status,
            userUpdated: state.updateUserData._id,
            users: [
              ...state.users.slice(0, userIndex),
              Object.assign({}, state.users[userIndex], data),
              ...state.users.slice(userIndex + 1)
            ],
            boxes: handleAddBox([...(state.boxes || [])], boxes),
            getAllApplicationAgain: true
          };

        case STATUS.UPDATE_APPLICATION_INFO_FAIL:
          return {
            ...state,
            status: action.data.status,
            error: action.data.statusInfo.error,
            getAllApplicationAgain: false
          };
        default:
          return state;
      }
    case ACTIONS.SET_APPLICATION_STORE_PARAMS: {
      const { type, ...rest } = action.data;
      return {
        ...state,
        [type]: {
          ...state.params,
          ...rest
        }
      };
    }
    case ACTIONS.GET_APPLICATIONS_SUCCESS: {
      const { list, boxes, ...rest } = action.payload;
      let users = list;
      const data = action.data;
      const boxParams = {};
      if (data.type !== 'search') {
        boxParams.boxes = boxes;
      }
      if (data.sort) {
        users = _.orderBy(users, ['changeStatusTimestamp'], ['asc']);
      }
      return {
        ...state,
        loaded: true,
        submitting: null,
        users,
        params: {
          ...state.params,
          ...rest
        },
        check: {
          checkAll: false,
          checkRows: [],
          indeterminateCheckAll: false
        },
        ...boxParams,
        status: null
      };
    }
    case ACTIONS.GET_APPLICATIONS_FAILURE:
      return {
        ...state,
        submitting: action.type,
        error: action.payload.error,
        users: []
      };
    case ACTIONS.RESET_APPLICATIONS_ERROR:
      return {
        ...state,
        submitting: initialState.submitting,
        status: initialState.status,
        error: initialState.error,
        applicationUpdateFails: initialState.applicationUpdateFails,
        recordImport: null,
        recordFail: null
      };
    case ACTIONS.IMPORT_AND_UPDATE_STATUS_SUCCESS:
      const applications = action.payload.applications;
      return {
        ...state,
        submitting: action.type,
        recordImport: action.payload.countChange,
        recordFail: action.payload.countFail,
        users: handleRemoveItem([...state.users], applications)
      };
    case ACTIONS.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        submitting: action.type,
        currentUser: action.payload,
        listPermission: action.payload.roleId.permission
      };
    case ACTIONS.GET_STATISTIC_NUMBER_CARD_SUCCESS:
      return {
        ...state,
        submitting: action.type,
        listStatisticNumberCard: action.payload,
      };
    case ACTIONS.IMPORT_AND_UPDATE_STATUS_FAILURE:
    case ACTIONS.GET_CURRENT_USER_FAILURE:
    case ACTIONS.GET_STATISTIC_NUMBER_CARD_FAILURE:
    case ACTIONS.EXPORT_STATISTIC_NUMBER_APPLICATION_FAIL:
      return {
        ...state,
        submitting: action.type,
        error: action.payload ? action.payload.error : action.payload
      };
    case ACTIONS.RESET_ADMIN_PAGE:
      return {
        ...state,
        users: initialState.users,
        boxes: initialState.boxes,
        status: null
      };
    default:
      return state;
  }
};

export const handleRemoveItem = (users, listData) => {
  let listUser = users;
  if (Array.isArray(listData) && listData.length > 0) {
    for (let i = 0; i < listData.length; i++) {
      listUser = listUser.filter(u => u._id !== listData[i]);
    }
  }
  return listUser;
};

export const handleAddBox = (boxes, data) => {
  let allBox = boxes;
  for (let i = 0; i < data.length; i++) {
    if (!allBox.find(val => val === data[i])) allBox.push(data[i]);
  }
  return allBox;
};

export const handleAddTimePdfExport = (users, data) => {
  let listUser = users;
  let listData = data && data.data;
  if (Array.isArray(listData) && listData.length > 0) {
    for (let i = 0; i < listData.length; i++) {
      let user = listUser.findIndex(u => u._id === listData[i]);
      listUser[user].timePdfExport = (listUser[user].timePdfExport || 0) + 1;
    }
  }
  return listUser;
};
