import {createSelector} from 'reselect'

const getErrorAuth = state => state.auth.authError;
const getErrorAdmin = state => state.admin.error;
const getErrorCard = state => state.cardManagement.error;
const getErrorMember = state => state.member.memberError;
const getErrorPermission = state => state.permission.permissionError;
const getErrorStatisticApplication = state => state.statisticApplication.error;
const getTokenErrors = createSelector(
    [
        getErrorAuth,
        getErrorAdmin,
        getErrorCard,
        getErrorMember,
        getErrorPermission,
        getErrorStatisticApplication,
    ],
    (
        authErr,
        adminErr,
        cardErr,
        memberErr,
        permissionErr,
        statisticApplicationErr,
    ) => {
        const errorsList = [
            authErr,
            adminErr,
            cardErr,
            memberErr,
            permissionErr,
            statisticApplicationErr,
        ];
        return errorsList.find(error => error && ['NOT_AUTHENTICATED_ERROR', 'TOKEN_EXPIRED'].includes(error.code));
    }
);
export {
    getTokenErrors,
}
