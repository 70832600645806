import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";
import CardSettings from "./CardSettings";
import CommissionSettings from "./CommissionSettings";
import AgencySettings from "./AgencySettings";
export default class OtherSetting extends Component {
    render () {
        return (
            <div>
                <Switch>
                    <Route path="/admin/other-setting/agency-settings" component={AgencySettings} />
                    <Route path="/admin/other-setting/card-settings" component={CardSettings} />
                    <Route path="/admin/other-setting/commission-setting" component={CommissionSettings} />
                </Switch>
            </div>
        )
    }
}