import React from 'react';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import Alert from '../../../components/Alert';
import {Translate} from 'react-localize-redux';
import {STATUS} from '../../../reducers/authReducer';
import {login} from '../../../actions/authActions';
import {getCurrentUser} from '../../../actions/adminActions';
import {Label} from "reactstrap";
import Header from "../../pages/Admin/Header";
import { Input, Button} from "../../../components/antd/";

import './style.css';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            alertAck: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(field, value) {
        this.setState({[field]: value});
    }

    loginForm = () => {
        const { getCurrentUser } = this.props;
        this.setState({alertAck: false});
        this.props.login({
            username: this.state.username,
            password: this.state.password
        }, () => {
            getCurrentUser();
        })
    };
    onKeyPress = (e) => {
        if (e.which === 13) {
            this.loginForm();
        }
    };

    render() {
        return (
            <div>
                <Header loginStatus={true}/>
                <Translate>
                    {({translate}) => (
                        <div className="container-pages-loginpage">
                            {this.props.auth.user && (
                                <Redirect to="/admin"/>
                            )}
                            <fieldset disabled={this.props.auth.status === STATUS.LOGIN_PROGRESS}>
                                <div className='title-form'>ログイン</div>
                                <div className="login-form">
                                    <Label>ログインID</Label>
                                    <Input
                                        value={this.state.username}
                                        onChange={(e) => {
                                            this.handleChange("username", e.target.value)
                                        }}
                                        onKeyPress={this.onKeyPress}
                                        className="margin-bottom-20px input"
                                        label="Username"
                                    />
                                    <Label>パスワード</Label>

                                    <Input
                                        value={this.state.password}
                                        onChange={(e) => {
                                            this.handleChange("password", e.target.value)
                                        }}
                                        onKeyPress={this.onKeyPress}
                                        className="margin-bottom-20px input"
                                        label="Password"
                                        type="password"
                                    />
                                    <Button outline
                                            color="secondary"
                                            onClick={this.loginForm}
                                            disabled={!this.state.username || !this.state.password}
                                            className="margin-bottom-20px custom-btn"
                                    >ログイン</Button>
                                </div>
                            </fieldset>
                            {[STATUS.LOGIN_FAILED].indexOf(this.props.auth.status) >= 0 && !this.state.alertAck && (
                                <Alert title="Error"
                                       content={translate("errors." + this.props.auth.loginError.code)}
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                        </div>
                    )}
                </Translate>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (loginData, callback) => {
            dispatch(login(loginData, callback));
        },
        getCurrentUser: () => {
            dispatch(getCurrentUser());
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
