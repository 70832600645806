import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  getDataCompare,
  ACTIONS, exportOldNew
} from "../../../../actions/adminActions";
import "./style.css";
import { Translate } from "react-localize-redux";
import {
  Table,
  PaginationBar,
  Button,
  Select,
  Input, Checkbox, Button as ButtonAntd
} from "../../../../components/antd";
import {
  statusOptions
} from "../../../../constants/commonData";
import {ROLE_SUPPER_ADMIN} from "../../../../utils/commonUtils";
import {STATUS} from "../../../../reducers/cardManagementReducer";
import {CircularProgress} from "@material-ui/core";

class CompareApplicationData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: false,
      str: "",
      exportAll: false
    };
    this._columns = [
      {
        title: 'UserCode',
        align: 'center',
        width: 150,
        dataIndex: 'businessMemberId',
        key: 'businessMemberId',
      },
      {
        title: 'Old',
        children: [
          {
            title: (
              <Fragment>
                Name
                <br />
                (1)
              </Fragment>
            ),
            width: 200,
            align: 'center',
            key: 'oldName',
            render: (cell, record) => (
              <Fragment>
                <div>{record.applicationOld.name}</div>
              </Fragment>
            )

          },
          {
            title: (
              <Fragment>
                Phone Number
                <br />
                (2)
              </Fragment>
            ),
            width: 200,
            align: 'center',
            key: 'oldPhoneNumber',
            render: (cell, record) => (
              <Fragment>
                <div>{record.applicationOld.phoneNumber}</div>
              </Fragment>
            )
          },
          {
            title: (
              <Fragment>
                Passport Number
                <br />
                (3)
              </Fragment>
            ),
            width: 200,
            align: 'center',
            key: 'oldPassportNumber',
            render: (cell, record) => (
              <Fragment>
                <div>{record.applicationOld.passportNumber}</div>
              </Fragment>
            )
          },
          {
            title: (
              <Fragment>
                Nationality
                <br />
                (4)
              </Fragment>
            ),
            width: 150,
            align: 'center',
            key: 'oldNationality',
            render: (cell, record) => (
              <Fragment>
                <div>{record.applicationOld.nationality}</div>
              </Fragment>
            )
          }
        ]
      },
      {
        title: 'New',
        children: [
          {
            title: (
              <Fragment>
                Name
                <br />
                (1)
              </Fragment>
            ),
            width: 200,
            align: 'center',
            key: 'newName',
            render: (cell, record) => (
              <Fragment>
                <div>{record.applicationNew.name}</div>
              </Fragment>
            )

          },
          {
            title: (
              <Fragment>
                Phone Number
                <br />
                (2)
              </Fragment>
            ),
            width: 200,
            align: 'center',
            key: 'newPhoneNumber',
            render: (cell, record) => (
              <Fragment>
                <div>{record.applicationNew.phoneNumber}</div>
              </Fragment>
            )
          },
          {
            title: (
              <Fragment>
                Passport Number
                <br />
                (3)
              </Fragment>
            ),
            width: 200,
            align: 'center',
            key: 'newPassportNumber',
            render: (cell, record) => (
              <Fragment>
                <div>{record.applicationNew.passportNumber}</div>
              </Fragment>
            )
          },
          {
            title: (
              <Fragment>
                Nationality
                <br />
                (4)
              </Fragment>
            ),
            width: 150,
            align: 'center',
            key: 'newNationality',
            render: (cell, record) => (
              <Fragment>
                <div>{record.applicationNew.nationality}</div>
              </Fragment>
            )
          }
        ]
      },
      {
        title: 'Is Change',
        width: 150,
        align: 'center',
        key: 'isChange',
        render: (item, record) => (
          <div className="compare">
            {
              record.arrCompare && record.arrCompare.map((change, index) => {
                if(index === record.arrCompare.length -1) {
                  return <span key={index}>{change}</span>
                }
                return <span key={index}>{change + ', '}</span>
              })
            }
          </div>
        )
      }
    ]
  }

  componentDidMount() {
    this._getDataCompare({
      page: 1,
      limit: 100,
      str: ""
    });
  }

  _getDataCompare = (newParams) => {
    const {
      getDataCompare,
      compareDataStore
    } = this.props;
    const {limit, page, status} = compareDataStore;
    const payload = {
      limit, page, status,
      ...newParams
    };

    getDataCompare(payload);
  };

  _onPaginationBarChange = (type, value) => {
    const {compareDataStore} = this.props;
    const {limit} = compareDataStore;
    if (type === "page") {
      this._getDataCompare({ page: value, limit });
    } else {
      this._getDataCompare({ page: 1, limit: value });
    }
  };

  _onStatusCardType = e => {
    const status = e.target.value;
    this._getDataCompare({ page: 1, limit: 100, status });
  }

  _onSearch = () => {
    const { str } = this.state;
    const { compareDataStore, getDataCompare } = this.props;
    const {status} = compareDataStore;
    this.setState({ isSearching: true });
    getDataCompare({
      page: 1, limit: 100,
      status, str
    }, () => {
      this.setState({ isSearching: false });
    });
  };

  handleExportOldNew () {
    const {
      compareDataStore: { users, page, total, limit, submitting, status },
    } = this.props;
    const listUsers = users ? users : "";

    this.props.exportOldNew({
      filter: status,
      page: page,
      limit: limit,
      exportAll: this.state.exportAll,
      listUsers
    })
  }

  render() {
    const {
      compareDataStore: { users, page, total, limit, submitting, status },
    } = this.props;

    const { adminStore: { currentUser }} = this.props;

    const { isSearching, str, exportAll } = this.state;

    const columns = this._columns;

    return (
      <Translate>
        {({ translate }) => (
          <div className="compare-data card-list">
              <div className="tool-box">
                <p className="title">申す込み古いと新しい</p>
                <div className="right-box">
                  <Input
                    modern
                    className="search-input"
                    placeholder="ID, MEMBERID, EMAIL, NAME, PASSPORT NUMBER"
                    name="str"
                    onChange={e => this.setState({ str: e.target.value })}
                    value={str}
                  />
                  <Button loading={isSearching} onClick={this._onSearch}>
                    検索
                  </Button>
                </div>
              </div>
              <div className="top-action-box">
                <div className="button-group">
                  <Select
                    className="select-status"
                    options={statusOptions}
                    placeholder="Select Status"
                    modern
                    name="status card"
                    size="small"
                    onChange={this._onStatusCardType}
                    value={status}
                    allowClear
                  />
                  <PaginationBar
                    current={page}
                    total={total}
                    onChange={this._onPaginationBarChange}
                    pageSize={limit}
                  />
                </div>
                <div className="action-box">
                  <div className="top-action">
                    {
                      currentUser && currentUser.name === ROLE_SUPPER_ADMIN && (
                          <div className="checkbox-export">
                            <Checkbox
                                onChange={() => {
                                  this.setState({
                                    exportAll: !exportAll
                                  });
                                }}
                                checked={exportAll}
                                color="#FFA500"
                            >
                              Select All Page To Export
                            </Checkbox>
                          </div>
                      )
                    }
                  </div>
                  <div className="bottom-action">
                    {
                      currentUser && currentUser.name === ROLE_SUPPER_ADMIN && (
                          <ButtonAntd
                              disabled={this.props.status === STATUS.CARDS_EXPORTING}
                              onClick={() => {
                                this.handleExportOldNew()
                              }}
                              color="secondary"
                          >
                            {this.props.status === STATUS.CARDS_EXPORTING && (
                                <CircularProgress className="margin-right-20px" size={20}/>)}
                            Export CSV
                          </ButtonAntd>
                      )
                    }
                  </div>
                </div>
              </div>
              <Table
                bordered
                loading={submitting === ACTIONS.GET_DATA_COMPARE_REQUEST}
                rowKey={(row, index) => index}
                columns={columns}
                dataSource={users}
                scroll={{ x: 1800, y: "calc(100vh - 330px)" }}
              />
            </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    applicationStore: state.admin,
    compareDataStore: state.compare,
    adminStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDataCompare: (data, callback) => {
      dispatch(getDataCompare(data, callback));
    },
    exportOldNew: data => {
      dispatch(exportOldNew(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareApplicationData);
