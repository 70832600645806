import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import { exportExcel, exportExcelEzp } from "../../../../actions/adminActions";
import { STATUS } from "../../../../reducers/adminReducer";
import { withLocalize } from "react-localize-redux";
import "./style.css";
import { Button } from "../../../../components/antd";
import { DatePicker } from "antd";
import { PERMISSION } from "../../../../utils/commonUtils";

class ExcelExport extends React.Component {
  constructor(props) {
    super(props);
    this.status = [
      "init",
      "pending",
      "done",
      "reconfirm",
      "reconfirmed",
      "checked",
      "printed",
      "reserve",
      "card_update",
      "input",
      "sent",
      "confirming",
      "confirmed",
      "confirmed_preparing_shipment",
      "confirmed_return",
      "confirmed_preparing_reshipment",
      "completed",
      "card_valid",
      "refunding",
      "refunded",
      "failed"
    ];
    this.fields = [
      "family_name",
      "given_name",
      "email",
      "pay_pin",
      "business_member_id",
      "gender",
      "date_of_birth",
      "profession",
      "phone_country_code",
      "phone_number",
      "nationality",
      "residential_address",
      "postcode",
      "passport_expiry_date",
      "passport_issue_date",
      "passport_number",
      "passport_issue_place",
      "status",
      "card_number",
      "box_name",
      "box_number",
      "expired_day",
      "payment_transaction_id",
      "total_paid",
      "business_created_at",
      "login_password",
      "receipt_number",
      "letter_tracking",
      "link_passport_image"
    ];
    this.state = {
      checkDate: false,
      startTime: null,
      endTime: null,
      alertAck: false,
      selectedStatus: this.status.map(() => {
        return false;
      }),
      selectedFields: this.fields.map(() => {
        return false;
      })
    };
  }

  _onChangeFromDate = startTime => {
    this.setState({ startTime });
  };

  _onChangeToDate = endTime => {
    this.setState({ endTime });
  };

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value
      },
      () => {
        this.validateDate();
      }
    );
  };

  validateDate() {
    let checkData = moment(this.state.startTime) > moment(this.state.endTime);
    this.setState({ checkDate: checkData });
  }

  render() {
    const {
      admin: { listPermission }
    } = this.props;
    const { startTime, endTime } = this.state;
    const loadingExport = this.props.admin.status === STATUS.EXCEL_EXPORTING;
    const loadingExportEzp =
      this.props.admin.status === STATUS.EXCEL_EZP_EXPORTING;
    return (
      <div className="container-pages-admin-admindashboard">
        <div className="excel-export">
          <div>
            <div className="title">申し込みのエクセル出力</div>
          </div>
          <div className="d-flex margin-top-20px">
            <div className="status-selector-status">
              <div className="little-title">支払状況で検索</div>
              {this.status.map((status, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={this.state.selectedStatus[index]}
                      onChange={() => {
                        this.setState({
                          selectedStatus: [
                            ...this.state.selectedStatus.slice(0, index),
                            !this.state.selectedStatus[index],
                            ...this.state.selectedStatus.slice(index + 1)
                          ]
                        });
                      }}
                      color="primary"
                    />
                  }
                  label={this.props.translate(status)}
                  disabled={this.props.admin.status === STATUS.EXCEL_EXPORTING}
                />
              ))}
            </div>

            <div className="status-selector ml-4">
              <div className="little-title excel">
                Excelファイルのフィールドを選択
              </div>
              {this.fields.map((field, index) => (
                <FormControlLabel
                  className="col-4"
                  key={index}
                  control={
                    <Checkbox
                      checked={this.state.selectedFields[index]}
                      onChange={() => {
                        this.setState({
                          selectedFields: [
                            ...this.state.selectedFields.slice(0, index),
                            !this.state.selectedFields[index],
                            ...this.state.selectedFields.slice(index + 1)
                          ]
                        });
                      }}
                      color="primary"
                    />
                  }
                  label={this.props.translate(field)}
                  disabled={this.props.admin.status === STATUS.EXCEL_EXPORTING}
                />
              ))}
            </div>
            <div className="status-selector ml-4">
              <div className="little-title">申込日</div>

              <div className="date-start">
                <p>FROM</p>
                <DatePicker
                  className="date-input"
                  placeholder="From"
                  value={startTime}
                  onChange={this._onChangeFromDate}
                  format="YYYY/MM/DD"
                />
              </div>
              <div className="date-end">
                <p>TO</p>
                <DatePicker
                  className="date-input"
                  placeholder="To"
                  value={endTime}
                  onChange={this._onChangeToDate}
                  format="YYYY/MM/DD"
                />
              </div>
            </div>
          </div>
          <div className="d-flex">
            {listPermission.includes(
                PERMISSION.ALLOW_EXPORT_EXCEL
              ) && (
                <React.Fragment>
                  <Button
                    disabled={
                      this.props.admin.status === STATUS.EXCEL_EXPORTING ||
                      this.state.checkDate
                    }
                    onClick={() => {
                      this.props.exportExcel({
                        startTime: this.state.startTime,
                        endTime: this.state.endTime,
                        status: this.status.filter((status, index) => {
                          return this.state.selectedStatus[index];
                        }),
                        fields: this.fields.filter((field, index) => {
                          return this.state.selectedFields[index];
                        })
                      });
                    }}
                    variant="contained"
                    loading={loadingExport}
                  >
                    ダウンロード
                  </Button>
                  <Button
                    disabled={
                      this.props.admin.status === STATUS.EXCEL_EZP_EXPORTING ||
                      this.state.checkDate
                    }
                    className="ml-4"
                    onClick={() => {
                      this.props.exportExcelEzp({
                        startTime: this.state.startTime,
                        endTime: this.state.endTime,
                        status: this.status.filter((status, index) => {
                          return this.state.selectedStatus[index];
                        })
                      });
                    }}
                    variant="contained"
                    loading={loadingExportEzp}
                  >
                    Export Excel for Ezp
                  </Button>
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    admin: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    exportExcel: filter => {
      dispatch(exportExcel(filter));
    },
    exportExcelEzp: filter => {
      dispatch(exportExcelEzp(filter));
    }
  };
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(ExcelExport)
);
