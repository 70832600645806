import React, { Component } from "react";
import {
  Modal,
  Form,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Input
} from "reactstrap";
import {
  getUsers,
  deleteMember,
  createUser,
  getInfo,
  editMember
} from "../../../../actions/memberAction";
import { connect } from "react-redux";
import Alert from "../../../../components/Alert";
import {
  ROLE_SUPPER_ADMIN,
  STATUS_USER,
  Validation,
  PERMISSION
} from "../../../../utils/commonUtils";
import { STATUS as ACTIONS } from "../../../../reducers/memberReducer";
import "./style.css";
import { Translate } from "react-localize-redux";
import {
  Table,
  Button,
  Page,
  Container,
  Column
} from "../../../../components/antd";

class AdministratorSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      info: null,
      selectedUser: {},
      alertAck: true,
      isShowModal: false,
      fullyChecked: false,
      deleteUser: false
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.alertAck();
    this.props.getUsers();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.users !== this.props.users) {
      this.setState({
        users: nextProps.users
      });
    }
  }

  handleSubmitBtn = (e, actionName) => {
    e.preventDefault();
    this.alertAck();
    this.setState({ isShowModal: false });
    const { selectedUser } = this.state;
    switch (actionName) {
      case "Update":
        this.props.handleUpdateMember(selectedUser);
        break;
      case "Create":
        this.props.handleCreateMember(selectedUser);
        break;
      case "Delete":
        this.props.handleDeleteMember(selectedUser);
        break;
      default:
        break;
    }
  };

  checkData() {
    let item = {
      id: this.state.selectedUser.id || "",
      name: this.state.selectedUser.name || "",
      email: this.state.selectedUser.email || "",
      password: this.state.selectedUser.password || "",
      role: this.state.selectedUser.role || "",
      status: this.state.selectedUser.status || ""
    };
    let fullyChecked = !Object.keys(item).some(key => {
      return item[key] === "" || item[key] === null || item[key] === "";
    });

    fullyChecked = fullyChecked && Validation.validateEmail(item.email);
    this.setState({ fullyChecked: fullyChecked });
    return fullyChecked;
  }

  handleChange(data) {
    let id =
      (this.state.selectedUser && this.state.selectedUser.id) ||
      (this.props.info && this.props.info.id);
    return e => {
      if (e.target) {
        let selectedUser = { ...this.state.selectedUser };
        selectedUser[data] = e.target.value;
        selectedUser["id"] = id;
        this.setState({ selectedUser: selectedUser }, () => {
          this.checkData();
        });
      }
    };
  }

  renderTable() {
    return (
      <Table bordered responsive>
        <thead>{this.renderHeaderTable()}</thead>
        <tbody>{this.renderContentTable()}</tbody>
      </Table>
    );
  }

  toggleDialog = () => {
    this.setState({ isShowModal: false, selectedUser: {} });
  };

  renderForm() {
    const { adminStore } = this.props;
    const { listPermission } = adminStore;
    const { selectedUser, isShowModal, deleteUser } = this.state;
    const { info } = this.props;
    return (
      <Modal
        isOpen={isShowModal}
        toggle={this.toggleDialog}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggleDialog}>
          {deleteUser
            ? "このメンバーを削除しますか"
            : selectedUser && selectedUser._id
            ? "会員の情報を更新する"
            : "新しいメンバーを作成する"}
        </ModalHeader>
        <ModalBody className="member-setting">
          <Form>
            <FormGroup
              row
              className="d-flex justify-content-between align-items-center"
            >
              {deleteUser ? (
                <>
                  <div className="ml-4" sm={2}>
                    ID
                  </div>
                  <Col sm={10}>
                    <Input
                      type="input"
                      name="id"
                      id="id"
                      value={
                        (selectedUser && selectedUser.id) ||
                        (info && info.id) ||
                        ""
                      }
                      placeholder="Id"
                      disabled={true}
                    />
                  </Col>
                </>
              ) : (
                <>
                  <div className="ml-4" sm={4}>
                    ID
                  </div>
                  <Col sm={8}>
                    <Input
                      type="input"
                      name="id"
                      id="id"
                      value={
                        (selectedUser && selectedUser.id) ||
                        (info && info.id) ||
                        ""
                      }
                      placeholder="Id"
                      disabled={true}
                    />
                  </Col>
                </>
              )}
            </FormGroup>
            {deleteUser ? null : (
              <React.Fragment>
                <FormGroup
                  row
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="ml-4" sm={4}>
                    名前
                  </div>
                  <Col sm={8}>
                    <Input
                      type="input"
                      name="name"
                      id="name"
                      value={(selectedUser && selectedUser.name) || ""}
                      onChange={this.handleChange("name")}
                      placeholder="名前"
                    />
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="ml-4" sm={4}>
                    メールアドレス
                  </div>
                  <Col sm={8}>
                    <Input
                      type="input"
                      name="email"
                      id="email"
                      value={(selectedUser && selectedUser.email) || ""}
                      onChange={this.handleChange("email")}
                      placeholder="メールアドレス"
                    />
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="ml-4" sm={4}>
                    パスワード
                  </div>
                  <Col sm={8}>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      value={
                        selectedUser._id ? "123456" : selectedUser.password
                      }
                      onChange={this.handleChange("password")}
                      placeholder="パスワード"
                      disabled={selectedUser._id}
                    />
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="ml-4" sm={4}>
                    ロール
                  </div>
                  <Col sm={8}>
                    <Input
                      type="select"
                      name="role"
                      id="role"
                      value={(selectedUser && selectedUser.role) || ""}
                      onChange={this.handleChange("role")}
                      placeholder="ロール"
                    >
                      <option value="">権限選択</option>
                      {info &&
                        info.allRole.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="ml-4" sm={4}>
                    ステータス
                  </div>
                  <Col sm={8}>
                    <Input
                      type="select"
                      name="status"
                      id="status"
                      value={(selectedUser && selectedUser.status) || ""}
                      onChange={this.handleChange("status")}
                      placeholder="ステータス"
                    >
                      <option value="">ステータス選択</option>
                      {Object.values(STATUS_USER).map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </React.Fragment>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.toggleDialog}>閉じる</Button>{" "}
          {listPermission.includes(PERMISSION.MEMBER) && deleteUser ? (
            <Button onClick={e => this.handleSubmitBtn(e, "Delete")}>
              削除
            </Button>
          ) : selectedUser && selectedUser._id ? (
            <Button
              disabled={!this.state.fullyChecked}
              onClick={e => this.handleSubmitBtn(e, "Update")}
            >
              更新
            </Button>
          ) : (
            <Button
              disabled={!this.state.fullyChecked}
              onClick={e => this.handleSubmitBtn(e, "Create")}
            >
              作成
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { userStore, adminStore } = this.props;
    const { listPermission } = adminStore;
    const columns = [
      {
        title: "ID",
        width: 120,
        dataIndex: "id",
        align: "center",
        key: "id"
      },
      {
        key: "name",
        width: 200,
        title: "名前",
        dataIndex: "name"
      },
      {
        key: "email",
        width: 200,
        title: "メールアドレス",
        dataIndex: "email"
      },
      {
        key: "role",
        width: 200,
        title: "ロール",
        dataIndex: "role"
      },
      {
        key: "status",
        width: 100,
        title: "ステータス",
        dataIndex: "status"
      },
      {
        title: "オプション",
        align: "center",
        dataIndex: "option",
        key: "option",
        width: 210,
        render: (text, record) => (
          <div className="action-cell">
            {record.role !== ROLE_SUPPER_ADMIN &&
              listPermission.includes(PERMISSION.MEMBER) && (
                <Button
                  type="edit"
                  icon="edit"
                  size="small"
                  onClick={() =>
                    this.setState(
                      {
                        isShowModal: true,
                        deleteUser: false,
                        selectedUser: { ...record, password: "123123" }
                      },
                      () => {
                        this.props.getInfo();
                      }
                    )
                  }
                >
                  編集
                </Button>
              )}
            {record.role !== ROLE_SUPPER_ADMIN &&
              listPermission.includes(PERMISSION.MEMBER) && (
                <Button
                  type="delete"
                  icon="delete"
                  size="small"
                  onClick={() =>
                    this.setState({
                      isShowModal: true,
                      deleteUser: true,
                      selectedUser: { ...record }
                    })
                  }
                >
                  削除
                </Button>
              )}
          </div>
        )
      }
    ];

    return (
      <Translate>
        {({ translate }) => (
          <Page className="member-setting">
            <Container>
              {this.renderForm()}
              <div className="title">メンバー設定</div>
              <div className="action-box">
                {listPermission.includes(PERMISSION.MEMBER) && (
                  <Button
                    icon="plus-circle"
                    size="small"
                    onClick={() =>
                      this.setState(
                        {
                          isShowModal: true,
                          selectedUser: {},
                          deleteUser: false
                        },
                        () => {
                          this.props.getInfo();
                        }
                      )
                    }
                  >
                    {" "}
                    新しいメンバー追加
                  </Button>
                )}
              </div>

              <Table
                loading={userStore.status === ACTIONS.GET_USER_PROGRESS}
                rowKey={(row, index) => index}
                columns={columns}
                dataSource={userStore.users}
                scroll={{ x: 1300, y: "calc(100vh - 300px)" }}
              />
              {[
                ACTIONS.CREATE_USER_FAILED,
                ACTIONS.DELETE_USER_FAILED,
                ACTIONS.GET_USER_FAILED,
                ACTIONS.UPDATE_USER_FAILED
              ].indexOf(this.props.member) >= 0 &&
                !this.state.alertAck && (
                  <Alert
                    title="Error"
                    content={translate(
                      this.props.memberError &&
                        "errors." + this.props.memberError.code
                    )}
                    okTitle="OK"
                    onOK={() => {
                      this.setState({ alertAck: true });
                    }}
                  />
                )}
              {/*{[ACTIONS.CREATE_ROLE_SUCCESS].indexOf(this.props.permission) >= 0 && !this.state.alertAck && (*/}
              {/*<Alert title="Success"*/}
              {/*content="Create role successfully"*/}
              {/*okTitle="OK"*/}
              {/*onOK={() => {*/}
              {/*this.setState({alertAck: true});*/}
              {/*}}/>*/}
              {/*)}*/}
              {/*{[ACTIONS.DELETE_ROLE_SUCCESS].indexOf(this.props.permission) >= 0 && !this.state.alertAck && (*/}
              {/*<Alert title="Success"*/}
              {/*content="Delete role successfully"*/}
              {/*okTitle="OK"*/}
              {/*onOK={() => {*/}
              {/*this.setState({alertAck: true});*/}
              {/*}}/>*/}
              {/*)}*/}
            </Container>
          </Page>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = store => {
  return {
    member: store.member.status,
    memberError: store.member.memberError,
    users: store.member.users,
    info: store.member.info,
    userStore: store.member,
    adminStore: store.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsers: () => {
      dispatch(getUsers());
    },
    getInfo: () => {
      dispatch(getInfo());
    },
    handleDeleteMember: data => {
      dispatch(deleteMember(data));
    },
    handleCreateMember: data => {
      dispatch(createUser(data));
    },
    handleUpdateMember: data => {
      dispatch(editMember(data));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdministratorSetting);
