import React, { Fragment } from "react";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import {
  getAllLog,
  setApplicationStoreParams,
  resetAdminPage
} from "../../../../actions/adminActions";
import Alert from "../../../../components/Alert";
import {
  Button,
  Column,
  Container,
  Input,
  Page,
  PaginationBar,
  Table
} from "../../../../components/antd";
import { STATUS as STATUSES } from "../../../../reducers/adminReducer";
import { formatDateTimeFull, ROLE_SUPPER_ADMIN } from "../../../../utils/commonUtils";
import "./style.css";

class LogAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertAck: true
    };
    this._columns = [
      {
        title: "No",
        align: "center",
        width: 100,
        key: "no",
        render: (cell, record, index) => index + 1
      },

      {
        key: "adminId",
        width: 100,
        title: "Admin Id",
        dataIndex: "adminId"
      },
      {
        key: "action",
        width: 120,
        title: "Action",
        dataIndex: "action"
      },
      {
        align: "center",
        width: 250,
        key: "application",
        title: "ApplicationId",
        render: (cell, record) => {
          return (
            Array.isArray(record.application) &&
            record.application.map((val, index) => (
              <Fragment key={val}>
                {val}
                <br />
              </Fragment>
            ))
          );
        }
      },
      {
        key: "content",
        width: 250,
        title: "Content",
        dataIndex: "content"
      },
      {
        align: "center",
        width: 250,
        key: "Timestamps",
        title: "Timestamps",
        render: (cell, record) => formatDateTimeFull(record.createdAt)
      }
    ];
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this._getAllLog({
      page: 1,
      limit: 100,
      str: ""
    });
  }

  componentWillUnmount() {
    const { resetAdminPage } = this.props;
    resetAdminPage();
  }

  _getAllLog = newParams => {
    this.alertAck();
    const {
      getAllLog,
      setApplicationStoreParams,
      adminStore: { params }
    } = this.props;

    let payload = {
      ...params,
      ...newParams
    };
    setApplicationStoreParams({
      ...newParams,
      type: "params"
    });

    getAllLog(payload);
  };

  _onPaginationBarChange = (type, value) => {
    if (type === "page") {
      this._getAllLog({ page: value });
    } else {
      this._getAllLog({ page: 1, limit: value });
    }
  };

  _onSearchFieldChange = e => {
    const { setApplicationStoreParams } = this.props;
    setApplicationStoreParams({
      [e.target.name]: e.target.value,
      type: "params"
    });
  };

  _onSearch = () => {
    this._getAllLog({ page: 1 });
  };

  render() {
    const {
      adminStore: { logs, status, error, params, currentUser }
    } = this.props;

    logs && logs.forEach(item=>{
      item.content = item.content.replace(/,/g, ", ")
    });

    return (
      <Translate>
        {({ translate }) => (
          <Page className="log-action-container">
            <Container>
              <div className="tool-box">
                <p className="title">Log</p>
                <div className="right-box">
                  {currentUser && currentUser.name === ROLE_SUPPER_ADMIN && (
                    <React.Fragment>
                      <Input
                        modern
                        className="search-input"
                        placeholder="ADMIN ID"
                        name="str"
                        onChange={this._onSearchFieldChange}
                        value={params.str}
                      />
                      <Button
                        disabled={status === STATUSES.GET_LOG_PROGRESS}
                        onClick={this._onSearch}
                      >
                        検索
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>
              {!!params.total && (
                <div className="top-action-box">
                  <div className="button-group">
                    <PaginationBar
                      current={params.page}
                      total={params.total}
                      onChange={this._onPaginationBarChange}
                      pageSize={params.limit}
                    />
                  </div>
                </div>
              )}
              <Table
                loading={status === STATUSES.GET_LOG_PROGRESS}
                rowKey={(row, index) => index}
                columns={this._columns}
                dataSource={logs}
                scroll={{ x: 1000, y: "calc(100vh - 275px)" }}
              />
              {[STATUSES.GET_LOG_FAIL].indexOf(status) >= 0 &&
                !this.state.alertAck && (
                  <Alert
                    title="Error"
                    content={translate(error && "errors." + error.code)}
                    okTitle="OK"
                    onOK={() => {
                      this.setState({ alertAck: true });
                    }}
                  />
                )}
            </Container>
          </Page>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    adminStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllLog: data => {
      dispatch(getAllLog(data));
    },
    setApplicationStoreParams: data => {
      dispatch(setApplicationStoreParams(data));
    },
    resetAdminPage: () => {
      dispatch(resetAdminPage());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogAction);
