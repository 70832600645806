import React from 'react';
import './style.css';
import {getEnv} from "../../../env";
import supportImage from '../../../assets/images/support.svg';
import NarrowLayout from "../../../components/_layouts/NarrowLayout";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";

export default class ContactPage extends React.Component {

    render() {

        return (
            <NarrowLayout>

                <div className="pages-contact">
                    <Header/>
                    <Menu/>
                    <div className="contact-content">
                        <div>For more information and assistant, please send us email to</div>
                        <a href={"mailto:" + getEnv('SUPPORT_EMAIL')} className="email">{getEnv('SUPPORT_EMAIL')}</a>
                        <img className="img-support" src={supportImage} alt="support"/>

                    </div>
                </div>
            </NarrowLayout>
        );

    }

}