import React from 'react';
import RegisterForm from '../Form';
import {Button, CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import {Translate} from 'react-localize-redux';
import './style.css';
import CodeInput from "../../../../components/CodeInput";
import Alert from "../../../../components/Alert";
import {Redirect} from 'react-router-dom';
import {STATUS, register, correctRegister, verify} from '../../../../actions/registrationActions';

class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {init: true, email: '', code: null, alertAck: false};
        this.handleEmail = this.handleEmail.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.emailRegex = /([\w\d.+_-])+@[\w\d+_-]+(\.[\w\d+_-]+)*/i;
    }

    handleEmail() {
        this.props.register({
            email: this.state.email,
            businessMemberId: this.props.businessMemberId
        });
        this.setState({alertAck: false});
    }

    checkValidEmail(email) {
        let result = this.emailRegex.exec(email);
        return result && result[0] === email;
    }

    handleEmailChange(email) {
        this.setState({email});
    }

    render() {
        return (
            <Translate>
                {({translate}) => (
                    <RegisterForm>
                        {this.props.stepData.status === STATUS.STEP2.VERIFY_SUCCESS && (
                            <Redirect to="/registers?step=3"/>
                        )}
                        <div className="container-pages-register-step2">
                            {([STATUS.STEP1.ACKNOWLEDGE, STATUS.STEP2.REGISTERING, STATUS.STEP2.REGISTER_FAILED, STATUS.STEP2.CORRECT_REGISTER].indexOf(this.props.stepData.status) >= 0) && (
                                <fieldset disabled={this.props.stepData.status === STATUS.STEP2.REGISTERING}>
                                    <div className="prompt">Please enter the email address to be registered</div>
                                    <div className="input">
                                        <div>
                                            <input className="large-input email-input"
                                                   disabled={this.props.stepData.status === STATUS.STEP2.REGISTERING}
                                                   value={this.state.email} onChange={(e) => {
                                                this.handleEmailChange(e.target.value)
                                            }} type="email"/>
                                        </div>
                                        <div className="control">
                                            <Button
                                                disabled={!this.checkValidEmail(this.state.email) || this.props.stepData.status === STATUS.STEP2.REGISTERING}
                                                variant="contained" color="primary"
                                                onClick={() => {
                                                    this.handleEmail();
                                                }}>
                                                {this.props.stepData.status === STATUS.STEP2.REGISTERING && (
                                                    <CircularProgress className="margin-right-20px" size={20}/>
                                                )}
                                                <span>Next</span>
                                            </Button>
                                        </div>
                                    </div>
                                </fieldset>
                            )}
                            {[STATUS.STEP2.REGISTER_SUCCESS, STATUS.STEP2.VERIFYING, STATUS.STEP2.VERIFY_FAILED].indexOf(this.props.stepData.status) >= 0 && (
                                <fieldset disabled={this.props.stepData.status === STATUS.STEP2.VERIFYING}>
                                    <div className="prompt">
                                        <div>Please enter the 4 digit authentication code that was sent to</div>
                                        <div className="email">{this.state.email}</div>
                                    </div>
                                    <div className="code-input">
                                        <CodeInput type="number" count={4} onInputNotFinish={() => {
                                            this.setState({code: null});
                                        }} onInputFinish={(value) => {
                                            this.setState({code: value});
                                        }}/>
                                    </div>
                                    <div className="input">
                                        <div className="control">
                                            <Button disabled={this.props.stepData.status === STATUS.STEP2.VERIFYING}
                                                    style={{marginRight: "15px"}} variant="contained" color="default"
                                                    onClick={() => {
                                                        this.props.correctRegister();
                                                        this.setState({code: null});
                                                    }}>Back</Button>
                                            <Button
                                                onClick={() => {
                                                    this.setState({init: false, alertAck: false});
                                                    this.props.verify({
                                                        email: this.state.email,
                                                        code: this.state.code
                                                    });
                                                }}
                                                disabled={!this.state.code || this.props.stepData.status === STATUS.STEP2.VERIFYING}
                                                variant="contained"
                                                color="primary">
                                                {this.props.stepData.status === STATUS.STEP2.VERIFYING && (
                                                    <CircularProgress className="margin-right-20px" size={20}/>
                                                )}
                                                <span>Confirm</span>
                                            </Button>
                                        </div>
                                    </div>
                                </fieldset>
                            )}
                            {[STATUS.STEP2.REGISTER_FAILED, STATUS.STEP2.VERIFY_FAILED].indexOf(this.props.stepData.status) >= 0 && !this.state.alertAck && (
                                <Alert title="Error"
                                       content={translate("errors." + this.props.stepData.statusInfo.error.code)}
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                        </div>
                    </RegisterForm>
                )}
            </Translate>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentStep: state.registration.currentStep,
        stepSuccess: state.registration.stepSuccess,
        stepData: state.registration.stepData,
        businessMemberId: state.registration.businessMemberId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        correctRegister: () => {
            dispatch(correctRegister());
        },
        register: (data) => {
            dispatch(register(data));
        },
        verify: (data) => {
            dispatch(verify(data));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
