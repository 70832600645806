import React, { Component } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../Admin/Header";
import Menu from "../Admin/Menu";
import PermissionSetting from "./PermissionSetting";
import AdministratorSetting from "./AdministratorSetting";
import ApplicationStatus from "./ApplicationStatus";
import CardIssuer from "./CardIssuer";
import ChargeRequestStepOne from "./ChargeRequestStepOne";
import ChargeRequestStepSecond from "./ChargeRequestStepSecond";
import ChargeList from "./ChargeList";
import OtherSetting from "./OtherSetting";
import ExcelExport from "./ExcelExport";
import RawRegistrationList from "./RawRegistrationList";
import RegistrationFormAdmin from "./RegistrationFormAdmin";
import LogAction from "./LogAction";
import StatisticApplication from "./StatisticApplication";
import LogIp from "./LogIp";
import RegistrationList from "./RegistrationList";
import PassportImageExport from "./PassportImageExport";
import ManageAPIService from "./ManageAPIService";
import SystemSendMail from "./SystemSendMail";
import SendMailToEachUser from "./SendMailToEachUser";
import SendMailPayment from "./SendMailPayment";
import CardManagement from "./CardManagement";
import CompareData from './CompareData';
import StatisticNumberApplication from "./StatisticNumberApplication";
import "./style.css";
import { getCurrentUser } from "../../../actions/adminActions";

class Admin extends Component {
  componentDidMount() {
    const { getCurrentUser } = this.props;
    getCurrentUser();
  }

  render() {
    const { uiStore } = this.props;
    return (
      <div className="container-home-page">
        {!this.props.user && <Redirect to="/login" />}
        <Header />
        <div className="section d-flex">
          <Menu />
          <div
            className={`content d-flex ${uiStore.isSideBarOpen ? "" : "open"}`}
          >
            <Switch>
              <Route path="/admin/card-management" component={CardManagement} />
              <Route
                path="/admin/permission-setting"
                component={PermissionSetting}
              />
              <Route
                path="/admin/administrator-setting"
                component={AdministratorSetting}
              />
              <Route
                path="/admin/application-status"
                component={ApplicationStatus}
              />
              <Route
                path="/admin/statistic-number-application"
                component={StatisticNumberApplication}
              />
              <Route path="/admin/card-issuer" component={CardIssuer} />
              <Route
                path="/admin/charge-request-step-one"
                component={ChargeRequestStepOne}
              />
              <Route
                path="/admin/charge-request-step-second"
                component={ChargeRequestStepSecond}
              />
              <Route path="/admin/charged-list" component={ChargeList} />
              <Route path="/admin/other-setting" component={OtherSetting} />
              <Route path="/admin/excel-export" component={ExcelExport} />
              <Route path="/admin/compare-data" component={CompareData} />
              <Route
                path="/admin/registration-list"
                component={RegistrationList}
              />
              <Route
                path="/admin/raw-registration-list"
                component={RawRegistrationList}
              />
              <Route
                path="/admin/registration-form-admin"
                component={RegistrationFormAdmin}
              />
              <Route path="/admin/logs" component={LogAction} />
              <Route path="/admin/log-login" component={LogIp} />
              <Route
                path="/admin/passport-image-export"
                component={PassportImageExport}
              />
              <Route
                path="/admin/manage-api-service"
                component={ManageAPIService}
              />
              <Route
                path="/admin/system-send-mail/send-all-user"
                component={SystemSendMail}
              />
              <Route
                path="/admin/system-send-mail/send-each-user"
                component={SendMailToEachUser}
              />
              <Route
                path="/admin/system-send-mail/send-mail-payment-again"
                component={SendMailPayment}
              />
              <Route
                path="/admin/statistic-application"
                component={StatisticApplication}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    user: store.auth.user,
    uiStore: store.ui
  };
}

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => {
    dispatch(getCurrentUser());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
