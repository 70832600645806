import React, {Component} from "react";
import {Link} from "react-router-dom";
import "./style.css";
import ezpLogo from "../../assets/images/logo.png";

class Menu extends Component {
	constructor() {
		super();
		this.state = {}
	}
	
	render() {
		return (
			<div className="container-fluid">
				<nav className="navbar navbar-expand-lg navbar-light nav-menu">
					<Link className="navbar-brand" to="/">
						<img src={ezpLogo} alt="logo"/>
					</Link>
					<button className="navbar-toggler" type="button" data-toggle="collapse"
					        data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
					        aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"/>
					</button>
					
					<div className="collapse navbar-collapse" id="navbarsExample09">
						<ul className="navbar-nav mr-auto menu">
							<li className="nav-item disabled-menu">
								<Link className="nav-link" to="/">HOME</Link>
							</li>
							<li className="nav-item disabled-menu">
								<Link className="nav-link" to="/">INFORMATION</Link>
							</li>
							<li className="nav-item disabled-menu">
								<Link className="nav-link" to="/">SERVICE</Link>
							</li>
							<li className="nav-item disabled-menu">
								<Link className="nav-link" to="/">DETAILED</Link>
							</li>
							<li className="nav-item disabled-menu">
								<Link className="nav-link" to="/">EXAMPLES</Link>
							</li>
							<li className="nav-item disabled-menu">
								<Link className="nav-link" to="/">ENTERPRISE</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/contact">CONTACT</Link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		)
	}
}

export default Menu;