import _ from "lodash";

import { ACTIONS } from "../actions/adminActions";

const initialState = {
  limit: 100,
  page: 1,
  total: 0,
  list: [],
  status: null,
  submitting: null,
  loading: false,
};

const STATUS = {
  GET_DATA_COMPARE_REQUEST: "GET_DATA_COMPARE_REQUEST",
  GET_DATA_COMPARE_SUCCESS: "GET_DATA_COMPARE_SUCCESS",
  GET_DATA_COMPARE_FAILURE: "GET_DATA_COMPARE_FAILURE"
};

export { STATUS };

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_DATA_COMPARE_REQUEST:
      return {
        ...state,
        submitting: action.type,
        loading: true
      };
    case ACTIONS.GET_DATA_COMPARE_SUCCESS:
      const { list, page, total } = action.payload;
      const { limit, status } = action.data;
      let users = list;
      const data = action.data;
      if (data.sort) {
        users = _.orderBy(users, ["changeStatusTimestamp"], ["asc"]);
      }
      return {
        ...state,
        loaded: true,
        submitting: null,

        users, total, limit, page, status
      };
    case ACTIONS.GET_DATA_COMPARE_FAILURE:
      return {
        ...state,
        loading: false,
        submitting: action.type,
        error: action.payload ? action.payload.error : action.payload
      };
    default:
      return state;
  }
};
