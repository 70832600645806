const ACTIONS = {
  TOGGLE_SIDE_BAR: "TOGGLE_SIDE_BAR"
};

const toggleSideBar = () => {
  return dispatch => {
    dispatch({
      type: ACTIONS.TOGGLE_SIDE_BAR
    });
  };
};

export { ACTIONS, toggleSideBar };
