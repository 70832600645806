import { message, Steps, Button } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { compose } from "redux";
import { withLocalize, Translate } from "react-localize-redux";
import { STATUS, verifyToken } from "../../../../actions/registrationActions";
import actions from "../../../../actions/registrationTrippleActions";
import { TRIPLE_STEPS } from "../../../../constants/commonData";
import { parseUrlQuery, Validation } from "../../../../utils/commonUtils";
import RegisterStep1 from './Steps/Step1';
import RegisterStep2 from './Steps/Step2';
import RegisterStep3 from './Steps/Step3';
import RegisterStep4 from './Steps/Step4';
import RegisterStep5 from './Steps/Step5';
import RegisterStep6 from './Steps/Step6';
import RegisterStep7 from './Steps/Step7';
import RegisterStep8 from './Steps/Step8';
import RegisterStep9 from './Steps/Step9';
import RegisterSuccess from './Steps/Success';
import "./style.css";
import VerifyTokenFail from './VerifyTokenFail';
import { businessIds } from '../../../../constants/commonData';

const { Step } = Steps;
const stepValidateImageCard = 4;
let businessId;
const Register = ({
  location,
  verifyToken,
  changeStepRegisterTripple,
  step,
  maxStep,
  status,
  error,
  resetRegisterTrippleError,
  setActiveLanguage,
  activeLanguage
}) => {

  const [businessMemberId, setBusinessMemberId] = useState('');
  const [ validateEmail, setValidateEmail ] = useState(true);
  const [ errorCode, setErrorCode ] = useState('');
  useEffect(() => {
    let params = parseUrlQuery(location.search);
    businessId = params.businessId;
    let emailBusiness;
    let language = localStorage.getItem('LANGUAGE');
    language = businessId === businessIds.GREENBOX.id ? 'jp' : 'cn';
    localStorage.setItem('LANGUAGE', language)
    setActiveLanguage(language);
    let businessToken = params.token || null;
    businessToken = params.token;

    if (businessId === businessIds.TRIPLE.id)
      emailBusiness = params.email;

    verifyToken({ businessToken, businessId }, (status, data) => {
      if(status !== STATUS.STEP1.TOKEN_VERIFY_SUCCESS) {
        setErrorCode(data.code);     
        return;
      }
      const businessMemberId = data.businessMemberId;
      const allRegistration = JSON.parse(localStorage.getItem("REGISTRATION_TRIPLE")) || {};
      const registrationInfo = allRegistration[businessMemberId];

      if (businessId === businessIds.GREENBOX.id)
        emailBusiness = data.email;

      if (!Validation.validateEmail(emailBusiness))
        setValidateEmail(false);
      
      allRegistration[businessMemberId] = {
        maxStep: registrationInfo ? registrationInfo.maxStep : 1,
        step: registrationInfo ? registrationInfo.step : 1,
        applicationInfo: registrationInfo ? {
          ...registrationInfo.applicationInfo,
          businessToken,
          emailBusiness
        } : {
          businessToken,
          emailBusiness
        }
      }

      localStorage.setItem("REGISTRATION_TRIPLE", JSON.stringify({ ...allRegistration }));
      let gotoStep = allRegistration[businessMemberId].maxStep;
      if(gotoStep >= stepValidateImageCard)
        gotoStep = stepValidateImageCard
      changeStepRegisterTripple({step: gotoStep, businessMemberId});
      setBusinessMemberId(businessMemberId)
    });

    return () => {
      setActiveLanguage('en');
    };
  }, []);

  useEffect(() => {
    if (error) {
      resetRegisterTrippleError();
    }
  }, [error]);

  useEffect(() => {
    if (window.innerWidth >= 481) {
      return;
    }
    const elementStep = document.getElementsByClassName('ant-steps ant-steps-horizontal ant-steps-label-horizontal');
    if (elementStep.length) {
      const scrollTo = (step <= 4) ? 0 : 250;
      elementStep[0].scroll(scrollTo, 0);
    }
  }, [step]); 

  const nextStep = (applicationInfo = {}) => {
    const nextStep = step + 1;
    const allRegistration = JSON.parse(localStorage.getItem("REGISTRATION_TRIPLE")) || {};
    const applicationInfoOld = allRegistration[businessMemberId].applicationInfo;

    allRegistration[businessMemberId] = {
      maxStep: nextStep > maxStep ? nextStep : maxStep,
      step: nextStep,
      applicationInfo: {...applicationInfoOld, ...applicationInfo}
    }

    localStorage.setItem("REGISTRATION_TRIPLE", JSON.stringify({ ...allRegistration }));
    changeStepRegisterTripple({step: nextStep, businessMemberId});
  }

  const prevStep = () => {
    changeStepRegisterTripple({step: step - 1, businessMemberId});
  };

  const changeLanguage = (code) => {
    setActiveLanguage(code);
    localStorage.setItem('LANGUAGE', code);
  };

  const renderContentStep = (step) => {
    let contentStep;
    const commonFunc = { nextStep, prevStep, businessId };
    switch (step) {
      case 1:
        contentStep = <RegisterStep1 {...commonFunc} />;
        break;
      case 2:
        contentStep = <RegisterStep2 {...commonFunc} />;
        break; 
      case 3:
        contentStep = <RegisterStep3 {...commonFunc} />;
        break; 
      case 4:
        contentStep = <RegisterStep4 {...commonFunc} />;
        break;  
      case 5:
        contentStep = <RegisterStep5 {...commonFunc} />;
        break; 
      case 6:
        contentStep = <RegisterStep6 {...commonFunc} />;
        break; 
      case 7:
        contentStep = <RegisterStep7 {...commonFunc} />;
        break;
      case 8:
        contentStep = <RegisterStep8 {...commonFunc} />;
        break;
      case 9:
        contentStep = <RegisterStep9 {...commonFunc} />;
        break;
      default:
        contentStep = <Redirect to="/404"/>;
    }
    return contentStep;
  }

  return (
    <div className="container-form-register-triple-page">
      <div className="header">
        <div className="container-fluid container head-page">
          <div className="float-left logo">
            <img src="assets/images/triple/logo.png" alt="" />
          </div>
          <div className="clearfix flags">
            <Button
              type="link"
              onClick={() => changeLanguage('cn')}
              className={activeLanguage && activeLanguage.code === 'cn' ? 'active' : ''}
            >
              <img className='flag' src="assets/images/triple/flags/china.png" alt="Chinese flag" />
            </Button>
            <Button
              type="link"
              onClick={() => changeLanguage('en')}
              className={activeLanguage && activeLanguage.code === 'en' ? 'active' : ''}
            >
              <img className='flag' src="assets/images/triple/flags/united-kingdom.png"  alt="United Kingdom flag" />
            </Button>
            <Button
              type="link"
              onClick={() => changeLanguage('jp')}
              className={activeLanguage && activeLanguage.code === 'jp' ? 'active' : ''}
            >
              <img className='flag' src="assets/images/triple/flags/japan.png"  alt="Japanese flag" />
            </Button>
          </div>
        </div>
      </div>
      <Translate>
        {({translate}) => (
          <div className="container-fluid content-register container">
            {
              step <= TRIPLE_STEPS.length ?
              <div>
                <div className="title">
                  <h4>
                    {
                      status === STATUS.STEP1.TOKEN_VERIFY_FAILED ? 
                        errorCode !== 'APPLICATION_INFO_REGISTERED' ? translate('errors.VERIFY_TOKEN_FAIL') : '' : translate(TRIPLE_STEPS[step-1].title)
                    } 
                  </h4>
                  <div className='description'>
                   { TRIPLE_STEPS[step-1].description}
                  </div>
                </div>
               
              </div>
              : <h4 className='title'>{translate('REGISTERED SUCCESSFULLY')}</h4>
            }
            <div className="inner-content">
              <div className="content">
                { (status === STATUS.STEP1.TOKEN_VERIFY_FAILED || !validateEmail) && <VerifyTokenFail/>}
                { status !== STATUS.STEP1.TOKEN_VERIFY_FAILED && validateEmail && (
                  <Fragment>
                    <div className='step-register'>
                      {
                        step <= TRIPLE_STEPS.length && 
                        <Steps current={step - 1}>
                          {TRIPLE_STEPS.map(item => <Step key={item.step} />)}
                        </Steps>
                      }
                    </div>
                    <div className="steps-content">
                      { 
                        step <= TRIPLE_STEPS.length ? renderContentStep(step) : <RegisterSuccess/>
                      }
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
            {error && message.error(translate(`errors.${error.code}`), 3)}
          </div>
        )}
      </Translate>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    maxStep: state.registrationTripple.maxStep,
    step: state.registrationTripple.step,
    status: state.registration.status,
    error: state.registrationTripple.error,
  };
};

export default compose(
  withLocalize,
  withRouter,
  connect(
    mapStateToProps,
    {
      verifyToken: verifyToken,
      changeStepRegisterTripple: actions.changeStepRegisterTripple,
      resetRegisterTrippleError: actions.resetRegisterTrippleError,
    }
  )
)(Register);
