import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import './style.css';

class RegisterForm extends React.Component {

    render() {
        return (
            <div className="container-pages-update-form">
                <div className="header">eZ-pay Update Some Wrong Information Form</div>
                {this.props.children}
            </div>
        );
    }
}

export default withRouter(connect()(RegisterForm));
