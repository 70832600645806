import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { Switch, Route } from "react-router-dom";
import {
  Page,
  Container,
  Button,
  Input,
  Select
} from "../../../../components/antd";
import {
  setApplicationStoreParams,
  getApplications,
  resetAdminPage,
  resetApplicationError,
  ACTIONS,
  getCurrentUser
} from "../../../../actions/adminActions";
import Alert from "../../../../components/Alert";

import WaitSubcriptionPayment from "./WaitSubcriptionPayment";
import PaymentCompletedPending from "./PaymentCompletedPending";
import PaymentCheckedComplete from "./PaymentCheckedComplete";
import BeforeConfirmAddress from "./BeforeConfirmAddress";
import ConfirmAddress from "./ConfirmingAddress";
import BeforeSentCard from "./BeforeSentCard";
import ConfirmedPreparingShipment from "./ConfirmedPreparingShipment";
import Reserve from "./Reserve";
import CompletedSentCard from "./CompletedSentCard";
import Reconfirming from "./Reconfirming";
import ReconfirmForChecked from "./ReconfirmForChecked";
import InputCardsApplication from "./InputCardsApplication";
import RefundingApplication from "./RefundingApplication";
import RefundedApplication from "./RefundedApplication";
import ListPrintCard from "./ListPrintCard";
import CardValid from "./CardValid";
import CardUpdate from "./CardUpdate";
import { STATUS as STATUSES } from "../../../../reducers/adminReducer";
import ConfirmedReturn from "./confirmed_return";
import ConfirmedPreparingReshipment from "./confirmed_preparing_reshipment";
import "./style.css";

function ApplicationStatus({
  resetApplicationStore,
  setApplicationStoreParams,
  getApplications,
  applicationStore: {
    boxes,
    submitting,
    status,
    params,
    error,
    applicationUpdateFails,
    recordImport,
    recordFail,
    errorNotEnoughCard
  },
  location,
  resetAdminPage,
  resetApplicationError
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [title, setTitle] = useState("");
  const [showBox, setShowBox] = useState(false);

  useEffect(() => {
    return () => {
      resetAdminPage();
    };
  }, []);

  useEffect(() => {
    resetAdminPage();
  }, [location.pathname]);

  const _onSearchFieldChange = e => {
    setApplicationStoreParams({
      [e.target.name]: e.target.value,
      type: "params"
    });
  };

  const _onSearch = () => {
    setApplicationStoreParams({
      page: 1,
      type: "params"
    });
    setIsSearching(true);

    let data = {
      ...params,
      page: 1,
      type: "search"
    };
    getApplications(data, () => {
      setIsSearching(false);
    });
  };

  const commonFunc = {
    setTitle: setTitle,
    setShowBox: setShowBox,
  };

  const hasError =
    [
      ACTIONS.GET_APPLICATIONS_FAILURE,
      ACTIONS.IMPORT_AND_UPDATE_STATUS_FAILURE
    ].indexOf(submitting) >= 0 ||
    [
      STATUSES.CHANGE_APPLICATION_STATUS_FAIL,
      STATUSES.UPDATE_APPLICATION_INFO_FAIL,
      STATUSES.SEND_MAIL_CONFIRM_ADDRESS_FAIL,
      STATUSES.EXPORT_PDF_APPLICATION_FAIL
    ].indexOf(status) >= 0;

  return (
    <Page className="application-situation">
      <Container>
        <div className="tool-box">
          <p className="title">{title}</p>
          <div className="right-box">
            {showBox && (
              <Select
                className="search-type"
                options={boxes}
                placeholder="選択ボックス"
                modern
                onChange={_onSearchFieldChange}
                name="box"
                value={params.box}
                allowClear
              />
            )}
              <Input
                modern
                className="search-input"
                placeholder="ID, MEMBERID, EMAIL, NAME, PASSPORT NUMBER"
                name="str"
                onChange={_onSearchFieldChange}
                value={params.str}
              />
              <Button loading={isSearching} onClick={_onSearch}>
                検索
              </Button>
          </div>
        </div>
        <Switch>
          <Route
            path="/admin/application-status/wait-subcription-payment"
            render={() => <WaitSubcriptionPayment {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/payment-completed-pending"
            render={() => <PaymentCompletedPending {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/payment-checked-complete"
            render={() => <PaymentCheckedComplete {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/input-cards-application"
            render={() => <InputCardsApplication {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/before-confirm-address"
            render={() => <BeforeConfirmAddress {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/confirming-address"
            render={() => <ConfirmAddress {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/before-sent-card"
            render={() => <BeforeSentCard {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/confirmed-preparing-shipment"
            render={() => <ConfirmedPreparingShipment {...commonFunc} />}
          />
           <Route
            path="/admin/application-status/confirmed_return"
            render={() => <ConfirmedReturn {...commonFunc} />}
          />
           <Route
            path="/admin/application-status/confirmed_preparing_reshipment"
            render={() => <ConfirmedPreparingReshipment {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/completed-sent-card"
            render={() => <CompletedSentCard {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/reconfirm-for-unchecked"
            render={() => <Reconfirming {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/reconfirm-for-checked"
            render={() => <ReconfirmForChecked {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/refunding"
            render={() => <RefundingApplication {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/refunded"
            render={() => <RefundedApplication {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/reserve-list"
            render={() => <Reserve {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/list-print-card"
            render={() => <ListPrintCard {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/card-valid"
            render={() => <CardValid {...commonFunc} />}
          />
          <Route
            path="/admin/application-status/card-update"
            render={() => <CardUpdate {...commonFunc} />}
          />
        </Switch>
      </Container>
      <Translate>
        {({ translate }) =>
          hasError && (
            <Alert
              title="Error"
              content={translate(error && "errors." + error.code)}
              okTitle="OK"
              onOK={() => {
                resetApplicationError();
              }}
            />
          )
        }
      </Translate>
      {status === STATUSES.CHANGE_APPLICATION_STATUS_SUCCESS &&
        !errorNotEnoughCard && (
          <Alert
            title="成功"
            content={
              applicationUpdateFails && applicationUpdateFails.length === 0
                ? "状況を変更しました"
                : "一部のアプリケーション更新が失敗する " +
                  applicationUpdateFails.toString()
            }
            okTitle="OK"
            onOK={() => {
              resetApplicationError();
            }}
          />
        )}
      {status === STATUSES.CHANGE_APPLICATION_STATUS_SUCCESS &&
        errorNotEnoughCard && (
          <Alert
            title="Error"
            content={"Not enough remaining card in stock. Please input card!"}
            okTitle="OK"
            onOK={() => {
              resetApplicationError();
            }}
          />
        )}
      {submitting === ACTIONS.IMPORT_AND_UPDATE_STATUS_SUCCESS && (
        <Alert
          title="成功"
          content={
            <div>
              {`${recordImport}枚のカード情報が更新されます。`}
              <br />
              <br />
              {`${recordFail}枚のカード情報が更新されません。`}
            </div>
          }
          okTitle="OK"
          onOK={() => {
            resetApplicationError();
          }}
        />
      )}
    </Page>
  );
}

const mapStateToProps = state => {
  return {
    applicationStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getApplications: (data, callback) => {
      dispatch(getApplications(data, callback));
    },
    setApplicationStoreParams: data => {
      dispatch(setApplicationStoreParams(data));
    },
    resetAdminPage: () => {
      dispatch(resetAdminPage());
    },
    resetApplicationError: () => {
      dispatch(resetApplicationError());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationStatus);
