import { Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { Translate } from "react-localize-redux";
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import { connect } from 'react-redux';
import actions, { ACTIONS } from '../../../../../../actions/registrationTrippleActions';
import { Button } from '../../../../../../components/antd';
import CodeInput from '../../../../../../components/CodeInput';
import { applicationInfoOnLocal } from '../../../../../../utils/commonUtils';
import { businessIds } from '../../../../../../constants/commonData';
import './style.css';

function Registration(props) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [codeSuccess, setCodeSuccess] = useState(false);
  const [activeCode, setActiveCode] = useState('');
  const [error, setError] = useState('');
  const { type } = props.registerTrippleStore;
  const businessId = props.businessId;

  useEffect(() => {
    const { maxStep, businessMemberId } = props.registerTrippleStore;

    if (maxStep > 2) {
        const applicationInfo = applicationInfoOnLocal(businessMemberId);
        const phone = applicationInfo.phoneNumber ? applicationInfo.phoneNumber.replace('-', '') : '';
        setPhoneNumber(phone);
      }
  }, []);

  const handleOnChangePhoneNumber = value => {
    value = value.replace(/\s/g, '');
    value = value.includes('+') ? value : `+${value}`;
    setPhoneNumber(value);
    if (!value) {
      setError('Phone number is required');
    } else if (error) {
      setError('');
    }
  };

  const handleOnChangeCode = value => {
    if (!value) {
      setError('Code verify is required');
    } else if (error) {
      setError('');
    }
    setActiveCode(value);
  };

  const validateActiveCode = () => {
    if (!activeCode) {
      setError('Code verify is required');
      return false;
    }
    if (activeCode.length !== 4) {
      setError('Code verify is invalid');
      return false;
    }
    setError('');
    return true;
  };

  const validatePhoneNumber = () => {
    if (!phoneNumber) {
      setError('Phone number is required');
      return false;
    }
    if (!phoneNumber || phoneNumber === '+') {
      setError('Phone number invalid');
      return false;
    }
    setError('');
    return true;
  };

  const handleOnVerifyCode = () => {
    if (!validateActiveCode()) {
      return;
    }

    const {
      verifyPhoneCode,
      registrationStore: { businessMemberId },
      nextStep
    } = props;
    const telePhone = phoneNumber.replace(/\-+/g, '-');
    verifyPhoneCode(
      { businessMemberId, activeCode, phoneNumber: telePhone },
      (action, response) => {
        if (action === ACTIONS.VERIFY_PHONE_CODE_SUCCESS) {
          nextStep({ phoneNumber: telePhone });
        }
      }
    );
  };

  const sendCode = async () => {
    if (!validatePhoneNumber()) {
      return;
    }

    const elementCountryCode = document.getElementsByClassName('selected-flag')[0];
    const countryCode = `+${elementCountryCode.title.split(' ').pop()}`;

    let phone = phoneNumber.split(countryCode)[1];
    phone = `${countryCode}-${phone}`;
    setPhoneNumber(phone);
    const {
      sendVerifyPhoneNumber,
      registrationStore: { businessMemberId },
    } = props;

    sendVerifyPhoneNumber(
      { businessMemberId, phoneNumber: phone.replace(/\-+/g, '-')},
      (action, response) => {
        if (action === ACTIONS.SEND_VERIFY_PHONE_NUMBER_SUCCESS) {
          setCodeSuccess(true);
        }
      }
    );
  }; 
  return (
    <Translate>
      {({translate}) => (
        <div className="phone-code-container center-content el-card">
          {!codeSuccess ? (
            <div className="phone-number-container phone-content">
              <ReactPhoneInput
                countryCodeEditable={false}
                defaultCountry={businessId === businessIds.TRIPLE.id ? 'cn' : 'jp'}
                value={phoneNumber}
                autoFormat={false}
                enableSearchField
                onChange={handleOnChangePhoneNumber}
                searchClass="searchClass"
                dropdownClass="dropdownClass"
                disabled={type === ACTIONS.SEND_VERIFY_PHONE_NUMBER_REQUEST}
              />
              {error && <p className="error">{error}</p>}
              <Button
                type="default"
                className="submit"
                onClick={sendCode}
                loading={type === ACTIONS.SEND_VERIFY_PHONE_NUMBER_REQUEST}
              >
                {translate('Send verify code')}
              </Button>
            </div>
          ) : (
            <div className="active-code-container phone-content">
              <h4>{translate('Enter verify code')}</h4>
              <Alert message={translate('Get code in your phone to active')} type="success" />
              <CodeInput
                type="number"
                count={4}
                onInputNotFinish={() => {
                  handleOnChangeCode(null);
                }}
                onInputFinish={value => {
                  handleOnChangeCode(value);
                }}
                onInputChange={value => {
                  handleOnChangeCode(value);
                }}
                disabled={type === ACTIONS.VERIFY_PHONE_CODE_REQUEST}
              />
              {error && <p className="error">{error}</p>}
              <div className="group-button-verify">
                <Button
                  type="delete"
                  onClick={() => setCodeSuccess(false)}
                  className="submit"
                  disabled={type === ACTIONS.VERIFY_PHONE_CODE_REQUEST}
                >
                  {translate('Back')}
                </Button>
                <Button
                  type="default"
                  onClick={handleOnVerifyCode}
                  className="submit"
                  loading={type === ACTIONS.VERIFY_PHONE_CODE_REQUEST}
                >
                  {translate('Submit')}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </Translate>
  );
}

export default connect(
  state => ({
    registerTrippleStore: state.registrationTripple,
    registrationStore: state.registration
  }),
  {
    sendVerifyPhoneNumber: actions.sendVerifyPhoneNumber,
    verifyPhoneCode: actions.verifyPhoneCode
  }
)(Registration);
