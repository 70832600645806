import {ACTIONS} from '../actions/authActions';
import {STATUS as REG_STATUS, ACTIONS as REG_ACTIONS} from '../actions/registrationActions';
import {saveToken, removeToken, removeUserRegisterToken} from "../utils/apiUtils";


const initialState = {
    status: null,
    user: Number(localStorage.getItem("TOKEN_EXPIRATION") || 0) < Date.now() ? null : localStorage.getItem("TOKEN"),
    authError: null,
};


const STATUS = {
    LOGIN_PROGRESS: "LOGIN_PROGRESS",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",
};

export {STATUS};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_STATUS:
            switch (action.data.status) {
                case STATUS.LOGIN_PROGRESS:
                    return {
                        ...state,
                        status: action.data.status
                    };
                case STATUS.LOGIN_SUCCESS:
                    saveToken(action.data.statusInfo);
                    return {
                        ...state,
                        status: action.data.status,
                        user: action.data.statusInfo
                    };
                case STATUS.LOGIN_FAILED:
                    return {
                        ...state,
                        status: action.data.status,
                        loginError: action.data.statusInfo.error
                    };
                default:
                    return state;
            }
        case REG_ACTIONS.REG_UPDATE_STATUS:
            switch (action.data.status) {
                case REG_STATUS.STEP4.SEND_MAIL_SUCCESS:
                case REG_STATUS.STEP4.PAYMENT_FINISHED:
                    removeUserRegisterToken();
                    return {
                        ...state,
                        user: null
                    };
                default:
                    return state;
            }
        case ACTIONS.LOGOUT:
            removeToken();
            return {
                ...state,
                user: null
            };
        default:
            return state;
    }
}
