import React, { Fragment } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  getAllLocation,
  resetAdminPage,
  setApplicationStoreParams
} from '../../../../actions/adminActions';
import Alert from '../../../../components/Alert';
import {
  Column,
  Container,
  Page,
  PaginationBar,
  Table
} from '../../../../components/antd';
import { STATUS as STATUSES } from '../../../../reducers/adminReducer';
import { formatDateTimeFull } from '../../../../utils/commonUtils';
import './style.css';

class LogIp extends React.Component {
  constructor() {
    super();
    this.state = {
      logIp: [],
      isShowModal: false,
      alertAck: true,
      activePage: 1
    };
    this._columns = [
      {
        title: 'No',
        align: 'center',
        width: 60,
        key: 'no',
        render: (cell, record, index) => index + 1
      },
      {
        key: 'adminId',
        width: 120,
        title: 'Admin Id',
        dataIndex: 'adminId'
      },
      {
        key: 'ip',
        width: 150,
        title: 'IP',
        dataIndex: 'ip'
      },
      {
        key: 'content',
        width: 200,
        title: 'Location',
        render: (iem, record) => (
          <Fragment>
            {record.local.city ? <div>{`${record.local.city}, ${record.local.country}`}</div> : <div>{record.local}</div>}
          </Fragment>
        )
      },
      {
        align: 'center',
        width: 200,
        key: 'Timestamps',
        title: 'Timestamps',
        render: (cell, record) => formatDateTimeFull(record.createdAt)
      }
    ];
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.alertAck();
    this._getAllLocation({
      page: 1,
      limit: 100,
      str: ''
    });
  }

  componentWillUnmount() {
    const { resetAdminPage } = this.props;
    resetAdminPage();
  }

  _getAllLocation = newParams => {
    const {
      getAllLocation,
      setApplicationStoreParams,
      adminStore: { params }
    } = this.props;

    let payload = {
      ...params,
      ...newParams
    };
    setApplicationStoreParams({
      ...newParams,
      type: 'params'
    });

    getAllLocation(payload);
  };

  _onPaginationBarChange = (type, value) => {
    if (type === 'page') {
      this._getAllLocation({ page: value });
    } else {
      this._getAllLocation({ page: 1, limit: value });
    }
  };

  _onSearchFieldChange = e => {
    const { setApplicationStoreParams } = this.props;
    setApplicationStoreParams({
      [e.target.name]: e.target.value,
      type: 'params'
    });
  };

  render() {
    const {
      adminStore: { logIp, status, error, params }
    } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <Page className="log-ip-container">
            <Container>
              <div className="tool-box">
                <p className="title">History login</p>
              </div>
              {!!params.total && (
                <div className="top-action-box">
                  <div className="button-group">
                    <PaginationBar
                      current={params.page}
                      total={params.total}
                      onChange={this._onPaginationBarChange}
                      pageSize={params.limit}
                    />
                  </div>
                </div>
              )}
              <Table
                loading={status === STATUSES.GET_LOG_LOCATION_PROGRESS}
                rowKey={(row, index) => index}
                columns={this._columns}
                dataSource={logIp}
                scroll={{ x: 800, y: 'calc(100vh - 263px)' }}
              />
              {[STATUSES.GET_LOG_LOCATION_FAIL].indexOf(status) >= 0 &&
                !this.state.alertAck && (
                  <Alert
                    title="Error"
                    content={translate(error && 'errors.' + error.code)}
                    okTitle="OK"
                    onOK={() => {
                      this.setState({ alertAck: true });
                    }}
                  />
                )}
            </Container>
          </Page>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    adminStore: state.admin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllLocation: data => {
      dispatch(getAllLocation(data));
    },
    setApplicationStoreParams: data => {
      dispatch(setApplicationStoreParams(data));
    },
    resetAdminPage: () => {
      dispatch(resetAdminPage());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogIp);
