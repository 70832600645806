import {ACTIONS} from '../actions/statisticApplicationAction';

const initialState = {
    status: null,
    error: null,
    statistic: [],
    currentFilter: null
};

const STATUS = {
    STATISTIC_APPLICATION_PROGRESS: "STATISTIC_APPLICATION_PROGRESS",
    STATISTIC_APPLICATION_SUCCESS: "STATISTIC_APPLICATION_SUCCESS",
    STATISTIC_APPLICATION_FAILED: "STATISTIC_APPLICATION_FAILED",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_STATUS:
            switch (action.data.status) {
                case STATUS.STATISTIC_APPLICATION_PROGRESS:
                    return {
                        ...state,
                        status: action.data.status
                    };
                case STATUS.STATISTIC_APPLICATION_SUCCESS:
                    return {
                        ...state,
                        status: action.data.status,
                        statistic: action.data.statusInfo.data
                    };

                case STATUS.STATISTIC_APPLICATION_FAILED:
                    return {
                        ...state,
                        status: action.data.status,
                        error: action.data.statusInfo.error
                    };
                default:
                    return state;
            }
        case ACTIONS.CHANGE_FILTER:
            return {
                ...state,
                currentFilter: action.data
            }
        default:
            return state;
    }
}

export {STATUS};

