import {callApi} from "../utils/apiUtils";
import {STATUS} from '../reducers/authReducer';

const ACTIONS = {
    UPDATE_STATUS: "UPDATE_STATUS",
    LOGOUT: "LOGOUT",
    NOT_AUTHENTICATED_ERROR: "NOT_AUTHENTICATED_ERROR",
    TOKEN_EXPIRED: "TOKEN_EXPIRED",
};

const updateStatus = (status, statusInfo) => {
    return {
        type: ACTIONS.UPDATE_STATUS,
        data: {
            status,
            statusInfo
        }
    }
};

const login = (loginData, callback = () => null) => {    
    let config = {
        method: 'post',
        body: {email: loginData.username, password: loginData.password}
    };    
    return (dispatch) => {
        dispatch(updateStatus(STATUS.LOGIN_PROGRESS));
        callApi('/api/login',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.LOGIN_SUCCESS, data));
                callback(STATUS.LOGIN_SUCCESS);
            },
            (err) => {
                dispatch(updateStatus(STATUS.LOGIN_FAILED, {error: err}));
            }
        );
    };
};

const logout = () => {
    return {
        type: ACTIONS.LOGOUT
    }
};

export {ACTIONS, login, logout};