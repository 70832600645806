import React, { Component } from "react";
import { FormGroup, Input, Button, Form, Col, Label } from "reactstrap";
import "./style.css";

export default class CommissionSettings extends Component {

    render() {
        return (
            <div className="commission-setting-page">
                <div className="d-flex align-items-center mb-4">
                    <div className="title h-40">Commission settings</div>
                </div>
                <div>
                    <Form>
                        <FormGroup row>
                            <FormGroup className="col-12 d-flex ">
                                <Label for="webSettlementFee" sm={4}>WEB Settlement fee</Label>
                                <Col sm={4}>
                                    <Input type="text" name="webSettlementFee" id="webSettlementFee" />
                                </Col>
                                <Label for="webFee" sm={1}>%</Label>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup row>
                            <FormGroup className="col-12 d-flex">
                                <Label for="posSettlementFee" sm={4}>POS Settlement fee</Label>
                                <Col sm={4}>
                                    <Input type="text" name="posSettlementFee" id="posSettlementFee" />
                                </Col>
                                <Label for="posFee" sm={1}>%</Label>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup row>
                            <FormGroup className="col-12 d-flex">
                                <Label for="amtWithdrawalFee" sm={4}>ATM Withdrawal fee</Label>
                                <Col sm={4}>
                                    <Input type="text" name="amtWithdrawalFee" id="amtWithdrawalFee" />
                                </Col>
                                <Label for="amtFee" sm={1}>%</Label>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup row>
                            <FormGroup className="col-12 d-flex">
                                <Label for="transferFee" sm={4}>Transfer fee</Label>
                                <Col sm={4}>
                                    <Input type="text" name="transferFee" id="transferFee" />
                                </Col>
                                <Label for="transFee" sm={1}>%</Label>
                            </FormGroup>
                        </FormGroup>

                        <FormGroup row>
                            <FormGroup className="col-12 d-flex align-items-center ">
                                <Label className="label-setting" sm={4} for="transferFee" >GBT rate</Label>
                                <Col sm={1} >
                                    <Label check><Input type="checkbox" />  {' '}Manual </Label>
                                </Col>
                                <Col sm={2}>
                                    <Input type="text" name="gbtRate" id="gbtRate" />
                                </Col>
                                <Col sm={1}>
                                    <Label check><Input type="checkbox" />  {' '}Auto </Label>
                                </Col>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup row>
                            <FormGroup className="col-12 d-flex align-items-center ">
                                <Label className="label-setting" sm={4} for="transferFee" >USD rate</Label>
                                <Col sm={1} >
                                    <Label check><Input type="checkbox" />  {' '}Manual </Label>
                                </Col>

                                <Col sm={2}>
                                    <Input type="text" name="gbtRate" id="gbtRate" />
                                </Col>
                                <Col sm={1}>
                                    <Label check><Input type="checkbox" />  {' '}Auto </Label>
                                </Col>
                            </FormGroup>
                        </FormGroup>
                    </Form>
                    <div className="d-flex justify-content-center">
                        <Button outline color="secondary" className="custom-btn" >OK</Button>
                    </div>
                </div>
            </div>
        )
    }
}