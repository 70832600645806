import React, {Component} from "react";
import {Radio, Button, CircularProgress, TextField, FormLabel, FormControlLabel} from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import {connect} from "react-redux";
import {Translate} from 'react-localize-redux';
import {DateTime, confirmDataList, commonFunctions, cardData, businessIds} from "../../constants/commonData";
import ContentPanel from "../../components/ContentPanel";
import ConfirmList from "../../components/ConfirmList";
import Alert from "../../components/Alert";
import Dialog from "../../components/Dialog";
import { Button as ButtonAnt, Modal } from "../../components/antd";
import {updateUserInfo, STATUS, sendInstructionEmail} from "../../actions/registrationActions";
import {
    updateApplicationInfo,
    changeApplicationStatus,
    noteSubmit,
    sendMailBlurPassport,
    sendMailWrongFullName,
    sendMailWrongPassportExpired,
    sendMailWrongPhoneNumber,
    sentMailConfirmAddress,
    sendMailBlurSelfie,
    sendMailWrongAddressDoc,
} from "../../actions/adminActions";
import {STATUS as ADMIN_STATUS} from "../../reducers/adminReducer";
import {getEnv} from "../../env";
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import "./style.scss";
import {
    STATUS as STATUS_APP,
    TYPE_SEND_MAIL,
    allProfession,
    allCountries,
    Validation,
    getBase64File,
    arrayImage,
    PERMISSION,
    setValueImage
} from "../../utils/commonUtils";
import { Popconfirm } from "antd";

const Compress = require('compress.js')
const compress = new Compress()

const FIELDS_REGISTRATION = {
    GREENBOX: {
        idNumber: {label: 'KYC number'},
        issueDate: {label: 'KYC of issue'},
        expiryDate: {label: 'KYC of expiry'},
        issuePlace: {label: ' KYC issuing place'},
        imageCard: {label: 'KYC image upload'},
    },
    TRIPLE: {
        idNumber: {label: 'Identity Card Number'},
        issueDate: {label: 'Identity Card Issue Date'},
        expiryDate: {label: 'Identity Card Expriry Date'},
        issuePlace: {label: 'Identity Card Issue Place'},
        imageCard: {label: 'Identity Card Image Font'}
    }
}

const nationalityOptions = [
    { code: 'CHN', name: 'China'},
    { code: 'TWN', name: 'Taiwan, Province of China'},
    { code: 'HKG', name: 'Hong Kong'},
    { code: 'MAC', name: 'Macao'},
    { code: 'JPN', name: 'Japan'}
];

class RegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = props.data || {
            firstname: "",
            lastname: "",
            maleGender: "male",
            birthYear: -1,
            birthMonth: -1,
            birthDate: -1,
            phoneNumber: "",

            country: -1,
            postCode: "",
            prefecture: "",
            city: "",
            afterAddress: "",
            buildingName: "",
            profession: "",
            nationality: -1,
            email: "",

            passportNumber: "",
            passportIssueYear: -1,
            passportIssueMonth: -1,
            passportIssueDate: -1,
            passportExpiryYear: -1,
            passportExpiryMonth: -1,
            passportExpiryDate: -1,
            passportPlaceIssued: -1,
            passportImage: null,
            KYC_1: null,
            KYC_1_OLD: null,
            KYC_2: null,
            KYC_2_OLD: null,
            KYC_3: null,
            KYC_3_OLD: null,
            KYC_4: null,
            KYC_4_OLD: null,
            showPin1: false,
            showPin2: false,
            showConfirmPin1: false,
            showConfirmPin2: false,
            showPasswordCard: false,
            showConfirmPasswordCard: false,
            passwordCard: "",
            confirmPasswordCard: "",
            cards: [{baseCurrency: "USD", pin: "", confirmPin: ""}],
            confirmList: confirmDataList.map((item) => {
                return Object.assign({}, item);
            }),

            // confirmChecked: false,
            fullyChecked: false,
            isSubmitted: false,
            alertAck: true,
            isShowPassportInstruction: false,
            previewPassportImage: null,
            nameImage: null,
        };
        this.countries = this.state.isAdminCreated ? allCountries : getEnv('ALLOWED_ADDRESS_COUNTRIES').filter(c => ['JPN'].indexOf(c.code) >= 0);
        this.countriesAdmin = this.state.isAdminCreated ? allCountries : getEnv('ALLOWED_ADDRESS_COUNTRIES');
        this.passportCountries = this.state.isAdminCreated ? allCountries : getEnv('ALLOWED_PASSPORT_COUNTRIES').filter(c => ['JPN'].indexOf(c.code) >= 0);
        this.passportCountriesAdmin = this.state.isAdminCreated ? allCountries : getEnv('ALLOWED_PASSPORT_COUNTRIES');
        this.arrImage = arrayImage(this.state);
    }

    componentDidMount() {
        this.setState({
            ...this.props.data
        })
    }

    alertAck = () => {
        this.setState({alertAck: false});
    };

    handleChange(name) {
        let {cards} = this.state;
        return async (e) => {
            if (e.target) {
                this.setState({[name]: e.target.value}, () => {
                    this.validateRegisterForm();
                });
            }

            if (e.target.files) {
                const file = e.target.files.length ? e.target.files[0] : null;
                if (!file) {
                    return;
                }

                const files = [...e.target.files];
                let uploadableFiles = [];
                compress.compress([...e.target.files], {
                    quality: 1, // the quality of the image, max is 1,
                }).then(async (modFiles) => {
                    for (let i = modFiles.length - 1; i >= 0; i--) {
                        let previewPassportImage = await getBase64File(Compress.convertBase64ToFile(modFiles[i].data, modFiles[i].ext))
                        let newFile = Compress.convertBase64ToFile(modFiles[i].data);
                        let filename = Date.now() + modFiles[i].alt;
                        let fileType = file.type;
                        let fileLastMod = files[i].lastModified;

                        uploadableFiles.push(new File([newFile], filename, {type: fileType, lastModified: fileLastMod}));

                        const nameImage = name;
                        this.setState({[name]: file}, () => {
                            this.validateRegisterForm();
                        });

                        this.setState({
                            [name]: uploadableFiles[0],
                            [name+'_OLD']: file,
                            previewPassportImage,
                            nameImage
                        });

                        this.props.changeImage({
                            linkImage: previewPassportImage,
                            nameImage:name,
                        });
                    }
                }).catch(async () => {
                    const previewPassportImage = await getBase64File(file);
                    const nameImage = name;
                    this.setState({[name]: file}, () => {
                        this.validateRegisterForm();
                    });
                    this.setState({
                        [name]: file,
                        previewPassportImage,
                        nameImage
                    });

                    this.props.changeImage({
                        linkImage: previewPassportImage,
                        nameImage:name,
                    });
                })
            }

            if (name === "selectedCard") {
                if (e.target.value === "1") {
                    cards = [{baseCurrency: "USD", pin: "", confirmPin: ""}];
                }

                if (e.target.value === "2") {
                    cards = [{baseCurrency: "USD", pin: "", confirmPin: ""}, {
                        baseCurrency: "USD",
                        pin: "",
                        confirmPin: ""
                    }]
                }
                this.setState({cards: cards});
            }
        }
    }

    handlePINChange(index) {
        let {cards} = this.state;
        return (e) => {
            if (e.target) {
                cards[index].pin = e.target.value;
                this.setState({cards: cards}, () => {
                    this.validateRegisterForm();
                });
            }
        }
    }

    handleConfirmPINChange(index) {
        let {cards} = this.state;
        return (e) => {
            if (e.target) {
                cards[index].confirmPin = e.target.value;
                this.setState({cards: cards}, () => {
                    this.validateRegisterForm();
                });
            }
        }
    }

    handleRegisterSubmit(e) {
        this.alertAck();
        e.preventDefault();
        let data = {
            applicationId: this.state._id,
            businessToken: this.props.businessToken,
            businessId: this.props.businessId,
            firstname: this.state.firstname.trim(),
            lastname: this.state.lastname.trim(),
            maleGender: this.state.maleGender,
            birthYear: this.state.birthYear,
            birthMonth: this.state.birthMonth,
            birthDate: this.state.birthDate,
            phoneNumber: this.state.phoneNumber,
            profession: this.state.profession,

            country: this.state.country,
            postCode: this.state.postCode,
            prefecture: this.state.prefecture,
            city: this.state.city,
            afterAddress: this.state.afterAddress,
            buildingName: this.state.buildingName,
            nationality: this.state.nationality,

            passportNumber: this.state.passportNumber,
            passportIssueYear: this.state.passportIssueYear,
            passportIssueMonth: this.state.passportIssueMonth,
            passportIssueDate: this.state.passportIssueDate,
            passportExpiryYear: this.state.passportExpiryYear,
            passportExpiryMonth: this.state.passportExpiryMonth,
            passportExpiryDate: this.state.passportExpiryDate,
            passportPlaceIssued: this.state.passportPlaceIssued,
            passportImage: this.state.passportImage,
            KYC_1: this.state.KYC_1,
            KYC_2: this.state.KYC_2,
            KYC_3: this.state.KYC_3,
            KYC_4: this.state.KYC_4,

            passwordCard: this.state.passwordCard,
            confirmPasswordCard: this.state.confirmPasswordCard,
            cards: this.state.cards,
            email: this.state.email,

            confirmList: this.state.confirmList,
        };

        this.setState({isSubmitted: true});
        this.props.updateUserInfo(data);
    }

    validateRegisterForm() {
        let {
            firstname, lastname, birthYear, birthMonth, birthDate, country, phoneNumber, postCode,
            prefecture, city, afterAddress, passportNumber, passportIssueYear, passportIssueMonth,
            passportIssueDate, passportExpiryYear, passportExpiryMonth, passportExpiryDate, passportPlaceIssued,
            passportImage, passwordCard, cards, confirmPasswordCard, profession, firstNameChinese, lastNameChinese, email,
            KYC_1, KYC_2, KYC_3, KYC_4, nationality
        } = this.state;
        const { isUpdate, data } = this.props;
        const businessId = data ? data.businessId : this.props.businessId || businessIds.GREENBOX.id;
        const isTriple = businessId === businessIds.TRIPLE.id;
        let item = {
            firstname, lastname, birthYear, birthMonth, birthDate, country, phoneNumber, postCode,
            city: city && city.trim(),
            profession: profession && profession.trim(),
            afterAddress: afterAddress && afterAddress.trim(),
            passportNumber, passportIssueYear, passportIssueMonth, passportIssueDate,
            passportExpiryYear, passportExpiryMonth, passportExpiryDate, passportPlaceIssued,
            passportImage, passwordCard, cards, KYC_1, KYC_3, KYC_4, nationality, email
        };

        if (!isUpdate) {
            item.confirmPasswordCard = confirmPasswordCard;
        }
        if (isUpdate && !profession) {
            item.profession = 'profession';
        }

        let fullyChecked = !Object.keys(item).some((key) => {
            return item[key] === '' || item[key] === null || item[key] === undefined || item[key] === -1 || item[key] === "-1";
        });

        fullyChecked = fullyChecked
            && commonFunctions.isNumeric(phoneNumber)
            && Validation.validateName(firstname)
            && phoneNumber.length <= 18
            && Validation.validateName(lastname)
            && Validation.validateAfterAddress(afterAddress)
            && Validation.validateAddress(city)
            && Validation.validateAddress(this.state.buildingName)
            && !(this.state.buildingName && !this.state.buildingName.trim())
            && Validation.validatePhoneNumber(phoneNumber)
            && Validation.checkDate(birthYear, birthMonth, birthDate)
            && Validation.checkDate(passportIssueYear, passportIssueMonth, passportIssueDate)
            && passportImage && Validation.validateUploadFileTripleSize(passportImage) === 0
            && KYC_1 && Validation.validateUploadFileTripleSize(KYC_1) === 0 && Validation.validateUploadFileType(KYC_1) === 0
            && KYC_3 && Validation.validateUploadFileTripleSize(KYC_3) === 0 && Validation.validateUploadFileType(KYC_3) === 0
            && KYC_4 && Validation.validateUploadFileTripleSize(KYC_4) === 0 && Validation.validateUploadFileType(KYC_4) === 0
            && Validation.validateEmail(email)

        if (isTriple && prefecture) {
            fullyChecked = fullyChecked && Validation.validateAddress(prefecture);
        } else if (!isTriple) {
            fullyChecked = fullyChecked && prefecture && Validation.validateAddress(prefecture);
        }

        if (KYC_2)
            fullyChecked = fullyChecked && Validation.validateUploadFileTripleSize(KYC_2) === 0 && Validation.validateUploadFileType(KYC_2) === 0;

        if (firstNameChinese)
            fullyChecked = fullyChecked && Validation.validateChineseName(firstNameChinese);

        if (lastNameChinese)
            fullyChecked = fullyChecked && Validation.validateChineseName(lastNameChinese);

        if (passportExpiryDate !== ' ') {
            fullyChecked == fullyChecked
            && Validation.checkDate(passportExpiryYear, passportExpiryMonth, passportExpiryDate)
        }

        if (!isUpdate) {
            fullyChecked = fullyChecked && !this.state.confirmList.some((item) => {
                return !item.checked;
            });

            for (let i = 0; i < cards.length; i++) {
                fullyChecked = fullyChecked &&
                    cards[i].pin.length === 6 &&
                    cards[i].confirmPin.length === 6 &&
                    Validation.validateNumber(cards[i].pin) &&
                    Validation.validateNumber(cards[i].confirmPin) &&
                    Validation.checkSamePassword(cards[i].pin, cards[i].confirmPin);
            }
            fullyChecked = fullyChecked
                && passwordCard.length === 8
                && Validation.validatePasswordCard(passwordCard)
                && confirmPasswordCard.length === 8
                && Validation.validatePasswordCard(confirmPasswordCard)
                && Validation.checkSamePassword(passwordCard, confirmPasswordCard)
                && Validation.validatePostCode(postCode)
        } else {
            fullyChecked = fullyChecked && Validation.validatePostCode(postCode, true)
        }
        this.setState({fullyChecked: fullyChecked});
        return fullyChecked;
    }

    handleChangeApplicationStatus(status) {
        this.alertAck();
        const data = {
            data: this.props.data && [this.props.data._id],
            status,
            fromStatus: this.props.data.status
        };
        this.props.changeApplicationStatus(data);
        this.props.onClose();
    }

    handleClose(e) {
        e.preventDefault();
        this.props.onClose();
    }

    handleSentMailConfirm() {
        this.alertAck();
        const data = {
            data: this.props.data && [this.props.data._id],
            status: STATUS_APP.CONFIRMING,
            fromStatus: this.props.data.status
        };
        this.props.sentMailConfirmAddress(data);
    }

    handleSendMailWrongInfo = () => {
        const data = {
            _id: this.props.data && this.props.data._id,
            typeMail: this.state.typeMail
        };
        this.props.sendMailWrongInfo(data);
    }

    handleNoteSubmit(e) {
        this.alertAck();
        e.preventDefault();
        const data = {
            _id: this.props.data && this.props.data._id,
            note: this.state.note
        };
        this.props.noteSubmit(data);
    }

    handleUpdateApplicationInfo(e) {
        this.alertAck();
        e.preventDefault();
        const { passportExpiryYear, passportExpiryMonth, passportExpiryDate } = this.state;
        const { data, status } = this.props;
        let passportExpired = '';
        if (passportExpiryDate === ' ') {
            passportExpired = ' ';
        } else {
            passportExpired = passportExpiryYear + '-' + passportExpiryMonth + '-' + passportExpiryDate;
        }
        const payload = {
            _id: data && data._id,
            status: status,
            firstname: this.state.firstname !== this.props.firstname && this.state.firstname,
            lastname: this.state.lastname,
            gender: this.state.maleGender === "male",
            birthday: this.state.birthYear + '-' + this.state.birthMonth + '-' + this.state.birthDate,
            phoneNumber: this.state.phoneNumber,
            country: this.state.country,
            prefecture: this.state.prefecture ? this.state.prefecture : ' ',
            profession: this.state.profession,
            city: this.state.city,
            postCode: this.state.postCode,
            passportIssue: this.state.passportIssueYear + '-' + this.state.passportIssueMonth + '-' + this.state.passportIssueDate,
            passportExpired,
            passportPlaceIssued: this.state.passportPlaceIssued,
            afterAddress: this.state.afterAddress,
            buildingName: this.state.buildingName,
            passportImage: this.state.passportImage,
            firstNameChinese: this.state.firstNameChinese ? this.state.firstNameChinese : '',
            lastNameChinese: this.state.lastNameChinese ? this.state.lastNameChinese: '',
            businessId: this.state.businessId,
            KYC_1: this.state.KYC_1,
            KYC_2: this.state.KYC_2,
            KYC_3: this.state.KYC_3,
            KYC_4: this.state.KYC_4,
            email: this.state.email
        };
        this.setState({passportImage: data ? data.passportImage : ''});
        this.setState({KYC_1: data ? data.KYC_1 : ''});
        this.setState({KYC_2: data ? data.KYC_2 : ''});
        this.setState({KYC_3: data ? data.KYC_3 : ''});
        this.setState({KYC_4: data ? data.KYC_4 : ''});
        this.props.updateApplicationInfo(payload);
    }

    renderMainInfo(translate) {
        let {firstname, lastname, maleGender, birthYear, birthMonth, birthDate, phoneNumber, profession, sentMailWrongPassport, lastNameChinese,
            firstNameChinese, email, nationality , businessId} = this.state;
        const { confirm, sendMailWrongInfoAdmin, editable, update, adminStepData, editMail, isUpdate } = this.props;
        return (
            <ContentPanel>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Name</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <TextField
                                        placeholder="Given name (Example: HANAKO)"
                                        className="input"
                                        id="firstname"
                                        label="First Name"
                                        value={firstname}
                                        onChange={this.handleChange('firstname')}
                                        error={!firstname || !Validation.validateName(firstname)}
                                        required={!firstname || !Validation.validateName(firstname)}
                                        disabled={confirm || editable}
                                        margin="normal"
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <TextField
                                        placeholder="Surname (Example: TANAKA)"
                                        className="input"
                                        id="lastname"
                                        label="Last Name"
                                        value={lastname}
                                        onChange={this.handleChange('lastname')}
                                        required={!lastname || !Validation.validateName(lastname)}
                                        error={!lastname || !Validation.validateName(lastname)}
                                        disabled={confirm || editable}
                                        margin="normal"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { ["China", "Macao", "Hong Kong", "Taiwan, Province of China"].includes(this.props.data.nationality) &&
                    <div className="row content-wrapper">
                        <div className="col-12 col-sm-3">
                            <div className="content-title">
                                <label htmlFor="other">Chinese name</label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-9">
                            <div className="content-value">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <TextField
                                            className="input"
                                            id="firstChineseName"
                                            label="First Chinese Name"
                                            value={firstNameChinese}
                                            onChange={this.handleChange('firstNameChinese')}
                                            error={!firstNameChinese || !Validation.validateChineseName(firstNameChinese)}
                                            required={!firstNameChinese || !Validation.validateChineseName(firstNameChinese)}
                                            disabled={confirm || editable}
                                            margin="normal"
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <TextField
                                            className="input"
                                            id="lastChineseName"
                                            label="Last Chinese Name"
                                            value={lastNameChinese}
                                            onChange={this.handleChange('lastNameChinese')}
                                            required={!lastNameChinese || !Validation.validateChineseName(lastNameChinese)}
                                            error={!lastNameChinese || !Validation.validateChineseName(lastNameChinese)}
                                            disabled={confirm || editable}
                                            margin="normal"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Gender</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-12 col-sm-3">
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={maleGender === "male"}
                                                onChange={this.handleChange('maleGender')}
                                                disabled={confirm || editable}
                                                value="male"
                                            />
                                        }
                                        label="Male"
                                    />
                                </div>
                                <div className="col-12 col-sm-3">
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={maleGender === "female"}
                                                onChange={this.handleChange('maleGender')}
                                                disabled={confirm || editable}
                                                value="female"
                                            />
                                        }
                                        label="Female"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Nationality</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-12 col-sm-3">
                                    <TextField
                                        id="select-currency-native"
                                        select
                                        className="input input-nationality"
                                        value={nationality}
                                        onChange={this.handleChange('nationality')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!nationality || nationality === -1 || nationality === "-1"}
                                        error={!nationality || nationality === -1 || nationality === "-1"}
                                        disabled={confirm || editable}
                                        margin="normal"
                                        label="nationality"
                                        disabled
                                    >
                                        <option value={-1}/>
                                        {
                                            nationalityOptions.map(option => (
                                            <option key={option.code} value={option.name}>
                                                {option.name}
                                            </option> ))
                                        }
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Birthday</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Year"
                                        value={birthYear}
                                        onChange={this.handleChange('birthYear')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!birthYear || birthYear === -1 || birthYear === "-1"}
                                        error={!Validation.checkDate(birthYear, birthMonth, birthDate) || !birthYear || birthYear === -1 || birthYear === "-1"}
                                        disabled={confirm || editable}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getYears().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Month"
                                        value={birthMonth}
                                        onChange={this.handleChange('birthMonth')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!birthMonth || birthMonth === -1 || birthMonth === "-1"}
                                        error={!Validation.checkDate(birthYear, birthMonth, birthDate) || !birthMonth || birthMonth === -1 || birthMonth === "-1"}
                                        disabled={confirm || editable}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getMonths().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Date"
                                        value={birthDate}
                                        onChange={this.handleChange('birthDate')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!birthDate || birthDate === -1 || birthDate === "-1"}
                                        error={!Validation.checkDate(birthYear, birthMonth, birthDate) || !birthDate || birthDate === -1 || birthDate === "-1"}
                                        disabled={confirm || editable}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getDates().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Mobile Phone</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-12 col-sm-6">

                                    <PhoneInput
                                        placeholder="Phone number"
                                        value={phoneNumber}
                                        country="JP"
                                        error="Exactly phone number is required"
                                        className={editable || confirm ? 'phone-input-disable' : 'phone-input-active'}
                                        indicateInvalid={
                                            !phoneNumber ||
                                            phoneNumber.length > 18 ||
                                            !commonFunctions.isNumeric(phoneNumber) ||
                                            !Validation.validatePhoneNumber(phoneNumber)
                                        }
                                        disabled
                                        onChange={(phoneNumber) => {
                                            this.setState({phoneNumber}, () => {
                                                this.validateRegisterForm();
                                            });
                                        }}
                                    />

                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-12">
                                {sendMailWrongInfoAdmin && this._renderPopoverConfirm(this.handleSendMailWrongInfo, (
                                    <ButtonAnt
                                        className="submit-btn mt-2 ml-2 d-inline-block"
                                        size="small"
                                        onClick={() => this.setState({
                                            isOpenConfirmSendMail: true,
                                            typeMail: TYPE_SEND_MAIL.WRONG_PHONE_NUMBER
                                        })}
                                        loading={adminStepData === ADMIN_STATUS.SEND_MAIL_WRONG_PHONE_NUMBER_PROGRESS}
                                        icon={((sentMailWrongPassport && sentMailWrongPassport.find(value => value === TYPE_SEND_MAIL.WRONG_PHONE_NUMBER))
                                            || this.props.sendWrongPhoneNumberMail) ? "check" : null}
                                    >
                                        WRONG PHONE NUMBER
                                    </ButtonAnt>
                                ))}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Email</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <TextField
                                        className="input input-email"
                                        id="email"
                                        label="Email"
                                        value={email}
                                        onChange={this.handleChange('email')}
                                        error={!email || !Validation.validateEmail(email)}
                                        required={!email || !Validation.validateEmail(email)}
                                        disabled={!editMail || confirm }
                                        margin="normal"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Profession</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <TextField
                                        className="input"
                                        id="select-profession"
                                        select
                                        label="Profession"
                                        value={profession}
                                        onChange={this.handleChange('profession')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!isUpdate && profession === ""}
                                        error={!isUpdate && profession === ""}
                                        disabled={confirm || editable}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {allProfession.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentPanel>
        )
    }

    renderAddressInfo(translate) {
        const {confirm, editable, data, isUpdate} = this.props;
        const {prefecture, postCode} = this.state;
        let businessId = data ? data.businessId : this.props.businessId;

        let validatePrefecture = {};
        let validatePostCode = {};
        if (businessId === businessIds.TRIPLE.id) {
            const errorPrefecture = !!prefecture && !Validation.validateAddress(prefecture);
            validatePrefecture = {
                required: errorPrefecture,
                error: errorPrefecture
            };
        } else {
            const errorPrefecture = !prefecture || !prefecture.trim() || !Validation.validateAddress(prefecture);
            validatePrefecture = {
                required: errorPrefecture,
                error: errorPrefecture
            };

        }
        if (isUpdate) {
            const errorPostCode = !postCode || !Validation.validatePostCode(postCode, true);
            validatePostCode = {
                inputProps: {maxLength: 10},
                required: errorPostCode,
                error: errorPostCode,
            };
        } else {
            const errorPostCode = !postCode || postCode.length < 7 || !Validation.validatePostCode(postCode);
            validatePostCode = {
                inputProps: {maxLength: 7},
                required: errorPostCode,
                error: errorPostCode,
            };
        }
        return (
            <ContentPanel header="Address" subHeader="It must be an address to receive postal items">
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Country</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="select-currency-native"
                                select
                                className="input"
                                value={this.state.country}
                                onChange={this.handleChange('country')}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                        className: "",
                                    },
                                }}
                                required={!this.state.country || this.state.country === -1 || this.state.country === "-1"}
                                error={!this.state.country || this.state.country === -1 || this.state.country === "-1"}
                                disabled={confirm || editable}
                                margin="normal"
                                label="Country"
                            >
                                <option value={-1}/>
                                { this.state.country !== -1 ?
                                    this.countriesAdmin.map(option => (
                                        <option key={option.code} value={option.name}>
                                            {option.name}
                                        </option> ))
                                    :
                                    this.countries.map(option => (
                                        <option key={option.code} value={option.name}>
                                            {option.name}
                                        </option> ))
                                }
                            </TextField>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Postal Code</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                className="input"
                                id="postCode"
                                label="Postal Code"
                                value={postCode}
                                onChange={this.handleChange('postCode')}
                                {...validatePostCode}
                                disabled={confirm || editable}
                                margin="normal"
                                placeholder="Postal Code (Example: 5370003)"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Prefectures</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="prefecture"
                                value={prefecture}
                                className="input"
                                onChange={this.handleChange('prefecture')}
                                {...validatePrefecture}
                                disabled={confirm || editable}
                                margin="normal"
                                label="Prefectures"
                                placeholder="Prefectures (Example: Aichi)"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">City</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="city"
                                label="City"
                                value={this.state.city}
                                className="input"
                                onChange={this.handleChange('city')}
                                required={!this.state.city || !Validation.validateAddress(this.state.city)}
                                error={!this.state.city || !this.state.city.trim() || !Validation.validateAddress(this.state.city)}
                                disabled={confirm || editable}
                                margin="normal"
                                placeholder="City (Example: Toyota)"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">After address</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                multiline
                                rowsMax={4}
                                id="afterAddress"
                                label="After Address"
                                value={this.state.afterAddress}
                                className="input after-address"
                                onChange={this.handleChange('afterAddress')}
                                required={!this.state.afterAddress || !Validation.validateAfterAddress(this.state.afterAddress)}
                                error={!this.state.afterAddress || !this.state.afterAddress.trim() || !Validation.validateAfterAddress(this.state.afterAddress)}
                                disabled={confirm || editable}
                                margin="normal"
                                placeholder="Address (Example: 1-2-3, Shinmachi, Nishi-ku)"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Building name/room number</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="buildingName"
                                label="Building name"
                                value={this.state.buildingName}
                                required={!Validation.validateAddress(this.state.buildingName)}
                                error={(this.state.buildingName && !this.state.buildingName.trim()) || !Validation.validateAddress(this.state.buildingName)}
                                className="input"
                                onChange={this.handleChange('buildingName')}
                                disabled={confirm || editable}
                                margin="normal"
                                placeholder="Room number and Building name (Example: 1203 Park Tower)"
                            />
                        </div>
                    </div>
                </div>
            </ContentPanel>
        )
    }

    _renderPopoverConfirm = (onConfirm, content) => (
        <Popconfirm
          title={<>Are you sure?<br /> Please confirm again.</>}
          onConfirm={onConfirm}
          okText="Yes"
          icon={null}
          cancelText="Cancel"
        >
          {content}
        </Popconfirm>
    )

    renderImage () {
        const businessId = this.props.data ? this.props.data.businessId : this.props.businessId || businessIds.GREENBOX.id;
        const { passportImage, sentMailWrongPassport } = this.state;
        const { editable, update, sendMailWrongInfoAdmin, adminStepData} = this.props;
        return this.arrImage.map((item, index) => {
            item.value = setValueImage(index, item.value, this.state);
            const label = businessId === businessIds.GREENBOX.id ? item.label01 : item.label02;
            const typeMail = item.blur === 'BLUR FONT' ? TYPE_SEND_MAIL.FUZZY_PASSPORT : item.blur === 'BLUR SELFIE' ? TYPE_SEND_MAIL.FUZZY_SELFIE : TYPE_SEND_MAIL.WRONG_ADDRESS_DOC;
            const adminStatus = item.blur === 'BLUR FONT' ? ADMIN_STATUS.SEND_MAIL_BLUR_PASSPORT_PROGRESS :
                                item.blur === 'BLUR SELFIE' ? ADMIN_STATUS.SEND_MAIL_BLUR_SELFIE_PROGRESS : ADMIN_STATUS.SEND_MAIL_WRONG_ADDRESS_DOC_PROGRESS;

            if (this.state.previewPassportImage && this.state.nameImage === item.name)
                item.updateImage = this.state.previewPassportImage;

            const sendBlur = item.blur === 'BLUR FONT' ? this.props.sendBlurPassportMail : item.blur === 'BLUR SELFIE' ? this.props.sendBlurSelfieMail : this.props.sendWrongAddressDocMail;
            return (
                <div key={index}>
                    <label className="label-image" >{label}</label>
                    {item ? (
                        <figure>
                            <img style={{width: "100%"}} src={item.updateImage || item.image} alt=""/>
                        </figure>
                    ) : (
                        <a
                            data-lightbox="example-set"
                            href={item}
                            className="m-5 image-item"
                        >
                            <figure>
                                <img style={{width: "100%"}} src={item.image} alt=""/>
                            </figure>
                        </a>
                    )}
                    {sendMailWrongInfoAdmin && item.blur && this._renderPopoverConfirm(this.handleSendMailWrongInfo, (
                        <ButtonAnt
                            className="submit-btn mt-2 d-inline-block btn-blur"
                            size="small"
                            onClick={() => this.setState({
                                isOpenConfirmSendMail: true,
                                typeMail
                            })}
                            loading={adminStepData === adminStatus}
                            icon={((sentMailWrongPassport && sentMailWrongPassport.find(value => value === typeMail))
                                || sendBlur) ? "check" : null}
                        >
                            {item.blur}
                        </ButtonAnt>
                    ))}
                    {!editable && <React.Fragment>
                        <div className="upload">
                            <div>
                                <input
                                    accept="image/*"
                                    multiple={false}
                                    required={true}
                                    placeholder="Please upload image"
                                    onChange={this.handleChange(item.name)}
                                    className="upload-image"
                                    type="file"
                                    name="fileToUpload"
                                    ref="fileInput"
                                    id="fileToUpload"/>
                            </div>
                            <div>
                                Required size: 300Kb ~ 4Mb
                            </div>
                            {item.value && Validation.validateUploadFileTripleSize(item.value) !== 0 &&
                                <div className="text-danger">
                                    File size is not valid
                                    (currently {Math.round(item.value.size / 1024)} Kb)
                                </div>
                            }
                            {item.value && Validation.validateUploadFileType(item.value) !== 0 &&
                                <div className="text-danger">
                                    File type is not valid
                                </div>
                            }
                        </div>
                    </React.Fragment>}
                </div>
            )
        })

    }

    renderCertificateInfo(translate) {
        const businessId = this.props.data ? this.props.data.businessId : this.props.businessId || businessIds.GREENBOX.id;
        const fieldsCertificate = businessId === businessIds.GREENBOX.id ? FIELDS_REGISTRATION.GREENBOX : FIELDS_REGISTRATION.TRIPLE;
        let {
            passportNumber, passportIssueYear, passportIssueMonth, passportIssueDate, passportExpiryYear,
            passportExpiryMonth, passportExpiryDate, passportPlaceIssued, passportImage, sentMailWrongPassport
        } = this.state;
        const {confirm, editable, register, isUpdate, sendMailWrongInfoAdmin, adminStepData} = this.props;
        return (
            <ContentPanel header="Certificate information">
                <FormLabel component="legend" className="certificate-note"
                           onClick={() => this.setState({isShowPassportInstruction: true})}>
                    Please check here before sending the certificate
                </FormLabel>

                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">{fieldsCertificate.idNumber.label}</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="passportNumber"
                                label={fieldsCertificate.idNumber.label}
                                value={passportNumber}
                                className="input"
                                onChange={this.handleChange('passportNumber')}
                                required={!passportNumber}
                                error={!passportNumber}
                                disabled={confirm || isUpdate}
                                margin="normal"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">{fieldsCertificate.issueDate.label}</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Year"
                                        value={passportIssueYear}
                                        onChange={this.handleChange('passportIssueYear')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!passportIssueYear || passportIssueYear === -1 || passportIssueYear === "-1"}
                                        error={!Validation.checkDate(passportIssueYear, passportIssueMonth, passportIssueDate) || !passportIssueYear || passportIssueYear === -1 || passportIssueYear === "-1"}
                                        disabled={confirm || editable}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getYears().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Month"
                                        value={passportIssueMonth}
                                        onChange={this.handleChange('passportIssueMonth')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!passportIssueMonth || passportIssueMonth === -1 || passportIssueMonth === "-1"}
                                        error={!Validation.checkDate(passportIssueYear, passportIssueMonth, passportIssueDate) || !passportIssueMonth || passportIssueMonth === -1 || passportIssueMonth === "-1"}
                                        disabled={confirm || editable}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getMonths().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Date"
                                        value={passportIssueDate}
                                        onChange={this.handleChange('passportIssueDate')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        required={!passportIssueDate || passportIssueDate === -1 || passportIssueDate === "-1"}
                                        error={!Validation.checkDate(passportIssueYear, passportIssueMonth, passportIssueDate) || !passportIssueDate || passportIssueDate === -1 || passportIssueDate === "-1"}
                                        disabled={confirm || editable}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getDates().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">{fieldsCertificate.expiryDate.label}</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                {
                                    passportExpiryDate === ' '
                                    ?
                                    <div className="long-term">Long term</div>
                                    :
                                    <>
                                        <div className="col-4">
                                        <TextField
                                            className="input"
                                            id="select-currency-native"
                                            select
                                            label="Year"
                                            value={passportExpiryYear}
                                            onChange={this.handleChange('passportExpiryYear')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: "",
                                                },
                                            }}
                                            required={!passportExpiryYear || passportExpiryYear === -1 || passportExpiryYear === "-1"}
                                            error={!Validation.checkDate(passportExpiryYear, passportExpiryMonth, passportExpiryDate) || !passportExpiryYear || passportExpiryYear === -1 || passportExpiryYear === "-1"}
                                            disabled={confirm || editable}
                                            margin="normal"
                                        >
                                            <option value={-1}/>
                                            {DateTime.getYearsExpiry().map(option => (
                                                <option key={option.id} value={option.value}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-4">
                                        <TextField
                                            className="input"
                                            id="select-currency-native"
                                            select
                                            label="Month"
                                            value={passportExpiryMonth}
                                            onChange={this.handleChange('passportExpiryMonth')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: "",
                                                },
                                            }}
                                            required={!passportExpiryMonth || passportExpiryMonth === -1 || passportExpiryMonth === "-1"}
                                            error={!Validation.checkDate(passportExpiryYear, passportExpiryMonth, passportExpiryDate) || !passportExpiryMonth || passportExpiryMonth === -1 || passportExpiryMonth === "-1"}
                                            disabled={confirm || editable}
                                            margin="normal"
                                        >
                                            <option value={-1}/>
                                            {DateTime.getMonths().map(option => (
                                                <option key={option.id} value={option.value}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-4">
                                        <TextField
                                            className="input"
                                            id="select-currency-native"
                                            select
                                            label="Date"
                                            value={passportExpiryDate}
                                            onChange={this.handleChange('passportExpiryDate')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: "",
                                                },
                                            }}
                                            required={!passportExpiryDate || passportExpiryDate === -1 || passportExpiryDate === "-1"}
                                            error={!Validation.checkDate(passportExpiryYear, passportExpiryMonth, passportExpiryDate) || !passportExpiryDate || passportExpiryDate === -1 || passportExpiryDate === "-1"}
                                            disabled={confirm || editable}
                                            margin="normal"
                                        >
                                            <option value={-1}/>
                                            {DateTime.getDates().map(option => (
                                                <option key={option.id} value={option.value}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </TextField>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">{fieldsCertificate.issuePlace.label}</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="select-currency-native"
                                select
                                className="input"
                                label={fieldsCertificate.issuePlace.label}
                                value={passportPlaceIssued}
                                onChange={this.handleChange('passportPlaceIssued')}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                        className: "",
                                    },
                                }}
                                required={!passportPlaceIssued || passportPlaceIssued === -1 || passportPlaceIssued === "-1"}
                                error={!passportPlaceIssued || passportPlaceIssued === -1 || passportPlaceIssued === "-1"}
                                disabled={confirm || editable}
                                margin="normal"
                            >
                                <option value={-1}/>
                                {
                                    passportPlaceIssued !== -1 ?
                                    <>
                                        { this.passportCountriesAdmin.map(option => (
                                        <option key={option.code} value={option.name}>
                                            {option.name}
                                        </option>
                                        ))}
                                    </>
                                    :
                                    <>
                                        { this.passportCountries.map(option => (
                                        <option key={option.code} value={option.name}>
                                            {option.name}
                                        </option>
                                        ))}
                                    </>
                                }
                            </TextField>
                        </div>
                    </div>
                </div>

                <div className="row content-wrapper">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Image</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            {register && (
                                <div>
                                    <div className="text-danger">
                                        <p>Required to have more than seven months' validity remaining on your
                                            passport.</p>
                                    </div>
                                    <div>
                                        <input
                                            accept="image/*"
                                            multiple={false}
                                            required={true}
                                            placeholder="Please upload image"
                                            onChange={this.handleChange("passportImage")}
                                            className="upload-image"
                                            type="file"
                                            name="fileToUpload"
                                            ref="fileInput"
                                            id="fileToUpload"/>
                                    </div>
                                    <div>
                                        Required size: 300Kb ~ 4Mb
                                    </div>
                                    {passportImage && Validation.validateUploadFileTripleSize(passportImage) !== 0 &&
                                    <div className="text-danger">
                                        File size is not valid
                                        (currently {Math.round(passportImage.size / 1024)} Kb)
                                    </div>
                                    }
                                </div>
                            )}
                            {confirm && this.props.stepData.userInfo &&
                            <a data-lightbox="example-set" href={this.props.stepData.userInfo.linkPassportImage}
                               className="m-5 image-item">
                                <figure>
                                    <img style={{width: "100%"}} src={this.props.stepData.userInfo.linkPassportImage}
                                         alt=""/>
                                </figure>
                            </a>}
                            {isUpdate && this.props.data &&
                            <div>
                                {this.renderImage()}
                                {sendMailWrongInfoAdmin &&
                                <div className="action-send-mail">
                                    {/* {this._renderPopoverConfirm(this.handleSendMailWrongInfo, (
                                        <ButtonAnt
                                            className="submit-btn mt-2 d-inline-block"
                                            size="small"
                                            onClick={() => this.setState({
                                                isOpenConfirmSendMail: true,
                                                typeMail: TYPE_SEND_MAIL.FUZZY_PASSPORT
                                            })}
                                            loading={adminStepData === ADMIN_STATUS.SEND_MAIL_BLUR_PASSPORT_PROGRESS}
                                            icon={((sentMailWrongPassport && sentMailWrongPassport.find(value => value === TYPE_SEND_MAIL.FUZZY_PASSPORT))
                                                || this.props.sendBlurPassportMail) ? "check" : null}
                                        >
                                            BLUR PASSPORT
                                        </ButtonAnt>
                                    ))}

                                    {this._renderPopoverConfirm(this.handleSendMailWrongInfo, (
                                        <ButtonAnt
                                            className="submit-btn mt-2 d-inline-block"
                                            size="small"
                                            onClick={() => this.setState({
                                                isOpenConfirmSendMail: true,
                                                typeMail: TYPE_SEND_MAIL.FUZZY_SELFIE
                                            })}
                                            loading={adminStepData === ADMIN_STATUS.SEND_MAIL_BLUR_SELFIE_PROGRESS}
                                            icon={((sentMailWrongPassport && sentMailWrongPassport.find(value => value === TYPE_SEND_MAIL.FUZZY_SELFIE))
                                                || this.props.sendBlurSelfieMail) ? "check" : null}
                                        >
                                            BLUR SELFIE
                                        </ButtonAnt>
                                    ))}

                                    {this._renderPopoverConfirm(this.handleSendMailWrongInfo, (
                                        <ButtonAnt
                                            className="submit-btn mt-2 d-inline-block"
                                            size="small"
                                            onClick={() => this.setState({
                                                isOpenConfirmSendMail: true,
                                                typeMail: TYPE_SEND_MAIL.WRONG_ADDRESS_DOC
                                            })}
                                            loading={adminStepData === ADMIN_STATUS.SEND_MAIL_WRONG_ADDRESS_DOC_PROGRESS}
                                            icon={((sentMailWrongPassport && sentMailWrongPassport.find(value => value === TYPE_SEND_MAIL.WRONG_ADDRESS_DOC))
                                                || this.props.sendWrongAddressDocMail) ? "check" : null}
                                        >
                                            WRONG ADDRESS DOC
                                        </ButtonAnt>
                                    ))} */}

                                    {/* {this._renderPopoverConfirm(this.handleSendMailWrongInfo, (
                                        <ButtonAnt
                                            className="submit-btn mt-2 d-inline-block"
                                            size="small"
                                            onClick={() => this.setState({
                                                isOpenConfirmSendMail: true,
                                                typeMail: TYPE_SEND_MAIL.WRONG_FULL_NAME
                                            })}
                                            loading={adminStepData === ADMIN_STATUS.SEND_MAIL_WRONG_FULL_NAME_PROGRESS}
                                            icon={((sentMailWrongPassport && sentMailWrongPassport.find(value => value === TYPE_SEND_MAIL.WRONG_FULL_NAME))
                                                || this.props.sendWrongNameMail) ? "check" : null}
                                        >
                                            WRONG FULL NAME
                                        </ButtonAnt>
                                    ))}

                                    {this._renderPopoverConfirm(this.handleSendMailWrongInfo, (
                                        <ButtonAnt
                                            className="submit-btn mt-2 d-inline-block"
                                            size="small"
                                            onClick={() => this.setState({
                                                isOpenConfirmSendMail: true,
                                                typeMail: TYPE_SEND_MAIL.WRONG_PASSPORT_EXPIRED
                                            })}
                                            loading={adminStepData === ADMIN_STATUS.SEND_MAIL_WRONG_PASSPORT_EXPIRED_PROGRESS}
                                            icon={((sentMailWrongPassport && sentMailWrongPassport.find(value => value === TYPE_SEND_MAIL.WRONG_PASSPORT_EXPIRED))
                                                || this.props.sendWrongPassportExpiredMail) ? "check" : null}
                                        >
                                            WRONG PASSPORT EXPIRED
                                        </ButtonAnt>
                                    ))} */}
                                </div>
                                }
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </ContentPanel>
        )
    }

    renderCardInfo(translate) {
        const {confirm, isUpdate, editable} = this.props;
        return (
            <ContentPanel header="Card information" subHeader="The number of items you can apply for up to two persons">
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-4">
                        <div className="content-title">
                            <label htmlFor="other">Number Of Card</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                select
                                id="Quantities"
                                label="Quantities"
                                value={this.state.cards.length}
                                className="input"
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                        className: "mr-t-10",
                                    },
                                }}
                                disabled={confirm || isUpdate}
                                margin="normal"
                                onChange={this.handleChange("selectedCard")}
                            >
                            {
                                this.state.cards.length === 1 ?
                                    cardData.quantity.map(option => (
                                        <option key={option.value} value={option.id}>
                                            {option.value}
                                        </option>
                                    )) :
                                    cardData.quantities.map(option => (
                                        <option key={option.value} value={option.id}>
                                            {option.value}
                                        </option>
                                    ))
                            }
                            </TextField>
                        </div>
                    </div>
                </div>
                {this.state.cards.map((item, index) => {
                    return (
                        <div key={index} className="row content-wrapper">
                            <div className="col-12 col-sm-4">
                                <div className="content-title">
                                    <label htmlFor="other">
                                        {(index === 1 ? `${index + 1}nd ` : '') + "Card base"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-5">
                                <div className="content-value">
                                    <TextField
                                        select
                                        id="Currencies"
                                        label="Currencies"
                                        value={item.baseCurrency}
                                        className="input"
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        disabled={confirm || isUpdate}
                                        margin="normal"
                                    >
                                        {cardData.currencies.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    )
                })
                }
                <div className="row content-wrapper">
                    <div className="col-12 col-sm-4">
                        <div className="content-title">
                            <label htmlFor="other">Member ID</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            {this.props.businessMemberId || (this.props.data && this.props.data.businessMemberId)}
                        </div>
                    </div>
                </div>
                {!isUpdate && <div className="row content-wrapper">
                    <div className="col-12 col-sm-4">
                        <div className="content-title">
                            <label htmlFor="other">Create a eZpay password (8 Digits)</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                type={this.state.showPasswordCard ? "text" : "password"}
                                id="passwordCard"
                                label="Create a password"
                                value={this.state.passwordCard}
                                inputProps={{maxLength: 8}}
                                className="input"
                                onChange={this.handleChange('passwordCard')}
                                required={!this.state.passwordCard}
                                error={!this.state.passwordCard || this.state.passwordCard.length !== 8 || !Validation.validatePasswordCard(this.state.passwordCard)}
                                disabled={confirm || editable}
                                margin="normal"
                            />
                        </div>
                    </div>
                    <div className="show-password">
                        <i onClick={() => this.setState({showPasswordCard: !this.state.showPasswordCard})}
                           className={` ${this.state.showPasswordCard ? "fas fa-eye" : "fas fa-eye-slash"}`}/>
                    </div>
                </div>}
                {(confirm || isUpdate) ? null : <div className="row content-wrapper">
                    <div className="col-12 col-sm-4">
                        <div className="content-title">
                            <label htmlFor="other">Confirm eZpay password</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                type={this.state.showConfirmPasswordCard ? "text" : "password"}
                                id="confirmPasswordCard"
                                label="Confirm password"
                                value={this.state.confirmPasswordCard}
                                inputProps={{maxLength: 8}}
                                className="input"
                                onChange={this.handleChange('confirmPasswordCard')}
                                required={!this.state.confirmPasswordCard}
                                error={!this.state.confirmPasswordCard || this.state.confirmPasswordCard.length !== 8 || !Validation.validatePasswordCard(this.state.confirmPasswordCard) || !Validation.checkSamePassword(this.state.passwordCard, this.state.confirmPasswordCard)}
                                disabled={confirm || editable}
                                margin="normal"
                            />
                        </div>
                    </div>
                    <div className="show-password">
                        <i onClick={() => this.setState({showConfirmPasswordCard: !this.state.showConfirmPasswordCard})}
                           className={` ${this.state.showConfirmPasswordCard ? "fas fa-eye" : "fas fa-eye-slash"}`}/>
                    </div>
                </div>}
                {this.state.cards.map((item, index) => {
                    return <React.Fragment key={index}>
                        {item.cardNumber && <div className="row content-wrapper">
                            <div className="col-12 col-sm-4">
                                <div className="content-title">
                                    <label htmlFor="other">
                                        {(index === 1 ? `${index + 1}nd ` : '') + "Card Number"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-5">
                                <div className="content-value">
                                    <TextField
                                        type='text'
                                        label="Card Number"
                                        value={
                                            item.cardNumber.slice(0, 4) + "-" +
                                            item.cardNumber.slice(4, 8) + "-" +
                                            item.cardNumber.slice(8, 12) + "-" +
                                            item.cardNumber.slice(12, 16)
                                        }
                                        disabled={confirm || isUpdate}
                                        margin="normal"
                                    />
                                </div>
                            </div>
                        </div>}
                        {item.expiredDay && <div className="row content-wrapper">
                            <div className="col-12 col-sm-4">
                                <div className="content-title">
                                    <label htmlFor="other">
                                        {(index === 1 ? `${index + 1}nd ` : '') + "Expired Day"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-5">
                                <div className="content-value">
                                    <TextField
                                        type="text"
                                        label="Expired Day"
                                        value={item.expiredDay}
                                        disabled={confirm || isUpdate}
                                        margin="normal"
                                    />
                                </div>
                            </div>
                        </div>}
                        {item.box && <div className="row content-wrapper">
                            <div className="col-12 col-sm-4">
                                <div className="content-title">
                                    <label htmlFor="other">
                                        {(index === 1 ? `${index + 1}nd ` : '') + "NO in Box"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-5">
                                <div className="content-value">
                                    <TextField
                                        type="text"
                                        label="No"
                                        value={item.box + " (" + Number(item.no) + ')'}
                                        disabled={confirm || isUpdate}
                                        margin="normal"
                                    />
                                </div>
                            </div>
                        </div>}

                        <div className="row content-wrapper">
                            <div className="col-12 col-sm-4">
                                <div className="content-title">
                                    <label htmlFor="other">
                                        {(index === 1 ? `${index + 1}nd ` : '') + "Card PIN Number (6 Digits)"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-5">
                                <div className="content-value">
                                    <TextField
                                        type={index === 1 ? this.state.showPin1 ? "text" : "password" : this.state.showPin2 ? "text" : "password"}
                                        label="Card PIN number"
                                        value={this.state.cards[index].pin}
                                        inputProps={{maxLength: 6}}
                                        className="input"
                                        onChange={this.handlePINChange(index)}
                                        required={!this.state.cards[index].pin}
                                        error={!this.state.cards[index].pin || this.state.cards[index].pin.length !== 6 || !Validation.validateNumber(this.state.cards[index].pin)}
                                        disabled={confirm || isUpdate}
                                        margin="normal"
                                    />
                                </div>
                            </div>
                            <div className="show-password">
                                {index === 1 ?
                                    <i onClick={() => this.setState({showPin1: !this.state.showPin1})}
                                       className={` ${this.state.showPin1 ? "fas fa-eye" : "fas fa-eye-slash"}`}/> :
                                    <i onClick={() => this.setState({showPin2: !this.state.showPin2})}
                                       className={` ${this.state.showPin2 ? "fas fa-eye" : "fas fa-eye-slash"}`}/>}
                            </div>
                        </div>
                        {(confirm || isUpdate) ? null : <div className="row content-wrapper">
                            <div className="col-12 col-sm-4">
                                <div className="content-title">
                                    <label htmlFor="other">
                                        {"Confirm " + (index === 1 ? `${index + 1}nd ` : '') + "Card PIN Number (6 Digits)"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-sm-5">
                                <div className="content-value">
                                    <TextField
                                        type={index === 1 ? this.state.showConfirmPin1 ? "text" : "password" : this.state.showConfirmPin2 ? "text" : "password"}
                                        label="Confirm Card PIN number"
                                        value={this.state.cards[index].confirmPin}
                                        inputProps={{maxLength: 6}}
                                        className="input"
                                        onChange={this.handleConfirmPINChange(index)}
                                        required={!this.state.cards[index].confirmPin}
                                        error={!this.state.cards[index].confirmPin || !Validation.checkSamePassword(this.state.cards[index].pin, this.state.cards[index].confirmPin)}
                                        disabled={confirm || editable}
                                        margin="normal"
                                    />

                                </div>
                            </div>
                            <div className="show-password">
                                {index === 1 ?
                                    <i onClick={() => this.setState({showConfirmPin1: !this.state.showConfirmPin1})}
                                       className={` ${this.state.showConfirmPin1 ? "fas fa-eye" : "fas fa-eye-slash"}`}/> :
                                    <i onClick={() => this.setState({showConfirmPin2: !this.state.showConfirmPin2})}
                                       className={` ${this.state.showConfirmPin2 ? "fas fa-eye" : "fas fa-eye-slash"}`}/>}
                            </div>
                        </div>}
                    </React.Fragment>
                })
                }
            </ContentPanel>
        )
    }

    renderConfirmInfo(translate) {
        return (
            <ContentPanel header="Please approve the following matters and check agreement for the application">
                <ConfirmList
                    disabled={this.props.confirm}
                    list={this.state.confirmList}
                    onItemChange={(index, check) => {
                        this.setState({
                            confirmList: [
                                ...this.state.confirmList.slice(0, index),
                                {...this.state.confirmList[index], checked: check},
                                ...this.state.confirmList.slice(index + 1)
                            ]
                        }, () => {
                            this.validateRegisterForm();
                        });
                    }}
                />
            </ContentPanel>
        )
    }

    renderPassportInstructionDialog(translate) {
        return (
            <Modal
                title="Certification image sample"
                visible={this.state.isShowPassportInstruction}
                onCancel={() => {
                    this.setState({isShowPassportInstruction: false})
                }}
                className="certification-image-dialog"
                maskClosable={false}
            >
                <div className="passport-dialog">
                    <div className="passport-title">
                        <p>Please note the following points for the proof image to be submitted:</p>
                    </div>
                    <div className="passport-instruction">
                        <div className="passport-send-file">
                            <p>Send possible file: PDF, JPG, PNG</p>
                            <p>It is clearer to scan and send it as PDF</p>
                        </div>
                        <div className="passport-certificate-type">
                            <label htmlFor="CertificateType">
                                Certificate type
                            </label>
                            <span>Passport(The expiration date)</span>
                        </div>
                        <div className="passport-image">
                            <label htmlFor="NecessaryImage">Necessary image: </label>
                            <span> Page with the photograph of the face</span>
                        </div>
                        <div className="passport-note">
                            <p>Do not see a letter; when move slightly, and cannot confirm it, there is a case to ask
                                for a resubmission.</p>
                        </div>
                    </div>
                    <div className="passport-image-detail">
                        <div className="image-item">
                            <img style={{width: "100%"}} src="/assets/images/passport.png" alt="Passport"/>
                        </div>
                        <div className="passport-image-right">
                            <ul>
                                <li>The whole of the passport entering</li>
                                <li>That letters are clearly visible</li>
                                <li>The thing which it reflects it by light, and a part does not look like is invalid
                                </li>
                                <li>The expiration date has not expired</li>
                                <li> Passport notation according with application contents</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    renderNote(translate) {
        const { adminStepData } = this.props;
        return (
            <ContentPanel header="Note for Admin">
                <FormLabel component="legend" className="certificate-note">
                    Please note some information in here
                </FormLabel>

                <div className="row content-wrapper">
                    <div className="col-12 col-sm-2">
                        <div className="content-title">
                            <label htmlFor="other">Note</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-7">
                        <div className="content-value">
                            <TextField
                                id="note"
                                value={this.state.note}
                                className="input"
                                onChange={(e) => {
                                    this.setState({note: e.target.value});
                                }}
                                required={!this.state.note}
                                margin="normal"
                                label="Note"
                                multiline={true}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-2">
                        <ButtonAnt
                            className="submit-btn"
                            size="small"
                            onClick={(e) => this.handleNoteSubmit(e)}
                            loading={adminStepData === ADMIN_STATUS.SAVE_NOTE_PROGRESS}
                            disabled={!this.state.note}
                            icon={adminStepData === ADMIN_STATUS.SAVE_NOTE_SUCCESS ? "check" : null}
                        >
                            Save
                        </ButtonAnt>
                    </div>
                </div>
            </ContentPanel>
        )
    }

    render() {
        const {register, reserve, cancel, editable, confirm, sendMailConfirmAddressAgain, update, editMail, reconfirming, reconfirmed, toChecked, showNote, sendMailConfirmAddress, closeButton, adminStepData, sentMailConfirmAdd, listPermission} = this.props;
        return (
            <Translate>
                {({translate}) => (
                    <fieldset disabled={this.props.stepData.status === STATUS.STEP3.UPDATING}
                        className='content-register'
                    >
                        {register ? (
                                <div className="header-title">Enter customer information</div>
                            ) : (
                                <div className="header-title">Verify customer information</div>
                        )}
                        <div className="container-RegistrationForm">
                            {this.renderMainInfo(translate)}
                            {this.renderAddressInfo(translate)}
                            {this.renderCertificateInfo(translate)}
                            {this.renderCardInfo(translate)}
                            {Array.isArray(this.state.confirmList) && this.state.confirmList.length > 0 && this.renderConfirmInfo(translate)}
                            {this.state.isShowPassportInstruction && this.renderPassportInstructionDialog(translate)}
                            {this.state.isSubmitted && !this.state.fullyChecked &&
                            <span className="message">Please input fields required and upload passport image</span>}
                            {showNote && this.renderNote(translate)}
                            {register && (
                                <div className="text-center margin-top-20px">
                                    <Button variant="contained"
                                            color="primary"
                                            disabled={this.props.stepData.status === STATUS.STEP3.UPDATING || !this.state.fullyChecked}
                                            className="submit-btn"
                                            onClick={(e) => this.handleRegisterSubmit(e)}>
                                        {this.props.stepData.status === STATUS.STEP3.UPDATING && (
                                            <CircularProgress className="margin-right-20px" size={20}/>
                                        )}
                                        <span>Register</span>
                                    </Button>
                                </div>
                            )}
                            {(!editable || editMail) && (
                                <div className="action-form text-center margin-top-20px">
                                    {update && (
                                        <ButtonAnt
                                            loading={adminStepData === ADMIN_STATUS.UPDATE_APPLICATION_INFO_PROGRESS}
                                            disabled={!this.state.fullyChecked}
                                            className="submit-btn"
                                            icon={adminStepData === ADMIN_STATUS.UPDATE_APPLICATION_INFO_SUCCESS ? "check" : null}
                                            size="small"
                                            onClick={(e) => this.handleUpdateApplicationInfo(e)}
                                        >
                                            Update
                                        </ButtonAnt>
                                    )}

                                    {reconfirming && (
                                        <ButtonAnt
                                            loading={adminStepData === ADMIN_STATUS.CHANGE_APPLICATION_STATUS_PROGRESS}
                                            className="submit-btn"
                                            size="small"
                                            onClick={() => this.setState({isOpenConfirm: true})}
                                        >
                                            {reconfirmed ? 'Reconfirm from checked list (3-1)' : 'Reconfirm from unchecking list (3-0)'}
                                        </ButtonAnt>
                                    )}
                                    {cancel && (
                                        <ButtonAnt
                                            loading={adminStepData === ADMIN_STATUS.CHANGE_APPLICATION_STATUS_PROGRESS}
                                            className="submit-btn"
                                            size="small"
                                            onClick={() => this.setState({isOpenCancel: true})}
                                        >
                                            Cancel (0-0)
                                        </ButtonAnt>
                                    )}
                                    {reserve && (
                                        <ButtonAnt
                                            loading={adminStepData === ADMIN_STATUS.CHANGE_APPLICATION_STATUS_PROGRESS}
                                            className="submit-btn"
                                            size="small"
                                            onClick={() => this.setState({isOpenReserve: true})}
                                        >
                                            To Reserve List (5-1)
                                        </ButtonAnt>
                                    )}
                                    {toChecked && (
                                        <ButtonAnt
                                            loading={adminStepData === ADMIN_STATUS.CHANGE_APPLICATION_STATUS_PROGRESS}
                                            className="submit-btn"
                                            size="small"
                                            onClick={() => this.setState({isOpenConfirmToChecked: true})}
                                        >
                                            To Checked (4)
                                        </ButtonAnt>
                                    )}
                                    {closeButton && (
                                        <ButtonAnt
                                            className="submit-btn"
                                            size="small"
                                            onClick={(e) => this.handleClose(e)}
                                        >
                                            Close
                                        </ButtonAnt>
                                    )}
                                    {sendMailConfirmAddress && (
                                        <ButtonAnt
                                            loading={adminStepData === ADMIN_STATUS.SEND_MAIL_CONFIRM_ADDRESS_PROGRESS}
                                            disabled={[ADMIN_STATUS.SEND_MAIL_CONFIRM_ADDRESS_SUCCESS, ADMIN_STATUS.SEND_MAIL_CONFIRM_ADDRESS_PROGRESS].indexOf(adminStepData) >= 0}
                                            className="submit-btn"
                                            size="small"
                                            onClick={() => this.setState({isOpenConfirmAddress: true})}
                                            icon={sentMailConfirmAdd ? "check" : null}
                                        >
                                            Resend Mail Confirm Address
                                        </ButtonAnt>
                                    )}
                                    {sendMailConfirmAddressAgain && (
                                        <ButtonAnt
                                            loading={adminStepData === ADMIN_STATUS.SEND_MAIL_CONFIRM_ADDRESS_PROGRESS}
                                            disabled={[ADMIN_STATUS.SEND_MAIL_CONFIRM_ADDRESS_SUCCESS, ADMIN_STATUS.SEND_MAIL_CONFIRM_ADDRESS_PROGRESS].indexOf(adminStepData) >= 0}
                                            className="submit-btn"
                                            size="small"
                                            onClick={() => this.setState({isOpenConfirmAddress: true})}
                                            icon={sentMailConfirmAdd ? "check" : null}
                                        >
                                            To confirming (7-1)
                                        </ButtonAnt>
                                    )}
                                </div>
                            )}
                            {confirm && (
                                <div className="text-center margin-top-20px">
                                    <Button className="margin-right-20px" variant="contained"
                                            onClick={() => this.props.goBack()}>
                                        Back
                                    </Button>
                                    <Button variant="contained" color="primary"
                                            disabled={this.props.stepData.status === STATUS.STEP4.SEND_MAIL_PROGRESS}
                                            onClick={() => {
                                                this.props.sendEmail({
                                                    _id: this.props.applicationInfoId
                                                });
                                            }}>
                                        {this.props.stepData.status === STATUS.STEP4.SEND_MAIL_PROGRESS && (
                                            <CircularProgress className="margin-right-20px" size={20}/>
                                        )}
                                        Confirm & Send Mail
                                    </Button>
                                </div>
                            )}
                            {this.props.stepData.status === STATUS.STEP4.SEND_MAIL_SUCCESS && this.props.showConfirm()}
                            {[STATUS.STEP4.SEND_MAIL_FAILED].indexOf(this.props.stepData.status) >= 0 && !this.state.alertAck && (
                                <Alert title="Error"
                                       content={translate("errors." + this.props.stepData.statusInfo.error.code)}
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                        </div>
                        {this.props.stepData.status === STATUS.STEP3.UPDATE_FAILED && !this.state.alertAck && (
                            <Alert title="Error"
                                   content={translate(this.props.stepData.statusInfo.error && ("errors." + this.props.stepData.statusInfo.error.code))}
                                   okTitle="OK"
                                   onOK={() => {
                                       this.setState({alertAck: true});
                                   }}/>
                        )}
                        {this.state.isOpenConfirm &&
                        <Alert title="確認ダイアログ"
                               content="次のステータスに移動しますか？"
                               okTitle="OK"
                               cancelTitle="Cancel"
                               onOK={() => {
                                   this.setState({isOpenConfirm: false});
                                   this.handleChangeApplicationStatus(this.state.statusUpdate)
                               }}
                               onCancel={() => {
                                   this.setState({isOpenConfirm: false});
                               }}/>
                        }
                        {this.state.isOpenReserve &&
                        <Alert title="確認ダイアログ"
                               content="次のステータスに移動しますか？"
                               okTitle="OK"
                               cancelTitle="Cancel"
                               onOK={() => {
                                   this.setState({isOpenReserve: false});
                                   this.handleChangeApplicationStatus(STATUS_APP.RESERVE)
                               }}
                               onCancel={() => {
                                   this.setState({isOpenReserve: false});
                               }}/>
                        }
                        {this.state.isOpenConfirmToChecked &&
                        <Alert title="確認ダイアログ"
                               content="次のステータスに移動しますか？"
                               okTitle="OK"
                               cancelTitle="Cancel"
                               onOK={() => {
                                   this.setState({isOpenConfirmToChecked: false});
                                   this.handleChangeApplicationStatus(this.state.statusUpdateChecked)
                               }}
                               onCancel={() => {
                                   this.setState({isOpenConfirmToChecked: false});
                               }}/>
                        }
                        {this.state.isOpenCancel &&
                        <Alert title="確認ダイアログ"
                               content="このユーザーの申請を取り消しますか？"
                               okTitle="OK"
                               cancelTitle="Cancel"
                               onOK={() => {
                                   this.setState({isOpenCancel: false});
                                   this.handleChangeApplicationStatus(STATUS_APP.REFUNDING)
                               }}
                               onCancel={() => {
                                   this.setState({isOpenCancel: false});
                               }}/>
                        }
                        {this.state.isOpenConfirmAddress &&
                        <Alert title="確認ダイアログ"
                               content="次のステータスに移動しますか？"
                               okTitle="OK"
                               cancelTitle="Cancel"
                               onOK={() => {
                                   this.setState({isOpenConfirmAddress: false});
                                   this.handleSentMailConfirm()
                               }}
                               onCancel={() => {
                                   this.setState({isOpenConfirmAddress: false});
                               }}/>
                        }
                        {[ADMIN_STATUS.UPDATE_APPLICATION_INFO_FAIL, ADMIN_STATUS.CHANGE_APPLICATION_STATUS_FAIL].indexOf(this.props.adminStepData) >= 0 && !this.state.alertAck && (
                            <Alert title="Error"
                                   content={translate(this.props.adminError && ("errors." + this.props.adminError.code))}
                                   okTitle="OK"
                                   onOK={() => {
                                       this.setState({alertAck: true});
                                   }}/>
                        )}
                    </fieldset>
                )}
            </Translate>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        applicationInfoId: state.registration.stepData.applicationInfo ? state.registration.stepData.applicationInfo._id : null,
        businessMemberId: state.registration.businessMemberId,
        businessToken: state.registration.businessToken,
        businessId: state.registration.businessId,
        currentStep: state.registration.currentStep,
        stepData: state.registration.stepData,
        sendBlurPassportMail: state.admin.sendBlurPassportMail,
        sentMailConfirmAdd: state.admin.sentMailConfirmAdd,
        sendWrongNameMail: state.admin.sendWrongNameMail,
        sendWrongPhoneNumberMail: state.admin.sendWrongPhoneNumberMail,
        sendWrongPassportExpiredMail: state.admin.sendWrongPassportExpiredMail,
        adminStepData: state.admin.status,
        adminError: state.admin.error,
        note: state.admin.note,
        sendBlurSelfieMail: state.admin.sendBlurSelfieMail,
        sendWrongAddressDocMail: state.admin.sendWrongAddressDocMail,
        listPermission: state.admin.listPermission
    }
};

function mapDispatchToProps(dispatch) {
    return {
        sendEmail: (data) => {
            dispatch(sendInstructionEmail(data));
        },
        updateUserInfo: (data) => {
            dispatch(updateUserInfo(data))
        },
        updateApplicationInfo: (data) => {
            dispatch(updateApplicationInfo(data))
        },
        changeApplicationStatus: (data) => {
            dispatch(changeApplicationStatus(data))
        },
        sentMailConfirmAddress: (data) => {
            dispatch(sentMailConfirmAddress(data))
        },
        noteSubmit: (data) => {
            dispatch(noteSubmit(data))
        },
        sendMailWrongInfo: (data) => {
            switch (data.typeMail) {
                case TYPE_SEND_MAIL.WRONG_PASSPORT_EXPIRED:
                    dispatch(sendMailWrongPassportExpired(data));
                    break;
                case TYPE_SEND_MAIL.WRONG_FULL_NAME:
                    dispatch(sendMailWrongFullName(data));
                    break;
                case TYPE_SEND_MAIL.FUZZY_PASSPORT:
                    dispatch(sendMailBlurPassport(data));
                    break;
                case TYPE_SEND_MAIL.WRONG_PHONE_NUMBER:
                    dispatch(sendMailWrongPhoneNumber(data));
                    break;
                case TYPE_SEND_MAIL.FUZZY_SELFIE:
                    dispatch(sendMailBlurSelfie(data));
                    break;
                case TYPE_SEND_MAIL.WRONG_ADDRESS_DOC:
                    dispatch(sendMailWrongAddressDoc(data));
                    break;
                default:
                    break
            }

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
