import React, {Component} from "react";
import {
    Input,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import {Translate} from "react-localize-redux";
import CcCheckbox from "../../../../components/ccCheckbox";
import {
    importCard,
    getCards,
    exportCard,
    editCard,
    searchCardNumber,
    updateCard,
    resetStatus
} from "../../../../actions/cardManagementActions";
import {STATUS} from "../../../../reducers/cardManagementReducer";

import "./style.css";
import {connect} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import {formatDateTimeFull, STATUS_CARD_NUMBER, PERMISSION, ROLE_SUPPER_ADMIN} from "../../../../utils/commonUtils";
import InputMask from 'react-input-mask';
import moment from "moment";
import Alert from "../../../../components/Alert";
import { Input as InputAntd } from "../../../../components/antd";

import { Icon } from 'antd';
import {
    Column,
    Button as ButtonAntd,
    PaginationBar,
    Select,
    Table,
    Checkbox
} from '../../../../components/antd';
import { Input as InputMater } from '@material-ui/core';

const optionStatusCard = [
    {value :'', name: 'All'},
    {value: STATUS_CARD_NUMBER.USED , name : 'Used'},
    {value: STATUS_CARD_NUMBER.REJECTED , name : 'Reject'},
    {value: STATUS_CARD_NUMBER.UNUSED , name : 'Unused'}
]
class CardManagement extends Component {
    constructor() {
        super();
        this.state = {
            cards: [],
            isShowModal: false,
            isShowChangeCard: false,
            edit: false,
            remove: false,
            checked: false,
            importFile: false,
            file: null,
            filter: null,
            toUnused: false,
            showSuccess: false,
            selectedCard: null,
            cardNumber: '',
            note: '',
            alertAck: true,
            activePage: 1,
            pageSize: 10,
            searchString: '',
            statusChecked: '',
            dataSearch: {},
            optionStatusCard,
            fileName: 'No file choose',
            exportAll: false
        };

        this._columns = [
            {
                title: 'NO.',
                align: 'center',
                width: 100,
                key: 'no',
                render: (cell, record, index) => {
                    const i = (this.state.activePage - 1) * this.state.pageSize + index + 1;
                    return <span>{i}</span>
                }
            },
            {
                title: 'Edit',
                width: 100,
                align: 'center',
                key: 'action',
                render: (cell, record) => {
                    if([STATUS_CARD_NUMBER.UNUSED, STATUS_CARD_NUMBER.REJECTED].includes(record.status))
                        return (
                            <Icon
                                onClick={e => this.setState({isShowModal: true, selectedCard: record})}
                                className="cell-edit-button"
                                type="edit"
                                theme="filled"
                            />
                        )
                }
            },
            {
                key: 'cardNumber',
                width: 200,
                dataIndex: 'cardNumber',
                title: 'Card Number'
            },
            {
                key: 'expiredDate',
                width: 140,
                align: 'center',
                title: 'Expire date',
                dataIndex: 'expiredDate'
            },
            {
                key: 'createdAt',
                width: 200,
                title: 'Imported Date',
                render: (cell, record) => (
                    <>
                        {formatDateTimeFull(record.createdAt)}
                    </>
                )
            },
            {
                title: 'Status',
                key: 'status',
                align: 'center',
                width: 120,
                render: (cell, data) => {
                    if(data.status === STATUS_CARD_NUMBER.USED)
                        return <i className="far fa-check-circle text-success"/>
                    else if(data.status === STATUS_CARD_NUMBER.REJECTED)
                        return <i className="far fa-times-circle text-danger"/>
                }
            },
            {
                key: 'note',
                width: 120,
                title: 'Note',
                dataIndex: 'note'
            }
        ]
    }

    alertAck = () => {
        this.setState({alertAck: false});
    };

    componentDidMount() {
        this.alertAck();
        this.props.getCards({page: this.state.activePage});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cards !== this.props.cards) {
            this.setState({cards: nextProps.cards});
        }
        if (nextProps.status === STATUS.IMPORT_CARD_SUCCESS) {
            this.setState({importFile: false, showSuccess: true});
            this.props.getCards({page: this.state.activePage});
        }
        if (nextProps.dataSearch !== this.props.dataSearch && nextProps.dataSearch) {
            this.setState({note: nextProps.dataSearch.note});
        }
    }

    handleFilter = async (name, value) => {
        let filter = null;
        this.alertAck();

        switch (name) {
            case 'status':
                await this.setState({statusChecked: value})
                 break;
            case 'cardNumber':
                await this.setState({searchString: value})
                break;
            default:
                break
        }

        filter = {
            status: this.state.statusChecked ? this.state.statusChecked : '',
            cardNumber: this.state.searchString ? {$regex: this.state.searchString.replace(/ |-/g, ''), $options: 'i'} : ''
        }

        if(filter.status === '')
            delete filter.status
        if(filter.cardNumber === '')
            delete filter.cardNumber    
        
        this.setState({dataSearch: filter})
        this.setState({activePage: 1})    
        this.setState({filter})
        this.props.getCards({
            filter,
            page: 1,
            limit: this.state.pageSize
        })
    };

    handleExportCard () {
        const listIdCards = this.props.cards.map(card => card._id);
        
        this.props.exportCard({
            filter: this.state.filter,
            page: this.state.activePage,
            limit: this.state.pageSize,
            exportAll: this.state.exportAll,
            listIdCards
        })
    }

    handleChange(name) {
        return e => {
            if (e.target) {
                this.setState({[name]: e.target.value});
            }
            if (e.target.files) {
                this.setState({
                    [name]:
                        e.target.files && e.target.files.length ? e.target.files[0] : null
                });
               this.setState({fileName: e.target.files[0].name})
            }
        };
    }

    closeModal = () => {
        this.alertAck();
        this.setState({isShowModal: !this.state.isShowModal, selectedUser: null, edit: false, remove: false});
        this.props.resetStatus()
    };

    _handlePageChange = (type, value) => {
        if (type === 'page') {
            this.props.getCards({
                filter: this.state.dataSearch,
                page: value,
                limit: this.state.pageSize
            });
            this.setState({activePage: value})
        } else {
            this.setState({activePage: 1})
            this.props.getCards({
                filter: this.state.dataSearch,
                page: 1,
                limit: value,
            });
            this.setState({pageSize: value})
        }

        const cardTable = document.getElementsByClassName('ant-table-body')
        cardTable[0].scrollTop = 0
    };

    checkData() {
        const {selectedCard} = this.state;
        let checked =
            !isNaN(selectedCard.cardNumber.replace(/-/g, '')) &&
            !isNaN(selectedCard.expiredDate.replace(/\//g, '')) &&
            moment(selectedCard.expiredDate, 'MM/YY').isValid();
        this.setState({checked});
    }

    handleCardNumber(name) {
        let {selectedCard} = this.state;
        return (e) => {
            if (e.target) {
                const newCard = {...selectedCard, [name]: e.target.value};
                this.setState({selectedCard: newCard}, () => {
                    this.checkData();
                });
            }
        }
    }

    handleSubmit() {
        let {selectedCard} = this.state;
        const data = {
            _id: selectedCard._id,
            cardNumber: selectedCard.cardNumber.replace(/-/g, ''),
            expiredDate: selectedCard.expiredDate,
            note: (selectedCard.note && selectedCard.note.trim()) || '',
        };
        
        if (selectedCard && selectedCard.status === STATUS_CARD_NUMBER.REJECTED)
            data.status = STATUS_CARD_NUMBER.UNUSED;

        this.alertAck();
        this.setState({isShowModal: false, edit: true}, () => {
            this.props.editCard(data);
        });
    }

    handleMoveTrash() {
        let {selectedCard} = this.state;
        const data = {
            status: STATUS_CARD_NUMBER.REJECTED,
            _id: selectedCard._id,
        };
        this.alertAck();
        this.setState({isShowModal: false, remove: true}, () => {
            this.props.editCard(data);
        });
    }

    renderEditDialog = () => {
        const {isShowModal, selectedCard, checked, edit, remove} = this.state;
        const status = selectedCard && selectedCard.status;
        if (selectedCard)
            return (
                <Modal isOpen={isShowModal} className="edit-card-modal">
                    <ModalHeader toggle={this.closeModal}>Edit card</ModalHeader>
                    <ModalBody>
                        <div className="form">
                            <div className="field mb-2">
                                <p className="label">Card number</p>
                                <div>
                                    <InputMask
                                        className="input-style form-control bold"
                                        mask="9999-9999-9999-9999"
                                        value={selectedCard.cardNumber}
                                        onChange={this.handleCardNumber('cardNumber')}
                                        placeholder="Enter card number"
                                        disabled={status === STATUS_CARD_NUMBER.REJECTED}
                                    />
                                </div>
                            </div>
                            <div className="field mb-2">
                                <p className="label">Expire day</p>
                                <div>
                                    <InputMask
                                        mask="99/99"
                                        className="input-style form-control bold"
                                        value={selectedCard.expiredDate}
                                        onChange={this.handleCardNumber('expiredDate')}
                                        placeholder="Enter expired day (mm/yy)"
                                        disabled={status === STATUS_CARD_NUMBER.REJECTED}
                                    />
                                </div>
                            </div>
                            { status === STATUS_CARD_NUMBER.UNUSED && 
                                <div className="field mb-2">
                                    <p className="label">Is used</p>
                                    <div>
                                        <Input
                                            value={'No'}
                                            className="input-style form-control"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="field mb-2">
                                <p className="label">Note</p>
                                <div>
                                    <InputMater
                                        value={selectedCard.note}
                                        className="input-style form-control note-card"
                                        onChange={this.handleCardNumber('note')}
                                        disabled={status === STATUS_CARD_NUMBER.REJECTED}
                                        multiline
                                        rowsMax={5}
                                        disableUnderline
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <p className="label"></p>
                                <div className="text-center mt-4 action-box">
                                    { status === STATUS_CARD_NUMBER.UNUSED && 
                                        <React.Fragment>
                                            <ButtonAntd
                                                disabled={!checked}
                                                outline
                                                color="secondary"
                                                className="mb-4 h-40 custom-btn btn-save-edit"
                                                variant="contained"
                                                onClick={() => this.setState({submitSave: true})}
                                            >
                                                {edit && this.props.status === STATUS.EDIT_CARD_SUCCESS && (
                                                    <i className='fas fa-check icon mr-2'/>)}
                                                Save
                                            </ButtonAntd>
                                            <ButtonAntd
                                                type="delete"
                                                outline
                                                color="secondary"
                                                className="mb-4 h-40 custom-btn"
                                                variant="contained"
                                                onClick={() => this.setState({submitDelete: true})}
                                            >
                                                {remove && this.props.status === STATUS.EDIT_CARD_SUCCESS && (
                                                    <i className='fas fa-check icon mr-2'/>)}
                                                Delete
                                            </ButtonAntd>
                                        </React.Fragment>
                                    }
                                    { status === STATUS_CARD_NUMBER.REJECTED && 
                                        <React.Fragment>
                                            <ButtonAntd
                                                type="delete"
                                                outline
                                                color="secondary"
                                                className="mb-4 h-40 custom-btn btn-change-status"
                                                variant="contained"
                                                onClick={() => this.setState({submitSave: true})}
                                            >
                                                {remove && this.props.status === STATUS.EDIT_CARD_SUCCESS && (
                                                    <i className='fas fa-check icon mr-2'/>)}
                                                Set unused
                                            </ButtonAntd>
                                        </React.Fragment>
                                    }
                                    <ButtonAntd
                                        outline
                                        color="secondary"
                                        className="mb-4 h-40 custom-btn btn-cancle-update"
                                        variant="contained"
                                        onClick={() => this.closeModal()}
                                    >
                                        Cancel
                                    </ButtonAntd>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            );
        else return null
    };

    closeChangeCard = () => {
        this.setState({isShowChangeCard: false, cardNumber: '', note: ''});
        this.props.resetStatus()
    };

    renderChangeCardDialog = () => {
        const {isShowChangeCard, cardNumber, note, toUnused} = this.state;
        const {dataSearch} = this.props;
        return (
            <Modal isOpen={isShowChangeCard} className="edit-card-modal">
                <ModalHeader toggle={() => this.closeChangeCard()}>
                    Change card
                </ModalHeader>
                <ModalBody>
                    <div className="form">
                        <div className="field">
                            <p className="label">Change Card</p>
                            <div>
                                <InputMask
                                    className="input-style form-control bold"
                                    mask="9999-9999-9999-9999"
                                    value={cardNumber}
                                    onChange={this.handleChange('cardNumber')}
                                    placeholder="Enter card number"
                                />
                            </div>
                        </div>
                        {dataSearch && <React.Fragment>
                            <div className="field">
                                <p className="label">User</p>
                                <div>
                                    <Input
                                        value={dataSearch && dataSearch.fullName}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <p className="label">User ID</p>
                                <div>
                                    <Input
                                        value={dataSearch && dataSearch.businessMemberId}
                                        disabled/>
                                </div>
                            </div>
                            <div className="field">
                                <p className="label">Card number</p>
                                <div>
                                    <InputMask
                                        className="input-style form-control bold"
                                        mask="9999-9999-9999-9999"
                                        value={dataSearch && dataSearch.cardNumber}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <p className="label">Expire day</p>
                                <div>
                                    <InputMask
                                        mask="99/99"
                                        className="input-style form-control bold"
                                        value={dataSearch && dataSearch.expiredDate}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <p className="label">Note</p>
                                <div>
                                    <Input
                                        value={note}
                                        onChange={this.handleChange('note')}
                                        className="input-style form-control"
                                    />
                                </div>
                            </div>
                            <div className="checkbox">
                                <CcCheckbox
                                    checked={this.state.toUnused}
                                    onChange={() => this.setState({toUnused: !toUnused})}
                                    className="check-box-item"
                                />
                                <p>Put card card to unused list</p>
                            </div>
                        </React.Fragment>}
                    </div>
                    < div className="text-center mt-4 action-box form">
                        <div className="field group-button-action">
                            <span className='label'></span>
                            <div>
                                <ButtonAntd
                                    outline
                                    color="secondary"
                                    className="mb-2 h-40 custom-btn"
                                    variant="contained"
                                    disabled={!cardNumber || (cardNumber && isNaN(cardNumber.replace(/-/g, '')))}
                                    onClick={() => {
                                        this.alertAck();
                                        this.props.searchCardNumber({cardNumber})
                                    }}
                                >
                                    Find Card
                                </ButtonAntd>
                                <ButtonAntd
                                    outline
                                    disabled={this.props.status !== STATUS.SEARCH_CARD_SUCCESS || (note && !note.trim())}
                                    color="secondary"
                                    className="mb-2 h-40 custom-btn btn-change {}"
                                    variant="contained"
                                    onClick={() => this.setState({submitChange: true})}
                                >
                                    Change
                                </ButtonAntd>
                                <ButtonAntd
                                    outline
                                    color="secondary"
                                    className="mb-2 h-40 custom-btn btn-cancel"
                                    variant="contained"
                                    onClick={() => this.closeChangeCard()}
                                >
                                    Cancel
                                </ButtonAntd>
                            </div>
                        </div>    
                    </div>
                </ModalBody>
            </Modal>
        )
    };

    renderImportDialog = () => {
        const {importFile, file} = this.state;
        return (
            <Modal isOpen={importFile} className="edit-card-modal">
                <ModalHeader
                    toggle={() =>
                        this.setState({
                            importFile: false,
                            file: null
                        })
                    }
                >
                    Import Card from Excel
                </ModalHeader>
                <ModalBody>
                    <div className="form">
                        <div className="field">
                            <p className="label">Open file</p>
                            <div>
                                <label htmlFor="fileSelect" className="input-upload-card fa fa-upload">
                                   Upload
                                </label>
                                <input
                                    type="file"
                                    id="fileSelect"
                                    accept=".xlsx, .xls, .csv"
                                    onChange={this.handleChange("file")}
                                />
                                <div className='show-file-name'>
                                    {this.state.fileName}
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4 action-box field">
                            <p className="label"></p>
                            <div>
                                <ButtonAntd
                                    className='btn-import'
                                    color="secondary"
                                    variant="contained"
                                    disabled={this.props.status === STATUS.IMPORT_CARD_PROGRESS || !file}
                                    onClick={() => {
                                        this.alertAck();
                                        this.props.importCard({file})
                                    }}
                                >
                                    {this.props.status === STATUS.IMPORT_CARD_PROGRESS && (
                                        <CircularProgress className="margin-right-20px" size={20}/>
                                    )}
                                    Import
                                </ButtonAntd>
                            </div>
                        </div>
                    </div>
                    
                </ModalBody>
            </Modal>
        );
    };

    render() {
        const { adminStore: { currentUser }} = this.props;      
        const columns = this._columns;
        const {dataSearch, cards} = this.props;
        const {toUnused, note, exportAll} = this.state;
        return (
            <Translate>
                {({translate}) => (
                    <div className="card-management">
                        <div className="top-box">
                            <p className="title">カード倉庫管理</p>
                            <div className='selection-input'>
                                {
                                    currentUser && currentUser.name === ROLE_SUPPER_ADMIN && (
                                        <React.Fragment>
                                            <Select
                                                className="search-status"
                                                options={this.state.optionStatusCard}
                                                placeholder="選択ボックス"
                                                modern
                                                onChange={(e) => {
                                                    this.setState({statusChecked: e.target.value});
                                                    this.handleFilter('status', e.target.value)
                                                }}
                                                name="box"
                                                value={this.state.statusChecked}
                                                allowClear
                                            />
                                            <InputAntd
                                                className="input-search"
                                                placeholder="Card number"
                                                value={this.state.searchString}
                                                onChange={(e) => {
                                                    this.setState({searchString: e.target.value});
                                                }}
                                            />
                                            <ButtonAntd loading={false} onClick={ () => this.handleFilter('cardNumber', this.state.searchString) }>
                                                検索
                                            </ButtonAntd>
                                        </React.Fragment>
                                    )
                                }
                                
                            </div>
                            
                        </div>
                        <div className="top-action-box">
                            <div className='section-pagination'>
                                <div>
                                    <div className="card-infor">
                                        <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Remaining card in Stock
                                                </td>
                                                <td>
                                                    <b> 
                                                        {this.props.unusedCard}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Current waiting card quantity in checked list
                                                </td>
                                                <td>
                                                    <b>
                                                        {this.props.checkedApplication}
                                                    </b>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                    <div className="button-group">
                                        <PaginationBar
                                            current={this.state.activePage}
                                            total={this.props.total}
                                            onChange={ this._handlePageChange}
                                            pageSize={this.state.pageSize}
                                        />                                     
                                    </div>
                                </div>
                                
                                <div className="action-box">
                                    <div className="top-action">
                                        {
                                            currentUser && currentUser.name === ROLE_SUPPER_ADMIN && (
                                                <div className="checkbox-export">
                                                    <Checkbox
                                                        onChange={() => {
                                                            this.setState({
                                                                exportAll: !exportAll
                                                            });
                                                        }}
                                                        checked={exportAll}
                                                        color="#FFA500"
                                                    >
                                                        Select All Page To Export
                                                    </Checkbox>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="bottom-action">
                                        {
                                            currentUser && currentUser.name === ROLE_SUPPER_ADMIN && (
                                                <ButtonAntd
                                                    color="secondary"
                                                    onClick={() => this.setState({importFile: true})}
                                                >
                                                    Import Card
                                                </ButtonAntd>
                                            )
                                        }
                                        {
                                            currentUser && currentUser.name === ROLE_SUPPER_ADMIN && (
                                                <ButtonAntd
                                                    disabled={this.props.status === STATUS.CARDS_EXPORTING}
                                                    onClick={() => {
                                                        this.handleExportCard()
                                                    }}
                                                    color="secondary"
                                                >
                                                    {this.props.status === STATUS.CARDS_EXPORTING && (
                                                        <CircularProgress className="margin-right-20px" size={20}/>)}
                                                    Export Card
                                                </ButtonAntd>
                                            )
                                        }
                                        {
                                            currentUser && currentUser.name === ROLE_SUPPER_ADMIN && (
                                                <ButtonAntd
                                                    color="secondary"
                                                    onClick={() =>
                                                        this.setState({
                                                            isShowChangeCard: true
                                                        })
                                                    }
                                                >
                                                    Change Card
                                                </ButtonAntd>
                                            )
                                        }  
                                    </div>                                  
                                </div>
                            </div>
                        </div>
                         <Table
                            loading={this.props.status === STATUS.GET_CARDS_PROGRESS}
                            rowKey={(row, index) => index}
                            columns={columns}
                            dataSource={cards}
                            scroll={{ x: 1400, y: 'calc(100vh - 390px)' }}
                        />
                        {this.props.status === STATUS.UPDATE_CARD_SUCCESS && !this.state.alertAck && (
                            <Alert title="成功"
                                   content="Change card successfully"
                                   okTitle="OK"
                                   onOK={() => {
                                       this.setState({alertAck: true, statusChecked: '', toUnused: false});
                                       this.closeChangeCard();
                                       this.props.getCards({page: this.state.activePage})
                                   }}
                            />)
                        }

                        {this.state.submitChange &&
                        <Alert title="Confirm Dialog"
                               content="Do you want change this card？"
                               okTitle="OK"
                               cancelTitle="Cancel"
                               onOK={() => {
                                   this.setState({submitChange: false});
                                   this.alertAck();
                                   this.props.updateCard({
                                       ...dataSearch,
                                       toUnused,
                                       note: (note && note.trim()) || ''
                                   })
                               }}
                               onCancel={() => {
                                   this.setState({submitChange: false});
                               }}/>
                        }
                        {this.state.submitSave &&
                        <Alert title="Confirm Dialog"
                               content="Do you want save that change？"
                               okTitle="OK"
                               cancelTitle="Cancel"
                               onOK={() => {
                                   this.setState({submitSave: false});
                                   this.handleSubmit()
                               }}
                               onCancel={() => {
                                   this.setState({submitSave: false});
                               }}/>
                        }
                        {this.state.submitDelete &&
                        <Alert title="Confirm Dialog"
                               content="Do you want delete that？"
                               okTitle="OK"
                               cancelTitle="Cancel"
                               onOK={() => {
                                   this.setState({submitDelete: false});
                                   this.handleMoveTrash()
                               }}
                               onCancel={() => {
                                   this.setState({submitDelete: false});
                               }}/>
                        }
                        {this.state.showSuccess && !this.state.alertAck && (
                            <Alert title="成功"
                                    content={
                                            <p>
                                                <span>Import card successfully</span> <br></br>
                                                <span>Successfully added {this.props.addCardCount} { this.props.addCardCount > 1 ? "cards" : "card"}</span>
                                            </p>
                                    }
                                    okTitle="OK"
                                    onOK={() => {
                                        this.setState({
                                            alertAck: true,
                                            showSuccess: false,
                                            file: null,
                                            statusChecked: ''
                                        });
                                    }}
                            />)
                        }
                        {this.renderImportDialog()}
                        {this.renderEditDialog()}
                        {this.renderChangeCardDialog()}
                        {[STATUS.IMPORT_CARD_FAILED, STATUS.GET_CARDS_FAIL, STATUS.EDIT_CARD_FAIL, STATUS.SEARCH_CARD_FAIL, STATUS.UPDATE_CARD_FAIL].indexOf(this.props.status) >= 0
                        && !this.state.alertAck && (
                            <Alert title="Error"
                                   content={translate(this.props.error && ("errors." + this.props.error.code))}
                                   okTitle="OK"
                                   onOK={() => {
                                       this.setState({alertAck: true});
                                   }}/>
                        )}
                    </div>

                )}
            </Translate>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.cardManagement.error,
        status: state.cardManagement.status,
        cards: state.cardManagement.cards,
        pages: state.cardManagement.pages,
        dataSearch: state.cardManagement.dataSearch,
        unusedCard: state.cardManagement.unusedCard,
        checkedApplication: state.cardManagement.checkedApplication,
        total: state.cardManagement.total,
        loading: state.cardManagement.loading,
        addCardCount: state.cardManagement.addCardCount,
        adminStore: state.admin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        importCard: data => {
            dispatch(importCard(data));
        },
        getCards: data => {
            dispatch(getCards(data));
        },
        exportCard: data => {
            dispatch(exportCard(data));
        },
        editCard: data => {
            dispatch(editCard(data));
        },
        searchCardNumber: data => {
            dispatch(searchCardNumber(data));
        },
        updateCard: data => {
            dispatch(updateCard(data));
        },
        resetStatus: () => {
            dispatch(resetStatus());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardManagement);
