import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Step from '../../../../components/Step';
import StepInFree from '../../../../components/StepInFree';
import {getCurrentStepFromUrl} from '../../../../utils/commonUtils';
import './style.css';


class RegisterForm extends React.Component {

    render() {
        return (
            <div className="container-pages-register-form">
                <div className="header">eZ-pay Registration Form</div>
                {
                    this.props.isFree ?
                        <StepInFree activeStep={getCurrentStepFromUrl(this.props.location.search)}/>
                        :
                        <Step activeStep={getCurrentStepFromUrl(this.props.location.search)}/>
                }
                {this.props.children}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentStep: state.registration.currentStep,
        isFree: state.registration.isFree
    }
};

export default withRouter(connect(mapStateToProps)(RegisterForm));
