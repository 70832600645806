import React from 'react';
import NarrowLayout from "../../../components/_layouts/NarrowLayout";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import './style.css';

export default () => (

    <NarrowLayout>
        <div className="con-pages-home">
            <Header/>
            <Menu/>
            <div className="home-content">
            </div>
        </div>
    </NarrowLayout>

);
