import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Popover } from "antd";
import styled from "styled-components";

import { logout, ACTIONS } from "../../../../actions/authActions";
import { toggleSideBar } from "../../../../actions/uiAction";
import "./style.css";
import { AccountCircle } from "@material-ui/icons";
import { getId } from "../../../../utils/apiUtils";

const HeaderContainer = styled.header`
  width: 100%;
  height: 50px;
  color: white;
  display: flex;
  background-color: #005398;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0px 0 8px 2px rgba(0, 0, 0, 0.2);
  z-index: 0;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-box {
      display: flex;
      align-items: center;

      .menu-button {
        margin-right: 20px;
        font-size: 22px;
        cursor: pointer;
        transition: opacity 0.2s;
        user-select: none;

        &:hover {
          opacity: 0.8;
        }

        &:active {
          opacity: 0.6;
        }
      }

      .title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 0;
      }
    }

    .right-box {
      display: flex;
      align-items: center;

      .user-box {
        display: flex;
        align-items: center;
        /* margin-right: 37px; */
        cursor: pointer;
        user-select: none;

        .avatar {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          margin-right: 20px;
        }

        .name {
          font-size: 16px;
          letter-spacing: 0.21px;
          margin-bottom: 0;
          margin-right: 10px;
        }

        .anticon {
          font-size: 12px;
        }
      }

      .flags {
        .flag {
          width: 35px;
          cursor: pointer;
          transition: opacity 0.2s;
          margin-right: 16px;
          opacity: 0.5;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            opacity: 0.7;
          }

          &.active {
            opacity: 1;
            cursor: auto;
          }
        }
      }
    }
  }
`;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer: false,
      menu: false
    };
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { error } = nextProps;
    if (nextProps.error !== this.props.error) {
      if (error && (error.code === ACTIONS.NOT_AUTHENTICATED_ERROR || error.code === ACTIONS.TOKEN_EXPIRED)) {
        this.props.logout();
      }
    }
  }

  handleMenuClose() {
    this.setState({ menu: false });
  }

  render() {
    const id = getId() || "";
    const { toggleSideBar } = this.props;

    return (
      <HeaderContainer>
        <div className="content">
          <div className="left-box">
            {this.props.loggedIn && (
              <Icon
                onClick={toggleSideBar}
                className="menu-button"
                type="menu"
              />
            )}
            <p className="title">カードシステム管理</p>
          </div>
          <div className="right-box">
            {this.props.loggedIn && (
              <Popover
                content={
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleMenuClose();
                      this.props.logout();
                    }}
                  >
                    ログアウト
                  </a>
                }
                trigger="click"
              >
                <div className="user-box">
                  {/* <img
                        className="avatar"
                        src="https://image-us.eva.vn/upload/2-2019/images/2019-04-24/ra-mv-moi-chipu-thi-dep-that-nhung-fan-chi-chu-y-den-chiec-luoc-chai-chay-f1e397547b12924ccb03-1556124078-242-width1000height1000.jpg"
                        alt=""
                    /> */}
                  <p className="name">{id}</p>
                  <AccountCircle />
                </div>
              </Popover>
            )}
          </div>
        </div>
      </HeaderContainer>
    );
  }
}

const mapStateToProps = store => {
  return {
    loggedIn: store.auth.user,
    user: store.auth.user,
    status: store.auth.status,
    error: store.admin.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(logout());
    },
    toggleSideBar: () => {
      dispatch(toggleSideBar());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
