import React from 'react';

import './style.css';

export default class ContentPanel extends React.Component {
	render() {
		return (
			<div className="component-ContentPanel">
				{(this.props.header || this.props.subHeader) && (
					<div className="header">
						<div className="mainHeader">{this.props.header}</div>
						<div className="subHeader">{this.props.subHeader}</div>
					</div>
				)}
				<div className="content-in-panel">{this.props.children}</div>
			</div>
		);
	}
}
