import { PERMISSION } from '../utils/commonUtils';

export const routes = [
    {
        name: "権限設定",
        link: "/admin/permission-setting",
        permission: PERMISSION.PERMISSION
    },
    {
        name: "管理者設定",
        link: "/admin/administrator-setting",
        permission: PERMISSION.MEMBER
    },
    {
        name: "申し込み状況",
        link: "/admin/application-status",
        children: [
            {
                no: "1",
                name: "カード申込者",
                extend: "(未決済）",
                link: "/admin/application-status/wait-subcription-payment",
                permission: PERMISSION.VIEW_UNPAY_LIST
            },
            {
                no: "2",
                name: "カード申込者",
                extend: "(決済済)(未チェック)",
                link: "/admin/application-status/payment-completed-pending",
                permission: PERMISSION.VIEW_DONE_LIST
            },
            {
                no: "3-0",
                name: "再申請",
                extend: "(未チェック用)",
                link: "/admin/application-status/reconfirm-for-unchecked",
                permission: PERMISSION.VIEW_RECONFIRM_LIST
            },
            {
                no: "3-1",
                name: "再申請",
                extend: "(チェック済用)",
                link: "/admin/application-status/reconfirm-for-checked",
                permission: PERMISSION.VIEW_RECONFIRMED_LIST
            },
            {
                no: "4",
                name: "カード申込者",
                extend: "(決済済)(チェック済)",
                link: "/admin/application-status/payment-checked-complete",
                permission: PERMISSION.VIEW_CHECKED_LIST
            },
            {
                no: "5-0",
                name: "カード印字",
                link: "/admin/application-status/list-print-card",
                permission: PERMISSION.VIEW_PRINTED_LIST
            },
            {
                no: "5-1",
                name: "カード送付保留",
                extend: "",
                link: "/admin/application-status/reserve-list",
                permission: PERMISSION.VIEW_RESERVE_LIST
            },
            {
                no: "5-2",
                name: "カード修正",
                link: "/admin/application-status/card-update",
                permission: PERMISSION.VIEW_CARD_UPDATE_LIST
            },
            {
                no: "6",
                name: "カード最終チェック",
                extend: "",
                link: "/admin/application-status/input-cards-application",
                permission: PERMISSION.VIEW_INPUT_LIST
            },
            {
                no: "7-0",
                name: "住所確認前",
                extend: "(送付前)",
                link: "/admin/application-status/before-confirm-address",
                permission: PERMISSION.VIEW_SENT_LIST
            },
            {
                no: "7-1",
                name: "住所確認前",
                extend: "(送付済)",
                link: "/admin/application-status/confirming-address",
                permission: PERMISSION.VIEW_CONFIRMING_LIST
            },
            {
                no: "8",
                name: "住所確認済",
                extend: "(カード未送付)",
                link: "/admin/application-status/before-sent-card",
                permission: PERMISSION.VIEW_CONFIRMED_LIST
            },
            {
                no: "8-1",
                name: "カード発送準備中",
                extend: "",
                link: "/admin/application-status/confirmed-preparing-shipment",
                permission: PERMISSION.VIEW_CONFIRMED_PREPARING_SHIPMENT_LIST
            },
            {
                no: "8-2",
                name: "返還カード",
                extend: "",
                link: "/admin/application-status/confirmed_return",
                permission: PERMISSION.VIEW_CONFIRMED_RETURN
            },
            {
                no: "8-3",
                name: "カード再発送準備中",
                extend: "",
                link: "/admin/application-status/confirmed_preparing_reshipment",
                permission: PERMISSION.VIEW_CONFIRMED_PREPARING_RESHIPMENT
            },
            {
                no: "9",
                name: "カード送付完了",
                extend: "(送付)",
                link: "/admin/application-status/completed-sent-card",
                permission: PERMISSION.VIEW_COMPLETED_LIST
            },
            {
                no: "10",
                name: "有効カード",
                extend: "",
                link: "/admin/application-status/card-valid",
                permission: PERMISSION.VIEW_CARD_VALID_LIST
            },
            {
                no: "0-0",
                name: "未返金",
                extend: "",
                link: "/admin/application-status/refunding",
                permission: PERMISSION.VIEW_REFUNDING_LIST
            },
            {
                no: "0-1",
                name: "返金済",
                extend: "",
                link: "/admin/application-status/refunded",
                permission: PERMISSION.VIEW_REFUNDED_LIST
            }
        ]
    },
    {
        no: "",
        name: "申込者数",
        extend: "",
        link: "/admin/statistic-number-application",
        permission: PERMISSION.VIEW_STATISTIC_NUMBER_APPLICATION
    },
    {
        name: "カード倉庫管理",
        link: "/admin/card-management",
        permission: ''
    },
    {
        name: "エクセル出力",
        link: "/admin/excel-export",
        permission: PERMISSION.ALLOW_EXPORT_EXCEL
    },
    {
        name: "申す込み古いと新しい",
        link: "/admin/compare-data",
        permission: ''
    },
    {
        name: "申し込みリスト",
        link: "/admin/registration-list",
        permission: PERMISSION.VIEW_ALL_REGISTRATION
    },
    {
        name: "原文",
        link: "/admin/raw-registration-list",
        permission: ''
    },
    {
        name: "アプリケーションを登録する",
        link: "/admin/registration-form-admin",
        permission: ''
    },
    {
        name: "ログ",
        link: "/admin/logs",
        permission: ''
    },
    {
        name: "ログイン履歴",
        link: "/admin/log-login",
        permission: ''
    },
    {
        name: "パスポート画像出力",
        link: "/admin/passport-image-export",
        permission: PERMISSION.PASSPORT_IMAGE_EXPORT
    },
    {
        name: "APIサービス管理",
        link: "/admin/manage-api-service",
        permission: PERMISSION.API_SERVICE
    },
    {
        name: "メール送信システム",
        link: "/admin/system-send-mail",
        children: [
            {
                name: "一斉送信",
                link: "/admin/system-send-mail/send-all-user",
                permission: PERMISSION.MAIL_SERVICE
            },
            {
                name: "グループのユーザーを送信",
                link: "/admin/system-send-mail/send-each-user",
                permission: PERMISSION.MAIL_SERVICE
            },
            {
                name: "支払うメールを再送する",
                link: "/admin/system-send-mail/send-mail-payment-again",
                permission: PERMISSION.MAIL_SERVICE
            }
        ]
    },
    {
        name: '申し込み状況一覧表',
        link: '/admin/statistic-application',
        permission: [PERMISSION.STATISTIC_APPLICATION_BY_YEAR, PERMISSION.STATISTIC_APPLICATION_BY_DAYS]
    }
];
