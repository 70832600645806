import React from 'react';
import Dialog from "../../components/Dialog";
import {Button, Select, MenuItem, CircularProgress} from '@material-ui/core';
import {STATUS, initPayment, loginArmAccount, fetchWallets, paymentCard} from "../../actions/registrationActions";
import Alert from "../../components/Alert";
import {parseUrlQuery} from "../../utils/commonUtils";
import CodeInput from "../../components/CodeInput";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Translate} from 'react-localize-redux';
import {payment} from "../../constants/commonData";
import { Radio } from 'antd';
import './style.css';

const InputField = ({type, title, onChange}) => (
    <div className="input">
        <span>{title}</span>
        <input onChange={onChange} className="large-input" type={type}/>
    </div>
);

const COIN = {
    GBT: "GBT",
    RBP: "RBP"
}

class PaymentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            armId: '', password: '', selectedPaymentWallet: -1, code: null,
            isSettlement: false,
            showLoginDialog: false,
            alertAck: false,
            statusInfo: {},
            wallets: [],
            coinType: COIN.GBT,
        };
        let params = parseUrlQuery(props.location.search);
        if (params['purchase_id']) {
            this.state.showLoginDialog = true;
            this.state.purchaseId = params['purchase_id'];
            props.initPayment();
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {

    }

    handleLogin(e) {
        e.preventDefault();
        this.props.loginArmAccount({armId: this.state.armId, password: this.state.password}, (status) => {
            if (status === STATUS.STEP4.LOGIN_SUCCESS && this.state.coinType === COIN.RBP)
                this.setState({isSettlement: true});
        });
        this.setState({alertAck: false});
    }

    handleWalletsData(data) {
        return data && data.wallets && data.wallets.map((wallet) => {
            return {
                balance: Array.isArray(wallet.balances) && wallet.balances.length > 0 && wallet.balances.find(wallet =>
                    wallet.asset_code === payment.currency),
                name: wallet.name,
                _id: wallet._id,
                address: wallet.address,
                userID: wallet.userID
            }
        });
    }

    handleInputChange(field, value) {
        let newState = {};
        newState[field] = value;
        this.setState(newState);
    }

    componentDidUpdate(prevProps) {
        if (this.props.armToken !== prevProps.armToken && this.state.coinType === COIN.GBT) {
            this.props.fetchWallets({armToken: this.props.armToken, applicationId: this.state.purchaseId});
        }
    }

    render() {

        let wallets = this.handleWalletsData(this.props.stepData.statusInfo);
        return (
            <Translate>
                {({translate}) => (
                    <div className="container-Payment">
                        <Dialog title="eZpay issue fee settlement page" visible={true}>
                            {(
                                !this.props.stepData.status || 
                                this.props.stepData.status === STATUS.STEP4.CHECK_TOKEN_SUCCESS || 
                                [
                                    STATUS.STEP4.LOGIN_PROGRESS, 
                                    STATUS.STEP4.FETCH_WALLETS, 
                                    STATUS.STEP4.LOGIN_FAILED, 
                                    STATUS.STEP4.FETCH_FAILED
                                ].indexOf(this.props.stepData.status) >= 0) && (
                                <fieldset
                                    disabled={this.props.stepData.status && [STATUS.STEP4.LOGIN_PROGRESS, STATUS.STEP4.FETCH_WALLETS].indexOf(this.props.stepData.status) >= 0}>
                                    <div className="text-center">Please login with your GreenBox account to continue
                                        with payment process
                                    </div>
                                    <div className="option-payment">
                                        <Radio.Group 
                                            onChange={e => this.setState({coinType: e.target.value})} 
                                            value={this.state.coinType}
                                        >
                                            <Radio value={COIN.GBT}> Payment with GBT (110) </Radio> <br/>
                                            <Radio value={COIN.RBP}> Payment with RBP (110) </Radio>
                                        </Radio.Group>
                                    </div>
                                    <div className="login-fields">
                                        <InputField onChange={(e) => {
                                            this.handleInputChange("armId", e.target.value);
                                        }} type="text" title="Email"/>
                                        <InputField onChange={(e) => {
                                            this.handleInputChange("password", e.target.value);
                                        }} type="password" title="Password"/>
                                    </div>
                                    <div className="controls">
                                        <Button
                                            disabled={!this.state.armId || !this.state.password || (this.props.stepData.status && [STATUS.STEP4.LOGIN_PROGRESS, STATUS.STEP4.FETCH_WALLETS].indexOf(this.props.stepData.status) >= 0)}
                                            color="primary"
                                            variant="contained" onClick={(e) => {
                                            this.handleLogin(e);
                                        }}>
                                            {this.props.stepData.status && [STATUS.STEP4.LOGIN_PROGRESS, STATUS.STEP4.FETCH_WALLETS].indexOf(this.props.stepData.status) >= 0 && (
                                                <CircularProgress className="margin-right-20px" size={20}/>
                                            )}
                                            <span>Login</span>
                                        </Button>
                                    </div>
                                </fieldset>
                            )}
                            {(  this.state.coinType === COIN.GBT && ( 
                                (   this.props.stepData.statusInfo && 
                                    this.props.stepData.statusInfo.totalPrice === 0 && 
                                    this.props.stepData.statusInfo.totalPrice === 0 && 
                                    this.props.stepData.statusInfo.totalPrice === 0 && 
                                    [STATUS.STEP4.PAYMENT_PROGRESS, STATUS.STEP4.PAYMENT_FAILED].indexOf(this.props.stepData.status) >= 0
                                ) || (this.props.stepData.status === STATUS.STEP4.FETCH_COMPLETED && !this.state.isSettlement))) && (
                                <div>
                                    <div className="text-center detail-payment">
                                        <p className="prompt">eZpay Card Issuance Fees</p>
                                        <p>Application number: {this.props.stepData.statusInfo.totalCard} cards</p>
                                        <p>Commission amount: {this.props.stepData.statusInfo.totalPrice} GBT</p>
                                    </div>
                                    <div className="text-center">Using GreenBox
                                        account: {this.props.stepData.armId}</div>
                                    {this.props.stepData.statusInfo.totalPrice !== 0 && (
                                        <div className="wallet-selector">
                                            <div className="title">Wallet to make settlement</div>
                                            <Select className="select"
                                                    value={this.state.selectedPaymentWallet}
                                                    onChange={(e) => {
                                                        this.setState({selectedPaymentWallet: e.target.value})
                                                    }}
                                            >
                                                {wallets.map((wallet, index) => (
                                                    <MenuItem disabled={!wallet.balance} key={index} value={index}>
                                                        <div className="wallet-info">
                                                            <div className="wallet-name">{wallet.name}</div>
                                                            <div
                                                                className={"wallet-amount" + (wallet.balance && Number(wallet.balance.balance) < this.props.stepData.statusInfo.totalPrice ? " insufficient" : "")}>{wallet.balance ? Number(wallet.balance.balance).toFixed(2) : "no trust "}
                                                                GBT
                                                            </div>
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    )}

                                    <div className="controls">
                                        <Button
                                            disabled={[STATUS.STEP4.PAYMENT_PROGRESS].indexOf(this.props.stepData.status) >= 0 || (this.props.stepData.statusInfo.totalPrice > 0 && (this.state.selectedPaymentWallet === -1 || Number(wallets[this.state.selectedPaymentWallet].balance.balance) < this.props.stepData.statusInfo.totalPrice))}
                                            color="primary"
                                            onClick={() => {
                                                this.setState({alertAck: false});
                                                if (this.props.stepData.statusInfo.totalPrice > 0) {
                                                    this.setState({isSettlement: true});
                                                }
                                                else {
                                                    this.props.paymentCard({
                                                        armToken: this.props.armToken,
                                                        applicationInfoId: this.props.stepData.statusInfo.applicationInfoId,
                                                    });
                                                }
                                            }}
                                            variant="contained">
                                            {this.props.stepData.status && [STATUS.STEP4.PAYMENT_PROGRESS].indexOf(this.props.stepData.status) >= 0 && (
                                                <CircularProgress className="margin-right-20px" size={20}/>
                                            )}
                                            {(this.props.stepData.statusInfo.totalPrice === 0 ? "Free " : "") + "Settlement"}
                                        </Button>
                                    </div>
                                </div>)
                            }

                            {(  (this.state.coinType === COIN.RBP && this.props.stepData.status === STATUS.STEP4.LOGIN_SUCCESS) || 
                                this.state.isSettlement ) && (
                                <fieldset disabled={this.props.stepData.status === STATUS.STEP4.PAYMENT_PROGRESS}>
                                    <div className="prompt">
                                        <div>Please enter two factor authentication code</div>
                                    </div>
                                    <div className="code-input">
                                        <CodeInput type="number" count={6} onInputNotFinish={() => {
                                            this.setState({code: null});
                                        }} onInputFinish={(value) => {
                                            this.setState({code: value});
                                        }}/>
                                    </div>
                                    <div className="input">
                                        <div className="control">
                                            <Button
                                                onClick={() => {
                                                    this.setState({alertAck: false});
                                                    this.props.paymentCard({
                                                        armToken: this.props.armToken,
                                                        applicationInfoId: this.state.purchaseId,
                                                        walletId: this.state.coinType === COIN.GBT ? wallets[this.state.selectedPaymentWallet]._id : null,
                                                        code: this.state.code,
                                                        coinType: this.state.coinType
                                                    });
                                                }}
                                                disabled={!this.state.code || this.props.stepData.status === STATUS.STEP4.PAYMENT_PROGRESS}
                                                variant="contained"
                                                color="primary">
                                                {this.props.stepData.status && [STATUS.STEP4.PAYMENT_PROGRESS].indexOf(this.props.stepData.status) >= 0 && (
                                                    <CircularProgress className="margin-right-20px" size={20}/>
                                                )}
                                                <span>Confirm</span>
                                            </Button>
                                        </div>
                                    </div>
                                </fieldset>
                            )}

                            {[STATUS.STEP4.PAYMENT_FAILED, STATUS.STEP4.FETCH_FAILED, STATUS.STEP4.LOGIN_FAILED].indexOf(this.props.stepData.status) >= 0 && !this.state.alertAck && (
                                <Alert title="Error"
                                       content={translate("errors." + this.props.stepData.statusInfo.error.code)}
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                        </Dialog>
                    </div>
                )}
            </Translate>

        );
    }
}

function mapStateToProps(state) {
    return {
        currentStep: state.registration.currentStep,
        stepData: state.registration.stepData,
        armToken: state.registration.armToken,
        armId: state.registration.armId,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        initPayment: () => {
            dispatch(initPayment());
        },
        loginArmAccount: (data, callBack) => {
            dispatch(loginArmAccount(data, callBack))
        },
        fetchWallets: (data) => {
            dispatch(fetchWallets(data))
        },
        paymentCard: (data) => {
            dispatch(paymentCard(data))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentDialog));