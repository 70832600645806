import React from "react";
import {connect} from "react-redux";
import {withRouter, Redirect, Link} from "react-router-dom";
import {Translate} from "react-localize-redux";
import {
    STATUS,
    sendInstructionEmail,
    modifyInfo,
    getUserInfo,
    checkTokenPayment
} from "../../../../actions/registrationActions";
import "./style.css";

import PaymentDialog from "../../../Payment";
import RegisterForm from "../Form";
import RegistrationForm from "../../../RegistrationForm";
import {saveUserRegisterToken} from "../../../../utils/apiUtils";
import {Button, CircularProgress} from "@material-ui/core";
import {parseUrlQuery} from "../../../../utils/commonUtils";
import Alert from "../../../../components/Alert";

class Step4 extends React.Component {
    constructor(props) {
        super(props);
        let showDialog = false;
        let showRegisterInfo = false;

        const parsed = parseUrlQuery(props.location.search);
        if (parsed["purchase_id"]) {
            saveUserRegisterToken({token: parsed["token"]});
            showDialog = true;
        } else {
            showRegisterInfo = true;
        }
        this.state = {
            showRegisterInfo,
            showDialog,
            alertAck: false
        };
    }

    handleShowConfirm() {
        this.setState({showRegisterInfo: false});
    }

    componentDidMount() {
        this.props.getUserInfo({applicationInfoId: this.props.applicationInfoId});
        let params = parseUrlQuery(this.props.location.search);
        this.props.checkTokenPayment(params.token || '');
    }

    // componentWillReceiveProps(nextProps) {
    //     if (this.props.stepData !== nextProps.stepData) {
    //         this.setState({stepData: nextProps.stepData});
    //     }
    // }

    render() {
        return (
            <Translate>
                {({translate}) => (
                    <RegisterForm>
                        {this.props.stepData.status === STATUS.STEP3.MODIFY && (
                            <Redirect to="/registers?step=3"/>
                        )}
                        {this.props.stepData.status === STATUS.STEP4.PAYMENT_FINISHED && (
                            <Redirect to="/registers?step=5"/>
                        )}
                        {this.state.showRegisterInfo && (
                            <div className="container-register-page4 container-register-step-4">
                                <RegistrationForm
                                    confirm={true}
                                    data={
                                        this.props.stepData && this.props.stepData.applicationInfo
                                    }
                                    goBack={() => {
                                        this.props.modifyInfo();
                                    }}
                                    showConfirm={() => this.handleShowConfirm()}
                                />
                            </div>
                        )}
                        {!this.state.showRegisterInfo && (
                            <div className="container-register-page4 container-register-step-4">
                                {this.props.isFree ? (
                                    <React.Fragment>
                                        <div className="content">
                                            We accepted your application. To the registered email address<br/>
                                            We sent the following content:<br/><br/>

                                            - Receipt number<br/><br/>

                                            Please keep this email carefully until issuing the card.<br/>
                                            Also, when making an inquiry, please be sure to inquiry the reception
                                            number,
                                            name, registered email
                                            address.<br/>
                                            Please let me know.<br/>
                                        </div>
                                        <div className="text-center">
                                            <Link to="/">
                                                <Button variant="contained" color="primary">OK</Button>
                                            </Link>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div className="content">
                                        We will send you an email containing payment instruction.
                                        <br/>
                                        Please prepare your account information and follow the
                                        instruction in the email. <br/>
                                        We're done from here, please close the browser tab when the
                                        process finishes.
                                    </div>
                                )}
                                {
                                    this.state.showDialog &&
                                    this.props.stepData.status &&
                                    this.props.stepData.status === STATUS.STEP4.CHECK_TOKEN_PROGRESS && (
                                        <div className="d-flex justify-content-center align-items-center waiting-point">
                                            <CircularProgress size={40}/>
                                        </div>
                                    )}
                                {
                                    this.state.showDialog &&
                                    this.props.stepData.status &&
                                    this.props.stepData.status === STATUS.STEP4.CHECK_TOKEN_FAILED &&
                                    !this.state.alertAck && (
                                        <Alert title="Error"
                                               content={translate("errors." + this.props.stepData.statusInfo.error.code)}
                                               okTitle="OK"
                                               onOK={() => {
                                                   this.setState({alertAck: true});
                                               }}
                                        />
                                    )}
                                {
                                    this.props.stepData.status &&
                                    this.props.stepData.status !== STATUS.STEP4.PAYMENT_FINISHED &&
                                    (<PaymentDialog/>)
                                }
                            </div>
                        )}
                    </RegisterForm>
                )}
            </Translate>
        );
    }
}

const mapStateToProps = state => {
    return {
        applicationInfoId: state.registration.stepData.applicationInfo
            ? state.registration.stepData.applicationInfo._id
            : null,
        currentStep: state.registration.currentStep,
        stepSuccess: state.registration.stepSuccess,
        stepData: state.registration.stepData,
        isFree: state.registration.isFree
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendEmail: data => {
            dispatch(sendInstructionEmail(data));
        },
        modifyInfo: () => {
            dispatch(modifyInfo());
        },
        getUserInfo: data => {
            dispatch(getUserInfo(data));
        },
        checkTokenPayment: (data) => {
            dispatch(checkTokenPayment(data))
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Step4)
);
