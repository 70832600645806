import { Divider, Form } from 'antd';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';
import actions, { ACTIONS } from "../../../../../../actions/registrationTrippleActions";
import { Button, FieldItem, Input, Select, DatePicker } from '../../../../../../components/antd';
import CodeInput from "../../../../../../components/antd/code-input";
import { getEnv } from "../../../../../../env";
import { applicationInfoOnLocal, professionOptions } from "../../../../../../utils/commonUtils";
import './style.css';
import { businessIds } from '../../../../../../constants/commonData'

const genderOptions = [{ value: 'male', name: 'Male'}, { value: 'female', name: 'Female'},];
const nationalityOptions = [
  { value: 'CHN', name: 'China'},
  { value: 'TWN', name: 'Taiwan'},
  { value: 'HKG', name: 'Hongkong'},
  { value: 'MAC', name: 'Macao'},
  { value: 'JPN', name: 'Japan'}
];
const countrieOptions = getEnv('ALLOWED_ADDRESS_COUNTRIES');

class RegistrationStep6 extends Component {
  constructor (props) {
    super(props);
    const { registerTrippleStore: { businessMemberId } } = props;
    this.initialValues = {
      quantity: 1,
      baseCurrency: 'USD',
      businessMemberId,
      ...applicationInfoOnLocal(businessMemberId),
    }
  }

  _renderForm = ({ handleSubmit, ...form }) => {    
    const { prevStep, registerTrippleStore: { type }, businessId } = this.props;
    return (
      <Translate>
        {({translate}) => (
          <Form className="form" labelCol={{ span: 5 }} wrapperCol={{ span: 5 }}>
            <p className="sub-title">{translate('User Information')}</p>
            <FieldItem form={form} component={Input} name="businessMemberId" label="Business Member Id" required disabled />
            <FieldItem form={form} component={Input} name="emailBusiness" label="Email" required disabled />
            <FieldItem
              form={form}
              name="gender"
              label="Gender"
              required
              component={Select}
              options={genderOptions}
              disabled
            />
            <FieldItem
              form={form}
              name="firstname"
              label="Given Name"
              required
              component={CodeInput}
              count={13}
              limit={1}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              disabled
            />
            <FieldItem
              form={form}
              name="middlename"
              label="Middle Name"
              component={CodeInput}
              count={13}
              limit={1}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              disabled
            />
            <FieldItem
              form={form}
              name="lastname"
              label="Family Name"
              required
              component={CodeInput}
              count={13}
              limit={1}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              disabled
            />
            { businessId === businessIds.TRIPLE.id && 
              <>
                <FieldItem
                  form={form}
                  component={Input}
                  name="firstNameChinese"
                  label={<>{translate('Given Name')}<br/>({translate('Chinese')})</>}
                  required
                  disabled
                  isTranslate={false}
                />
                <FieldItem
                  form={form}
                  component={Input}
                  name="middleNameChinese"
                  label={<>{translate('Middle Name')}<br/>({translate('Chinese')})</>}
                  disabled
                  isTranslate={false}
                />
                <FieldItem
                  form={form}
                  component={Input}
                  name="lastNameChinese"
                  label={<>{translate('Family Name')}<br/>({translate('Chinese')})</>}
                  required
                  disabled
                  isTranslate={false}
                />
              </>
            }
            <FieldItem
              form={form}
              component={DatePicker}
              name="birthday"
              required
              label="Birthday"
              disabled
            />
            <FieldItem
              form={form}
              name="nationality"
              label="Nationality"
              required
              component={Select}
              options={nationalityOptions}
              disabled
            />
            <FieldItem
              form={form}
              name="country"
              label="Country"
              required
              component={Select}
              options={countrieOptions}
              optionBinding={{
                value: 'code',
                name: 'name'
              }}
              showSearch
              disabled
            />
            <FieldItem form={form} component={Input} name="prefecture" label="Province" required disabled />
            <FieldItem form={form} component={Input} name="postCode" label="Post code" required disabled />
            <FieldItem form={form} component={Input} name="city" label="City" required disabled />
            <FieldItem form={form} component={Input} name="afterAddress" label="Street address" required disabled />
            <FieldItem form={form} component={Input} name="buildingName" label="Building Name" disabled />
            <FieldItem
              form={form}
              name="profession"
              label="Occupation"
              required
              component={Select}
              options={professionOptions}
              showSearch
              disabled
            />
            <Divider />
            <Divider />
            <p className="sub-title">{translate('Card Information')}</p>
            <FieldItem form={form} component={Input} name="quantity" label="Quantity" disabled />
            <FieldItem form={form} component={Input} name="baseCurrency" label="Base Currency" disabled />
            <div className="group-button-submit">
              <Button
                type="delete"
                onClick={prevStep}
                className="submit"
                disabled={type === ACTIONS.VERIFY_PHONE_CODE_REQUEST}
              >
                {translate('Back')}
              </Button>
              <Button
                htmlType="submit"
                onClick={handleSubmit}
                className="submit"
                loading={type === ACTIONS.VERIFY_PHONE_CODE_REQUEST}
              >
                {translate('Submit')}
              </Button>
            </div>
          </Form>
        )}
      </Translate>
    )
  }

  _onSubmit = (values) => {
    const { nextStep } = this.props;
    nextStep();
  }

  render() {
    return (
      <div className='registration-triple-step6'>
        <Formik
          enableReinitialize
          initialValues={this.initialValues}
          onSubmit={this._onSubmit}
          component={this._renderForm}
        />
      </div> 
    )
  }
}

export default connect(
  state => ({
    registerTrippleStore: state.registrationTripple,
    registrationStore: state.registration,
  }),
  {
    sendVerifyPhoneNumber: actions.sendVerifyPhoneNumber,
  }
)(RegistrationStep6);
