import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Translate } from 'react-localize-redux';
import { Button } from '../../../../../../components/antd';
import "./style.css";

const Registration = ({ nextStep, history, businessId }) => {
  const [showConfirmBtn, setShowConfirmBtn] = useState(false);
  const [conditionAccepted, setConditionAccepted] = useState(null);

  const handleContentScroll = (e) => {
    if (e.nativeEvent.target.scrollHeight - e.nativeEvent.target.scrollTop <= e.nativeEvent.target.clientHeight) {
      setShowConfirmBtn(true)
    }
  }

  const handleClickBtnAgree = (agreed) => {
    if(agreed) {
      nextStep({businessId});
    } else {
      history.push('/');
    }
  }

  return (
    <Translate>
      {({translate}) => (
        <div className="register-step-1 el-card">
          <div className="content-registration">
            <div
              className="container-registration-content"
              onScroll={e => handleContentScroll(e)}
            >
              <p className="text-right">{`${translate('Date')}: 10 ${translate('July')} 2018`}</p>
              <h5>
                <u>{translate('GENERAL TERMS AND CONDITIONS')}</u>
              </h5>
              <p>
                <strong>eZpay Limited - Mobile App</strong>
              </p>
              <br />
    
              <p>
                <strong>1. General</strong>
              </p>
              <br />
              <p>
                1.1 You should read these General Terms and Conditions carefully and
                make sure that you understand them before using the eZpay App. If
                you do not accept these General Terms and Conditions, you may not
                access or use the eZpay App.
              </p>
              <p>
                The General Terms and Conditions (as defined below) will apply each
                time you access and use the eZpay App, including any updates or
                supplements to the App. The General Terms and Conditions comprise:
              </p>
              <p>(A) these terms and conditions;</p>
              <p>(B) the Privacy Policy ; and</p>
              <p>(C) all other documents referred to herein,</p>
              <p>
                in the event of inconsistency between the terms and conditions of
                the documents listed above, (A) shall take priority over both (B)
                and (C), and (B) shall take priority over (C). In case of
                discrepancies between the English and any Chinese versions of the
                General Terms and Conditions, the English version shall apply and
                prevail.
              </p>
              <br />
    
              <p>
                1.2 These General Terms and Conditions were most recently updated on
                July 10, 2018. eZpay amends these General Terms and Conditions from
                time to time as set out in Clause 3.5 . The version of these General
                Terms and Conditions in force at the time you access or use the
                eZpay App shall apply at that time.
              </p>
              <br />
    
              <p>
                <strong>2. Definitions</strong>
              </p>
              <br />
              <p>
                2.1 In these General Terms and Conditions, unless the context
                otherwise requires, the following words have the following meanings:
              </p>
              <p>
                "AMLO" means the Anti-Money Laundering and Counter-Terrorist
                Financing (Financial Institutions) Ordinance (Cap. 615).
              </p>
              <p>
                "App" or " eZpay App" means the eZpay mobile application of eZpay
                Limited, as available in the relevant app store for your Device.
              </p>
              <p>
                "Business Day" means between the hours of 0900 to 1600 in Hong Kong
                not including Saturdays, Sundays or any public or statutory holidays
                in Hong Kong.
              </p>
              <p>
                "Contents" means all or any of the data, texts, buttons icons,
                links, HTML codes, trademarks, softwares, music, audio and/or visual
                information, photographs, graphics, pictures, series of moving
                pictures, (animated or otherwise), movies, merchandises, products,
                advertisements, services or any compilation collection combination
                of all and/or any of the items mentioned above and/or any other
                contents materials and/or information displayed on the App,
                including these General Terms and Conditions.
              </p>
              <p>
                "Device" means any smartphone or handheld device that is controlled
                by you and onto which the eZpay App can be downloaded.
              </p>
              <p>"End User" or "you" means an end user of the App.</p>
              <p>
                "Force Majeure Events" means any event arising from any cause
                preventing a party from performing any or all of its obligations
                under these General Terms and Conditions which arises from or is
                attributable to acts, events, omissions or accidents beyond the
                reasonable control of the party so prevented.
              </p>
              <p>
                "General Terms and Conditions" means the terms and conditions
                governing eZpay's provision of, and your rights of access and use
                of, the eZpay App, and comprise the documents set out in Clause 1.1.
              </p>
              <p>"Marks" has the meaning given to it in Clause 16.3 .</p>
              <p>"MasterCard" means MasterCard Inc.</p>
              <p>
                "eZpay" or "eZpay Limited" or "we" or "us" means eZpay Limited a
                company incorporated in Hong Kong with company number 2693884 or any
                of its affiliates.
              </p>
              <p>
                "Prepaid Card" means the co-branded prepaid card issued by "Card
                Issuer" and licensed by MasterCard Asia/Pacific Pte Ltd upon
                application by an End User, subject to the eZpay terms and
                conditions.
              </p>
              <p>
                "Prepaid Card Account" means your account with Card Issuer for the
                purpose of issuing a Prepaid Card to you.
              </p>
              <p>
                "Services" means the services offered by us to you <em>via</em> the
                eZpay App as set out in Clause 3.1 .
              </p>
              <p>
                "Transaction" means a transaction, including the purchase of goods
                and/or services, effected by the use of the Prepaid Card.
              </p>
              <br />
    
              <p>
                <strong> 3. Scope of service</strong>
              </p>
              <br />
    
              <p>
                3.1 You may use the App to carry out some or all of the following
                services:
              </p>
              <p>(A) open a Prepaid Card Account; and</p>
              <p>(B) order a Prepaid Card; and</p>
              <p>
                (C) get instructions to facilitate top-ups of your Prepaid Card
                Account; and
              </p>
              <p>(D) check the balance of your Prepaid Card Account; and</p>
              <p>
                (E) check the transaction records of your Prepaid Card Account; and
              </p>
              <p>(F) analyse your spending behaviour; and</p>
              <p>(G) make peer-to-peer payments to other eZpay App users</p>
              <p>(H) set spending budgets and related alerts; and</p>
              <p>
                (I) remotely switch on or off the usage of your Prepaid Card; and
              </p>
              <p>
                (J) help you with any issues related to your Prepaid Card Account
                you may have
              </p>
              <p>
                (together, the "Services"). Not all of the above Services may be
                available to you. You must not use the eZpay App for any other
                purpose.
              </p>
              <br />
    
              <p>3.2 You acknowledge and agree that:</p>
              <p>
                (A) provision of the Services is conditional upon your successful
                completion of verification of identity for the purpose of the AMLO;
                and
              </p>
              <p>
                (B) By tapping "SIGN UP" in the eZpay App you shall be legally bound
                by these General Terms and Conditions and hereby authorise eZpay to
                allow access to the designated account(s) in order to provide you
                with the Services; and
              </p>
              <p>
                (C) the effective date of these General Terms and Conditions is the
                date upon which you first tap "SIGN UP" in the eZpay App.
              </p>
              <br />
    
              <p>
                3.3 The issue of the Prepaid Card is the responsibility of the Card
                Issuer. eZpay does not offer prepaid cards or any other stored value
                facility. You are responsible for complying with the terms and
                conditions provided by the Card Issuer in order to maintain the
                continued validity of your Prepaid Card. We do not guarantee that
                the application for a Prepaid Card will be made successfully. In
                addition, upon successful application for a Prepaid Card, we are not
                liable for the refusal by any merchant to accept your Prepaid Card
                as a form of payment.
              </p>
              <br />
    
              <p>
                3.4 We are not responsible for authorising, validating or effecting
                any Transaction or crediting any refunds to your Prepaid Card
                Account. No dispute relating to your use of the Prepaid Card with
                the Card Issuer, any authorised merchants or other third parties
                shall prejudice your responsibilities to eZpay under these General
                Terms and Conditions.
              </p>
              <br />
    
              <p>
                3.5 We reserve the right to change the provisions of these General
                Terms and Conditions from time to time and such changes will be
                communicated in accordance with Clause 12 of these General Terms and
                Conditions. If you continue to use our Service <em>via</em> the App
                after such changes have been notified, this will constitute
                acceptance of the amended terms and conditions.
              </p>
              <br />
    
              <p>
                <strong>4. Account opening</strong>
              </p>
              <br />
              <p>
                4.1 You may access and use the Services upon successful registration
                of a personalised account with us and completion of the relevant
                login code and password.
              </p>
              <br />
              <p>4.2 In order to register for a personalised account with us,</p>
              <p>
                (A) you must be a natural person who is 16 years old or above, and
                be a citizen of any country that is not in the list of restricted
                countries (see Appendix A) ; and
              </p>
              <p>
                (B) from within the App, you must complete a designated registration
                process by furnishing us with all true and accurate information
                required and agreeing to comply with our Privacy Policy.
              </p>
              <p>
                We reserve all rights, at our sole discretion, to set other
                eligibility criteria or revoke an account without giving any reason
                for doing so.
              </p>
              <br />
    
              <p>
                <strong>5. Required documents to verify your identity</strong>
              </p>
              <br />
              <p>
                5.1 Amongst other identity verification processes, we require you to
                use the eZpay App to submit a scan of the following documents via
                the App:
              </p>
              <p>(A) Your current and valid passport; or</p>
              <p>
                The verification process is conducted by eZpay on behalf of Card
                Issuer.
              </p>
              <br />
              <p>
                5.2 We may, if necessary, conduct further personal identification
                checks on you.
              </p>
              <br />
              <p>
                5.3 If you fail to provide the information pursuant to Clauses 5.1
                and 5.2 above, we may refuse to open an account for you and/or may
                refuse to issue a Prepaid Card to you.
              </p>
              <br />
    
              <p>
                <strong>6. Grant of license, access and security</strong>
              </p>
              <br />
              <p>
                6.1 In consideration of you agreeing to comply with these General
                Terms and Conditions, we grant you a non-transferable, non-exclusive
                license to use the App on your Device, subject to the terms entered
                into between you and the relevant app store provider. We reserve all
                other rights.
              </p>
              <br />
              <p>6.2 Subject to Clauses 7, 8 and 16 below, You may:</p>
              <p>
                (A) download and install a copy of the App onto your Device and to
                view, use and display the App on the Device for your personal
                purposes only; and
              </p>
              <p>
                (B) use the information displayed on the App for your personal
                purposes only.
              </p>
              <br />
              <p>
                6.3 Your eZpay App account is accessed by using a unique login code
                and a password as specified by you to ensure that your account is
                safe from unauthorised access by any third parties
              </p>
              <br />
              <p>
                6.4 We will automatically log you out of the eZpay App if it has not
                been used for 15 minutes.
              </p>
              <br />
              <p>
                6.5 You must take all reasonable precautions to keep safe and
                prevent fraudulent use of your Device and security information.
                These precautions include:
              </p>
              <p>
                (A) not leaving your Device unattended whilst logged in the account;
              </p>
              <p>
                (B) keeping your security details unique and distinguishable for the
                account;
              </p>
              <p>
                (C) if you choose to activate fingerprint sign-on, ensure that yours
                is the only fingerprint registered on the Device;
              </p>
              <p>
                (D) keeping login code, password or other security features
                introduced in connection with the eZpay App strictly confidential
                and secure against tampering, unauthorised use or disclosure;
              </p>
              <p>
                (E) referring to and complying with all security procedures,
                conditions and directions regarding access to and use of the eZpay
                App as notified by us from time to time, including procedures
                relating to the use of login codes; and
              </p>
              <p>
                (F) notifying us as soon as reasonably practicable at
                support@ezpay-card.com in the event:
              </p>
              <p>
                (1) of your login code or password having been accessed or disclosed
                to unauthorised third parties or otherwise compromised;
              </p>
              <p>
                (2) that you have any reason to suspect unauthorised use of your
                login code and/or password; or
              </p>
              <p>(3) of your Device being lost or stolen.</p>
              <br />
              <p>
                6.6 We may act and rely on the correct entry of a login code and
                password as conclusive evidence of your identity to whom the login
                code is assigned to. Unless eZpay is notified in accordance with
                Clause 6.5(F) :
              </p>
              <p>
                (A) we will not be obliged in any manner to investigate or take any
                other step to verify your identity; and
              </p>
              <p>
                (B) you will remain liable for any access and/or use of the App and
                the consequences of such use.
              </p>
              <br />
              <p>
                6.7 With the exception of any service provided by us at our
                discretion from time to time, you acknowledge and agree that you are
                responsible for obtaining at your sole cost any relevant licences,
                consents, software, Internet connectivity and equipment necessary
                for the End User(s) to access and use the eZpay App.
              </p>
              <br />
    
              <p>
                <strong>7. Access to Services subject to restrictions</strong>
              </p>
              <br />
              <p>
                7.1 By choosing to use the eZpay App, you acknowledge and agree
                that:
              </p>
              <p>
                (A) any information displayed on the App is provided for your
                information only and is not to be further distributed without the
                consent of eZpay. eZpay accepts no liability whatsoever for the
                actions of third parties in this respect;
              </p>
              <p>
                (B) we cannot guarantee that no viruses or other contaminating or
                destructive properties will be transmitted or that no damage will
                occur to your Device. We are not responsible for any loss you may
                incur as a result of this;
              </p>
              <p>
                (C) the eZpay App is provided "as-is" with no warranty,
                representation, guarantee or agreement of any kind as to its
                functionality, merchantability, quality or fitness for any
                particular purpose;
              </p>
              <p>
                (D) eZpay assumes no responsibility for the content of website links
                within the App or third party software or services that may be
                offered through the App. The ability to access such links, software
                or services should not be interpreted as an endorsement by eZpay.
                eZpay is not be liable for any loss or damage that may arise from
                the End User"s accessing or using such links, software or services;
                and
              </p>
              <p>
                (E) In order to ensure that the App will function properly and
                efficiently, we may carry out regular testing of the App which may
                require periodic updates to the App to be installed. For some
                Devices, such updates will be downloaded automatically; you are
                otherwise required to download and install the update supplied by
                the relevant app store provider or operator for your Device.
                Depending on the update, you may not be able to use the App until
                the latest version has been installed.
              </p>
              <br />
              <p>7.2 You must not:</p>
              <p>
                (A) use the App or any Services in any unlawful manner, for any
                unlawful purpose, or in any manner inconsistent with these General
                Terms and Conditions, or act fraudulently or maliciously, for
                example, by hacking into or inserting malicious code, including
                viruses, or harmful data, into the App, any Service or any operating
                system;
              </p>
              <p>
                (B) infringe our intellectual property rights or those of any third
                party in relation to your use of the App or any Service (to the
                extent that such use is not licensed by these General Terms and
                Conditions);
              </p>
              <p>
                (C) transmit any material that is defamatory, offensive or otherwise
                objectionable in relation to your use of the App or any Service;
              </p>
              <p>
                (D) use the App or any Service in a way that could damage, disable,
                overburden, impair or compromise our systems or security or
                interfere with other users; and
              </p>
              <p>
                (E) collect or harvest any information or data from any Service or
                our systems or attempt to decipher any transmissions to or from the
                servers running any Service.
              </p>
              <br />
              <p>7.3 You agree:</p>
              <p>
                (A) not to copy the App except where such copying is incidental to
                normal use of the App, or where it is necessary for the purpose of
                back-up or operational security;
              </p>
              <p>
                (B) not to rent, lease, sub-license, loan, translate, merge, adapt,
                vary or modify the App;
              </p>
              <p>
                (C) not to make alterations to, or modifications of, the whole or
                any part of the App, or permit the App or any part of it to be
                combined with, or become incorporated in, any other programs;
              </p>
              <p>
                (D) not to disassemble, decompile, reverse-engineer or create
                derivative works based on the whole or any part of the App or
                attempt to do any such thing to the extent permitted by applicable
                laws and regulations;
              </p>
              <p>
                (E) not to provide or otherwise make available the App in whole or
                in part (including object and source code), in any form to any
                person without our prior written consent; and
              </p>
              <p>
                (F) You must not use the App on any Device or operating system that
                has been modified outside the Device or operating system vendor
                supported or warranted configurations. This includes Devices that
                have been "jail-broken" or "rooted".
              </p>
              <br />
    
              <p>
                <strong>
                  {" "}
                  8. Information displayed on the App and in relation to your
                  Prepaid Card Account
                </strong>
              </p>
              <br />
              <p>
                8.1 You acknowledge and agree that information displayed in the App
                may not be the same as that displayed in the system of the Card
                Issuer in connection of your Prepaid Card Account, owing to the
                different data feeds of the two services. Please refresh the eZpay
                App frequently to ensure the latest data feeds are reflected.
              </p>
              <br />
              <p>
                8.2 Information displayed in the App including transaction records
                of your Prepaid Card Account is solely prepared for your convenience
                and may not fully and accurately reflect all transactions which have
                been carried out using your Prepaid Card during a relevant period
                owing to various reasons including the difference in time zones and
                settlement procedures.
              </p>
              <br />
              <p>
                8.3 You acknowledge and agree that you shall not rely on any and all
                information displayed in the App for tax or other reporting
                purposes.
              </p>
              <br />
              <p>8.4 eZpay is not be responsible for:</p>
              <p>
                (A) any loss or liability sustained or borne by you due to the
                improper use of the Prepaid Card, the malfunctioning of the Prepaid
                Card or any other system provided by the Card Issuer, the use of any
                services or supply of any products provided in connection to the
                Prepaid Card;
              </p>
              <p>
                (B) any delays or errors in the provision of Card Issuer services in
                connection with your Prepaid Card; or
              </p>
              <p>
                (C) loss or damage of any nature directly or indirectly sustained by
                you due to any suspension, cancellation, termination or non-approval
                by Card Issuer.
              </p>
              <br />
    
              <p>
                <strong> 9. Interruption in service</strong>
              </p>
              <br />
              <p>
                9.1 We do not guarantee that you will have error-free, uninterrupted
                access to the Services. You acknowledge and agree that there may be
                occasions where the Services are disrupted or delayed from time to
                time for whatever reason and are not available for access or use.
              </p>
              <br />
              <p>
                9.2 We hereby exclude any and all liability to each End User and/or
                any third party for any loss, damage or claims arising from or in
                connection with:
              </p>
              <p>
                (A) delays, disruption, technical faults, breakdowns,
                non-availability or interruptions (including maintenance work),
                transmission errors, illegal interventions into transmission
                networks, interception, suspension, delay, loss, mutilation or other
                failure of Services;
              </p>
              <p>
                (B) a virus, distributed denial-of-service attack, or other
                technologically harmful material that may infect your Device, other
                computer equipment, software, data or other proprietary material due
                to the use of the Services or the downloading of any content in
                them, or on any website linked to them;
              </p>
              <p>(C) Force Majeure Events; and</p>
              <p>
                (D) IT or communication systems/computers of the End User or of any
                third party (including systems in the public domain).
              </p>
              <br />
    
              <p>
                <strong>10. Charges</strong>
              </p>
              <br />
              <p>
                10.1 Details of the fees for the Services and services provided by
                eZpay Limited are provided in Appendix B
              </p>
              <br />
              <p>
                10.2 In addition to the fees referred to in Clause 10.1 above, your
                Device network operator may charge you for accessing the App and
                these charges may vary if you access it when abroad. You are
                responsible for these charges.
              </p>
              <br />
              <p>
                10.3 Issuing fee paid at the time of application will not be
                returned even if cancellation of application, cancellation is done.
              </p>
              <br />
    
              <p>
                <strong>11. Protection of personal data</strong>
              </p>
              <br />
              <p>
                11.1 Personal data collected by eZpay <em>via</em> your access and
                use of the eZpay App will be used by us in accordance with the terms
                of the Privacy Policy.
              </p>
              <br />
              <p>
                11.2 You confirm that you have received, read and understood our
                Privacy Policy from time to time, and all other privacy policy
                documents which we may publish, and you agree to be bound by their
                contents. The latest version of our Privacy Policy can be obtained
                at https://www.ezpay.com.hk
              </p>
              <br />
    
              <p>
                <strong> 12. Communications and notices</strong>
              </p>
              <br />
              <p>
                12.1 Communications between the End User and eZpay shall be by
                email, chat, telephone, post, or by a notification message within
                the eZpay App. As part of the sign up process for the eZpay App, you
                will supply an address, an email address and a telephone number to
                eZpay at which you may be contacted for the purpose of such
                communications. Should there be any change to your contact details
                during the term of these General Terms and Conditions, you will
                immediately notify eZpay and provide your updated contact details in
                the relevant form specified. Requests for alterations will be
                processed within a reasonable period, and until eZpay has processed
                the alteration the most recently supplied contact details shall be
                valid.
              </p>
              <br />
              <p>
                12.2 Any notice, demand or communication to be given by us to you
                shall be deemed to have been given if:
              </p>
              <p>(a) sent by email to the email address provided by you;</p>
              <p>(b) sent by hand or post to any address provided by you,</p>
              <p>
                and any notice sent by us shall be deemed (notwithstanding that it
                may not in fact be received and even if we know of such fact) to
                have been received on the calendar day of dispatch if delivered by
                hand or email and on the following calendar day if delivered by
                post.
              </p>
              <br />
              <p>
                12.3 You agree that all telephone calls and email communications may
                be recorded electronically for training and other purposes and you
                herewith explicitly consent to such recordings. We retain the right
                to erase communication records in accordance with our internal
                policy and applicable data protection and retention laws and
                regulations.
              </p>
              <br />
    
              <p>
                <strong>13. Indemnity</strong>
              </p>
              <br />
              <p>
                13.1 You agree to indemnify us for ourselves and on behalf of our
                employees, officers, nominees and agents (each an "Indemnified
                Party") against any and all claims, demands, actions and
                proceedings, and any and all damages, liabilities, losses (including
                direct, indirect or consequential losses) and expenses (including
                legal fees, duties and taxes) which the Indemnified Party may incur
                or suffer arising from or in connection with:
              </p>
              <p>
                (A) any breach of or non-compliance with these General Terms and
                Conditions by you; and
              </p>
              <p>
                (B) any representation, warranty or information made or provided by
                you.
              </p>
              <br />
    
              <p>
                <strong> 14. Suspension</strong>
              </p>
              <br />
              <p>
                14.1 We may at any time at our discretion and without giving any
                notice:
              </p>
              <p>(A) suspend your use of the eZpay App; and/or</p>
              <p>
                (B) block or suspend the use of any account or otherwise disable or
                suspend or terminate the access of any End User to the eZpay App,
              </p>
              <p>
                for any reason whatsoever, including reasonable suspicion of
                fraudulent or unauthorised access or use of the eZpay App, loss of
                your Prepaid Card, or deactivation of your Prepaid Card Account; or
                if there are any legal obligations we are required to meet.
              </p>
              <br />
              <p>
                14.2 We will try our best to give you prior notice <em>via</em>{" "}
                e-mail, chat, SMS or notification message in the App and reasons of
                any suspension. However, we will not do so if: (a) this would
                compromise our reasonable security measures, (b) it is unlawful to
                do so, or (c) we are unable to contact you.
              </p>
              <br />
              <p>
                14.3 If you have entered incorrect login code and password on five
                occasions we will suspend your access to the eZpay App. We will not
                restore blocked access or reset your login details unless and until
                requested by you and subject to you being able to identify yourself
                and answering our security questions correctly.
              </p>
              <br />
              <p>
                14.4 If you have any questions regarding your login or lifting of
                the suspension, please send us an email at support@ezpay-card.com
              </p>
              <br />
    
              <p>
                <strong>15. Termination</strong>
              </p>
              <br />
              <p>
                15.1 You may terminate our service within 14 days written notice to
                the following email address: support@ezpay-card.com
              </p>
              <p>
                The notice period begins upon our receipt of your written notice. We
                will pass on your notice of termination to Card Issuer but this does
                not affect your contractual relationship with eZpay or validity of
                your Prepaid Card.
              </p>
              <br />
              <p>
                15.2 We may immediately suspend or terminate your App account or any
                Services we are providing to you if:
              </p>
              <p>
                (A) you have not used the App for 12 months, in which case you will
                have to re-register to re-activate your account;
              </p>
              <p>(B) we have reasonable grounds to suspect that:</p>
              <p>
                (1) you commit a material or persistent breach of these General
                Terms and Conditions;
              </p>
              <p>
                (2) you have acted fraudulently or there has been fraud involving
                any of your accounts in the eZpay App or the use of Prepaid Cards;
              </p>
              <p>(3) your security details have not been kept safe;</p>
              <p>
                (4) there has been unauthorised or fraudulent use of your security
                details for the eZpay App;
              </p>
              <p>
                (5) you have committed or are about to commit a crime in connection
                with your account;
              </p>
              <p>
                (6) you have not satisfied the anti-money laundering requirements
                related to Clause 5 above.
              </p>
              <p>
                (C) you did not meet the eligibility criteria, as determined by us
                from time to time, to register for a eZpay App account;
              </p>
              <p>
                (D) the eZpay App is withdrawn by the manufacturer of your Device
                operating system or, any intermediary;
              </p>
              <p>
                (E) we cease to support the eZpay App on your Device or the
                operating system it runs; or
              </p>
              <p>
                (F) we reasonably consider that by continuing our contractual
                relationship under the General Terms and Conditions:
              </p>
              <p>
                (1) we may break a law, regulation, regulatory guidelines and code,
                court order or other duty; or
              </p>
              <p>
                (2) we may be exposed to action or censure from any government,
                regulatory authority or law enforcement agency.
              </p>
              <br />
              <p>
                15.3 We will notify you immediately upon termination of your account
                or service with us. Notification may be made <em>via</em> email,
                chat, SMS, phone, a message when you log onto the App, by post or in
                any other way that will be sent to you individually.
              </p>
              <br />
              <p>
                15.4 We may close your account or cease to provide our service to
                you for any other reason by giving you at least two months notice.
              </p>
              <br />
              <p>15.5 On termination for any reason pursuant to this Clause 15:</p>
              <p>
                (A) all rights granted to you under the General Terms and Conditions
                shall cease;
              </p>
              <p>
                (B) you must immediately cease all activities authorised by the
                General Terms and Conditions;
              </p>
              <p>
                (C) you must immediately delete or remove the App from your Device,
                and immediately destroy all copies of information about the App then
                in your possession, custody or control;
              </p>
              <p>
                (D) you remain liable for all fees and charges incurred from using
                the Prepaid Card; and
              </p>
              <p>
                (E) we shall have no responsibility to return to you any balance of
                your Prepaid Card Account. Any such request must be directly made to
                eZpay.
              </p>
              <br />
    
              <p>
                <strong>16. Intellectual Property</strong>
              </p>
              <br />
              <p>
                16.1 Your rights are limited to using the App in accordance with
                these General Terms and Conditions. All title, ownership rights and
                intellectual property rights in or relating to the Services, any
                related services or information transmitted by, to or over the
                Services and information regarding the use of Services will remain
                with eZpay or its licensors. Nothing in these General Terms and
                Conditions will be construed as conferring on any End User any
                licence of any of eZpay's or any third party's title, ownership
                rights and/or intellectual property rights, whether by estoppel,
                implication or otherwise. These are licensed but not sold to you.
              </p>
              <br />
              <p>
                16.2 You agree and acknowledge that you have no right to have access
                to the App in source-code form.
              </p>
              <p>
                16.3 Unless otherwise indicated, we own the intellectual property
                rights including but without limitation to trademarks, logos and
                service marks (the "Marks") and copyright of all the Contents.
                Nobody or any End User is allowed to use, copy, alter, modify,
                extract, share or reproduce any of the Marks for any other purposes
                or for gain without our prior written approval. We reserve all
                rights to take criminal and/or civil actions as may be advised
                against any person, corporate or entity for infringing any of our
                intellectual property rights regarding the Marks. Also, we agree
                that there is a possibility that the cards used may not be
                temporarily or permanently used due to these illegal acts.
              </p>
              <br />
              <p>
                <strong> 17. Records conclusive</strong>
              </p>
              <br />
              <p>
                17.1 eZpay's records in relation to the access and the use of the
                eZpay App by an End User will, in the absence of manifest error, be
                binding and conclusive evidence. You agree that such records are
                admissible in evidence and you waive any right to challenge or
                dispute the admissibility, reliability, accuracy or authenticity of
                the contents of such records merely on the basis that such records
                were incorporated or produced by or were the output of a computer
                system and/or are set out in electronic form.
              </p>
              <br />
              <p>
                17.2 Without prejudice to Clause 8 , where an End User receives any
                information or other statements or records of any instructions,
                communications, operations or transactions received <em>via</em> or
                in connection with the eZpay App, the End User must inform us of any
                mistake or omission or disagreement within fourteen (14) days from
                the date such information, statement or record was sent, failing
                which the End User shall forfeit the right to dispute the accuracy
                of such information, statement or record.
              </p>
              <br />
              <p>
                <strong>18. Governing law</strong>
              </p>
              <br />
              <p>
                18.1 These General Terms and Conditions will be governed by and
                construed in accordance with the laws of Hong Kong and you
                irrevocably and unconditionally submit to the exclusive jurisdiction
                of the courts of Hong Kong to settle any disputes which may arise
                out of or in connection with these General Terms and Conditions.
                This Clause 18.1 is for the benefit of eZpay and will not limit any
                right of eZpay to take proceedings in any other court of competent
                jurisdiction in relation to these General Terms and Conditions.
              </p>
              <br />
    
              <p>
                <strong> 19. Miscellaneous</strong>
              </p>
              <br />
              <p>
                19.1 Headings used in these General Terms and Conditions are for
                ease of reference only and shall not be deemed to be a part of these
                General Terms and Conditions or be taken into consideration in the
                interpretation or construction hereof. Where the context so
                requires, words importing the singular shall include the plural and
                vice versa. Any words following the terms "including", "include" or
                any similar expression shall be construed as illustrative and shall
                not limit the sense of the words, description, definition, phrase or
                term preceding those terms. Unless the context otherwise requires,
                "Clause" means a clause of these General Terms and Conditions.
              </p>
              <br />
              <p>
                19.2 Nothing in these General Terms and Conditions operate to
                prevent or limit eZpay"s right to assign, novate, sub-contract or
                otherwise transfer or confer any benefit or interest derived from or
                in connection with these General Terms and Conditions in favour of
                any other person. These General Terms and Conditions are personal to
                the End User and the End User will not assign, novate, sub-contract
                or otherwise transfer or confer any benefit or interest under or in
                connection with these General Terms and Conditions in favour of any
                other person without eZpay's prior written consent.
              </p>
              <br />
              <p>
                19.3 If any part of these General Terms and Conditions is unlawful
                or unenforceable under any applicable law, it will, to the extent
                permitted by such law, be severed herefrom and rendered ineffective
                where possible without modifying the other provisions of these
                General Terms and Conditions.
              </p>
              <br />
              <p>
                19.4 Nothing in these General Terms and Conditions will reduce your
                statutory rights including your rights relating to mis-described
                accounts or services, the fairness of terms on which they are
                provided to you and/or any rights you may have to close your
                account.
              </p>
              <p>
                <br />
                Appendix A - Restricted Countries
              </p>
              <p>Afghanistan</p>
              <p>Bosnia and Herzegovina</p>
              <p>Central African Republic</p>
              <p>Democratic People's Republic of Korea</p>
              <p>Democratic Republic of the Congo</p>
              <p>Eritrea</p>
              <p>Guyana</p>
              <p>Iran</p>
              <p>Iraq</p>
              <p>Lao People's Democratic Republic (Laos)</p>
              <p>Lebanon</p>
              <p>Libya</p>
              <p>Somalia</p>
              <p>Sudan</p>
              <p>Syria</p>
              <p>Uganda</p>
              <p>Vanuatu</p>
              <p>Yemen</p>
              <br />
            </div>
            <div className="group-button">
              <Button
                type="delete"
                disabled={!showConfirmBtn}
                className='btn-dis-agree'
                onClick={e => handleClickBtnAgree(false)}
              >
                {translate('I do not agree')}
              </Button>
              <Button
                className='btn-agree'
                disabled={!showConfirmBtn}
                onClick={e => handleClickBtnAgree(true)}
              >
                {translate('I agree')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Translate>
  );
};

const mapStateToProps = state => {
  return {
    currentStep: state.registration.currentStep,
    stepSuccess: state.registration.stepSuccess
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Registration)
);
