import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "querystring";
import {Translate} from "react-localize-redux";
import {
    STATUS,
    updatePassportAgain,
} from "../../../../actions/registrationActions";
import "./style.css";
import {TYPE_SEND_MAIL, Validation} from "../../../../utils/commonUtils"
import {saveUserRegisterToken} from "../../../../utils/apiUtils";
import {Button, CircularProgress, FormLabel, TextField} from "@material-ui/core";
import RegisterForm from "../Form";
import Alert from "../../../../components/Alert";
import {getEnv} from "../../../../env";
import ContentPanel from "../../../../components/ContentPanel";
import {DateTime, countryUsing, businessIds} from "../../../../constants/commonData";
import Dialog from "../../../../components/Dialog";
import { Checkbox } from '../../../../components/antd';

class NewPassport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passportNumber: "",
            passportIssueYear: -1,
            passportIssueMonth: -1,
            passportIssueDate: -1,
            passportExpiryYear: -1,
            passportExpiryMonth: -1,
            passportExpiryDate: -1,
            passportPlaceIssued: -1,
            passportImage: null,
            alertAck: false,
            fullyChecked: false,
            isShowPassportInstruction: false,
            purchaseId: null,
            businessId: null,
            nationality: null,
            longTerm: false
        };
        this.passportCountries = getEnv('ALLOWED_PASSPORT_COUNTRIES').filter(c => ['JPN'].indexOf(c.code) >= 0);
        const issuePlaceTriple = ['CHN', 'TWN', 'MAC', 'HKG'];
        this.Countries = getEnv('ALLOWED_PASSPORT_COUNTRIES').filter(c => issuePlaceTriple.indexOf(c.code) >= 0);
        let query = props.location.search;
        if (query.indexOf("?") >= 0) query = query.substr(query.indexOf("?") + 1);
        let parsed = queryString.parse(query);
        if (parsed["purchase_id"]) {
            const token = parsed["token"];
            this.state.purchaseId = parsed['purchase_id'];
            saveUserRegisterToken({token: token});
        }
        this.state.businessId = parsed['businessId'];
        this.state.nationality = parsed['nationality'];
        this.countryUsingCard = countryUsing.identityCard.includes(this.state.nationality);
    }

    handleChange(name) {
        return (e) => {
            if (e.target) {
                this.setState({[name]: e.target.value}, () => {
                    this.validateRegisterForm();
                });
            }
            if (e.target.files) {
                this.setState({[name]: e.target.files && e.target.files.length ? e.target.files[0] : null});
            }
        }
    }

    validateRegisterForm() {
        const {
            businessId, passportNumber, passportIssueYear, passportIssueMonth, passportIssueDate,
            passportExpiryYear, passportExpiryMonth, passportExpiryDate, passportPlaceIssued, passportImage,
        } = this.state;
        let item = {
            passportNumber,
            passportIssueYear,
            passportIssueMonth,
            passportIssueDate,
            passportExpiryYear,
            passportExpiryMonth,
            passportExpiryDate,
            passportPlaceIssued,
            passportImage,
        };

        let fullyChecked = !Object.keys(item).some((key) => {
            return (
                item[key] === '' || 
                item[key] === null || 
                item[key] === undefined || 
                (   
                    // check application using card or passport. If using passport then required expity date of passport 
                    (businessIds.GREENBOX.id === this.state.businessId || !this.countryUsingCard) &&
                    (item[key] === -1 || item[key] === '-1')
                )
            )
        });

        fullyChecked = fullyChecked && Validation.validateUploadFileSize(this.state.passportImage) === 0;

        if (businessId !== businessIds.GREENBOX.id) {
            fullyChecked = fullyChecked
                && !Validation.passportNumber(passportNumber)
                && Validation.checkDate(passportIssueYear, passportIssueMonth, passportIssueDate)
                && Validation.checkDate(passportExpiryYear, passportExpiryMonth, passportExpiryDate)
                && Validation.dateLessThanNow(passportIssueYear, passportIssueMonth, passportIssueDate)
                && Validation.passportExpiry(passportExpiryYear, passportExpiryMonth, passportExpiryDate)
        }

        this.setState({fullyChecked: fullyChecked});
        return fullyChecked;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.stepData !== nextProps.stepData) {
            this.setState({stepData: nextProps.stepData});
        }
    }

    handleSubmitForm(e) {
        e.preventDefault();
        let data = {
            type: TYPE_SEND_MAIL.WRONG_PASSPORT_EXPIRED,
            applicationId: this.state.purchaseId,
            passportNumber: this.state.passportNumber,
            passportIssueYear: this.state.passportIssueYear,
            passportIssueMonth: this.state.passportIssueMonth,
            passportIssueDate: this.state.passportIssueDate,
            passportExpiryYear: this.state.passportExpiryYear,
            passportExpiryMonth: this.state.passportExpiryMonth,
            passportExpiryDate: this.state.passportExpiryDate,
            passportPlaceIssued: this.state.passportPlaceIssued,
            passportImage: this.state.passportImage,
        };
        this.setState({alertAck: false});
        this.props.updatePassport(data);
    }

    validateDateTripleForm() {
        const {
            passportNumber, passportIssueYear, passportIssueMonth, passportIssueDate,
            passportExpiryYear, passportExpiryMonth, passportExpiryDate
        } = this.state;

        const passportIssue = Validation.checkDate(passportIssueYear, passportIssueMonth, passportIssueDate)
            && Validation.dateLessThanNow(passportIssueYear, passportIssueMonth, passportIssueDate);
        const passportExpiry = Validation.checkDate(passportExpiryYear, passportExpiryMonth, passportExpiryDate)
            && Validation.passportExpiry(passportExpiryYear, passportExpiryMonth, passportExpiryDate);
        const passportIssueYearRequired = !passportIssueYear || passportIssueYear === -1 || passportIssueYear === "-1";
        const passportIssueMonthRequired = !passportIssueMonth || passportIssueMonth === -1 || passportIssueMonth === "-1";
        const passportIssueDateRequired = !passportIssueDate || passportIssueDate === -1 || passportIssueDate === "-1";
        const passportExpiryYearRequired = !passportExpiryYear || passportExpiryYear === -1 || passportExpiryYear === "-1";
        const passportExpiryMonthRequired = !passportExpiryMonth || passportExpiryMonth === -1 || passportExpiryMonth === "-1";
        const passportExpiryDateRequired = !passportExpiryDate || passportExpiryDate === -1 || passportExpiryDate === "-1";

        return {
            passportIssueYear: {
                required: passportIssueYearRequired,
                error: passportIssueYearRequired || !passportIssue
            },
            passportIssueMonth: {
                required: passportIssueMonthRequired,
                error: passportIssueMonthRequired || !passportIssue
            },
            passportIssueDate: {
                required: passportIssueDateRequired,
                error: passportIssueDateRequired || !passportIssue
            },
            passportExpiryYear: {
                required: passportExpiryYearRequired,
                error: passportExpiryYearRequired || !passportExpiry
            },
            passportExpiryMonth: {
                required: passportExpiryMonthRequired,
                error: passportExpiryMonthRequired || !passportExpiry
            },
            passportExpiryDate: {
                required: passportExpiryDateRequired,
                error: passportExpiryDateRequired || !passportExpiry
            },
        };
    }

    renderTripleForm(translate) {
        const {
            passportNumber, passportIssueYear, passportIssueMonth, passportIssueDate,
            passportExpiryYear, passportExpiryMonth, passportExpiryDate
        } = this.state;

        const validateDate = this.validateDateTripleForm();
        
        return (
            <>
                <div className="row content">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Id number</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="passportNumber"
                                label="Id number"
                                value={this.state.passportNumber}
                                className="input"
                                onChange={this.handleChange('passportNumber')}
                                required={!passportNumber}
                                error={!passportNumber || Validation.passportNumber(passportNumber)}
                                margin="normal"
                            />
                        </div>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Issue date</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Year"
                                        value={passportIssueYear}
                                        onChange={this.handleChange('passportIssueYear')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        {...validateDate.passportIssueYear}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getYears().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Month"
                                        value={this.state.passportIssueMonth}
                                        onChange={this.handleChange('passportIssueMonth')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        {...validateDate.passportIssueMonth}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getMonths().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Date"
                                        value={this.state.passportIssueDate}
                                        onChange={this.handleChange('passportIssueDate')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        {...validateDate.passportIssueDate}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getDates().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.countryUsingCard ?
                <div className="row content">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Date of expiry</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Year"
                                        value={passportExpiryYear}
                                        onChange={this.handleChange('passportExpiryYear')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        disabled={this.state.longTerm}
                                        error={validateDate.passportExpiryYear.error}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getYearsExpiry().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                    
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Month"
                                        value={this.state.passportExpiryMonth}
                                        onChange={this.handleChange('passportExpiryMonth')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        disabled={this.state.longTerm}
                                        error={validateDate.passportExpiryMonth.error}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getMonths().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Date"
                                        value={this.state.passportExpiryDate}
                                        onChange={this.handleChange('passportExpiryDate')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        disabled={this.state.longTerm}
                                        error={validateDate.passportExpiryDate.error}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getDates().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                {
                                    this.countryUsingCard && 
                                    <Checkbox
                                    required
                                    onChange={e => {
                                        const longTerm = e.target.checked;
                                        this.setState({ longTerm });
                                    }}
                                    checked={this.state.longTerm}
                                    className="check-box-expiry-date"
                                    >
                                    {translate('Long term')}
                                    </Checkbox>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                : 
                <div className="row content">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Date of Expiry</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">
                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Year"
                                        value={this.state.passportExpiryYear}
                                        onChange={this.handleChange('passportExpiryYear')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        {...validateDate.passportExpiryYear}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getYearsExpiry().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Month"
                                        value={this.state.passportExpiryMonth}
                                        onChange={this.handleChange('passportExpiryMonth')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        {...validateDate.passportExpiryMonth}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getMonths().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="col-4">
                                    <TextField
                                        className="input"
                                        id="select-currency-native"
                                        select
                                        label="Date"
                                        value={this.state.passportExpiryDate}
                                        onChange={this.handleChange('passportExpiryDate')}
                                        SelectProps={{
                                            native: true,
                                            MenuProps: {
                                                className: "",
                                            },
                                        }}
                                        {...validateDate.passportExpiryDate}
                                        margin="normal"
                                    >
                                        <option value={-1}/>
                                        {DateTime.getDates().map(option => (
                                            <option key={option.id} value={option.value}>
                                                {option.value}
                                            </option>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="row content">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Issue place</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="content-value">
                            <TextField
                                id="select-currency-native"
                                select
                                className="input"
                                label="Passport issuing place"
                                value={this.state.passportPlaceIssued}
                                onChange={this.handleChange('passportPlaceIssued')}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                        className: "",
                                    },
                                }}
                                required={!this.state.passportPlaceIssued || this.state.passportPlaceIssued === -1 || this.state.passportPlaceIssued === "-1"}
                                error={!this.state.passportPlaceIssued || this.state.passportPlaceIssued === -1 || this.state.passportPlaceIssued === "-1"}
                                margin="normal"
                            >
                                <option value={-1}/>
                                {this.Countries.map(option => (
                                    <option key={option.code} value={option.name}>
                                        {option.name}
                                    </option>
                                ))}
                            </TextField>
                        </div>
                    </div>
                </div>

                <div className="row content">
                    <div className="col-12 col-sm-3">
                        <div className="content-title">
                            <label htmlFor="other">Image upload</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="content-value">

                            <div>
                                <div className="text-danger">
                                    <p>Required to have more than seven months' validity remaining on your passport.</p>
                                </div>
                                <div>
                                    <input
                                        accept="image/jpg, image/JPG, image/JPEG, image/jpeg"
                                        multiple={false}
                                        required={true}
                                        placeholder="Please upload image"
                                        onChange={this.handleChange("passportImage")}
                                        className="upload-image"
                                        type="file"
                                        name="fileToUpload"
                                        ref="fileInput"
                                        id="fileToUpload"/>
                                </div>
                                <div>
                                    Required size: 300Kb ~ 4Mb
                                </div>
                                {this.state.passportImage && Validation.validateUploadFileTripleSize(this.state.passportImage) !== 0 &&
                                <div className="text-danger">
                                    File size is not valid
                                    (currently {Math.round(this.state.passportImage.size / 1024)} Kb)
                                </div>
                                }
                            </div>
                            
                            <div className="content-value mt-2">
                                <Button
                                    onClick={(e) => this.handleSubmitForm(e)}
                                    disabled={!this.state.fullyChecked || !this.state.passportImage || Validation.validateUploadFileTripleSize(this.state.passportImage) !== 0 || this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS}
                                    variant="contained"
                                    color="primary">
                                    {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS && (
                                        <CircularProgress className="margin-right-20px" size={20}/>
                                    )}
                                    <span>Update Passport</span>
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    };

    renderCertificateInfo(translate) {
        const submiting = this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS;
        const disableUpdate = !this.state.fullyChecked || submiting

        return (
            <ContentPanel header="Update New Passport">
                <FormLabel component="legend" className="certificate-note"
                           onClick={() => this.setState({isShowPassportInstruction: true})}>
                    Please check here before sending the certificate
                </FormLabel>

                { this.state.businessId === '01' ? 
                <>
                    <div className="row content">
                        <div className="col-12 col-sm-3">
                            <div className="content-title">
                                <label htmlFor="other">Passport number</label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-5">
                            <div className="content-value">
                                <TextField
                                    id="passportNumber"
                                    label="Passport number"
                                    value={this.state.passportNumber}
                                    className="input"
                                    onChange={this.handleChange('passportNumber')}
                                    required={!this.state.passportNumber}
                                    error={!this.state.passportNumber}
                                    margin="normal"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12 col-sm-3">
                            <div className="content-title">
                                <label htmlFor="other">Passport of Issue</label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-9">
                            <div className="content-value">
                                <div className="row">
                                    <div className="col-4">
                                        <TextField
                                            className="input"
                                            id="select-currency-native"
                                            select
                                            label="Year"
                                            value={this.state.passportIssueYear}
                                            onChange={this.handleChange('passportIssueYear')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: "",
                                                },
                                            }}
                                            required={!this.state.passportIssueYear || this.state.passportIssueYear === -1 || this.state.passportIssueYear === "-1"}
                                            error={!this.state.passportIssueYear || this.state.passportIssueYear === -1 || this.state.passportIssueYear === "-1"}
                                            margin="normal"
                                        >
                                            <option value={-1}/>
                                            {DateTime.getYears().map(option => (
                                                <option key={option.id} value={option.value}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-4">
                                        <TextField
                                            className="input"
                                            id="select-currency-native"
                                            select
                                            label="Month"
                                            value={this.state.passportIssueMonth}
                                            onChange={this.handleChange('passportIssueMonth')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: "",
                                                },
                                            }}
                                            required={(this.state.passportIssueMonth === undefined) || (this.state.passportIssueMonth === null) || this.state.passportIssueMonth === -1 || this.state.passportIssueMonth === "-1"}
                                            error={(this.state.passportIssueMonth === undefined) || (this.state.passportIssueMonth === null) || this.state.passportIssueMonth === -1 || this.state.passportIssueMonth === "-1"}
                                            margin="normal"
                                        >
                                            <option value={-1}/>
                                            {DateTime.getMonths().map(option => (
                                                <option key={option.id} value={option.value}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-4">
                                        <TextField
                                            className="input"
                                            id="select-currency-native"
                                            select
                                            label="Date"
                                            value={this.state.passportIssueDate}
                                            onChange={this.handleChange('passportIssueDate')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: "",
                                                },
                                            }}
                                            required={!this.state.passportIssueDate || this.state.passportIssueDate === -1 || this.state.passportIssueDate === "-1"}
                                            error={!this.state.passportIssueDate || this.state.passportIssueDate === -1 || this.state.passportIssueDate === "-1"}
                                            margin="normal"
                                        >
                                            <option value={-1}/>
                                            {DateTime.getDates().map(option => (
                                                <option key={option.id} value={option.value}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12 col-sm-3">
                            <div className="content-title">
                                <label htmlFor="other">Passport of Expiry</label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-9">
                            <div className="content-value">
                                <div className="row">
                                    <div className="col-4">
                                        <TextField
                                            className="input"
                                            id="select-currency-native"
                                            select
                                            label="Year"
                                            value={this.state.passportExpiryYear}
                                            onChange={this.handleChange('passportExpiryYear')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: "",
                                                },
                                            }}
                                            required={!this.state.passportExpiryYear || this.state.passportExpiryYear === -1 || this.state.passportExpiryYear === "-1"}
                                            error={!this.state.passportExpiryYear || this.state.passportExpiryYear === -1 || this.state.passportExpiryYear === "-1"}
                                            margin="normal"
                                        >
                                            <option value={-1}/>
                                            {DateTime.getYearsExpiry().map(option => (
                                                <option key={option.id} value={option.value}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-4">
                                        <TextField
                                            className="input"
                                            id="select-currency-native"
                                            select
                                            label="Month"
                                            value={this.state.passportExpiryMonth}
                                            onChange={this.handleChange('passportExpiryMonth')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: "",
                                                },
                                            }}
                                            required={(this.state.passportExpiryMonth === undefined) || (this.state.passportExpiryMonth === null) || this.state.passportExpiryMonth === -1 || this.state.passportExpiryMonth === "-1"}
                                            error={(this.state.passportExpiryMonth === undefined) || (this.state.passportExpiryMonth === null) || this.state.passportExpiryMonth === -1 || this.state.passportExpiryMonth === "-1"}
                                            margin="normal"
                                        >
                                            <option value={-1}/>
                                            {DateTime.getMonths().map(option => (
                                                <option key={option.id} value={option.value}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="col-4">
                                        <TextField
                                            className="input"
                                            id="select-currency-native"
                                            select
                                            label="Date"
                                            value={this.state.passportExpiryDate}
                                            onChange={this.handleChange('passportExpiryDate')}
                                            SelectProps={{
                                                native: true,
                                                MenuProps: {
                                                    className: "",
                                                },
                                            }}
                                            required={!this.state.passportExpiryDate || this.state.passportExpiryDate === -1 || this.state.passportExpiryDate === "-1"}
                                            error={!this.state.passportExpiryDate || this.state.passportExpiryDate === -1 || this.state.passportExpiryDate === "-1"}
                                            margin="normal"
                                        >
                                            <option value={-1}/>
                                            {DateTime.getDates().map(option => (
                                                <option key={option.id} value={option.value}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12 col-sm-3">
                            <div className="content-title">
                                <label htmlFor="other">Passport issuing place</label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-5">
                            <div className="content-value">
                                <TextField
                                    id="select-currency-native"
                                    select
                                    className="input"
                                    label="Passport issuing place"
                                    value={this.state.passportPlaceIssued}
                                    onChange={this.handleChange('passportPlaceIssued')}
                                    SelectProps={{
                                        native: true,
                                        MenuProps: {
                                            className: "",
                                        },
                                    }}
                                    required={!this.state.passportPlaceIssued || this.state.passportPlaceIssued === -1 || this.state.passportPlaceIssued === "-1"}
                                    error={!this.state.passportPlaceIssued || this.state.passportPlaceIssued === -1 || this.state.passportPlaceIssued === "-1"}
                                    margin="normal"
                                >
                                    <option value={-1}/>
                                    {this.passportCountries.map(option => (
                                        <option key={option.code} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                    </div>

                    <div className="row content">
                        <div className="col-12 col-sm-3">
                            <div className="content-title">
                                <label htmlFor="other">Passport image upload</label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-9">
                            <div className="content-value">

                                <div>
                                    <div className="text-danger">
                                        <p>Required to have more than seven months' validity remaining on your passport.</p>
                                    </div>
                                    <div>
                                        <input
                                            accept="image/*"
                                            multiple={false}
                                            required={true}
                                            placeholder="Please upload image"
                                            onChange={this.handleChange("passportImage")}
                                            className="upload-image"
                                            type="file"
                                            name="fileToUpload"
                                            ref="fileInput"
                                            id="fileToUpload"/>
                                    </div>
                                    <div>
                                        Required size: 300Kb ~ 4Mb
                                    </div>
                                    {this.state.passportImage && Validation.validateUploadFileTripleSize(this.state.passportImage) !== 0 &&
                                    <div className="text-danger">
                                        File size is not valid
                                        (currently {Math.round(this.state.passportImage.size / 1024)} Kb)
                                    </div>
                                    }
                                    {this.state.passportImage && Validation.validateUploadFileType(this.state.passportImage) !== 0 &&
                                    <div className="text-danger">
                                        File type is not valid
                                    </div>
                                    }
                                </div>
                                
                                <div className="content-value mt-2">
                                    <Button
                                        onClick={(e) => this.handleSubmitForm(e)}
                                        disabled={disableUpdate}
                                        variant="contained"
                                        color="primary">
                                        {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS && (
                                            <CircularProgress className="margin-right-20px" size={20}/>
                                        )}
                                        <span>Update Passport</span>
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
                : this.renderTripleForm(translate)}
            </ContentPanel>
        )
    }

    renderPassportInstructionDialog() {
        return (
            <Dialog title="Certification image sample" visible={true}
                    onClose={() => {
                        this.setState({isShowPassportInstruction: false})
                    }}>
                <div className="passport-dialog">
                    <div className="passport-title">
                        <p>Please note the following points for the proof image to be submitted:</p>
                    </div>
                    <div className="passport-instruction">
                        <div className="passport-send-file">
                            <p>Send possible file: PDF, JPG, PNG</p>
                            <p>It is clearer to scan and send it as PDF</p>
                        </div>
                        <div className="passport-certificate-type">
                            <label htmlFor="CertificateType">
                                Certificate type
                            </label>
                            <span>Passport(The expiration date)</span>
                        </div>
                        <div className="passport-image">
                            <label htmlFor="NecessaryImage">Necessary image: </label>
                            <span> Page with the photograph of the face</span>
                        </div>
                        <div className="passport-note">
                            <p>Do not see a letter; when move slightly, and cannot confirm it, there is a case to ask
                                for a resubmission.</p>
                        </div>
                    </div>
                    <div className="passport-image-detail">
                        <div className="image-item">
                            <img style={{width: "100%"}} src="../../../../assets/images/passport.png" alt="Passport"/>
                        </div>
                        <div className="passport-image-right">
                            <ul>
                                <li>The whole of the passport entering</li>
                                <li>That letters are clearly visible</li>
                                <li>The thing which it reflects it by light, and a part does not look like is invalid
                                </li>
                                <li>The expiration date has not expired</li>
                                <li> Passport notation according with application contents</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }

    render() {
        return (
            <Translate>
                {({translate}) => (
                    <RegisterForm>
                        <div className="passport-update-again">
                            {this.renderCertificateInfo(translate)}
                            {this.state.isShowPassportInstruction && this.renderPassportInstructionDialog(translate)}

                            {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_FAIL && !this.state.alertAck && (
                                <Alert title="Error"
                                       content={translate("errors." + this.props.stepData.statusInfo.error.code)}
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}
                            {this.props.stepData.status === STATUS.UPDATE_INFO.UPDATE_INFO_SUCCESS && !this.state.alertAck && (
                                <Alert title="Success"
                                       content="Update passport success. Thank for your support"
                                       okTitle="OK"
                                       onOK={() => {
                                           this.setState({alertAck: true});
                                       }}/>
                            )}

                        </div>
                    </RegisterForm>
                )}
            </Translate>
        );
    }
}

const mapStateToProps = state => {
    return {
        stepData: state.registration.stepData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePassport: data => {
            dispatch(updatePassportAgain(data));
        },
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NewPassport)
);
