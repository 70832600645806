import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getStatisticNumberCard,
    resetUpdatedStatus,
    handleExportStatisticCSV,
    ACTIONS
} from "../../../../actions/adminActions";
import {
    Table,
    Button,
} from "../../../../components/antd";
import { Translate } from "react-localize-redux";
import "./style.css";
import {PERMISSION} from "../../../../utils/commonUtils";

class StatisticNumberApplication extends Component {
    constructor(props) {
        super(props);
        this._columns = [
            {
                title: "",
                align: "center",
                width: 100,
                key: "label",
                className: "again",
                dataIndex: "label"
            },
            {
                title: "1",
                width: 60,
                align: "center",
                key: "pending",
                dataIndex: "pending"
            },
            {
                title: "2",
                width: 60,
                align: "center",
                key: "done",
                dataIndex: "done"
            },
            {
                key: "reconfirm",
                width: 60,
                align: "center",
                title: "3-0",
                dataIndex: "reconfirm"
            },
            {
                key: "reconfirmed",
                width: 60,
                align: "center",
                title: "3-1",
                dataIndex: "reconfirmed"
            },
            {
                align: "center",
                width: 60,
                key: "checked",
                title:"4",
                dataIndex: "checked"
            },
            {
                align: "center",
                width: 60,
                key: "printed",
                title: "5-0",
                dataIndex: "printed"
            },
            {
                align: "center",
                width: 60,
                key: "reserve",
                title: "5-1",
                dataIndex: "reserve"
            },
            {
                key: "input",
                align: "center",
                width: 60,
                title: "6",
                dataIndex: "input"
            },
            {
                key: "sent",
                align: "center",
                width: 60,
                title: "7-0",
                dataIndex: "sent"
            },
            {
                key: "confirming",
                width: 60,
                align: "center",
                title: "7-1",
                dataIndex: "confirming"
            },
            {
                key: "confirmed",
                width: 60,
                align: "center",
                title: "8",
                dataIndex: "confirmed"
            },
            {
                key: "confirmed_preparing_shipment",
                width: 60,
                align: "center",
                title: "8-1",
                dataIndex: "confirmed_preparing_shipment"
            },
            {
                key: "confirmed_return",
                width: 60,
                align: "center",
                title: "8-2",
                dataIndex: "confirmed_return"
            },
            {
                key: "confirmed_preparing_reshipment",
                align: "center",
                width: 60,
                title: "8-3",
                dataIndex: "confirmed_preparing_reshipment"
            },
            {
                key: "completed",
                align: "center",
                width: 60,
                title: "9",
                dataIndex: "completed"
            },
            {
                key: "card_valid",
                align: "center",
                width: 60,
                title: "10",
                dataIndex: "card_valid"
            },
            {
                key: "",
                align: "center",
                width: 60,
                title: "完了",
                dataIndex: ""
            },
            {
                key: "",
                align: "center",
                width: 60,
                title: "合計",
                dataIndex: "sum"
            }
        ];
    }

    componentDidMount() {
        this.props.getStatisticNumberCard();
    };

    render() {
        const {
            applicationStore: {
                submitting,
                listPermission,
                listStatisticNumberCard
            }
        } = this.props;

        let columns = this._columns;
        return (
            <Translate>
                {({ translate }) => (
                    <>
                        <div className="statistic-number">
                            <p className="title">申込者数</p>
                            {listPermission.includes(PERMISSION.EXPORT_EXCEL_STATISTIC_NUMBER_APPLICATION) &&
                                <div className="btn-export-csv">
                                    <Button
                                        loading={submitting === ACTIONS.EXPORT_STATISTIC_NUMBER_APPLICATION_REQUEST}
                                        type="primary"
                                        size="small"
                                        disabled={submitting === ACTIONS.EXPORT_STATISTIC_NUMBER_APPLICATION_REQUEST}
                                        onClick={() => this.props.handleExportStatisticCSV()}
                                    >
                                        EXPORT CSV
                                    </Button>
                                </div>}
                            <Table
                                loading={submitting === ACTIONS.GET_STATISTIC_NUMBER_CARD_REQUEST}
                                rowKey={(row, index) => index}
                                columns={columns}
                                dataSource={listStatisticNumberCard.result}
                                scroll={{ x: 1500, y: "calc(100vh)" }}
                            />
                        </div>
                    </>
                )}
            </Translate>
        );
    }
}

const mapStateToProps = state => {
    return {
        applicationStore: state.admin,
        cardStore: state.cardManagement
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getStatisticNumberCard: () => {
            dispatch(getStatisticNumberCard());
        },
        handleExportStatisticCSV: () => {
            dispatch(handleExportStatisticCSV());
        },
        resetUpdatedStatus: () => {
            dispatch(resetUpdatedStatus());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticNumberApplication);
