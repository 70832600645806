import {callApi, saveUserRegisterToken} from '../utils/apiUtils';
import {TYPE_SEND_MAIL} from "../utils/commonUtils";

const ACTIONS = {
    REG_UPDATE_STATUS: "REG_UPDATE_STATUS",
};

const STATUS = {
    STEP1: {
        TOKEN_VERIFY_PROGRESS: "TOKEN_VERIFY_PROGRESS",
        TOKEN_VERIFY_SUCCESS: "TOKEN_VERIFY_SUCCESS",
        TOKEN_VERIFY_FAILED: "TOKEN_VERIFY_FAILED",
        INIT: "INIT",
        ACKNOWLEDGE: "ACKNOWLEDGE",
    },
    STEP2: {
        CORRECT_REGISTER: "CORRECT_REGISTER",
        REGISTERING: "REGISTERING",
        REGISTER_SUCCESS: "REGISTER_SUCCESS",
        REGISTER_FAILED: "REGISTER_FAILED",
        VERIFYING: "VERIFYING",
        VERIFY_SUCCESS: "VERIFY_SUCCESS",
        VERIFY_FAILED: "VERIFY_FAILED",
    },
    STEP3: {
        MODIFY: "MODIFY",
        UPDATING: "UPDATING",
        UPDATED: "UPDATED",
        UPDATE_FAILED: "UPDATE_FAILED",
        GET_USER_SUCCESS: "GET_USER_SUCCESS",
        GET_USER_FAILED: "GET_USER_FAILED"
    },
    STEP4: {
        SEND_MAIL_PROGRESS: "SEND_MAIL_PROGRESS",
        SEND_MAIL_SUCCESS: "SEND_MAIL_SUCCESS",
        SEND_MAIL_FAILED: "SEND_MAIL_FAILED",
        INIT_PAYMENT: "INIT_PAYMENT",
        LOGIN_PROGRESS: "LOGIN_PROGRESS",
        LOGIN_FAILED: "LOGIN_FAILED",
        LOGIN_SUCCESS: "LOGIN_SUCCESS",
        FETCH_WALLETS: "FETCH_WALLETS",
        FETCH_COMPLETED: "FETCH_COMPLETED",
        FETCH_FAILED: "FETCH_FAILED",
        PAYMENT_PROGRESS: "PAYMENT_PROGRESS",
        PAYMENT_FINISHED: "PAYMENT_FINISHED",
        PAYMENT_FAILED: "PAYMENT_FAILED",
        CHECK_TOKEN_PROGRESS: "CHECK_TOKEN_PROGRESS",
        CHECK_TOKEN_SUCCESS: "CHECK_TOKEN_SUCCESS",
        CHECK_TOKEN_FAILED: "CHECK_TOKEN_FAILED",
    },
    STEP5: {},
    UPDATE_INFO: {
        UPDATE_INFO_PROGRESS: "UPDATE_INFO_PROGRESS",
        UPDATE_INFO_SUCCESS: "UPDATE_INFO_SUCCESS",
        UPDATE_INFO_FAIL: "UPDATE_INFO_FAIL",
    }
};

const updateStatus = (status, statusInfo) => {
    return {
        type: ACTIONS.REG_UPDATE_STATUS,
        data: {
            status,
            statusInfo
        }
    }
};

const init = (data) => {
    return updateStatus(STATUS.STEP1.INIT, data);
};

const verifyToken = (data, callBack = () => null) => {
    let config = {
        method: 'post',
        body: {businessToken: data.businessToken, businessId: data.businessId}
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.STEP1.TOKEN_VERIFY_PROGRESS));
        callApi('/api/check_business_token',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STEP1.TOKEN_VERIFY_SUCCESS, data));
                callBack(STATUS.STEP1.TOKEN_VERIFY_SUCCESS, data);
            },
            (error) => {
                dispatch(updateStatus(STATUS.STEP1.TOKEN_VERIFY_FAILED, error));
                callBack(STATUS.STEP1.TOKEN_VERIFY_FAILED, error);
            }
        );
    };

};

const updateWrongUserInfo = (data) => {
    let formData = new FormData();
    formData.append("applicationId", data.applicationId);
    formData.append("type", data.type);
    switch (data.type) {
        case TYPE_SEND_MAIL.FUZZY_PASSPORT:
        case TYPE_SEND_MAIL.FUZZY_SELFIE:
        case TYPE_SEND_MAIL.WRONG_ADDRESS_DOC:
            formData.append("passportImage", data.passportImage);
            break;
        case TYPE_SEND_MAIL.WRONG_FULL_NAME:
            formData.append("firstname", data.firstname.trim());
            formData.append("lastname", data.lastname.trim());
            break;
        case TYPE_SEND_MAIL.WRONG_PHONE_NUMBER:
            formData.append("phoneNumber", data.phoneNumber);
            break;
        default:
            break;
    }
    let config = {
        method: 'post',
        body: formData,
        isFormData: true,
        isUserRegisterAuthorization: true,
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS));
        callApi('/api/update_wrong_info',
            config,
            null,
            (result) => {
                dispatch(updateStatus(STATUS.UPDATE_INFO.UPDATE_INFO_SUCCESS, result));
            },
            (err) => {
                dispatch(updateStatus(STATUS.UPDATE_INFO.UPDATE_INFO_FAIL, {error: err}));
            }
        );
    };
};

const updatePassportAgain = (data) => {
    let formData = new FormData();
    formData.append("applicationId", data.applicationId);
    formData.append("type", data.type);
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("passportImage", data.passportImage);
    formData.append("passportNumber", data.passportNumber);
    formData.append("passportIssue", data.passportIssueYear + '-' + data.passportIssueMonth + '-' + data.passportIssueDate);
    formData.append("passportExpired", data.passportExpiryYear + '-' + data.passportExpiryMonth + '-' + data.passportExpiryDate);
    formData.append("passportPlaceIssued", data.passportPlaceIssued);

    let config = {
        method: 'post',
        body: formData,
        isFormData: true,
        isUserRegisterAuthorization: true,
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.UPDATE_INFO.UPDATE_INFO_PROGRESS));
        callApi('/api/update_passport',
            config,
            null,
            (result) => {
                dispatch(updateStatus(STATUS.UPDATE_INFO.UPDATE_INFO_SUCCESS, result));
            },
            (err) => {
                dispatch(updateStatus(STATUS.UPDATE_INFO.UPDATE_INFO_FAIL, {error: err}));
            }
        );
    };
};

const acknowledge = () => {
    return updateStatus(STATUS.STEP1.ACKNOWLEDGE);
};

const correctRegister = () => {
    return updateStatus(STATUS.STEP2.CORRECT_REGISTER)
};

const register = (data) => {

    let config = {
        method: 'post',
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.STEP2.REGISTERING));
        callApi('/api/send_verify_mail',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STEP2.REGISTER_SUCCESS, data));
            },
            (err) => {
                dispatch(updateStatus(STATUS.STEP2.REGISTER_FAILED, {error: err}));
            }
        );
    };
};

const verify = (data) => {

    let config = {
        method: 'post',
        body: {email: data.email, code: data.code}
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.STEP2.VERIFYING));
        callApi('/api/verify_mail',
            config,
            null,
            (data) => {
                saveUserRegisterToken({token: data.token});
                dispatch(updateStatus(STATUS.STEP2.VERIFY_SUCCESS, data));
            },
            (err) => {
                dispatch(updateStatus(STATUS.STEP2.VERIFY_FAILED, {error: err}));
            }
        );
    };
};

const updateUserInfo = (data) => {

    let formData = new FormData();
    formData.append("applicationId", data.applicationId || "");
    formData.append("businessToken", data.businessToken);
    formData.append("businessId", data.businessId);
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("gender", data.maleGender === "male");
    formData.append("birthday", data.birthYear + '-' + data.birthMonth + '-' + data.birthDate);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("profession", data.profession);
    formData.append("country", data.country);
    formData.append("prefecture", data.prefecture.trim());
    formData.append("city", data.city.trim());
    formData.append("postCode", String(data.postCode));
    formData.append("afterAddress", data.afterAddress.trim());
    formData.append("buildingName", data.buildingName.trim());
    formData.append("passportNumber", data.passportNumber.trim());
    formData.append("passportIssue", data.passportIssueYear + '-' + data.passportIssueMonth + '-' + data.passportIssueDate);
    formData.append("passportExpired", data.passportExpiryYear + '-' + data.passportExpiryMonth + '-' + data.passportExpiryDate);
    formData.append("passportPlaceIssued", data.passportPlaceIssued);
    formData.append("passwordCard", data.passwordCard);
    formData.append("passportImage", data.passportImage);
    formData.append("cards", JSON.stringify(data.cards));
    let config = {
        method: 'post',
        body: formData,
        isFormData: true,
        isUserRegisterAuthorization: true,
    };
    return (dispatch) => {
        dispatch(updateStatus(STATUS.STEP3.UPDATING, data));
        callApi('/api/update_infor_user',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STEP3.UPDATED, data));
            },
            (err) => {
                dispatch(updateStatus(STATUS.STEP3.UPDATE_FAILED, {error: err}));
            }
        );
    };
};

const getUserInfo = (data) => {
    let config = {
        method: 'post',
        isUserRegisterAuthorization: true,
        body: {
            _id: data.applicationInfoId
        }
    };

    return (dispatch) => {
        callApi('/api/get_infor_of_user',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STEP3.GET_USER_SUCCESS, data));
            },
            (err) => {
                dispatch(updateStatus(STATUS.STEP3.GET_USER_FAILED, {error: err}));
            }
        );
    };
};

const modifyInfo = () => {
    return updateStatus(STATUS.STEP3.MODIFY);
};

const sendInstructionEmail = (data) => {
    let config = {
        method: 'post',
        isUserRegisterAuthorization: true,
        body: {
            _id: data._id
        }
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.STEP4.SEND_MAIL_PROGRESS));
        callApi('/api/send_instruction_mail',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STEP4.SEND_MAIL_SUCCESS, data));
            },
            (err) => {
                dispatch(updateStatus(STATUS.STEP4.SEND_MAIL_FAILED, {error: err}));
            }
        );
    };
};


const initPayment = () => {
    return updateStatus(STATUS.STEP4.INIT_PAYMENT)
};

const loginArmAccount = (data, callBack = () => null) => {
    let config = {
        method: 'post',
        body: {
            armId: data.armId,
            password: data.password
        },
        isUserRegisterAuthorization: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.STEP4.LOGIN_PROGRESS, data));
        callApi('/api/login_arm_account',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STEP4.LOGIN_SUCCESS, data));
                callBack(STATUS.STEP4.LOGIN_SUCCESS);
            },
            (err) => {
                dispatch(updateStatus(STATUS.STEP4.LOGIN_FAILED, {error: err}));
            }
        );
    };
};

const fetchWallets = (options) => {
    let config = {
        method: 'post',
        body: {
            armToken: options.armToken,
            _id: options.applicationId
        },
        isUserRegisterAuthorization: true
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.STEP4.FETCH_WALLETS));
        callApi('/api/get_price_cards',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STEP4.FETCH_COMPLETED, data));
            },
            (err) => {
                dispatch(updateStatus(STATUS.STEP4.FETCH_FAILED, {error: err}));
            }
        );
    };
};

const paymentCard = (options) => {
    let config = {
        method: 'post',
        body: {
            armToken: options.armToken,
            applicationInfoId: options.applicationInfoId,
            walletId: options.walletId,
            code: options.code,
            coinType: options.coinType
        },
        isUserRegisterAuthorization: true
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.STEP4.PAYMENT_PROGRESS));
        callApi('/api/payment_cards',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STEP4.PAYMENT_FINISHED, data));
            },
            (err) => {
                dispatch(updateStatus(STATUS.STEP4.PAYMENT_FAILED, {error: err}));
            }
        );
    };
};

const checkTokenPayment = (data) => {
    let config = {
        method: 'post',
        body: {token: data}
    };

    return (dispatch) => {
        dispatch(updateStatus(STATUS.STEP4.CHECK_TOKEN_PROGRESS));
        callApi('/api/check_token_payment',
            config,
            null,
            (data) => {
                dispatch(updateStatus(STATUS.STEP4.CHECK_TOKEN_SUCCESS, data));
            },
            (err) => {
                dispatch(updateStatus(STATUS.STEP4.CHECK_TOKEN_FAILED, {error: err}));
            }
        );
    };

};

export {
    ACTIONS, STATUS,
    init, acknowledge,
    register, correctRegister, verify,
    updateUserInfo, modifyInfo,
    getUserInfo, sendInstructionEmail,
    initPayment, loginArmAccount, fetchWallets, paymentCard,
    verifyToken, checkTokenPayment, updateWrongUserInfo, updatePassportAgain
};
